import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Image, Item, List, Popup } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { clearSelectedEvent } from "../../events/eventReducer";
import { FOLLOW_FEST, UNFOLLOW_FEST } from "../../college/collegeFestReducer";
import { handeleFollowFestApi } from "../../../app/apiRequest/collegeFestService";
import UnauthModal from "../../auth/UnauthModal";

export default function FestivalHeader({ fest, isAdmin, isFollower }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { selectedFestIsFollowed } = useSelector((state) => state.college);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isFollower) dispatch({ type: FOLLOW_FEST });
    else dispatch({ type: UNFOLLOW_FEST });
  }, [isFollower, dispatch]);

  const handleCreateEvent = () => {
    dispatch(clearSelectedEvent());
    history.push(`/event/create/details?festival=${fest?._id}`);
  };

  const handleFollow = () => {
    if (!isAuthenticated) {
      setModalOpen(true);
    } else if (selectedFestIsFollowed) {
      dispatch({ type: UNFOLLOW_FEST });
      dispatch(handeleFollowFestApi(fest, "unfollow"));
    } else {
      dispatch({ type: FOLLOW_FEST });
      dispatch(handeleFollowFestApi(fest, "follow"));
    }
  };

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      <Button
        icon="arrow left"
        floated="left"
        style={{ top: 78, position: "fixed", color: "blue" }}
        onClick={() => history.goBack()}
      />
      <div style={{ marginLeft: 50, marginRight: 50 }}>
        <Item.Group>
          <Item>
            <Item.Content>
              <Image
                src={fest.festImg}
                size="medium"
                floated="right"
                style={{ marginTop: 16 }}
              />
              <Item.Meta as="a" style={{ color: "blue", display: "block" }}>
                {fest.college.collegeName}
              </Item.Meta>
              <Item.Header>{fest.festName}</Item.Header>
              <Item.Description>
                <p style={{ width: 600 }}>{fest.description}</p>
                <List horizontal relaxed style={{ display: "block" }}>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="users" /> {fest.followedBy.length}{" "}
                      <strong>followers</strong>
                    </List.Content>
                  </List.Item>
                </List>
                <List horizontal relaxed style={{ display: "block" }}>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="calendar alternate outline" />
                      {new Date(fest.startDate).toLocaleDateString()} -{" "}
                      {new Date(fest.endDate).toLocaleDateString()}
                    </List.Content>
                  </List.Item>
                </List>
                <List horizontal relaxed style={{ display: "block" }}>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="linkify" />{" "}
                      <a href={fest.website}>{fest.website}</a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="mail" />{" "}
                      <a href={`mailto:${fest.email}`}>{fest.email}</a>
                    </List.Content>
                  </List.Item>
                </List>
                {fest.instagram && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="instagram" />{" "}
                        <a href={fest.instagram} target="#blank">
                          {fest.instagram}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                {fest.linkedIn && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkedin" />{" "}
                        <a href={fest.linkedIn} target="#blank">
                          {fest.linkedIn}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
              </Item.Description>
              <Item.Extra>
                {isAdmin ? (
                  <div>
                    <Button
                      content="Create Event"
                      color="blue"
                      icon="add"
                      style={{ marginTop: 30 }}
                      onClick={handleCreateEvent}
                    />
                    <Popup
                      trigger={
                        <Button
                          color="blue"
                          icon="ellipsis vertical"
                          style={{ marginTop: 30 }}
                          onClick={() => setOpenPopup(true)}
                        />
                      }
                      content={
                        <>
                          <Button
                            fluid
                            name="manageAdmin"
                            icon="setting"
                            content="Manage Admin"
                            style={{ display: "block", marginBottom: 5 }}
                            onClick={() => {
                              dispatch(
                                openModal({
                                  modalType: "ManageAdmin",
                                  modalProps: {
                                    admin: fest.admin,
                                    type: "festival",
                                    selected: fest,
                                  },
                                })
                              );
                              setOpenPopup(false);
                            }}
                          />
                          <Button
                            name="editPage"
                            fluid
                            icon="edit"
                            content="Edit Page"
                            style={{ display: "block" }}
                            onClick={() => {
                              dispatch(
                                openModal({
                                  modalType: "FestivalForm",
                                  modalProps: { fest: fest._id },
                                })
                              );
                              setOpenPopup(false);
                            }}
                          />
                        </>
                      }
                      on="click"
                      position="bottom left"
                      open={openPopup}
                      onClose={() => setOpenPopup(false)}
                      onOpen={() => setOpenPopup(true)}
                    />
                  </div>
                ) : (
                  <div>
                    <Button
                      color="blue"
                      content={selectedFestIsFollowed ? "Following" : "Follow"}
                      icon={selectedFestIsFollowed ? "bell" : "bell outline"}
                      style={{ marginTop: 30 }}
                      onClick={handleFollow}
                    />
                    <Button
                      color="blue"
                      icon="eye"
                      content="View Admin"
                      style={{ marginTop: 30 }}
                      onClick={() => {
                        dispatch(
                          openModal({
                            modalType: "ViewAdminModal",
                            modalProps: {
                              admin: fest.admin,
                            },
                          })
                        );
                      }}
                    />
                    <Button
                      color="blue"
                      icon="exclamation triangle"
                      style={{ marginTop: 30 }}
                      onClick={() => {
                        if (!isAuthenticated) {
                          setModalOpen(true);
                        } else {
                          dispatch(
                            openModal({
                              modalType: "ReportForm",
                              modalProps: {
                                type: "festival",
                                id: fest._id,
                              },
                            })
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </div>
    </>
  );
}
