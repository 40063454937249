import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";
import {
  addFiles,
  filesFailed,
  filesLoading,
  manageFile,
  unreadFile,
  uploadFile,
} from "../../features/club/clubDetailed/files/clubFilesReducer";
import {
  addSessions,
  createSession,
  deleteSession,
  sessionsFailed,
  sessionsLoading,
  updateSession,
} from "../../features/club/clubDetailed/sessions/clubSessionReducer";
import {
  addChats,
  discussionsFailed,
  discussionsLoading,
  unreadDiscussionApi,
} from "../../features/club/clubDetailed/discussion/discussionReducer";
import {
  fetchSessionReminder,
  todaysSessions,
} from "../../features/reminder/reminderReducer";
import { addgroupChats } from "../../features/club/clubDetailed/discussion/groupDiscussionReducer";
import {
  listenToSelectedChannel,
  listenToSelectedChannelMembers,
} from "../../features/club/clubReducer";

export const createChannelApi = (channel) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "channel", {
    method: "POST",
    body: JSON.stringify(channel),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      window.location.reload();
    })
    .catch((error) => {
      console.log("Create channel ", error.message);
    });
};

export const deleteChannelApi = (channelId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "channel/" + channelId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("channel Deleted", response);
      window.location.reload();
    })
    .catch((error) => console.log(error.message));
};

export const updateChannelApi = (channel) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "channel/" + channel._id, {
    method: "PUT",
    body: JSON.stringify(channel),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => window.location.reload())
    .catch((error) => {
      console.log("channel updated ", error.message);
      alert("Your channel could not be updated\nError: " + error.message);
    });
};

export const fetchSingleChannelApi = (channelId) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "channel/" + channelId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedChannel(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError(error.message));
    });
};

export const fetchChannelChatsApi = (channelId, query) => (dispatch) => {
  dispatch(discussionsLoading());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      channelId +
      "/discussions" +
      query,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (query.includes("[$exists]=false")) {
        dispatch(
          addChats(response.chats, response.moreChats, response.lastDocId)
        );
      } else {
        dispatch(
          addgroupChats(response.chats, response.moreChats, response.lastDocId)
        );
      }
      dispatch(markReadDiscussionsApi(channelId));
    })
    .catch((error) => dispatch(discussionsFailed(error.message)));
};

export const sendChannelChatApi = (chat) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      chat.channel +
      "/discussions",
    {
      method: "POST",
      body: JSON.stringify(chat),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const countUnreadDiscussionsApi = (channelId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + channelId + "/chatCount",
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const markReadDiscussionsApi = (channelId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + channelId + "/discussions",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(unreadDiscussionApi(0));
    })
    .catch((error) => {
      console.log("Notification updated ", error.message);
    });
};

export const fetchChannelFilesApi = (channelId, query) => (dispatch) => {
  dispatch(filesLoading());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      channelId +
      "/files" +
      query,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(
        addFiles(response.files, response.moreFiles, response.lastDocId)
      );
      dispatch(markReadFilesApi(channelId));
    })
    .catch((error) => dispatch(filesFailed(error.message)));
};

export const uploadFilesToChannelApi = (file) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + file.channel + "/files",
    {
      method: "POST",
      body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(uploadFile(response)))
    .catch((error) => {
      console.log("Create file ", error.message);
      alert("Your file could not be uploaded\nError: " + error.message);
    });
};

export const manageFilesApi = (file) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      file.channel +
      "/files/" +
      file._id,
    {
      method: "PUT",
      body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(manageFile(response)))
    .catch((error) => {
      console.log("file updated ", error.message);
      alert("Your file could not be updated\nError: " + error.message);
    });
};

export const countUnreadFilesApi = (channelId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + channelId + "/filesCount",
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const markReadFilesApi = (channelId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + channelId + "/files",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(unreadFile(0));
    })
    .catch((error) => {
      console.log("files unread updated ", error.message);
    });
};

export const fetchChannelSessionsApi = (channelId, query) => (dispatch) => {
  dispatch(sessionsLoading());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      channelId +
      "/session" +
      query,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (query.includes("startTime[$lte]")) {
        console.log(response.length);
        dispatch(todaysSessions(response.length));
      } else if (query.includes("startTime[$gte]")) {
        dispatch(fetchSessionReminder(response));
      } else {
        var upcomingSessions = [];
        var recentlySessions = [];
        for (let session of response) {
          if (new Date(session.startTime) > new Date()) {
            upcomingSessions.push(session);
          } else {
            recentlySessions.push(session);
          }
        }
        dispatch(addSessions(upcomingSessions, recentlySessions));
      }
    })
    .catch((error) => dispatch(sessionsFailed(error.message)));
};

export const createSessionApi = (session, channel) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "clubs/" + channel._id + "/session",
    {
      method: "POST",
      body: JSON.stringify(session),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(createSession(response));
    })
    .catch((error) => {
      console.log("Session club ", error.message);
      alert("Your session could not be created\nError: " + error.message);
    });
};

export const updateSessionApi = (session, channel) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      channel._id +
      "/session/" +
      session._id,
    {
      method: "PUT",
      body: JSON.stringify(session),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(updateSession(response));
    })
    .catch((error) => {
      console.log("Session updated ", error.message);
      alert("Your session could not be updated\nError: " + error.message);
    });
};

export const deleteSessionApi = (session) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "clubs/" +
      session.channel +
      "/session/" +
      session._id,
    {
      method: "DELETE",
      headers: {
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(deleteSession(response._id));
    })
    .catch((error) => console.log(error.message));
};

export const fetchChannelMembersApi = (channelId) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "channels/" + channelId + "/members",
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedChannelMembers(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const manageMembersApi = (member) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "channels/" +
      member.channel +
      "/members/" +
      member._id,
    {
      method: "PUT",
      body: JSON.stringify(member),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("Member updated ", error.message);
      alert("Your member could not be updated\nError: " + error.message);
    });
};

export const addMemberToChannelApi = (member) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "channels/" +
      member.channel +
      "/members",
    {
      method: "POST",
      body: JSON.stringify(member),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const SingleChannelMemberApi = (channelId, userId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "channels/" +
      channelId +
      "/members/" +
      userId,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const deleteChannelMemberApi = (channelId, memberId) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "channels/" +
      channelId +
      "/members/" +
      memberId,
    {
      method: "DELETE",
      headers: {
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("removed!", response);
    })
    .catch((error) => console.log(error.message));
};
