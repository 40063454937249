import React, { useState } from "react";
import { Icon, Button, Feed, FeedContent, Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import displayLinks from "../../../app/common/text/handleLinkClick";

export default function PostDetailedInfo({ post }) {
  const history = useHistory();
  const [currentSlide, setCurrentSlide] = useState(0);

  var displayname = "user";
  var displayImg = "/assets/user.png";
  var link = "/clubs";
  if (post.user) {
    displayname = post.user?.displayName;
    displayImg = post.user.userImg;
    link = `/profile/${post.user?._id}`;
  } else if (post.club) {
    displayname = post.club.clubName;
    displayImg = post.club.clubImg;
    link = `club/about/${post.club?._id}`;
  } else if (post.fest) {
    displayname = post.fest.festName;
    displayImg = post.fest.festImg;
    link = `club/fest/${post.fest?._id}`;
  }

  var sharedUsername = "user";
  var sharedUserImg =
    "https://react.semantic-ui.com/images/avatar/small/elliot.jpg";
  var sharedUserLink = "/clubs";
  if (post.sharedPost && post.sharedPost.user) {
    sharedUsername = post.sharedPost.user?.displayName;
    sharedUserImg = post.sharedPost.user.userImg;
    sharedUserLink = `/profile/${post.sharedPost.user?._id}`;
  } else if (post.sharedPost && post.sharedPost.club) {
    sharedUsername = post.sharedPost.club.clubName;
    sharedUserImg = post.sharedPost.club.clubImg;
    sharedUserLink = `club/about/${post.sharedPost.club?._id}`;
  } else if (post.sharedPost && post.sharedPost.fest) {
    sharedUsername = post.sharedPost.fest.festName;
    sharedUserImg = post.sharedPost.fest.festImg;
    sharedUserLink = `club/about/${post.sharedPost.club?._id}`;
  }

  const prevSlide = () => {
    console.log(currentSlide);
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const nextSlide = () => {
    console.log(currentSlide);
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  return (
    <div>
      <div className="post-container" style={{ marginTop: 45, maxWidth: 800 }}>
        <div className="post-header">
          <div className="avatar">
            <img src={displayImg} alt="Author Avatar" />
            <div className="author-name">
              <a href={link}>{displayname}</a>
            </div>
          </div>
        </div>
        <div className="post-content">
          <p>{displayLinks(post.text)}</p>
        </div>
        {post.images?.length > 0 && (
          <div className="post-slideshow">
            {post.images?.length === 1 ? (
              <Image
                style={{ maxWidth: 600, maxHeight: 600 }}
                src={post.images[currentSlide]}
                alt={`Slide ${currentSlide + 1}`}
              />
            ) : (
              <Image
                style={{ width: 600, height: 600, objectFit: "cover" }}
                src={post.images[currentSlide]}
                alt={`Slide ${currentSlide + 1}`}
              />
            )}
            {/* <img
            style={{ height: 600, width: 600 }}
            src={post.images[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
          /> */}

            {post.images?.length > 1 && (
              <div className="slide-nav">
                <button
                  className="prev-slide"
                  onClick={prevSlide}
                  style={{
                    visibility: currentSlide === 0 ? "hidden" : "visible",
                  }}
                >
                  &#10094;
                </button>
                <button
                  className="next-slide"
                  onClick={nextSlide}
                  style={{
                    visibility:
                      currentSlide === post.images.length - 1
                        ? "hidden"
                        : "visible",
                  }}
                >
                  &#10095;
                </button>
              </div>
            )}
          </div>
        )}
        {post.videofile && (
          <div className="post-video">
            <video controls>
              <source src={post.videofile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        {post.sharedPost && (
          <div
            className="post-container"
            style={{ maxWidth: 600, marginLeft: 50 }}
          >
            <div className="post-header">
              <div className="avatar">
                <img src={sharedUserImg} alt="Author Avatar" />
                <div className="author-name">
                  <a href={sharedUserLink}>{sharedUsername}</a>
                </div>
                <div style={{ marginLeft: 20 }}>
                  {new Date(post.sharedPost.createdAt).toLocaleString()}
                </div>
              </div>
            </div>
            <div className="post-content">
              <p>{displayLinks(post.sharedPost.text)}</p>
            </div>

            {post.sharedPost.images?.length > 0 && (
              <div className="post-slideshow">
                {post.sharedPost.images?.length === 1 ? (
                  <Image
                    id="slide-image"
                    style={{ maxWidth: 500, maxHeight: 500 }}
                    src={post.sharedPost.images[currentSlide]}
                    alt={`Slide ${currentSlide + 1}`}
                  />
                ) : (
                  <Image
                    style={{ width: 500, height: 500, objectFit: "cover" }}
                    src={post.sharedPost.images[currentSlide]}
                    alt={`Slide ${currentSlide + 1}`}
                  />
                )}
                {/* <img
                className="slide-image"
                src={post.sharedPost.images[currentSlide]}
                alt={`Slide ${currentSlide + 1}`}
              /> */}

                {post.sharedPost.images?.length > 1 && (
                  <div className="slide-nav">
                    <button
                      className="prev-slide"
                      onClick={prevSlide}
                      style={{
                        visibility: currentSlide === 0 ? "hidden" : "visible",
                      }}
                    >
                      &#10094;
                    </button>
                    <button
                      className="next-slide"
                      onClick={nextSlide}
                      style={{
                        visibility:
                          currentSlide === post.images.length - 1
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      &#10095;
                    </button>
                  </div>
                )}
              </div>
            )}
            {post.sharedPost.videofile && (
              <div className="post-video">
                <video controls>
                  <source src={post.sharedPost.videofile} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        )}

        <div className="post-footer">
          <FeedContent>
            <Feed.Like style={{ marginRight: 10 }}>
              <Icon name="like" />
              {post.likes.length} Like
            </Feed.Like>
            <Feed.Like style={{ marginRight: 10 }}>
              <Icon name="chat" />
              {post.commentsCount} Comment
            </Feed.Like>
            <Feed.Like style={{ marginRight: 10 }}>
              <Icon name="share" />
              {post.shares.length} Share
            </Feed.Like>
          </FeedContent>
          <div className="time">
            {new Date(post.createdAt).toLocaleString()}
          </div>
        </div>
      </div>
      <div className="go-back-btn">
        <Button
          content="Post"
          icon="left arrow"
          labelPosition="left"
          onClick={() => history.goBack()}
        />
      </div>
    </div>
  );
}
