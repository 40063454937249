import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, List, Transition } from "semantic-ui-react";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { fetchChannelMembersApi } from "../../../../app/apiRequest/channelService";
import ClubNavMenu from "../ClubNavMenu";
import ChannelMemberItem from "./ChannelMemberItem";
import { listenToSelectedChannel } from "../../clubReducer";
import { openModal } from "../../../../app/common/modals/modalReducer";

export default function ChannelMembers({ match }) {
  const dispatch = useDispatch();
  const { selectedClub, selectedChannel, channelMembers } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.clubId)).then(() => {
      dispatch(fetchChannelMembersApi(match.params.channelId));
    });
  }, [dispatch, match.params.clubId, match.params.channelId]);

  useEffect(() => {
    if (selectedClub) {
      for (const channel of selectedClub.channels) {
        if (channel._id === match.params.channelId) {
          dispatch(listenToSelectedChannel(channel));
        }
      }
    }
  }, [dispatch, selectedClub, match.params.channelId]);

  const [visibleAdmin, setVisibleAdmin] = useState(true);
  const [visibleMember, setVisibleMember] = useState(true);

  const handleToggleVisibility = (e, { name }) => {
    if (name === "visibleAdmin") setVisibleAdmin(!visibleAdmin);
    else if (name === "visibleMember") setVisibleMember(!visibleMember);
  };

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  var admins = [];
  var generalMember = [];

  //eslint-disable-next-line
  {
    channelMembers.length > 0 && //eslint-disable-next-line
      channelMembers.map((member) => {
        if (member.admin) admins.push(member);
        else generalMember.push(member);
      });
  }

  return (
    <div className="club-detailed-page" style={{ paddingBottom: 100 }}>
      <ClubNavMenu club={selectedClub} channelId={match.params.channelId} />
      <Button
        icon="add"
        content="Add member"
        type="button"
        floated="right"
        color="blue"
        style={{ marginRight: 100, marginTop:10 }}
        onClick={() =>
          dispatch(
            openModal({
              modalType: "AddMemberToChannel",
              modalProps: { channel: selectedChannel },
            })
          )
        }
      />
      <div>
        <List style={{ marginTop: 20 }}>
          <List.Item
            name="visibleAdmin"
            icon={visibleAdmin ? "angle down" : "angle right"}
            content={<a>Channel Admin</a>} //eslint-disable-line
            onClick={handleToggleVisibility}
          />
        </List>
        <Transition
          visible={visibleAdmin}
          animation="scale"
          unmountOnHide={true}
          duration={500}
        >
          <div>
            {admins.length > 0 &&
              admins.map((member) => (
                <ChannelMemberItem
                  member={member}
                  key={member._id}
                  adminCount={admins.length}
                />
              ))}
          </div>
        </Transition>
        <List style={{ marginTop: 20 }}>
          <List.Item
            name="visibleMember"
            icon={visibleMember ? "angle down" : "angle right"}
            content={<a>Channel Members</a>} //eslint-disable-line
            onClick={handleToggleVisibility}
          />
        </List>
        <Transition
          visible={visibleMember}
          animation="scale"
          unmountOnHide={true}
          duration={500}
        >
          <div>
            {generalMember.length > 0 &&
              generalMember.map((member) => (
                <ChannelMemberItem
                  member={member}
                  key={member._id}
                  adminCount={admins.length}
                />
              ))}
          </div>
        </Transition>
      </div>
    </div>
  );
}
