import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Container,
  Grid,
  Image,
  Item,
  Segment,
} from "semantic-ui-react";
import MyTextArea from "../../../app/common/form/MyTextArea";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import { getFile, isImage, isVideo } from "../../../app/common/util/util";
import { toast } from "react-toastify";
import { Player, ControlBar } from "video-react";
import { useDispatch, useSelector } from "react-redux";
import SimpleImageSlider from "react-simple-image-slider";
import { clearSelectedPost } from "../postReducer";
import {
  createPostApi,
  updatePostDetailsApi,
} from "../../../app/apiRequest/postService";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import { fetchClubsApi } from "../../../app/apiRequest/clubService";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { compressImg } from "../../../app/common/photos/compressImg";

export default function CreatePostForm() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { selectedPost } = useSelector((state) => state.post);
  const { userId, displayName, displayImg, todo, postBy, postId } = useSelector(
    (state) => state.modal.modalProps
  );
  const { yourClubs } = useSelector((state) => state.club);
  const [imageNo, setImageNo] = useState(-1);
  const [editing, setEditing] = useState(false); // eslint-disable-line no-unused-vars
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);
  const hiddenImageInput = useRef(null);
  const hiddenVideoInput = useRef(null);
  const { loading } = useSelector((state) => state.async);

  useEffect(() => {
    if (todo === "create") {
      dispatch(clearSelectedPost());
      return;
    }
  }, [dispatch, todo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (yourClubs.length > 0) return;
    dispatch(fetchClubsApi(`?members=${user}`));
    // eslint-disable-next-line
  }, [dispatch, user]);

  var initialValues = {
    text: "",
    images: [],
    videofile: null,
  };
  if (todo === "edit" && selectedPost) initialValues = selectedPost;
  const [files, setFiles] = useState([...initialValues.images]);
  var [images, setImages] = useState([...initialValues.images]);
  const [videofile, setVideofile] = useState(initialValues.videofile ?? null);

  if (postBy === "user") {
    initialValues.user = userId;
  } else if (postBy === "club") {
    initialValues.club = userId;
  } else if (postBy === "fest") {
    initialValues.fest = userId;
  }

  const handleClick = (e, { name }) => {
    name === "addImage"
      ? hiddenImageInput.current.click()
      : hiddenVideoInput.current.click();
  };

  const [filename, setFilename] = useState([]);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    if (isImage(file.name) && videofile === null) {
      setFiles((arr) => [...arr, URL.createObjectURL(file)]);
      setFilename((arr) => [...arr, file.name]);
      setImageNo(files.length);
      if (images.length > 0) setAspectRatio(1 / 1);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select only image");
    }
  };

  const [video, setVideo] = useState(null);
  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    console.log(file.size);
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    if (isVideo(file.name) && files.length < 1) {
      setVideofile(URL.createObjectURL(file));
      setVideo(file);
    } else {
      toast.error("select only video");
    }
  };

  var clubsOption = [
    {
      key: "public",
      text: "Public",
      value: null,
      icon: "globe",
    },
  ];
  if (postBy === "club") {
    clubsOption.push({
      key: userId,
      text: displayName,
      value: userId,
      image: { src: displayImg },
    });
  } else {
    yourClubs.length > 0 && // eslint-disable-next-line
      yourClubs.map((club) => {
        clubsOption.push({
          key: club._id,
          text: club.clubName,
          value: club._id,
          image: { src: club.clubImg },
        });
      });
  }

  return (
    <ModalWrapper
      size="tiny"
      header={todo === "edit" ? "Edit Post" : "Create a Post"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (
              (images.length > 0 || videofile) &&
              (todo === "create" || editing)
            ) {
              var data = new FormData();
              if (videofile) data.append("imageFile", video);
              else {
                for (let pic of images) {
                  await compressImg(pic).then(async (compressedDataUrl) => {
                    let file = await getFile(
                      compressedDataUrl,
                      filename[images.indexOf(pic)]
                    );
                    data.append("imageFile", file);
                  });
                }
              }
              dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                if (videofile) values.videofile = response[0].location;
                else
                  values.images = response.map((file) => {
                    return file.location;
                  });
                if (todo === "edit") {
                  dispatch(updatePostDetailsApi(values)).then(() =>
                    dispatch(closeModal())
                  );
                } else {
                  dispatch(createPostApi(values)).then(() =>
                    dispatch(closeModal())
                  );
                }
              });
            } else {
              if (todo === "edit") {
                dispatch(updatePostDetailsApi(values)).then(() =>
                  dispatch(closeModal())
                );
              } else {
                if (postId) values.sharedPost = postId;
                dispatch(createPostApi(values)).then(() =>
                  dispatch(closeModal())
                );
              }
            }
          } catch (error) {
            dispatch(asyncActionError(error));
            console.log(error);
          } finally {
            setSubmitting(false);
            console.log(values);
          }
        }}
      >
        {({ values, isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form" style={{ marginTop: -10 }}>
            <Image avatar spaced="right" src={displayImg} />
            <span>{displayName}</span>
            <MySelectInput
              name="privacy"
              placeholder="Public"
              value={values.privacy?._id}
              options={clubsOption}
              label={"This post will be seen in"}
            />
            <MyTextArea
              name="text"
              placeholder="Write something here..."
              rows="4"
            />
            {videofile && (
              <Container>
                <Player playsInline fluid={false} width="100%" height={380}>
                  <source src={videofile} />
                  <ControlBar autoHide={true} />
                </Player>
              </Container>
            )}
            <Grid padded="vertically">
              <Grid.Row columns={2}>
                {images.length > 0 &&
                  images.map((image) => (
                    <Grid.Column key={image}>
                      {todo === "create" && (
                        <Button
                          type="button"
                          icon="close"
                          floated="left"
                          compact
                          onClick={() => {
                            setImages(images.filter((e) => e !== image));
                            setFiles(
                              files.filter(
                                (e) => e !== files[images.indexOf(image)]
                              )
                            );
                            if (images.length === 2) setAspectRatio(4 / 3);
                          }}
                        />
                      )}
                      <Image
                        centered
                        src={image}
                        style={{
                          height: 242,
                          width: 242,
                          overflow: "hidden",
                          padding: 5,
                          objectFit: images.length > 1 ? "cover" : "contain",
                          background: "#807E7575",
                        }}
                      />
                      {todo === "create" && (
                        <Button
                          type="button"
                          content="Edit"
                          floated="right"
                          onClick={() => {
                            setPhotoCropperOpen(true);
                            setEditing(true);
                            setImageNo(images.indexOf(image));
                            console.log(files[images.indexOf(image)]);
                          }}
                        />
                      )}
                    </Grid.Column>
                  ))}
              </Grid.Row>
            </Grid>
            {todo === "share" && (
              <Segment style={{ marginTop: -20 }} size="tiny">
                <Item.Group>
                  <Item>
                    <Item.Image
                      size="mini"
                      circular
                      src={
                        selectedPost.user?.userImg ?? selectedPost.club?.clubImg
                      }
                      avatar
                      floated="right"
                      as="a"
                    />
                    <Item.Content>
                      <Item.Header>
                        {selectedPost.user
                          ? selectedPost?.user?.displayName
                          : selectedPost.club?.clubName}
                      </Item.Header>
                      <Item.Meta style={{ marginTop: 1 }}>
                        {new Date(selectedPost?.createdAt).toDateString(
                          "MMM dd yyyy"
                        )}
                      </Item.Meta>
                      <Item.Description>{selectedPost?.text}</Item.Description>
                      <Item.Extra>
                        <Container>
                          {selectedPost?.videofile && (
                            <Player
                              playsInline
                              fluid={false}
                              width="100%"
                              height={290}
                            >
                              <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                              <ControlBar autoHide={true} />
                            </Player>
                          )}
                          {selectedPost?.images.length === 1 && (
                            <Image
                              id="slide-image"
                              src={selectedPost?.images[0]}
                              alt="img"
                            />
                          )}
                          {selectedPost?.images.length > 1 && (
                            <SimpleImageSlider
                              width={410}
                              height={410}
                              images={selectedPost?.images}
                              showBullets={true}
                              showNavs={true}
                              navStyle={2}
                              navSize={40}
                              navMargin={0}
                              loop={false}
                            />
                          )}
                        </Container>
                      </Item.Extra>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Segment>
            )}
            <Button
              loading={isSubmitting || loading}
              disabled={
                !isValid ||
                (!dirty && images.length === 0 && !video) ||
                isSubmitting ||
                loading
              }
              type="submit"
              floated="right"
              size="large"
              color="teal"
              content="Post"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </Form>
        )}
      </Formik>
      {todo === "create" && (
        <Button.Group size="small" style={{ marginTop: 10, marginBottom: 10 }}>
          <Button
            name="addImage"
            icon="file image"
            content="Add Images"
            color="green"
            onClick={handleClick}
            disabled={files.length === 4}
          />
          <input
            type="file"
            ref={hiddenImageInput}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <Button
            icon="file video"
            content="Add Video"
            color="blue"
            name="addVideo"
            onClick={handleClick}
          />
          <input
            type="file"
            name="videofile"
            ref={hiddenVideoInput}
            onChange={handleVideoChange}
            style={{ display: "none" }}
          />
        </Button.Group>
      )}
      <PhotoCropper
        photoCropperOpen={photoCropperOpen}
        setPhotoCropperOpen={setPhotoCropperOpen}
        images={images}
        setImages={setImages}
        imagePreview={files[imageNo]}
        imageNo={imageNo}
        editing={editing}
        aspectRatio={aspectRatio}
      />
    </ModalWrapper>
  );
}
