import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PostcommentItem from "./PostCommentItem";
import InfiniteScroll from "react-infinite-scroller";
import { Loader } from "semantic-ui-react";
import { fetchCommentsApi } from "../../../app/apiRequest/postService";
import { clearComments, dispatchSelectedPost } from "../commentReducer";

export default function PostComment({ post }) {
  const dispatch = useDispatch();
  const { selectedPost, comments, moreComments, lastDocId } = useSelector(
    (state) => state.comment
  );
  const { loading } = useSelector((state) => state.async);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if(selectedPost === post._id) return;
    dispatch(clearComments());
    setLoadingInitial(true);
    dispatch(fetchCommentsApi(post._id, "")).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch(dispatchSelectedPost(post._id));
    };
    // eslint-disable-next-line
  }, [dispatch, selectedPost]);

  function handleFetchNextComments() {
    dispatch(fetchCommentsApi(post._id, `?_id[$lt]=${lastDocId}`));
  }

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={handleFetchNextComments}
        hasMore={!loading && moreComments}
        initialLoad={loadingInitial}
      >
        <PostcommentItem comments={comments} post={post} />
      </InfiniteScroll>

      <Loader active={loading || loadingInitial} />
    </>
  );
}
