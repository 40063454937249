const CLEAN_QUERY = "CLEAN_QUERY";
const START_SEARCH = "START_SEARCH";
const FINISH_SEARCH = "FINISH_SEARCH";
const UPDATE_SELECTION = "UPDATE_SELECTION";
const ADD_QUERY = "ADD_QUERY";
const ADD_PROFILE = "ADD_PPROFILE";
const ADD_COLLEGE = "ADD_COLLEGE";
const ADD_CLUB = "ADD_CLUB";
const ADD_EVENT = "ADD_EVENT";
const CLEAN_SEARCH = "CLEAN_SEARCH";

export const SEARCH_RETAIN_STATE = "SEARCH_RETAIN_STATE";

export function cleanSearchQuery() {
  return {
    type: CLEAN_QUERY,
  };
}

export function startSearch(query) {
  return {
    type: START_SEARCH,
    payload: query,
  };
}

export function finishSearch(results) {
  return {
    type: FINISH_SEARCH,
    payload: results,
  };
}

export function updateSelection(selection) {
  return {
    type: UPDATE_SELECTION,
    payload: selection,
  };
}

export function addQueries(queries) {
  return {
    type: ADD_QUERY,
    payload: queries,
  };
}

export function addProfiles(profiles) {
  return {
    type: ADD_PROFILE,
    payload: profiles,
  };
}

export function addColleges(colleges) {
  return {
    type: ADD_COLLEGE,
    payload: colleges,
  };
}

export function addClubsToSearch(clubs) {
  return {
    type: ADD_CLUB,
    payload: clubs,
  };
}

export function addEventsToSearch(events) {
  return {
    type: ADD_EVENT,
    payload: events,
  };
}

export function cleanSearchData() {
  return {
    type: CLEAN_SEARCH,
  };
}

const initialState = {
  loading: false,
  results: [],
  value: "",
  queries: [],
  profiles: [],
  colleges: [],
  clubs: [],
  events: [],
  reatainState: false,
};

export default function searchReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_QUERY:
      return { ...state, loading: false, results: [], value: "" };

    case START_SEARCH:
      return { ...state, loading: true, value: payload };

    case FINISH_SEARCH:
      return { ...state, loading: false, results: payload };

    case UPDATE_SELECTION:
      return { ...state, value: payload };

    case ADD_QUERY:
      return {
        ...state,
        queries: [...payload[0].people, ...payload[0].club, ...payload[0].event, ...payload[0].college],
      };

    case ADD_PROFILE:
      return {
        ...state,
        profiles: payload,
      };

    case ADD_COLLEGE:
      return {
        ...state,
        colleges: payload,
      };

    case ADD_CLUB:
      return {
        ...state,
        clubs: payload,
      };

    case ADD_EVENT:
      return {
        ...state,
        events: payload,
      };

    case SEARCH_RETAIN_STATE:
      return {
        ...state,
        reatainState: true,
      };

    case CLEAN_SEARCH:
      return initialState;

    default:
      return state;
  }
}
