import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { List, Transition } from "semantic-ui-react";
import {
  fetchClubMembersApi,
  fetchSingleClubApi,
} from "../../../../app/apiRequest/clubService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClubMemberItem from "./ClubMemberItem";

export default function ManageClubMembers({ match }) {
  const dispatch = useDispatch();
  const { selectedClub, members } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.id)).then(() => {
      dispatch(fetchClubMembersApi(match.params.id));
    });
  }, [dispatch, match.params.id]);

  const [visibleAdmin, setVisibleAdmin] = useState(true);
  const [visibleMember, setVisibleMember] = useState(true);
  const [visibleRequested, setVisibleRequested] = useState(true);

  const handleToggleVisibility = (e, { name }) => {
    if (name === "visibleAdmin") setVisibleAdmin(!visibleAdmin);
    else if (name === "visibleMember") setVisibleMember(!visibleMember);
    else setVisibleRequested(!visibleRequested);
  };

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  console.log(members);

  var admins = [];
  var generalMember = [];
  var requestedMember = [];

  //eslint-disable-next-line
  {
    members.length > 0 && //eslint-disable-next-line
      members.map((member) => {
        if (member.admin) admins.push(member);
        else if (member.active) generalMember.push(member);
        else requestedMember.push(member);
      });
  }

  return (
    <div
      
      style={{ marginLeft: 20, marginTop: 70, paddingBottom: 100 }}
    >
      <div>
        <List style={{ marginTop: 20 }}>
          <List.Item
            name="visibleAdmin"
            icon={visibleAdmin ? "angle down" : "angle right"}
            content={<a>Club Admin</a>} //eslint-disable-line
            onClick={handleToggleVisibility}
          />
        </List>
        <Transition
          visible={visibleAdmin}
          animation="scale"
          unmountOnHide={true}
          duration={500}
        >
          <div>
            {admins.length > 0 &&
              admins.map((member) => (
                <ClubMemberItem
                  member={member}
                  key={member._id}
                  adminCount={admins.length}
                />
              ))}
          </div>
        </Transition>
        <List style={{ marginTop: 20 }}>
          <List.Item
            name="visibleMember"
            icon={visibleMember ? "angle down" : "angle right"}
            content={<a>Club Members</a>} //eslint-disable-line
            onClick={handleToggleVisibility}
          />
        </List>
        <Transition
          visible={visibleMember}
          animation="scale"
          unmountOnHide={true}
          duration={500}
        >
          <div>
            {generalMember.length > 0 &&
              generalMember.map((member) => (
                <ClubMemberItem
                  member={member}
                  key={member._id}
                  adminCount={admins.length}
                />
              ))}
          </div>
        </Transition>
        <List style={{ marginTop: 20 }}>
          <List.Item
            name="visibleRequested"
            icon={visibleRequested ? "angle down" : "angle right"}
            content={<a>Requested Members</a>} //eslint-disable-line
            onClick={handleToggleVisibility}
          />
        </List>
        <Transition
          visible={visibleRequested}
          animation="scale"
          unmountOnHide={true}
          duration={500}
        >
          <div>
            {requestedMember.length > 0 &&
              requestedMember.map((member) => (
                <ClubMemberItem member={member} key={member._id} />
              ))}
          </div>
        </Transition>
      </div>
    </div>
  );
}
