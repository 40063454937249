const FETCH_USER_FEED = "FETCH_USER_FEED";
const CREATE_USER_FEED = "CREATE_USER_FEED";
const CHATS_LOADING = "CHATS_LOADING";
const CHATS_FAILED = "CHATS_FAILED";
const ADD_CHATS = "ADD_CHATS";
const LISTEN_TO_SELECTED_CHAT = "LISTEN_TO_SELECTED_CHAT";
const CREATE_CHAT = "CREATE_CHAT";
const CLEAR_CHATS = "CLEAR_CHATS";
const CHAT_RETAIN_STATE = "CHAT_RETAIN_STATE";
const UNREAD_CHATROOM = "UNREAD_CHATROOM";
const UNREAD_CHAT = "UNREAD_CHAT";

export function fetchUserFeed(userfeed) {
  return {
    type: FETCH_USER_FEED,
    payload: userfeed,
  };
}

export function createUserFeed(user) {
  return {
    type: CREATE_USER_FEED,
    payload: user,
  };
}

export const chatsLoading = () => ({
  type: CHATS_LOADING,
});

export const chatsFailed = (errmess) => ({
  type: CHATS_FAILED,
  payload: errmess,
});

export const addChats = (chats, moreChats, lastDocId) => ({
  type: ADD_CHATS,
  payload: { chats, moreChats, lastDocId },
});

export function listenToselectedChat(user) {
  return {
    type: LISTEN_TO_SELECTED_CHAT,
    payload: user,
  };
}

export function createChat(chat) {
  return {
    type: CREATE_CHAT,
    payload: chat,
  };
}

export function clearChats() {
  return {
    type: CLEAR_CHATS,
  };
}

export function chatRetainState(room) {
  return {
    type: CHAT_RETAIN_STATE,
    payload: room,
  };
}

export function unreadChat() {
  return {
    type: UNREAD_CHAT,
  };
}

export function unreadChatroom(count) {
  return {
    type: UNREAD_CHATROOM,
    payload: count,
  };
}

const initialState = {
  userfeed: [],
  chats: [],
  selectedChat: null,
  room: null,
  isLoading: false,
  errMess: null,
  moreChats: false,
  lastDocId: null,
  unreadChatroom: 0,
  isUnreadChatCounted: false,
};

export default function chatReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_USER_FEED:
      return {
        ...state,
        userfeed: payload,
      };
    case CREATE_USER_FEED:
      return {
        ...state,
        userfeed: [payload, ...state.userfeed],
      };
    case CHATS_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case CHATS_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case ADD_CHATS:
      return {
        ...state,
        chats: [...state.chats, ...payload.chats],
        moreChats: payload.moreChats,
        lastDocId: payload.lastDocId,
        isLoading: false,
        errMess: null,
      };
    case LISTEN_TO_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: payload,
      };
    case CREATE_CHAT:
      return {
        ...state,
        chats: [payload, ...state.chats],
      };
    case CLEAR_CHATS:
      return {
        ...state,
        chats: [],
        moreChats: true,
      };
    case CHAT_RETAIN_STATE:
      return {
        ...state,
        room: payload,
      };
    case UNREAD_CHATROOM:
      return {
        ...state,
        unreadChatroom: payload,
      };
    case UNREAD_CHAT:
      return {
        ...state,
        isUnreadChatCounted: true,
      };
    default:
      return state;
  }
}
