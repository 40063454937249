import React, { useEffect, useState } from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Dropdown, FormGroup, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../app/apiRequest/signupService";
import { genderOptions } from "../../app/api/genderOptions";
import { format } from "date-fns";
import MyDateInput from "../../app/common/form/MyDateInput";
import { fetchCollegesApi } from "../../app/apiRequest/collegeFestService";
import { toast } from "react-toastify";

export default function RegisterForm() {
  const dispatch = useDispatch();
  const { colleges } = useSelector((state) => state.search);
  const [emailDomain, setEmailDomain] = useState("");

  const initialValues = {
    firstname: "",
    lastname: "",
    gender: "",
    email: "",
    password: "",
    confirmPassword: "",
    college: null,
    birthday: "",
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    email: Yup.string().email(),
    password: Yup.string()
      .min(6, "Must contain 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(),
    college: Yup.string().required(),
    gender: Yup.string().required(),
  });

  var collegeList = [];
  useEffect(() => {
    dispatch(fetchCollegesApi(""));
  }, [dispatch]);

  colleges.length > 0 &&
    colleges.map((college) =>
      collegeList.push({
        key: college._id,
        text: college.collegeName,
        value: {
          id: college._id,
          restricted: college.restricted,
          emailDomain: college.emailDomain,
        },
        image: { avatar: true, src: college.collegeImg },
      })
    );

  const handleSelectCollege = (value) => {
    if (value.restricted) {
      setEmailDomain(value.emailDomain);
    } else setEmailDomain("");
  };

  return (
    <ModalWrapper size="small" header="Create Your account">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (!values.email.includes(emailDomain)) {
              toast.error(
                `To select this college, Signup with  ${emailDomain} email-id.`
              );
              return;
            }
            values.username = values.email;
            console.log(values);
            dispatch(signupUser(values, setErrors));
          } catch (error) {
            setErrors({ auth: "Problem with username or password" });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <FormGroup widths={"equal"}>
              <MyTextInput
                name="firstname"
                placeholder="First Name"
                label={"First Name"}
              />
              <MyTextInput
                name="lastname"
                placeholder="Last Name"
                label={"Last Name"}
              />
            </FormGroup>
            <p>
              <strong>Select College </strong>(*if not found your college then
              select 'other')
            </p>
            <Dropdown
              name="college"
              placeholder="Select College"
              onChange={(e, { value }) => {
                values.college = value.id;
                handleSelectCollege(value);
              }}
              fluid
              search
              selection
              defaultValue={{ id: values.college?._id } ?? { id: null }}
              options={collegeList}
              style={{ marginTop: -10, marginBottom: 10 }}
            />
            <MyTextInput
              name="email"
              placeholder="Email Address"
              label={"Email Address (College Email)"}
            />
            <FormGroup widths={"equal"}>
              <MyTextInput
                name="password"
                placeholder="Password"
                type="password"
                label={"Password (Minimum 6 characters)"}
              />
              <MyTextInput
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                label={"Confirm Password"}
              />
            </FormGroup>
            <FormGroup widths={2}>
              <MySelectInput
                name="gender"
                placeholder="Gender"
                options={genderOptions}
                label={"Gender"}
              />
              <MyDateInput
                name="birthday"
                placeholderText={format(new Date(), "MMMM d, yyyy")}
                dateFormat="MMMM d, yyyy"
                autoComplete="off"
                maxDate={new Date()}
                showYearDropdown
                dropdownMode="select"
                label={"Date of Birth"}
              />
            </FormGroup>
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginTop: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Register"
              style={{ marginTop: 20 }}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
