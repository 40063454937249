import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Divider,
  FormCheckbox,
  Segment,
} from "semantic-ui-react";
import { setEventFeedFilter } from "../eventFeedReducer";

export default function EventFilters({ loading }) {
  const dispatch = useDispatch();
  const [sizeValue, setSizeValue] = useState({ name: "allSize" });
  const [paymentValue, setPaymentValue] = useState({ name: "all" });
  const [eventTypeValue, setEventTypeValue] = useState({ name: "all" });
  const [locationValue, setLocationValue] = useState({ name: "all" });
  const [category, setCategory] = useState([]);
  const { feedQuery } = useSelector((state) => state.eventFeed);

  const handleEventTypeChange = (e, { name }) => {
    setEventTypeValue({ name });
    if (feedQuery.includes("eventType")) {
      if (name === "all") {
        dispatch(
          setEventFeedFilter(
            feedQuery
              .replace(/contest|entertainment/i, "")
              .replace(`&eventType=`, "")
          )
        );
      } else
        dispatch(setEventFeedFilter(feedQuery.replace(/contest|entertainment/i, name)));
    } else {
      if (name === "all") dispatch(setEventFeedFilter(`${feedQuery}`));
      else dispatch(setEventFeedFilter(`${feedQuery}&eventType=${name}`));
    }
  };

  const handleSizeChange = (e, { name }) => {
    setSizeValue({ name });
    if (feedQuery.includes("SizeTeam")) {
      if (name === "1") {
        dispatch(
          setEventFeedFilter(
            feedQuery
              .replace("&maxSizeTeamGte=2", "&minSizeTeam=1")
              .replace("&maxSizeTeamGt=2", "&minSizeTeam=1")
          )
        );
      } else if (name === "2") {
        dispatch(
          setEventFeedFilter(
            feedQuery
              .replace("&minSizeTeam=1", "&maxSizeTeamGte=2")
              .replace("&maxSizeTeamGt=2", "&maxSizeTeamGte=2")
          )
        );
      } else if (name === "twoPlus") {
        dispatch(
          setEventFeedFilter(
            feedQuery
              .replace("&minSizeTeam=1", "&maxSizeTeamGt=2")
              .replace("&maxSizeTeamGte=2", "&maxSizeTeamGt=2")
          )
        );
      } else {
        dispatch(
          setEventFeedFilter(
            feedQuery
              .replace("&minSizeTeam=1", "")
              .replace("&maxSizeTeamGte=2", "")
              .replace("&maxSizeTeamGt=2", "")
          )
        );
      }
    } else {
      if (name === "1") dispatch(setEventFeedFilter(`${feedQuery}&minSizeTeam=1`));
      else if (name === "2")
        dispatch(setEventFeedFilter(`${feedQuery}&maxSizeTeamGte=2`));
      else if (name === "twoPlus")
        dispatch(setEventFeedFilter(`${feedQuery}&maxSizeTeamGt=2`));
      else dispatch(setEventFeedFilter(`${feedQuery}`));
    }
  };

  const handlePaymentChange = (e, { name }) => {
    setPaymentValue({ name });
    if (feedQuery.includes("registrationCharge")) {
      if (name === "all") {
        dispatch(
          setEventFeedFilter(
            feedQuery.replace(/paid|free/i, "").replace(`&registrationCharge=`, "")
          )
        );
      } else dispatch(setEventFeedFilter(feedQuery.replace(/paid|free/i, name)));
    } else {
      if (name === "all") dispatch(setEventFeedFilter(`${feedQuery}`));
      else dispatch(setEventFeedFilter(`${feedQuery}&registrationCharge=${name}`));
    }
  };

  const handleLocationChange = (e, { name }) => {
    setLocationValue({ name });
    if (feedQuery.includes("&location=")) {
      if (name === "all") {
        dispatch(
          setEventFeedFilter(
            feedQuery.replace(/online|offline/i, "").replace(`&location=`, "")
          )
        );
      } else dispatch(setEventFeedFilter(feedQuery.replace(/online|offline/i, name)));
    } else {
      if (name === "all") dispatch(setEventFeedFilter(`${feedQuery}`));
      else dispatch(setEventFeedFilter(`${feedQuery}&location=${name}`));
    }
  };

  // const handleCityDropdown = (e, data) => {
  //   if (feedQuery.includes("&city.address=")) {
  //     const str = feedQuery.split("&");
  //     var el = str[0];
  //     for (let i = 1; i < str.length; i++) {
  //       if (!str[i].includes("city.address=")) el = el + `&${str[i]}`;
  //     }
  //     console.log(el);
  //     dispatch(setEventFeedFilter(`${el}&city.address=${data.value}`));
  //   } else dispatch(setEventFeedFilter(`${feedQuery}&city.address=${data.value}`));
  // };

  function handleCheckBox(e, { name }) {
    if (!category.includes(name)) {
      setCategory([...category, name]);
      dispatch(setEventFeedFilter(`${feedQuery}&eventCategory[$in]=${name}`));
    } else {
      setCategory([...category.filter((id) => id !== name)]);
      dispatch(
        setEventFeedFilter(feedQuery.replace(`&eventCategory[$in]=${name}`, ""))
      );
    }
  }

  const handleResetFilter = () => {
    window.location.reload();
  };

  return (
    <div>
      <Form>
        <Form.Group inline>
          <h3 style={{ marginRight: 100, marginTop: 10 }}>Filters</h3>
          <Button
            content="Reset Filters"
            size="tiny"
            inverted
            floated="right"
            color="blue"
            onClick={handleResetFilter}
          />
        </Form.Group>
        <Divider style={{ marginTop: -10 }} />
        <Segment>
          <Form.Group inline>
            <label>Event type</label>
            <Button
              name="all"
              size="mini"
              content="All"
              positive={eventTypeValue.name === "all"}
              onClick={handleEventTypeChange}
            />
            <Button
              name="contest"
              size="mini"
              content="Contest"
              positive={eventTypeValue.name === "contest"}
              onClick={handleEventTypeChange}
            />
            <Button
              name="entertainment"
              size="mini"
              content="Non-contest"
              positive={eventTypeValue.name === "entertainment"}
              onClick={handleEventTypeChange}
            />
          </Form.Group>
          <Divider />
          <Form.Group inline>
            <label>Payment :-</label>
            <Button
              name="all"
              size="mini"
              content="All"
              positive={paymentValue.name === "all"}
              onClick={handlePaymentChange}
            />
            <Button
              name="paid"
              size="mini"
              content="Paid"
              positive={paymentValue.name === "paid"}
              onClick={handlePaymentChange}
            />
            <Button
              name="free"
              size="mini"
              content="Free"
              positive={paymentValue.name === "free"}
              onClick={handlePaymentChange}
            />
          </Form.Group>
          <Divider />
          <Form.Group inline>
            <label>Location :-</label>
            <Button
              name="all"
              size="mini"
              content="All"
              positive={locationValue.name === "all"}
              onClick={handleLocationChange}
            />
            <Button
              name="online"
              size="mini"
              content="Online"
              positive={locationValue.name === "online"}
              onClick={handleLocationChange}
            />
            <Button
              name="offline"
              size="mini"
              content="Offline"
              positive={locationValue.name === "offline"}
              onClick={handleLocationChange}
            />
          </Form.Group>
          <Divider />
          {/* <Form.Group inline>
            <label>City</label>
            <Dropdown
              placeholder="city"
              search
              selection
              options={cityListArrays}
              onChange={handleCityDropdown}
            />
          </Form.Group>
          <Divider /> */}
          <Form.Group inline>
            <label>Team size</label>
            <Form.Radio
              label="All"
              checked={sizeValue.name === "allSize"}
              onChange={handleSizeChange}
              name="allSize"
            />
            <Form.Radio
              label="1"
              checked={sizeValue.name === "1"}
              onChange={handleSizeChange}
              name="1"
            />
            <Form.Radio
              label="2"
              checked={sizeValue.name === "2"}
              onChange={handleSizeChange}
              name="2"
            />
            <Form.Radio
              label="2+"
              checked={sizeValue.name === "twoPlus"}
              onChange={handleSizeChange}
              name="twoPlus"
            />
          </Form.Group>
          <Divider />
          <label>
            <strong>Category</strong>
          </label>
          <Form.Group widths={2} style={{ marginTop: 10 }}>
            <FormCheckbox
              name="Article Writing"
              label="Article Writing"
              defaultChecked={category.includes("Article Writing")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Coding challange"
              label="Coding challange"
              defaultChecked={category.includes("Coding challange")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="College festival"
              label="College festival"
              defaultChecked={category.includes("College festival")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Conclave"
              label="Conclave"
              defaultChecked={category.includes("Conclave")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Conference"
              label="Conference"
              defaultChecked={category.includes("Conference")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Dance"
              label="Dance"
              defaultChecked={category.includes("Dance")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Data Analytics"
              label="Data Analytics"
              defaultChecked={category.includes("Data Analytics")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Data science"
              label="Data science"
              defaultChecked={category.includes("Data science")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Debates"
              label="Debates"
              defaultChecked={category.includes("Debates")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Designing"
              label="Designing"
              defaultChecked={category.includes("Designing")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="DJ Concert"
              label="DJ Concert"
              defaultChecked={category.includes("DJ Concert")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Dramatics"
              label="Dramatics"
              defaultChecked={category.includes("Dramatics")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Entrepreneurship"
              label="Entrepreneurship"
              defaultChecked={category.includes("Entrepreneurship")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Fashion"
              label="Fashion"
              defaultChecked={category.includes("Fashion")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Fellowship"
              label="Fellowship"
              defaultChecked={category.includes("Fellowship")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Finance"
              label="Finance"
              defaultChecked={category.includes("Finance")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Hackathon"
              label="Hackathon"
              defaultChecked={category.includes("Hackathon")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Human Resource"
              label="Human Resource"
              defaultChecked={category.includes("Human Resource")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Literary"
              label="Literary"
              defaultChecked={category.includes("Literary")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Marketing"
              label="Marketing"
              defaultChecked={category.includes("Marketing")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Music"
              label="Music"
              defaultChecked={category.includes("Music")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Online Trading"
              label="Online Trading"
              defaultChecked={category.includes("Online Trading")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Panel discussion"
              label="Panel discussion"
              defaultChecked={category.includes("Panel discussion")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Poetry"
              label="Poetry"
              defaultChecked={category.includes("Poetry")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Photography"
              label="Photography"
              defaultChecked={category.includes("Photography")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Presentation"
              label="Presentation"
              defaultChecked={category.includes("Presentation")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Quiz"
              label="Quiz"
              defaultChecked={category.includes("Quiz")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Robotics"
              label="Robotics"
              defaultChecked={category.includes("Robotics")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Scholarship"
              label="Scholarship"
              defaultChecked={category.includes("Scholarship")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Social Media"
              label="Social Media &amp; Digital"
              defaultChecked={category.includes("Social Media")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Sports"
              label="Sports"
              defaultChecked={category.includes("Sports")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Standup Comedy"
              label="Standup Comedy"
              defaultChecked={category.includes("Standup Comedy")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Startup fair"
              label="Startup fair"
              defaultChecked={category.includes("Startup fair")}
              onClick={handleCheckBox}
            />
            <FormCheckbox
              name="Treasure hunt"
              label="Treasure hunt"
              defaultChecked={category.includes("Treasure hunt")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <FormCheckbox
              name="Workshops"
              label="Workshops"
              defaultChecked={category.includes("Workshops")}
              onClick={handleCheckBox}
            />
          </Form.Group>
          <Form.Group widths={2}></Form.Group>
        </Segment>
      </Form>
    </div>
  );
}
