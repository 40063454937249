import React, { useState } from "react";
import {
  Button,
  Header,
  Icon,
  Image,
  Input,
  List,
  Segment,
} from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserFeedApi,
  sendChatApi,
} from "../../../app/apiRequest/chatService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { closeModal } from "../../../app/common/modals/modalReducer";

export default function SharePostToChatModal() {
  const timeoutRef = React.useRef();
  const dispatch = useDispatch();
  const { userfeed } = useSelector((state) => state.chats);
  const currentUser = useSelector((state) => state.auth.user);
  const { link } = useSelector((state) => state.modal.modalProps);
  const [profiles, setProfiles] = useState([]);
  const [a, setA] = useState([]);

  useEffect(() => {
    if (userfeed.length > 0) return;
    dispatch(fetchUserFeedApi(currentUser));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (userfeed.length > 0) {
      for (let profile of userfeed) {
        if (profile.users[0]._id === currentUser) {
          setA((oldArray) => [
            ...oldArray,
            { ...profile.users[1], room: profile._id },
          ]);
          setProfiles((oldArray) => [
            ...oldArray,
            { ...profile.users[1], room: profile._id },
          ]);
        } else {
          setA((oldArray) => [
            ...oldArray,
            { ...profile.users[0], room: profile._id },
          ]);
          setProfiles((oldArray) => [
            ...oldArray,
            { ...profile.users[0], room: profile._id },
          ]);
        }
      }
    }
    // eslint-disable-next-line
  }, [userfeed]);

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          setA([...profiles]);
          return;
        }

        const re = new RegExp(_.escapeRegExp(data.value), "i");
        setA(profiles.filter((el) => re.test(el.firstname)));
      }, 300);
    },
    [profiles]
  );
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleSendMessage = (room) => {
    sendChatApi({ chat: link, filetype: "link", room: room }).then(() => {
      toast.success("Message sent!");
      dispatch(closeModal());
    });
  };

  return (
    <ModalWrapper size="tiny" header="Share To..">
      <Input
        fluid
        icon="search"
        onChange={handleSearchChange}
        placeholder="Search..."
      />
      {a.length > 0 ? (
        a.map((user) => (
          <List key={user._id} relaxed>
            <List.Item>
              <List.Content floated="right">
                <Button
                  color="blue"
                  onClick={() => handleSendMessage(user.room)}
                >
                  Send
                </Button>
              </List.Content>
              <Image avatar src={user.userImg} />
              <List.Content>
                <List.Header className="twenty-chars">{user.displayName}</List.Header>
                {user.user_name}
              </List.Content>
            </List.Item>
          </List>
        ))
      ) : (
        <Header>No results found</Header>
      )}
      {userfeed.length === 0 && (
        <Segment placeholder>
          <Header icon>
            <Icon name="user times" />
            You have no chatlist to share.
          </Header>
        </Segment>
      )}
    </ModalWrapper>
  );
}
