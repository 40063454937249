import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { Divider, Feed, Header, Icon, Image, Modal } from "semantic-ui-react";
import * as Yup from "yup";
import { ControlBar, Player } from "video-react";
import Discussionitem from "./DiscussionItem";
import { formatDistance } from "date-fns";
import { chatRetainState, sendgroupChat } from "./groupDiscussionReducer";
import { isValidHttpUrl } from "../../../../app/common/util/util";
import { cleargroupChats } from "./groupDiscussionReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import { updateChat } from "./discussionReducer";
import {
  fetchChannelChatsApi,
  sendChannelChatApi,
} from "../../../../app/apiRequest/channelService";

export default function GroupDiscussion({
  openModal,
  setOpenModal,
  parentChat,
}) {
  const dispatch = useDispatch();
  const groupSocket = useRef();

  const { parentChatId, chats, isLoading, moreChats, lastDocId, retainState } =
    useSelector((state) => state.groupChat);
  const { currentUserProfile } = useSelector((state) => state.profile);

  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    groupSocket.current = io(process.env.REACT_APP_API_BASE_URL);
    groupSocket.current.emit("joinRoom", {
      sender: currentUserProfile,
      room: parentChat._id,
    });
  }, [parentChat, currentUserProfile]);

  useEffect(() => {
    if (parentChatId === parentChat._id) return;
    dispatch(cleargroupChats());
    dispatch(
      fetchChannelChatsApi(
        parentChat.channel,
        `?parentChatId=${parentChat._id}`
      )
    );
    return () => {
      dispatch(chatRetainState(parentChat._id));
    };
    // eslint-disable-next-line
  }, [dispatch, parentChat, retainState]);

  useEffect(() => {
    if (groupSocket.current) {
      groupSocket.current.on("message", (message) => {
        console.log(message);
        dispatch(sendgroupChat(message));
        dispatch(
          updateChat({
            ...parentChat,
            repliedCount: parentChat.repliedCount + 1,
          })
        );
      });
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (groupSocket.current) {
      groupSocket.current.on("roomUsers", ({ room, users }) => {
        const newActiveUserIds = [];
        for (const active of users) {
          newActiveUserIds.push(active.sender._id);
        }
        setActiveUsers([...activeUsers, ...newActiveUserIds]);
      });
    }
    // eslint-disable-next-line
  }, [dispatch, groupSocket.current]);

  const initialValues = {
    chat: "",
    parentChatId: parentChat._id,
    club: parentChat.club,
    channel: parentChat.channel,
    filetype: "text",
  };

  function handleFetchNextChats() {
    dispatch(
      fetchChannelChatsApi(
        parentChat.channel,
        `?parentChatId=${parentChat._id}&_id[$lt]=${lastDocId}`
      )
    );
  }

  return (
    <Modal
      size="small"
      closeIcon
      open={openModal}
      onClose={() => {
        setOpenModal(false);
        groupSocket.current.disconnect();
      }}
      onOpen={() => setOpenModal(true)}
    >
      <Header content={parentChat.title} />
      <Modal.Content scrolling>
        <Feed>
          <Feed.Event>
            <Feed.Label
              image={parentChat.sender.userImg || "/assets/user.png"}
            />
            <Feed.Content>
              <Feed.Summary>
                <Feed.Summary>
                  <Feed.User>{parentChat.sender.displayName}</Feed.User>
                  <Feed.Date>
                    {formatDistance(
                      new Date(parentChat.createdAt),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                  </Feed.Date>
                </Feed.Summary>
              </Feed.Summary>
              <Feed.Extra text as={"h4"}>
                {parentChat.title}
              </Feed.Extra>
              <Feed.Extra text>{parentChat.chat}</Feed.Extra>
              <Feed.Extra>
                {parentChat.file && parentChat.filetype === "video" && (
                  <Player playsInline fluid={false} width={"45%"} height={290}>
                    <source src={parentChat.file} />
                    <ControlBar autoHide={true} />
                  </Player>
                )}
                {parentChat.file && parentChat.filetype === "image" && (
                  <Image src={parentChat.file} size="large" />
                )}
              </Feed.Extra>
              <Feed.Meta>
                <Feed.Like>
                  <Icon name="chat" />
                  {`${parentChat.repliedCount} messages`}
                </Feed.Like>
              </Feed.Meta>
            </Feed.Content>
          </Feed.Event>
        </Feed>
        <Divider />
        {chats.length > 0 && (
          <InfiniteScroll
            dataLength={chats.length}
            next={handleFetchNextChats}
            hasMore={!isLoading && moreChats}
            loader={<h4>Loading...</h4>}
            initialScrollY={0}
          >
            {chats.map((chat) => (
              <div key={chat._id} style={{ overflow: "hidden" }}>
                <Discussionitem chat={chat} />
              </div>
            ))}
          </InfiniteScroll>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            chat: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              if (isValidHttpUrl(values.chat)) {
                values.filetype = "link";
              }
              values.seenBy = activeUsers;
              groupSocket.current.emit("chatMessage", values);
              sendChannelChatApi(values);
            } catch (error) {
              toast.error(error);
            } finally {
              resetForm();
              setSubmitting(false);
              window.scrollTo(0, document.body.scrollHeight);
            }
          }}
        >
          {({ isSubmitting, handleSubmit, isValid }) => (
            <Form className="ui form">
              <Field name="chat">
                {({ field }) => (
                  <div style={{ position: "relative" }}>
                    <textarea
                      rows="2"
                      {...field}
                      placeholder="write here... (Enter to submit, SHIFT + Enter for new line)"
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && e.shiftKey) {
                          return;
                        }
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          isValid && handleSubmit();
                        }
                      }}
                    />
                  </div>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </Modal.Actions>
    </Modal>
  );
}
