import React, { useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleClubApi,
  updateClubApi,
} from "../../../app/apiRequest/clubService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { toast } from "react-toastify";

export default function EditClubFaqs({ match }) {
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.id));
  }, [dispatch, match.params.id]);

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  //if (error) return <Redirect to="/error" />;

  return (
    <div style={{ marginLeft: 16, marginTop: 70, paddingBottom: 100 }}>
      <div>
        <Header content="Edit Club frequently asked questions." />
        <Formik
          initialValues={selectedClub}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              dispatch(updateClubApi(values)).then(() => {
                toast.success("updated!!");
              });
              setSubmitting(false);
            } catch (error) {
              setErrors({ auth: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors, values }) => (
            <Form className="ui form">
              <FieldArray name="faqs">
                {({ insert, remove, push }) => (
                  <div>
                    {values.faqs?.length > 0 &&
                      values.faqs.map((faq, index) => (
                        <div key={index} style={{ marginBottom: 16 }}>
                          <MyTextArea
                            name={`faqs.${index}.question`}
                            placeholder={`Question ${index + 1}`}
                            label={`Question ${index + 1}`}
                            rows={2}
                          />
                          <MyTextArea
                            name={`faqs.${index}.answer`}
                            placeholder={`Answer ${index + 1}`}
                            label={`Answer ${index + 1}`}
                            rows={3}
                          />
                        </div>
                      ))}
                    <Button
                      content="Add more.."
                      floated="left"
                      type="button"
                      color="blue"
                      onClick={() => push({ question: "", answer: "" })}
                    />
                  </div>
                )}
              </FieldArray>
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <div>
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  floated="right"
                  color="teal"
                  content="Save"
                  style={{ margin: 10 }}
                />
                <Button
                  content="Cancel"
                  floated="right"
                  type="button"
                  style={{ margin: 10 }}
                  onClick={() => window.history.back()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
