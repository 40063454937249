import React, { useState } from "react";
import EventListItem from "./EventListIem";
import InfiniteScroll from "react-infinite-scroller";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";

export default function EventList({
  events,
  getNextEvents,
  loading,
  moreEvents,
}) {
  const { pathname } = useSelector((state) => state.router.location);
  const [isEventPath, setIsEventPath] = useState(true);

  useState(() => {
    if (pathname === "/events") return;
    setIsEventPath(false);
  }, [pathname]);

  const handleResetFilter = () => {
    window.location.reload();
  };

  return (
    <div>
      {events.length !== 0 ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextEvents}
          hasMore={!loading && moreEvents}
          initialLoad={false}
        >
          {events.map((event) => (
            <EventListItem event={event} key={event._id} />
          ))}
        </InfiniteScroll>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name={isEventPath ? "filter" : "dont"} />
            {isEventPath
              ? "Oops! No opportunities with the entered keywords!"
              : "No events"}
          </Header>
          {isEventPath && (
            <Button onClick={handleResetFilter} primary>
              Reset Filters
            </Button>
          )}
        </Segment>
      )}
    </div>
  );
}
