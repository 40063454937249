import React from "react";
import FooterPage from "./FooterPage";
import { useSelector } from "react-redux";
import { Divider } from "semantic-ui-react";

export default function EndUserLicense() {
  const { isAuthenticated, isMobileDevice } = useSelector(
    (state) => state.auth
  );
  return (
    <div>
      <div
        style={{
          marginTop: 80,
          marginLeft: isAuthenticated && !isMobileDevice ? 160 : 40,
          marginRight: 40,
          marginBottom: 40,
        }}
      >
        <h2>End User License Agreement</h2>
        <p>
          <strong>Last updated: January 01, 2023 </strong>
          <br />
          This End User License Agreement ("EULA") is a binding legal agreement
          between you, as an individual or entity, and Club-Chat. By
          downloading, installing, or using this application for Android, iOS or
          other mobile platform, as applicable (the "Software"), you agree to be
          bound by the terms of this EULA. If you do not agree to this EULA, do
          not use the Software and delete it. You agree that installation or use
          of the Software signifies that you have read, understood, and agree to
          be bound by this EULA.
          <br />
          <br />
          For clarification, any Club-Chat service (“Club-Chat Service”)
          accessed through the Software, or otherwise, is also subject to the{" "}
          <a href="https://clubchat.live/privacy-policy">
            Club-Chat Privacy Policy
          </a>
          . Additional terms may apply to specific features. The Software is
          provided to you under this EULA solely for your private,
          non-commercial use. Use of the Software or any Club-Chat Service
          within an organization requires a commercial agreement for the
          applicable Club-Chat Service.
          <br />
          <br />
          you are entering into this contract with Club-Chat Pvt ltd
          (“Club-Chat Pvt ltd) and Club-Chat will be the controller of your
          personal data provided to, or collected by or for, or processed in
          connection with the Software or Club-Chat Service.
        </p>
        <h3>1. Description of Software</h3>
        <p>
          The Software is a downloadable software application that enables you
          to access Club-Chat functionality directly from your Android, iPhone,
          iPad or other mobile device supported by Club-Chat (“Device”). You may
          download the Software whether or not you use the Club-Chat Service,
          but you must associate it with your Club-Chat account to enable its
          full functionality.
        </p>
        <Divider />
        <h3>2. License</h3>
        <p>
          Club-Chat hereby grants you, subject to the terms and conditions of
          this EULA, a non-exclusive, non-transferable personal license to:
        </p>
        <ul>
          <li>Use the Software for your own personal use;</li>
          <li>Install the Software on only one Device; and</li>
          <li>
            Make one copy of the Software in any machine readable form solely
            for back-up purposes, provided you reproduce the Software in its
            original form and with all proprietary notices on the back-up copy.
          </li>
        </ul>
        <p>
          For clarity, the foregoing is not intended to prohibit you from
          installing and backing-up the Software for another Device on which you
          also agreed to the EULA. Each instance of this EULA that you agree to
          grants you the aforementioned rights in connection with the
          installation, use and back-up of one copy of the Software on one
          Device.
        </p>
        <Divider />
        <h3>3. Title</h3>
        <p>
          Title, ownership and all rights (including without limitation
          intellectual property rights) in and to the Software shall remain with
          Club-Chat. Except for those rights expressly granted in this EULA, no
          other rights are granted, whether express or implied.
        </p>
        <Divider />
        <h3>4. Restrictions</h3>
        <p>
          You understand and agree that you shall only use the Software in a
          manner that complies with any and all applicable laws in the
          jurisdictions in which you use the Software. Your use shall be in
          accordance with applicable restrictions concerning privacy and
          intellectual property rights.
          <br />
          <br />
          You may not:
        </p>
        <ul>
          <li>Create derivative works based on the Software;</li>
          <li>
            Use the Software for any purpose other than as described herein;
          </li>
          <li>
            Copy or reproduce the Software except as described in this EULA;
          </li>
          <li>
            Sell, assign, license, disclose, distribute or otherwise transfer or
            make available the Software or any copies of the Software in any
            form to any third parties;
          </li>
          <li>
            Alter, translate, decompile, reverse assemble or reverse engineer
            the Software, or attempt to do any of the foregoing, except to the
            extent this prohibition is not permitted under an applicable law; or
          </li>
          <li>
            Remove or alter any proprietary notices or marks on the Software.
          </li>
        </ul>
        <Divider />
        <h3>5. Personal Information and Privacy</h3>
        <p>
          Our handling of personal information we collect through the Club-Chat
          Services or the Software is governed by the{" "}
          <a href="https://clubchat.live/privacy-policy">
            Club-Chat Privacy Policy
          </a>
          .
        </p>
        <Divider />
        <h3>6. No Warranty</h3>
        <p>
          CLUB-CHAT DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SOFTWARE
          WILL MEET ANY REQUIREMENTS OR NEEDS YOU MAY HAVE, OR THAT THE SOFTWARE
          WILL OPERATE ERROR FREE, OR IN AN UNINTERRUPTED MANNER, OR THAT ANY
          DEFECTS OR ERRORS WILL BE CORRECTED, OR THAT THE SOFTWARE IS FULLY
          COMPATIBLE WITH ANY PARTICULAR PLATFORM. THE SOFTWARE IS OFFERED ON AN
          "AS-IS" BASIS AND NO WARRANTY, EITHER EXPRESS OR IMPLIED, IS GIVEN.
          CLUB-CHAT EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. SOME JURISDICTIONS DO NOT ALLOW THE WAIVER OR
          EXCLUSION OF IMPLIED WARRANTIES SO THEY MAY NOT APPLY TO YOU.
        </p>
        <Divider />
        <h3>7. Right to Terminate or Modify Software</h3>
        <p>
          Club-Chat may modify the Software and this EULA without notice. You
          may cease use of the Software at any time. Either party may terminate
          this EULA at any time, with or without notice.
        </p>
        <Divider />
        <h3>8. Indemnification</h3>
        <p>
          To the fullest extent permitted by law, you agree to indemnify and
          otherwise hold harmless Club-Chat Pvt Ltd, its officers,
          employees, agents, subsidiaries, affiliates and other partners from
          any direct, indirect, incidental, special, consequential or exemplary
          damages arising out of, relating to, or resulting from your use of the
          Software or any other matter relating to the Software.
        </p>
        <Divider />
        <h3>9. Limitation of Liability</h3>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT CLUB-CHAT SHALL NOT BE LIABLE
          FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
          DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
          GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF CLUB-CHAT HAS
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). IN NO EVENT WILL
          CLUB-CHAT'S AGGREGATE LIABILITY TO YOU EXCEED THE AMOUNT OF LICENSING
          FEES PAID BY YOU TO CLUB-CHAT. THESE LIMITATIONS AND EXCLUSIONS WILL
          APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
          REMEDY. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OF DAMAGES
          AND/OR EXCLUSIONS OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
          DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO
          YOU.
        </p>
        <Divider />
        <h3>10. Export Restrictions</h3>
        <p>
          This EULA is expressly made subject to any laws, regulations, orders
          or other restrictions on the export of software from the United States
          of America, and may be subject to export and import regulations of
          other countries. You acknowledge and agree not to import, export,
          re-export, transfer or use, directly or indirectly, the Software
          without compliance with such laws, regulations, orders or other
          restrictions.
        </p>
        <Divider />
        <h3>11. General</h3>
        <p>
          If you live in the Designated Countries: a) you and Club-Chat Ireland
          agree that the laws of Ireland, excluding conflict of laws rules,
          shall exclusively govern any dispute relating to this EULA, the
          Software and/or Club-Chat Service; and b) you and Club-Chat Ireland
          agree that claims and disputes can be litigated only in Dublin,
          Ireland, and we each agree to personal jurisdiction of the courts
          located in Dublin, Ireland. For others outside of Designated
          Countries, including those who live outside of the United States: a)
          you and Club-Chat agree that the laws of the State of California,
          U.S.A., excluding its conflict of laws rules, shall exclusively govern
          any dispute relating to this EULA, the Software and/or Club-Chat
          Service; and b) you and Club-Chat both agree that all claims and
          disputes can be litigated only in the federal or state courts in Santa
          Clara County, California, USA, and you and Club-Chat each agree to
          personal jurisdiction in those courts. This EULA constitutes the
          entire agreement between you and Club-Chat regarding the Software. If
          any provision of this EULA is held by a court of competent
          jurisdiction to be contrary to law, such provision will be changed and
          interpreted so as to best accomplish the objectives of the original
          provision to the fullest extent allowed by law and the remaining
          provisions of this EULA will remain in full force and effect. You may
          not assign this EULA, and any assignment of this EULA by you will be
          null and void. Club-Chat, Club-Chat (stylized), the "in" logos, and
          other Club-Chat-owned logos and names are trademarks of Club-Chat and
          its affiliates. You agree not to display or use these trademarks in
          any manner without Club-Chat's prior, written permission. The section
          titles and numbering of this EULA are displayed for convenience and
          have no legal effect.
        </p>
        <Divider />
      </div>
      <FooterPage />
    </div>
  );
}
