const FEED_LOADING = "FEED_LOADING";
const FEED_FAILED = "FEED_FAILED";
const ADD_FEED = "ADD_FEED";
const CREATE_FEED = "CREATE_FEED";
const UPDATE_FEED = "UPDATE_FEED";
const REMOVE_FEED = "REMOVE_FEED";
const SET_SAVE_FEED = "SET_SAVE_FEED";
const SET_UNSAVE_FEED = "SET_UNSAVE_FEED";
const SET_LIKE_FEED = "SET_LIKE_FEED";
const SET_UNLIKE_FEED = "SET_UNLIKE_FEED";

export const HOME_RETAIN_STATE = "HOME_RETAIN_STATE";

export const fetchFeed = (query) => (dispatch) => {
  dispatch(feedLoading(true));

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "post/feed", {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(addFeed(response.posts, response.morePosts, response.lastFetchedIds));
    })
    .catch((error) => {
      dispatch(feedFailed(error.message));
    });
};

export const feedLoading = () => ({
  type: FEED_LOADING,
});

export const feedFailed = (errmess) => ({
  type: FEED_FAILED,
  payload: errmess,
});

export const addFeed = (feed, moreFeed, lastFetchedIds) => ({
  type: ADD_FEED,
  payload: { feed, moreFeed, lastFetchedIds },
});

export function createPost(post) {
  return {
    type: CREATE_FEED,
    payload: post,
  };
}

export function updatePost(post) {
  return {
    type: UPDATE_FEED,
    payload: post,
  };
}

export function removePostFeed(postId) {
  return {
    type: REMOVE_FEED,
    payload: postId,
  };
}

export function setSavePostFeed(postId, currentUserId) {
  return {
    type: SET_SAVE_FEED,
    payload: { postId, currentUserId },
  };
}

export function setUnSavePostFeed(postId, currentUserId) {
  return {
    type: SET_UNSAVE_FEED,
    payload: { postId, currentUserId },
  };
}

export function setLikePostFeed(postId, currentUserId) {
  return {
    type: SET_LIKE_FEED,
    payload: { postId, currentUserId },
  };
}

export function setUnLikePostFeed(postId, currentUserId) {
  return {
    type: SET_UNLIKE_FEED,
    payload: { postId, currentUserId },
  };
}

const initialState = {
  isFeedLoading: true,
  feedErrMess: null,
  feed: [],
  moreFeed: true,
  lastFetchedIds: [],
  retainState: false,
};

export default function homeFeedReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_FEED:
      return {
        ...state,
        isFeedLoading: false,
        feedErrMess: null,
        feed: [...state.feed, ...payload.feed],
        moreFeed: payload.moreFeed,
        lastFetchedIds: [...state.lastFetchedIds, ...payload.lastFetchedIds]
      };

    case FEED_LOADING:
      return {
        ...state,
        isFeedLoading: true,
        moreFeed: true,
        feedErrMess: null,
      };

    case FEED_FAILED:
      return {
        ...state,
        isFeedLoading: false,
        moreFeed: false,
        feedErrMess: payload,
      };

    case CREATE_FEED:
      return {
        ...state,
        feed: [payload, ...state.feed],
      };

    case UPDATE_FEED:
      return {
        ...state,
        feed: [
          payload,
          ...state.feed.filter((post) => post._id !== payload._id),
        ],
      };

    case REMOVE_FEED:
      return {
        ...state,
        feed: [...state.feed.filter((post) => post._id !== payload)],
      };
    case SET_SAVE_FEED:
      const index1 = state.feed.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray1 = [...state.feed];
      newArray1[index1].savedBy = [
        ...newArray1[index1].savedBy,
        payload.currentUserId,
      ];
      return {
        ...state,
        feed: newArray1,
      };
    case SET_UNSAVE_FEED:
      const index2 = state.feed.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray2 = [...state.feed];
      newArray2[index2].savedBy = [
        ...newArray2[index2].savedBy.filter(
          (id) => id !== payload.currentUserId
        ),
      ];
      return {
        ...state,
        feed: newArray2,
      };
    case SET_LIKE_FEED:
      const index3 = state.feed.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray3 = [...state.feed];
      newArray3[index3].likes = [
        ...newArray3[index3].likes,
        payload.currentUserId,
      ];
      return {
        ...state,
        feed: newArray3,
      };
    case SET_UNLIKE_FEED:
      const index4 = state.feed.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray4 = [...state.feed];
      newArray4[index4].likes = [
        ...newArray4[index4].likes.filter((id) => id !== payload.currentUserId),
      ];
      return {
        ...state,
        feed: newArray4,
      };
    case HOME_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
