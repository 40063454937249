import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";
import PostComment from "./PostComment";
import PostDetailedInfo from "./PostDetailedInfo";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import FeaturedFeed from "../../common/FeaturedFeed";
import { fetchSinglePostApi } from "../../../app/apiRequest/postService";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

export default function PostDetailedPage({ match }) {
  const dispatch = useDispatch();
  const post = useSelector((state) => state.post.selectedPost);
  const { user } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    if (match.params.id === post?._id) return;
    dispatch(fetchSinglePostApi(match.params.id));
  }, [dispatch, match.params.id, post]);

  if (loading || (!post && !error))
    return <LoadingComponent content="Loadind post..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <Grid>
      <Grid.Column width={9} style={{ marginLeft: 10, marginRight: -10 }}>
        <PostDetailedInfo post={post} />
        <PostComment post={post} />
      </Grid.Column>
      <Grid.Column width={1} />
      <Grid.Column width={5} id="featured-feed">
        <FeaturedFeed userId={user} />
      </Grid.Column>
    </Grid>
  );
}
