import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Dropdown, Table } from "semantic-ui-react";
import {
  fetchRegisteredTeamsApi,
  fetchSingleEventApi,
  updateRegisteredTeamApi,
} from "../../../app/apiRequest/eventService";

export default function EventRegisteredTeams({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [status, setStatus] = useState("verified");
  const { teams, selectedEvent } = useSelector((state) => state.event);
  const { error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleEventApi(match.params.id));
    dispatch(fetchRegisteredTeamsApi(match.params.id, ""));
  }, [dispatch, match.params.id]);

  const options = [
    {
      key: "verified",
      text: "verified",
      value: "verified",
      icon: { name: "check circle outline", color: "green" },
    },
    {
      key: "unverified",
      text: "Not verified",
      value: "unverified",
      icon: { name: "remove circle", color: "red" },
    },
    {
      key: "eliminated",
      text: "Eliminated",
      value: "eliminated",
      icon: { name: "remove", color: "blue" },
    },
  ];

  const handleStatusChange = (team, index) => {
    dispatch(updateRegisteredTeamApi(team, index));
  };

  const handleExportTeams = () => {
    const csvData = dataToCSV(teams);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${selectedEvent.eventTitle}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const dataToCSV = (data) => {
    const headers =
      [
        "Team number",
        "Team name",
        "Status",
        "Member name",
        "College name",
        "Email-id",
        "Contact number",
        "Other Details",
        "Registration Date",
      ].join(",") + "\n";

    const rows = data
      .map((team, i) => {
        const membersData = team.members
          .map((member) => {
            return [
              i + 1,
              team.teamName,
              team.status,
              member.memberName,
              member.college,
              member.email,
              member.phone,
              member.otherDetails,
              new Date(team.createdAt).toDateString("MMM dd yyyy hh:mm"),
            ].join(",");
          })
          .join("\n");
        return membersData;
      })
      .join("\n");

    return headers + rows;
  };

  const handleCopyMails = () => {
    let emails = [];
    for (let team of teams) {
      if (team.status === status) {
        for (let teamMember of team.members) {
          emails.push(teamMember.email);
        }
      }
    }

    if (emails.length > 0) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(emails.join(", "))
          .then(() => {
            alert(`Copied ${emails.length} email(s) to clipboard`);
          })
          .catch((err) => {
            alert("Error copying emails to clipboard");
            console.error(err);
          });
      } else {
        // Fallback mechanism using a textarea
        const textarea = document.createElement("textarea");
        textarea.value = emails.join(", ");
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert(`Manually copied ${emails.length} email(s) to clipboard`);
      }
    } else {
      alert("No emails to copy");
    }
  };

  const handleStatusDropdown = (e, data) => {
    setStatus(data.value);
  };

  if (error) return <Redirect to="/error" />;

  return (
    <div style={{ marginRight: -100 }}>
      <Button
        icon="arrow left"
        content="List of Registered Teams"
        labelPosition="left"
        onClick={() => history.goBack()}
      />
      <p style={{ marginTop: 15 }}>
        * You can eliminate teams which is not qualified in elimination round.
        <br />* Eliminated team can't participate in next round.
      </p>
      <div>
        <Button
          icon="download"
          content="Export teams"
          color="red"
          labelPosition="left"
          onClick={handleExportTeams}
        />
        <span>
          <Button
            icon="copy"
            color="blue"
            content="Copy email of all"
            labelPosition="left"
            onClick={() => handleCopyMails()}
          />
          <Dropdown
            inline
            header="Copy mail of"
            options={options}
            defaultValue={options[0].value}
            onChange={handleStatusDropdown}
          />
        </span>
      </div>
      <div id="table-contents">
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2">Team Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" rowSpan="2">
                Status
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Team Member</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">College Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" rowSpan="2">
                Email
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Contact Number</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">
                Additional details
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Registered By</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Registration Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {teams.map((team) =>
              team.members.map((member, i) => (
                <Table.Row
                  key={member._id}
                  warning={team.status === "unverified"}
                  error={team.status === "eliminated"}
                >
                  {i === 0 && (
                    <Table.Cell rowSpan={team.members.length}>
                      {team.teamName}
                    </Table.Cell>
                  )}
                  {i === 0 && (
                    <Table.Cell
                      textAlign="center"
                      rowSpan={team.members.length}
                    >
                      <Dropdown
                        defaultValue={team.status}
                        openOnFocus
                        selection
                        options={options}
                        onChange={(e, data) =>
                          handleStatusChange(
                            {
                              ...team,
                              status: data.value,
                            },
                            teams.indexOf(team)
                          )
                        }
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>{member.memberName}</Table.Cell>
                  <Table.Cell>{member.college}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <a
                      href={`mailto:${member.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {member.email}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{member.phone}</Table.Cell>
                  <Table.Cell>{member.otherDetails}</Table.Cell>
                  {i === 0 && (
                    <Table.Cell rowSpan={team.members.length}>
                      <a href={`/profile/${team.creator?._id}`}>
                        {team.creator?.displayName}
                      </a>
                    </Table.Cell>
                  )}
                  {i === 0 && (
                    <Table.Cell rowSpan={team.members.length}>
                      {new Date(team.createdAt).toDateString(
                        "MMM dd yyyy hh:mm"
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}
