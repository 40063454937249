import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Item, Segment, List } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { fetchSingleRegisteredTeamApi } from "../../../app/apiRequest/eventService";

export default function EventDetailedStage({ event, isAdmin }) {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { registeredTeam } = useSelector((state) => state.event);

  useEffect(() => {
    if (!isAdmin && isAuthenticated) {
      dispatch(fetchSingleRegisteredTeamApi(event._id, user));
    }
  }, [isAdmin, event, user, dispatch, isAuthenticated]);

  return (
    <>
      <Header
        as={"h2"}
        icon="dot circle"
        content={`${event.eventTitle} : Stages and Timeline`}
      />
      {event.stages.map((stage) => (
        <Segment key={stage._id}>
          <Item.Group>
            <Item>
              <Item.Content>
                {isAdmin && stage.roundType === "online" ? (
                  <Button
                    floated="right"
                    size="mini"
                    content="update round link"
                    color="blue"
                    onClick={() =>
                      dispatch(
                        openModal({
                          modalType: "RoundLinkModal",
                          modalProps: {
                            event: event,
                            round: stage.round,
                            title: stage.roundTitle,
                          },
                        })
                      )
                    }
                  />
                ) : (
                  <div>
                    {stage.link &&
                      stage.roundType === "online" &&
                      registeredTeam?.status==="verified" && (
                        <a
                          href={stage.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            floated="right"
                            size="mini"
                            content="start round"
                            color="blue"
                          />
                        </a>
                      )}
                  </div>
                )}
                <Item.Header>
                  {stage.round}. {stage.roundTitle}
                </Item.Header>
                <Item.Description>{stage.description}</Item.Description>
                {stage.roundType === "offline" && (
                  <Item.Extra>
                    <List>
                      <List.Item icon="marker" content={"offline"} />
                    </List>
                  </Item.Extra>
                )}
                {stage.link &&
                  stage.roundType === "online" &&
                  (isAdmin || registeredTeam?.status==="verified") && (
                    <Item.Extra>
                      <List>
                        <List.Item
                          icon="code"
                          content={<a href={stage.link}>{stage.link}</a>}
                        />
                      </List>
                    </Item.Extra>
                  )}
                <Item.Extra>
                  <Header
                    as="h4"
                    content={`${new Date(
                      stage.startDate
                    ).toLocaleString()} - ${new Date(
                      stage.endDate
                    ).toLocaleString()}`}
                    icon="calendar alternate outline"
                  />
                </Item.Extra>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      ))}
    </>
  );
}
