export var phoneFormat = /^\d{10}$/;
export var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line no-useless-escape

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function createDataTree(dataset) {
  let hashTable = Object.create(null);
  dataset.forEach((a) => (hashTable[a._id] = { ...a, childNodes: [] }));
  let dataTree = [];
  dataset.forEach((a) => {
    if (a.parentCommentId)
      hashTable[a.parentCommentId].childNodes.push(hashTable[a._id]);
    else dataTree.push(hashTable[a._id]);
  });
  return dataTree;
}

function getExtension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
    case "jpeg":
      //etc
      return true;
    default:
      return false;
  }
}

export function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
      // etc
      return true;
    default:
      return false;
  }
}

export async function getFile(dataUrl, filename) {
  return await fetch(dataUrl)
    .then((r) => r.blob())
    .then(
      (blobFile) =>
        new File([blobFile], filename, {
          type: "image/png",
        })
    );
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function formatBytes(a, b = 2) {
  if (!+a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
}
