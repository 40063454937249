import React, { useState } from "react";
import {
  Button,
  Header,
  Icon,
  Image,
  Input,
  List,
  Segment,
} from "semantic-ui-react";
import ModalWrapper from "../../../../app/common/modals/ModalWrapper";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchClubMembersToAddMemberApi,
  fetchSingleClubApi,
} from "../../../../app/apiRequest/clubService";
import { addMemberToChannelApi } from "../../../../app/apiRequest/channelService";
import { listenToSelectedChannelMembers } from "../../clubReducer";
import { toast } from "react-toastify";

export default function AddMemberToChannel() {
  const timeoutRef = React.useRef();
  const dispatch = useDispatch();
  const { channel } = useSelector((state) => state.modal.modalProps);
  const [a, setA] = useState([]);
  const { members, channelMembers } = useSelector((state) => state.club);
  const [buttonIcons, setButtonIcons] = useState({});

  useEffect(() => {
    dispatch(fetchSingleClubApi(channel.club)).then(() => {
      dispatch(
        fetchClubMembersToAddMemberApi({
          channelMembers: channel.members,
          club: channel.club,
        })
      );
    });
  }, [dispatch, channel]);

  useEffect(() => {
    if (members.length > 0) {
      setA([...members]);
    }
    // eslint-disable-next-line
  }, [members]);

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          setA([...members]);
          return;
        }

        const re = new RegExp(_.escapeRegExp(data.value), "i");
        setA(members.filter((el) => re.test(el.user.firstname)));
      }, 300);
    },
    [members]
  );
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleAddMember = (member) => {
    setButtonIcons((prevIcons) => ({
      ...prevIcons,
      [member._id]: "check", // Set the icon to 'home' for the clicked button
    }));
    member.channel = channel._id;
    member.admin = false;
    addMemberToChannelApi(member).then(() => {
      listenToSelectedChannelMembers([...channelMembers, member]);
      toast.success(`${member.user.displayname} added to this channel`);
    });
  };

  return (
    <ModalWrapper size="tiny" header="Add member to channel">
      <Input
        fluid
        icon="search"
        onChange={handleSearchChange}
        placeholder="Search..."
      />
      {a.length > 0 ? (
        a.map((member) => (
          <List key={member._id} relaxed>
            <List.Item>
              <List.Content floated="right">
                <Button
                  color="blue"
                  icon={buttonIcons[member._id] || "add user"}
                  onClick={() => handleAddMember(member)}
                />
              </List.Content>
              <Image avatar src={member.user.userImg} />
              <List.Content>
                <List.Header className="twenty-chars">
                  {member.user.displayName}
                </List.Header>
                {member.user.user_name}
              </List.Content>
            </List.Item>
          </List>
        ))
      ) : (
        <Header>No results found</Header>
      )}
      {members.length === 0 && (
        <Segment placeholder>
          <Header icon>
            <Icon name="users" />
            All club members are on this channel
          </Header>
        </Segment>
      )}
    </ModalWrapper>
  );
}
