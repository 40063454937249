import React from "react";
import { Segment, Button, Placeholder } from "semantic-ui-react";
export default function PostListItemPlaceholder() {
  return (
    <Placeholder fluid>
      <Segment style={{ minHeight: 110 }}>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
        <Placeholder style={{ marginLeft: 80, height: 300, width: 500 }}>
          <Placeholder.Image />
        </Placeholder>
        <Button.Group style={{ marginLeft: 80, marginTop: 20 }} compact>
          <Button
            content="Like"
            icon="heart"
            label={{ as: "a", basic: true, content: "0" }}
            labelPosition="right"
            disabled
          />
          <Button
            content="Comment"
            icon="comment"
            label={{ as: "a", basic: true, content: "0" }}
            labelPosition="right"
            style={{ marginLeft: 25 }}
            disabled
          />
          <Button
            content="Share"
            icon="share"
            label={{ as: "a", basic: true, content: "0" }}
            labelPosition="right"
            style={{ marginLeft: 25 }}
            disabled
          />
        </Button.Group>
      </Segment>
    </Placeholder>
  );
}
