/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FooterPage from "./FooterPage";

export default function CloseAccount({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, user, isMobileDevice } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuthenticated && match.params.id !== user) {
      history.push("/error");
    }
  }, [isAuthenticated, user, match.params.id, history]);

  const handleCloseAccount = () => {
    if (isAuthenticated && match.params.id === user) {
      dispatch(openModal({ modalType: "CloseAccountModal" }));
    } else {
      dispatch(openModal({ modalType: "LoginForm" }));
    }
  };

  return (
    <div
      class="account-container"
      style={{ marginTop: isMobileDevice ? 70 : 0 }}
    >
      <div class="account-header">Close your Club-Chat account</div>

      <div class="alert-box">
        <p>
          <strong>Quickly close your account</strong>
          <br />
          We'll be sorry to see you go. Closing your account will delete any
          recommendations or endorsements you've given or received, plus your
          messages and connections.
        </p>
        <button onClick={handleCloseAccount}>Close account</button>
      </div>

      <div>
        <p>
          Closing your account means permanently deleting your profile and
          removing access to all your Club-Chat information from our site.
        </p>
      </div>

      <div class="info-box">
        <p>
          <strong>Important to know</strong>
          <br />
          You can reopen your account within 15 days of account close.
        </p>
      </div>
      <div>
        <p>
          <strong>
            You can also close your account directly from the Close Account
            page. Before you do, please note:
          </strong>
        </p>
        <ul>
          <li>
            You won't have access to your connections or any information you've
            added to your account.
          </li>
          <li>Your profile will no longer be visible on Club-Chat.</li>
          <li>
            Search engines like Yahoo!, Bing, and Google may still display your
            information temporarily due to the way they collect and update their
            search data.
          </li>
          <li>
            You'll lose all recommendations and endorsements you've collected on
            your Club-Chat profile.
          </li>
          <li>
            You may want to download a copy of your data before you close your
            account with us.
          </li>
        </ul>
        <p>
          <strong>
            You can <a href="#">reopen your account</a> in most cases if it's
            been closed less than 14 days, but we may not unable to recover the
            following even if you reopen your account:
          </strong>
        </p>
        <ul>
          <li>Endorsements and recommendations</li>
          <li>Ignored or pending invitations</li>
          <li>Followings (Top Voices, Companies, etc.)</li>
          <li>Group memberships</li>
        </ul>
      </div>
      <FooterPage />
    </div>
  );
}
