import React from "react";
import PostListItem from "./PostListItem";
import InfiniteScroll from "react-infinite-scroller";
import { Feed, Header, Icon, Segment } from "semantic-ui-react";

export default function PostList({
  posts,
  getNextPosts,
  loading,
  morePosts,
  isFeed,
}) {
  return (
    <div>
      {posts.length !== 0 ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextPosts}
          hasMore={!loading && morePosts}
          initialLoad={false}
        >
          <Feed size="small">
          {posts.map((post) => (
            <PostListItem post={post} key={post._id} isFeed={isFeed} />
          ))}
          </Feed>
        </InfiniteScroll>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="dont" />
            No post.
          </Header>
        </Segment>
      )}
    </div>
  );
}
