import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Header, Icon, Segment } from "semantic-ui-react";
import SessionListItem from "../club/clubDetailed/sessions/SessionListItem";

export default function ReminderSessionList({ sessions }) {
  return (
    <>
      {sessions.length > 0 ? (
        <Card.Group >
          {sessions.map((session, i) => (
            <SessionListItem
            session={session}
            key={i}
            isAdmin={false}
          />
          ))}
        </Card.Group>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell outline" />
            You have no upcoming sessions.
          </Header>
          <Button as={Link} to="/clubs" primary>
            Go to Home Page
          </Button>
        </Segment>
      )}
    </>
  );
}
