import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Label } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import MyTextArea from "../../app/common/form/MyTextArea";
import { reportApi, uploadFileApi } from "../../app/apiRequest/extraService";
import { closeModal } from "../../app/common/modals/modalReducer";
import { asyncActionError, asyncActionFinish } from "../../app/async/asyncReducer";

export default function ReportForm() {
  const dispatch = useDispatch();
  const { type, id } = useSelector((state) => state.modal.modalProps);
  const [file, setFile] = useState(null);
  const { loading } = useSelector((state) => state.async);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <ModalWrapper
      size="tiny"
      header={`What is to report in this ${type}?`}
    >
      <Formik
        initialValues={{ report: "" }}
        validationSchema={Yup.object({
          report: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values.reportOn =  type;
            values.reportedId = id;
            dispatch(closeModal());
            if (file) {
              var data = new FormData();
              data.append("imageFile", file);
              dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                values.file = response[0].location;
                reportApi(values);
              });
            } else {
              reportApi(values);
            }
            console.log(values);
          } catch (error) {
            dispatch(asyncActionError(error));
            console.log(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyTextArea
              name="report"
              placeholder="tell us what is wrong here!"
              label={"Write your report here."}
              rows={6}
              style={{ marginTop: 8 }}
            />
            <div style={{ marginBottom: 16 }}>
              <label htmlFor="myfile">{`Select a file to support your claim : (optional)`}</label>
              <input
                type="file"
                id="myfile"
                name="myfile"
                onChange={handleFileChange}
              />
            </div>
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 20 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting || loading}
              disabled={!isValid || !dirty || isSubmitting || loading}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Submit"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
