import {
  addNotifications,
  notificationsFailed,
  notificationsLoading,
  unreadNotificationCount,
} from "../../features/notification/notificationReducer";

export const createNotificationApi = (notification) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "notifications", {
    method: "POST",
    body: JSON.stringify(notification),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("notification ", error.message);
    });
};

export const updateNotificationApi = (notification) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "notifications/" + notification.post,
    {
      method: "PUT",
      body: JSON.stringify(notification),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("Notification updated ", error.message);
    });
};

export const fetchNotificationsApi = (query) => (dispatch) => {
  dispatch(notificationsLoading(true));

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "notifications" + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) =>
      dispatch(
        addNotifications({
          notifications: response.notifications,
          moreNotification: response.moreNotifications,
          lastDocId: response.lastDocId,
        })
      )
    )
    .catch((error) => dispatch(notificationsFailed(error.message)));
};

export const deleteNotificationApi = (notificationId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "notifications/" + notificationId,
    {
      method: "DELETE",
      headers: {
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((notifications) => {
      console.log("Notification Deleted", notifications);
      dispatch(addNotifications(notifications));
    })
    .catch((error) => dispatch(notificationsFailed(error.message)));
};

export const countUnreadNotificationsApi = () => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "notifications/count", {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(unreadNotificationCount(response.count)))
    .catch((error) => console.log(error.message));
};

export const markReadNotificationApi = () => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "notifications",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(unreadNotificationCount(0));
    })
    .catch((error) => {
      console.log("Notification updated ", error.message);
    });
};
