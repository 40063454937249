import React from "react";
import { useSelector } from "react-redux";
import RegisterForm from "../../../features/auth/RegisterForm";
import ForgotPasswordForm from "../../../features/auth/ForgotPsswordForm";
import VerificationForm from "../../../features/auth/VerificationForm";
import LoginForm from "../../../features/auth/LoginForm";
import EditProfileForm from "../../../features/profile/profileForm/EditProfileForm";
import CreateClubForm from "../../../features/club/clubForm/CreateClubForm";
import ClubDescriptionForm from "../../../features/club/clubForm/ClubDescriptionForm";
import ClubfaqsForm from "../../../features/club/clubForm/ClubfaqsForm";
import CreateSessionsForm from "../../../features/club/clubDetailed/sessions/CreateSessionsform";
import CreatePostForm from "../../../features/posts/postForm/CreatePostForm";
import CollegeForm from "../../../features/college/collegeForm/CollegeForm";
import FestivalForm from "../../../features/festival/festivalForm/FestivalForm";
import UserRegistrationForm from "../../../features/events/eventForm/UserRegistrationForm";
import EventResultForm from "../../../features/events/eventForm/EventResultForm";
import ManageAdmin from "../../../features/college/collegeForm/ManageAdmin";
import ClubListModal from "../../../features/posts/postForm/ClubListModal";
import FullSizeImgModal from "../../../features/posts/postDetailed/FullSizeImgModal";
import ReportForm from "../../../features/common/ReportForm";
import SendFileForm from "../../../features/common/SendFileForm";
import NewChatModal from "../../../features/chat/NewChatModal";
import RoundLinkModal from "../../../features/events/eventForm/RoundLinkModal";
import SharePostToChatModal from "../../../features/posts/postForm/SharePostToChatModal";
import StoreSessionLink from "../../../features/club/clubDetailed/sessions/StoreSessionLink.jsx";
import ContactUsForm from "../../../features/help/form/ContactUsForm";
import CollegePageRequest from "../../../features/help/form/CollegePageRequest";
import GuestMentorForm from "../../../features/help/form/GuestMentorForm";
import FeedbackForm from "../../../features/help/form/FeedbackForm";
import BugReportForm from "../../../features/help/form/BugReportForm";
import SuggestFeatureForm from "../../../features/help/form/SuggestFeatureForm";
import ResetPasswordForm from "../../../features/auth/ResetPasswordForm";
import FeaturedAdForm from "../../../features/help/form/FeaturedAdForm";
import DiscussionForm from "../../../features/club/clubDetailed/discussion/DiscussionForm";
import ChannelForm from "../../../features/club/clubDetailed/channel/ChannelForm.jsx";
import AddMemberToChannel from "../../../features/club/clubDetailed/channel/AddmemberToChannel.jsx";
import ViewAdminModal from "../../../features/common/ViewAdminModal.jsx";
import CloseAccountModal from "../../../features/more/CloseAccountModal.jsx";
import ActivateAccountModal from "../../../features/auth/ActivateAccountModal.jsx";
import SelectCollegeModal from "../../../features/college/SelectCollegModal.jsx";

export default function ModalManager() {
  const modalLookup = {
    RegisterForm,
    ForgotPasswordForm,
    VerificationForm,
    LoginForm,
    EditProfileForm,
    CreateClubForm,
    ClubDescriptionForm,
    ClubfaqsForm,
    CreateSessionsForm,
    CreatePostForm,
    CollegeForm,
    FestivalForm,
    UserRegistrationForm,
    EventResultForm,
    ManageAdmin,
    ClubListModal,
    FullSizeImgModal,
    ReportForm,
    SendFileForm,
    NewChatModal,
    RoundLinkModal,
    SharePostToChatModal,
    StoreSessionLink,
    ContactUsForm,
    CollegePageRequest,
    GuestMentorForm,
    FeedbackForm,
    BugReportForm,
    SuggestFeatureForm,
    ResetPasswordForm,
    FeaturedAdForm,
    DiscussionForm,
    ChannelForm,
    AddMemberToChannel,
    ViewAdminModal,
    CloseAccountModal,
    ActivateAccountModal,
    SelectCollegeModal
  };
  const currentModal = useSelector((state) => state.modal);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span> {renderedModal} </span>;
}
