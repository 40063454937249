import React, { useEffect, useState } from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Label } from "semantic-ui-react";
import { fetchCollegesApi } from "../../app/apiRequest/collegeFestService";
import { closeModal } from "../../app/common/modals/modalReducer";
import { updateUserApi } from "../../app/apiRequest/profileService";

export default function SelectCollegeModal() {
  const dispatch = useDispatch();
  const { colleges } = useSelector((state) => state.search);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [collegeError, setCollegeError] = useState(null);
  const [collegeId, setCollegeId] = useState(null);
  var collegeList = [];

  useEffect(() => {
    dispatch(fetchCollegesApi(""));
  }, [dispatch, currentUserProfile]);

  colleges.length > 0 &&
    colleges.map((college) =>
      collegeList.push({
        key: college._id,
        text: college.collegeName,
        value: {
          id: college._id,
          restricted: college.restricted,
          emailDomain: college.emailDomain,
        },
        image: { avatar: true, src: college.collegeImg },
      })
    );

  const handleSelectCollege = (value) => {
    if (
      value.restricted &&
      !currentUserProfile?.username.includes(value.emailDomain)
    ) {
      setCollegeError(
        `To select this college, Signup with  ${value.emailDomain} email-id.`
      );
    } else {
      setCollegeError(null);
      setCollegeId(value.id);
    }
  };

  const handleSaveBtn = () => {
    const values = {
      _id: currentUserProfile._id,
      firstname: currentUserProfile.firstname,
      lastname: currentUserProfile.lastname,
      college: collegeId,
    };
    dispatch(updateUserApi(values)).then(() => {
      dispatch(closeModal());
    });
  };

  return (
    <ModalWrapper size="tiny" header="Select a College">
      <Dropdown
        name="college"
        placeholder="Select College"
        onChange={(e, { value }) => {
          handleSelectCollege(value);
        }}
        fluid
        search
        selection
        options={collegeList}
        style={{ marginTop: -10, marginBottom: 10 }}
      />
      {collegeError && (
        <Label
          basic
          color="red"
          style={{ marginBottom: 10 }}
          content={collegeError}
        />
      )}
      <div>
        <Button
          disabled={collegeError != null || collegeId == null}
          onClick={handleSaveBtn}
          floated="right"
          color="teal"
          content="Save"
          style={{ margin: 10 }}
        />
        <Button
          content="Cancel"
          floated="right"
          type="button"
          style={{ margin: 10 }}
          onClick={() => dispatch(closeModal())}
        />
      </div>
    </ModalWrapper>
  );
}
