import { toast } from "react-toastify";
import { createPost, updatePost } from "../../features/home/homeFeedReducer";
import {
  addComments,
  createComment,
  deleteComment,
  updateComment,
} from "../../features/posts/commentReducer";
import {
  addPosts,
  listenToSelectedPost,
  postsFailed,
  postsLoading,
} from "../../features/posts/postReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const createPostApi = (post) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts", {
    method: "POST",
    body: JSON.stringify(post),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(createPost(response));
      toast.success("Posted Successfully!");
    })
    .catch((error) => {
      console.log("Post comments ", error.message);
      alert("Your post could not be posted\nError: " + error.message);
    });
};

export const fetchPostsApi = (query) => (dispatch) => {
  dispatch(postsLoading(true));

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/fetch", {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) =>
      dispatch(addPosts(response.posts, response.morePosts, response.lastDocId))
    )
    .catch((error) => dispatch(postsFailed(error.message)));
};

export const deletePostApi = (postId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + postId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("Post Deleted", response);
    })
    .catch((error) => console.log(error.message));
};

export const updatePostDetailsApi = (post) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + post._id + "/info", {
    method: "PUT",
    body: JSON.stringify(post),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updatePost(response)))
    .catch((error) => {
      console.log("Post updated ", error.message);
      alert("Your post could not be updated\nError: " + error.message);
    });
};

export const updatePostApi = (post) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + post._id, {
    method: "PUT",
    body: JSON.stringify(post),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("Post updated ", error.message);
      alert("Post could not be updated\nError: " + error.message);
    });
};

export const fetchSinglePostApi = (postId) => (dispatch) => {
  dispatch(asyncActionStart());
  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + postId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedPost(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError(error.message));
    });
};

export const createPostCommentApi = (comment, post) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + post._id + "/comments", {
    method: "POST",
    body: JSON.stringify(comment),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(createComment(response));
    })
    .catch((error) => {
      console.log("Post comments ", error.message);
      alert("Your comment could not be posted\nError: " + error.message);
    });
};

export const fetchCommentsApi = (postId, query) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + postId + "/comments" + query)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(
        addComments(
          response.comments,
          response.moreComments,
          response.lastDocId
        )
      );
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error)));
};

export const deletePostCommentApi = (comment) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + comment.post + "/comments" + comment._id, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("Comment Deleted", response);
      dispatch(deleteComment(comment._id));
    })
    .catch((error) => console.log(error.message));
};

export const updatePostCommentApi = (comment) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "posts/" + comment.post + "/comments" + comment._id, {
    method: "PUT",
    body: JSON.stringify(comment),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateComment(response)))
    .catch((error) => {
      console.log("Post comment updated ", error.message);
      alert("Your comment could not be updated\nError: " + error.message);
    });
};
