import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Grid, Image } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { toast } from "react-toastify";
import { getFile, isImage } from "../../../app/common/util/util";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyDateInput from "../../../app/common/form/MyDateInput";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { clearSelectedFest } from "../../college/collegeFestReducer";
import {
  createCollegeFestApi,
  fetchSingleCollegeFestApi,
  updateCollegeFestApi,
} from "../../../app/apiRequest/collegeFestService";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { compressImg } from "../../../app/common/photos/compressImg";

export default function FestivalForm() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state) => state.modal);
  const { selectedFest } = useSelector((state) => state.college);
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const { loading } = useSelector((state) => state.async);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (modalProps?.fest == null) {
      dispatch(clearSelectedFest());
      return;
    }
    dispatch(fetchSingleCollegeFestApi(modalProps?.fest));
  }, [dispatch, modalProps]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  const initialValues = selectedFest ?? {
    festName: "",
    festImg: "https://learningx-s3.s3.ap-south-1.amazonaws.com/image_2_1.png",
    email: "",
    website: "",
    instagram: "",
    linkedIn: "",
    description: "",
  };

  const validationSchema = Yup.object({
    festName: Yup.string().required(),
    email: Yup.string().email().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
  });

  console.log(new Date().toISOString());

  return (
    <>
      <ModalWrapper size="small" header="Create a Club">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (images.length > 0) {
                var data = new FormData();
                await compressImg(images[images.length - 1]).then(
                  async (compressedDataUrl) => {
                    let file = await getFile(compressedDataUrl, filename);
                    data.append("imageFile", file);
                  }
                );
                dispatch(uploadFileApi(data)).then((response) => {
                  dispatch(asyncActionFinish());
                  values.festImg = response[0].location;
                  if (modalProps?.fest) {
                    dispatch(updateCollegeFestApi(values)).then(() => {
                      dispatch(closeModal());
                      toast.success("updated sucessfully!");
                    });
                  } else {
                    values.college = modalProps?.college;
                    dispatch(createCollegeFestApi(values)).then(() => {
                      dispatch(closeModal());
                      toast.success("Page created sucessfully!");
                    });
                  }
                });
              } else {
                if (modalProps?.fest) {
                  dispatch(updateCollegeFestApi(values)).then(() => {
                    dispatch(closeModal());
                    toast.success("updated sucessfully!");
                  });
                } else {
                  values.college = modalProps?.college;
                  dispatch(createCollegeFestApi(values)).then(() => {
                    dispatch(closeModal());
                    toast.success("Page created sucessfully!");
                  });
                }
              }
            } catch (error) {
              dispatch(asyncActionError(error));
              toast.error(error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form className="ui form">
              <Grid>
                <Grid.Column width={9}>
                  <MyTextInput
                    name="festName"
                    placeholder="College fest name"
                    label={"College fest Name"}
                  />
                  <MyTextInput
                    name="email"
                    placeholder="Contact email"
                    label={"Contact Email"}
                  />
                  <MyTextInput
                    name="website"
                    placeholder="Website"
                    label={"Website"}
                  />
                  <MyTextInput
                    name="linkedIn"
                    placeholder="linkedIn"
                    label={"LinkedIn"}
                  />
                  <MyTextInput
                    name="instagram"
                    placeholder="instagram"
                    label={"Instagram"}
                  />
                </Grid.Column>
                <Grid.Column width={7} textAlign="center">
                  <Image
                    style={{ marginTop: 20 }}
                    src={
                      images.length > 0
                        ? images[images.length - 1]
                        : values.festImg
                    }
                  />
                  <Button
                    icon="file image"
                    content="Add college fest image"
                    color="blue"
                    onClick={handleClick}
                    style={{ marginTop: 20 }}
                    type="button"
                  />
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </Grid.Column>
              </Grid>
              <MyTextArea
                name="description"
                placeholder="Tell people about your college"
                label={"Description"}
                rows="5"
              />
              <FormGroup widths="equal">
                <MyDateInput
                  name="startDate"
                  placeholderText={"MMMM d, yyyy"}
                  dateFormat="MMMM d, yyyy"
                  autoComplete="off"
                  label={"Start Date"}
                />
                <MyDateInput
                  name="endDate"
                  placeholderText={"MMMM d, yyyy"}
                  dateFormat="MMMM d, yyyy"
                  autoComplete="off"
                  label={"End Date"}
                />
              </FormGroup>
              <Button
                loading={isSubmitting || loading}
                disabled={!isValid || isSubmitting || loading}
                type="submit"
                floated="right"
                positive
                content="Submit"
                style={{ marginBottom: 20 }}
              />
              <Button
                disabled={isSubmitting}
                type="button"
                floated="right"
                content="cancel"
                onClick={() => dispatch(closeModal())}
                style={{ marginBottom: 20 }}
              />
            </Form>
          )}
        </Formik>

        <PhotoCropper
          photoCropperOpen={photoCropperOpen}
          setPhotoCropperOpen={setPhotoCropperOpen}
          images={images}
          setImages={setImages}
          imagePreview={files}
          imageNo={0}
          editing={false}
          aspectRatio={2 / 1}
        />
      </ModalWrapper>
    </>
  );
}
