export const topicListArrays = [
    {
      key: "post",
      text: "Post",
      value: "post",
    },
    {
      key: "club",
      text: "Club",
      value: "club",
    },
    {
      key: "college",
      text: "College",
      value: "college",
    },
    {
      key: "fest",
      text: "Fest",
      value: "fest",
    },
    {
      key: "event",
      text: "Event",
      value: "event",
    },
    {
      key: "chats",
      text: "Chats",
      value: "chats",
    },
    {
      key: "profile",
      text: "Profile",
      value: "profile",
    },
    {
      key: "notification",
      text: "Notification",
      value: "notification",
    },
    {
      key: "reminder",
      text: "Reminder",
      value: "reminder",
    },
    {
      key: "search",
      text: "Search",
      value: "search",
    },
    {
      key: "other",
      text: "Other",
      value: "other",
    },
  ];
  