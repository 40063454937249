import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Transition, List } from "semantic-ui-react";
import {
  fetchClubMembersApi,
  fetchSingleClubApi,
} from "../../../../app/apiRequest/clubService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClubNavMenu from "../ClubNavMenu";
import ClubMemberItem from "./ClubMemberItem";

export default function ClubMembersPage({ match }) {
  const dispatch = useDispatch();
  const { selectedClub, members } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.clubId)).then(() => {
      dispatch(fetchClubMembersApi(match.params.clubId));
    });
  }, [dispatch, match.params.clubId]);

  const [visibleAdmin, setVisibleAdmin] = useState(true);
  const [visibleMember, setVisibleMember] = useState(true);

  const handleToggleVisibility = (e, { name }) => {
    if (name === "visibleAdmin") setVisibleAdmin(!visibleAdmin);
    else setVisibleMember(!visibleMember);
  };

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  const admins = [...members.filter((member) => member.admin === true)];
  const generalMember = [
    ...members.filter(
      (member) => member.active === true && member.admin === false
    ),
  ];

  return (
    <div
      className="club-detailed-page"
      style={{ paddingBottom: 100 }}
    >
      <ClubNavMenu club={selectedClub} channelId={match.params.channelId} />
      <div>
        <List style={{ marginTop: 20 }}>
          <List.Item
            name="visibleAdmin"
            icon={visibleAdmin ? "angle down" : "angle right"}
            content={<a>Club Admin</a>} //eslint-disable-line
            onClick={handleToggleVisibility}
          />
        </List>
        <Transition
          visible={visibleAdmin}
          animation="scale"
          unmountOnHide={true}
          duration={500}
        >
          <div>
            {admins.map((member) => (
              <ClubMemberItem member={member} key={member._id} />
            ))}
          </div>
        </Transition>
        <List style={{ marginTop: 20 }}>
          <List.Item
            name="visibleMember"
            icon={visibleMember ? "angle down" : "angle right"}
            content={<a>Club Members</a>} //eslint-disable-line
            onClick={handleToggleVisibility}
          />
        </List>
        <Transition
          visible={visibleMember}
          animation="scale"
          unmountOnHide={true}
          duration={500}
        >
          <div>
            {generalMember.map((member) => (
              <ClubMemberItem member={member} key={member._id} />
            ))}
          </div>
        </Transition>
      </div>
    </div>
  );
}
