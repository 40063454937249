import React, { useEffect, useState } from "react";
import {
  Button,
  FormCheckbox,
  FormGroup,
  FormRadio,
  Header,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { toast } from "react-toastify";
import {
  fetchSingleEventApi,
  manageEventApi,
} from "../../../app/apiRequest/eventService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Link, Redirect, useHistory } from "react-router-dom";
import * as Yup from 'yup';

export default function EventRewardForm({ match, location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [radioValue, setRadioValue] = useState({ value: true });
  const { selectedEvent } = useSelector((state) => state.event);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleEventApi(match.params.id));
  }, [dispatch, match.params.id, location.pathname]);

  useEffect(() => {
    if (selectedEvent) {
      setRadioValue({ value: selectedEvent.partCertificate });
    }
  }, [selectedEvent]);

  const initialValues = selectedEvent?.rewards?.length>0 ? selectedEvent : {
    partCertificate: selectedEvent?.partCertificate,
    totalRewards: selectedEvent?.totalRewards,
    rewards: [],
  };

  const validationSchema = Yup.object({
    totalRewards: Yup.string().required(),
    rewards: Yup.array().of(
      Yup.object().shape({
        rank: Yup.string().required(),
        money: Yup.number().required()
      })
    ),
  });

  const handleRadioChange = (e, { value }) => {
    setRadioValue({ value });
  };

  if (loading) return <LoadingComponent content="Loadind event..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <Segment
      clearing
      style={{ marginTop: 75, marginRight: 20 }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            values._id = selectedEvent?._id;
            console.log(values);
            values.partCertificate = radioValue.value;
            values.stepsDone = 6;
            dispatch(manageEventApi(values)).then(() => {
              history.push("/events");
            });
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className="ui form">
            <FieldArray name="rewards">
              {({ insert, remove, push }) => (
                <div style={{ marginBottom: 16 }}>
                  <Header color="teal" content="Prize" />
                  <Button
                    content="Add more prize"
                    type="button"
                    icon="add"
                    color="blue"
                    floated="right"
                    onClick={() =>
                      push({
                        rank: "",
                        money: "",
                        cetificate: false,
                        otherDetails: "",
                      })
                    }
                  />
                  <Header
                    as={"h4"}
                    color="black"
                    content="Participation Certificate will be provided?"
                  />
                  <FormGroup inline>
                    <FormRadio
                      label="Yes"
                      checked={radioValue.value === true}
                      onChange={handleRadioChange}
                      value={true}
                    />
                    <FormRadio
                      label="No"
                      checked={radioValue.value === false}
                      onChange={handleRadioChange}
                      value={false}
                    />
                  </FormGroup>
                  <MyTextInput
                    name="totalRewards"
                    placeholder=""
                    label={"Total rewards"}
                  />
                  {values.rewards?.length > 0 &&
                    values.rewards.map((reward, index) => (
                      <Segment key={index}>
                        <FormGroup widths="equal">
                          <MyTextInput
                            name={`rewards.${index}.rank`}
                            placeholder="Rank"
                            label={"Rank"}
                          />
                          <MyTextInput
                            name={`rewards.${index}.money`}
                            placeholder="Prize money"
                            label={"Prize money"}
                          />
                          <FormCheckbox
                            name={`rewards.${index}.certificate`}
                            label="Cerificate"
                            defaultChecked= {values.rewards[index].certificate}
                            onClick={() => values.rewards[index].certificate = !values.rewards[index].certificate}
                            style={{ marginLeft: 20, marginTop: 30 }}
                          />
                          <Button type="button" icon='close' color="red" floated="right" size="mini" onClick={() => remove(index)}/>
                        </FormGroup>
                        <MyTextInput
                          name={`rewards.${index}.otherDetails`}
                          placeholder="Other details"
                          label={"Other details"}
                        />
                      </Segment>
                    ))}
                </div>
              )}
            </FieldArray>
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              floated="right"
              positive
              content="Done"
              style={{ marginTop: 40 }}
            />
            <Button
              disabled={isSubmitting}
              type="button"
              floated="right"
              content="Previos"
              as={Link}
              to={`/event/manage/rounds/${selectedEvent?._id}`}
              style={{ marginTop: 40 }}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
