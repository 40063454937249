import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Header } from "semantic-ui-react";
import {
  contactUsApi,
  uploadFileApi,
} from "../../../app/apiRequest/extraService";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { closeModal } from "../../../app/common/modals/modalReducer";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";

export default function CollegPageRequest() {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const { loading } = useSelector((state) => state.async);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    college: "",
    location: "",
    collegeMail: "",
    website: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    college: Yup.string().required(),
    location: Yup.string().required(),
    collegeMail: Yup.string().required(),
    website: Yup.string().url(),
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    setFile(file);
  };

  const handleCancleBtn = () => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="small" header="Contact us">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            if (file) {
              var data = new FormData();
              data.append("imageFile", file);
              await dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                values.file = response[0].location;
                dispatch(contactUsApi(values));
              });
            } else {
              dispatch(contactUsApi(values));
            }
          } catch (error) {
            dispatch(asyncActionError(error));
            console.log(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="ui form">
            <Header
              sub
              color="blue"
              content="This page is only created by College official Id. We will contact you via email or WhatsApp."
              style={{ marginBottom: 20 }}
            />
            <MyTextInput name="name" placeholder="your name" label={"Name"} />
            <FormGroup widths={"equal"}>
              <MyTextInput
                name="email"
                placeholder="your email"
                label={"Your College Email"}
              />
              <MyTextInput
                name="phone"
                placeholder="whats app number"
                label={"Whats app number"}
              />
            </FormGroup>
            <MyTextInput
              name="college"
              placeholder="College Name"
              label={"College Name"}
            />
            <MyTextInput
              name="location"
              placeholder="College Location"
              label={"College Location"}
            />
            <FormGroup widths={"equal"}>
              <MyTextInput
                name="collegeMail"
                placeholder="College Email"
                label={"College Email"}
              />
              <MyTextInput
                name="website"
                placeholder="College Website"
                label={"College Website"}
              />
            </FormGroup>

            <div style={{ marginBottom: 16 }}>
              <label htmlFor="myfile">{`Add a file (optional)`}</label>
              <input
                type="file"
                id="myfile"
                name="myfile"
                onChange={handleFileChange}
              />
            </div>
            <Button
              loading={isSubmitting || loading}
              disabled={!isValid || !dirty || isSubmitting || loading}
              floated="right"
              type="submit"
              color="blue"
              content="Submit"
              style={{ margin: 10 }}
            />
            <Button
              loading={isSubmitting}
              floated="right"
              type="button"
              content="Cancel"
              color="grey"
              onClick={handleCancleBtn}
              style={{ margin: 10 }}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
