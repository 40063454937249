/* global google */
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormRadio,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import MyPlaceInput from "../../../app/common/form/MyPlaceInput";
import { getFile, isImage } from "../../../app/common/util/util";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import {
  fetchSingleEventApi,
  manageEventApi,
} from "../../../app/apiRequest/eventService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { fetchCollegeFestsApi } from "../../../app/apiRequest/collegeFestService";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { compressImg } from "../../../app/common/photos/compressImg";
import MyDateInput from "../../../app/common/form/MyDateInput";

export default function EventDetailsForm({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [radioValue, setRadioValue] = useState({ name: "offline" });
  const { selectedEvent } = useSelector((state) => state.event);
  const { loading, error } = useSelector((state) => state.async);
  const { fests } = useSelector((state) => state.college);

  useEffect(() => {
    dispatch(fetchSingleEventApi(match.params.id));
    // eslint-disable-next-line
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (selectedEvent) {
      setRadioValue({ name: selectedEvent.location });
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (!selectedEvent) return;
    if (selectedEvent.college)
      dispatch(fetchCollegeFestsApi(selectedEvent.college?._id));
    // eslint-disable-next-line
  }, [dispatch, selectedEvent]);

  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  var festsOption = [];
  fests.length > 0 &&
    fests.map((fest) =>
      festsOption.push({
        key: fest._id,
        text: fest.festName,
        value: fest._id,
        image: { avatar: true, src: fest.festImg },
      })
    );

  const initialValues = selectedEvent;

  const validationSchema = Yup.object({
    eventTitle: Yup.string().required("You must provide a title"),
  });

  const handleRadioChange = (e, { name }) => {
    setRadioValue({ name });
  };

  if (!selectedEvent && !error)
    return <LoadingComponent content={"Loadind event..."} />;

  if (error) return <Redirect to="/error" />;

  return (
    <>
      <Segment clearing style={{ marginTop: 75, marginRight: 20 }}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              values.location = radioValue.name;
              if (values.festival === "") {
                values.festival = null;
              }
              if (images.length > 0) {
                var data = new FormData();
                await compressImg(images[images.length - 1]).then(
                  async (compressedDataUrl) => {
                    let file = await getFile(compressedDataUrl, filename);
                    data.append("imageFile", file);
                  }
                );
                dispatch(uploadFileApi(data)).then((response) => {
                  dispatch(asyncActionFinish());
                  values.eventImg = response[0].location;
                  dispatch(manageEventApi(values)).then(
                    () => {
                      history.push(
                        `/event/manage/registration/${selectedEvent?._id}`
                      );
                    },
                    (error) => history.push("/error")
                  );
                });
              } else {
                dispatch(manageEventApi(values)).then(
                  () => {
                    history.push(
                      `/event/manage/registration/${selectedEvent?._id}`
                    );
                  },
                  (error) => history.push("/error")
                );
              }
            } catch (error) {
              dispatch(asyncActionError(error));
              toast.error(error.message);
            } finally {
              setSubmitting(false);
              console.log(values);
            }
          }}
        >
          {({ isSubmitting, isValid, values }) => (
            <Form className="ui form">
              <Header color="teal" content="Event Details" />
              <Grid>
                <Grid.Column width={9}>
                  <MyTextInput
                    name="eventTitle"
                    placeholder="Event title"
                    label={"Event Title*"}
                  />
                  {selectedEvent.college && festsOption.length > 0 && (
                    <MySelectInput
                      name="festival"
                      placeholder={
                        selectedEvent.festival?.festName ?? "Fest name"
                      }
                      options={festsOption}
                      label={"Festival Name (optional)"}
                    />
                  )}
                  <Header
                    sub
                    color="teal"
                    content="Event Date &amp; Location detail"
                    style={{ marginBottom: 10 }}
                  />
                  <FormGroup widths={"equal"}>
                    <MyDateInput
                      name="eventStartDate"
                      placeholderText={new Date().toString("MMM dd yyyy hh:mm")}
                      timeFormat="HH:mm"
                      showTimeSelect
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy  h:mm a"
                      autoComplete="off"
                      minDate={new Date()}
                      label={"Start Date & Time"}
                    />
                    <MyDateInput
                      name="eventEndDate"
                      placeholderText={new Date().toString("MMM dd yyyy hh:mm")}
                      timeFormat="HH:mm"
                      showTimeSelect
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy  h:mm a"
                      autoComplete="off"
                      minDate={new Date()}
                      label={"End Date & Time"}
                    />
                  </FormGroup>
                  <FormGroup inline>
                    <FormRadio
                      label="offline"
                      checked={radioValue.name === "offline"}
                      onChange={handleRadioChange}
                      name="offline"
                    />
                    <FormRadio
                      label="online"
                      checked={radioValue.name === "online"}
                      onChange={handleRadioChange}
                      name="online"
                    />
                  </FormGroup>
                  {radioValue.name === "offline" && (
                    <>
                      <MyPlaceInput name="city" placeholder="City" />
                      <MyPlaceInput
                        name="venue"
                        disabled={!values.city.latLng}
                        placeholder="Venue"
                        options={{
                          location: new google.maps.LatLng(values.city.latLng),
                          radius: 1000,
                          types: ["establishment"],
                        }}
                      />
                    </>
                  )}
                  {radioValue.name === "online" &&
                    values.eventType === "entertainment" && (
                      <>
                        <MyTextInput
                          name="eventLink"
                          placeholder="Event Link"
                        />
                      </>
                    )}
                </Grid.Column>
                <Grid.Column width={7}>
                  <Segment textAlign="center">
                    <Image
                      style={{ width: 510, height: 189, marginTop: 20 }}
                      src={
                        images.length > 0
                          ? images[images.length - 1]
                          : values.eventImg
                      }
                    />
                    <p>Image dimensions:- 3 : 1</p>
                    <Button
                      basic
                      icon="file image"
                      content="Change image"
                      color="blue"
                      onClick={handleClick}
                      style={{ marginTop: 20 }}
                      type="button"
                    />
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
              <div style={{ marginTop: 20 }}>
                <Button
                  loading={isSubmitting || loading}
                  disabled={!isValid || isSubmitting || loading}
                  type="submit"
                  floated="right"
                  positive
                  content="Save &amp; Continue"
                />
                <Button
                  disabled={isSubmitting}
                  type="button"
                  floated="right"
                  content="cancel"
                  as={Link}
                  to={`/events`}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
      <PhotoCropper
        photoCropperOpen={photoCropperOpen}
        setPhotoCropperOpen={setPhotoCropperOpen}
        images={images}
        setImages={setImages}
        imagePreview={files}
        imageNo={0}
        editing={false}
        aspectRatio={3 / 1}
      />
    </>
  );
}
