import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { openModal } from "../../../app/common/modals/modalReducer";
import UnauthModal from "../../auth/UnauthModal";
import {
  Button,
  Confirm,
  Grid,
  Header,
  Image,
  Item,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";
import { deleteEventApi } from "../../../app/apiRequest/eventService";

export default function EventDetailedHeader({ event, isAdmin }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  var hostName, hostImg, hostEmail, hostWebsite, hostLink;
  if (event.club) {
    hostName = event.club.clubName;
    hostImg = event.club.clubImg;
    hostEmail = event.club.email;
    hostWebsite = event.club.website;
    hostLink = `/club/about/${event.club._id}`;
  } else if (event.festival) {
    hostName = event.festival.festName;
    hostImg = event.festival.festImg;
    hostEmail = event.festival.email;
    hostWebsite = event.festival.website;
    hostLink = `/club/fest/${event.festival._id}`;
  } else if (event.college) {
    hostName = event.college.collegeName;
    hostImg = event.college.collegeImg;
    hostEmail = event.college.email;
    hostWebsite = event.college.website;
    hostLink = `/college/${event.college._id}`;
  }

  var registerBtn = "Register";
  var registerDisabled = false;
  if (event.registrationEndDate < new Date().toISOString()) {
    registerBtn = "Registeration closed";
    registerDisabled = true;
  } else if (event.registerdTeamLead.includes(user)) {
    registerBtn = "Registered";
    registerDisabled = true;
  }

  const handleUserRegistration = () => {
    if (!isAuthenticated) {
      setModalOpen(true);
    } else if (event.registrationPlace === "out") {
      window.open(event.registrationLink, "_blank");
    } else {
      dispatch(openModal({ modalType: "UserRegistrationForm" }));
    }
  };

  const handleReportBtn = () => {
    if (!isAuthenticated) {
      setModalOpen(true);
    } else {
      dispatch(
        openModal({
          modalType: "ReportForm",
          modalProps: {
            type: "event",
            id: event._id,
          },
        })
      );
    }
  };

  const handeleDeleteEvent = () => {
    deleteEventApi(event._id).then(() => {
      setOpenDeleteConfirm(false);
      history.push("/events");
    });
  };

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      <Segment
        vertical
        style={{
          marginBottom: 20,
        }}
      >
        <Image src={event.eventImg} style={{ width: "100%" }} />
      </Segment>
      <Grid>
        <Grid.Column width={8}>
          <Grid>
            <Grid.Column width={4}>
              <Item.Image size="small" src={hostImg} />
            </Grid.Column>
            <Grid.Column width={11}>
              <Item.Group>
                <Item>
                  <Item.Content>
                    <Item.Header
                      style={{
                        display: "block",
                        marginTop: 2,
                        marginBottom: 10,
                      }}
                    >
                      {event.eventTitle}
                    </Item.Header>
                    <Item.Description
                      as={"a"}
                      onClick={() => history.push(hostLink)}
                    >
                      {hostName}
                    </Item.Description>
                    {(event.club || event.festival) && event.college && (
                      <Item.Extra
                        as="a"
                        href={`${process.env.REACT_APP_DOMAIN_URL}college/${event.college?._id}`}
                      >
                        {event.college?.collegeName}
                      </Item.Extra>
                    )}
                    <Item.Extra>
                      <List>
                        <List.Item
                          icon="mail"
                          content={
                            <a
                              href={`mailto:${hostEmail}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {hostEmail}
                            </a>
                          }
                        />
                        {hostWebsite && (
                          <List.Item
                            icon="linkify"
                            content={
                              <a
                                href={hostWebsite}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {hostWebsite}
                              </a>
                            }
                          />
                        )}
                      </List>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              </Item.Group>
              <div style={{ marginTop: 20 }}>
                {isAdmin && (
                  <>
                    <Button
                      content="Manage Event"
                      color="blue"
                      floated="left"
                      onClick={() =>
                        history.push(`/event/manage/details/${event._id}`)
                      }
                    />
                    <Popup
                      trigger={<Button icon="ellipsis vertical" color="blue" />}
                      content={
                        <>
                          {event.takeRegistration &&
                            event.registrationPlace === "on" && (
                              <Button
                                fluid
                                icon="eye"
                                content="Registered teams"
                                color="blue"
                                style={{ display: "block", marginBottom: 4 }}
                                as={Link}
                                to={`/events/teams/${event._id}`}
                              />
                            )}
                          {event.eventType === "contest" && (
                            <Button
                              fluid
                              icon="upload"
                              content="Upload Result"
                              color="blue"
                              style={{ display: "block", marginBottom: 4 }}
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalType: "EventResultForm",
                                    modalProps: event,
                                  })
                                );
                                setOpenPopup(false);
                              }}
                            />
                          )}
                          <Button
                            fluid
                            icon="setting"
                            content="Manage Admin"
                            color="blue"
                            style={{ display: "block", marginBottom: 4 }}
                            onClick={() => {
                              dispatch(
                                openModal({
                                  modalType: "ManageAdmin",
                                  modalProps: {
                                    admin: event.admin,
                                    type: "event",
                                    selected: event,
                                  },
                                })
                              );
                              setOpenPopup(false);
                            }}
                          />
                          <Button
                            fluid
                            icon="trash"
                            content="Delete Event"
                            color="blue"
                            style={{ display: "block" }}
                            onClick={() => {
                              setOpenPopup(false);
                              setOpenDeleteConfirm(true);
                            }}
                          />
                        </>
                      }
                      on="click"
                      position="bottom left"
                      open={openPopup}
                      onClose={() => setOpenPopup(false)}
                      onOpen={() => setOpenPopup(true)}
                    />
                  </>
                )}
                {!isAdmin && event.takeRegistration && (
                  <>
                    <Button
                      style={{ width: 150 }}
                      content={registerBtn}
                      color="blue"
                      floated="left"
                      disabled={registerDisabled}
                      onClick={handleUserRegistration}
                    />
                    <Button
                      icon="exclamation triangle"
                      floated="left"
                      onClick={handleReportBtn}
                    />
                  </>
                )}
                {!isAdmin && !event.takeRegistration && (
                  <>
                    <Button
                      icon="exclamation triangle"
                      content="Report Event"
                      color="blue"
                      onClick={handleReportBtn}
                    />
                  </>
                )}
              </div>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column width={8}>
          <Segment compact>
            <List relaxed>
              {event.takeRegistration && (
                <List.Item>
                  <List.Content>
                    <List.Icon name="calendar alternate outline" color="blue" />
                    <b>Registration Deadline :- </b>
                    {new Date(event.registrationEndDate).toDateString(
                      "MMM dd yyyy hh:mm"
                    )}
                  </List.Content>
                </List.Item>
              )}
              <List.Item>
                <List.Content>
                  <List.Icon name="calendar outline" color="blue" />
                  <b>Event Timeline :- </b>
                  {`${new Date(
                    event.eventStartDate
                  ).toLocaleString()} - ${new Date(
                    event.eventEndDate
                  ).toLocaleString()}`}
                </List.Content>
              </List.Item>
              {event.takeRegistration && (
                <List.Item>
                  <List.Content>
                    <List.Icon name="user" color="blue" />
                    <b>Team size :- </b>
                    {event.participation === "team"
                      ? `${event.minSizeTeam} - ${event.maxSizeTeam} members`
                      : "Individual Participation"}
                  </List.Content>
                </List.Item>
              )}
              <List.Item>
                <List.Content>
                  <List.Icon name="eye" color="blue" /> {event.views}{" "}
                  impressions
                </List.Content>
              </List.Item>
              {event.takeRegistration && (
                <List.Item>
                  <List.Content>
                    <List.Icon name="rupee sign" color="blue" />
                    {event.registrationCharge === "yes"
                      ? `${event.registrationFee} ${event.payment}`
                      : "Free Registration"}
                  </List.Content>
                </List.Item>
              )}
              <List.Item>
                <List.Content>
                  <List.Icon name="marker" color="blue" />
                  {event.location === "offline"
                    ? event.venue.address
                    : event.takeRegistration || event.eventLink === ""
                    ? "online"
                    : event.eventLink}
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        </Grid.Column>
      </Grid>
      {event.results.length > 0 &&
        (isAdmin || event.registerdTeamLead.includes(user)) && (
          <Header color="blue">
            Results :-{" "}
            {event.results.map((i) => (
              <a href={i.file} key={i._id}>
                <Button content={i.filename} icon="download" />
              </a>
            ))}
          </Header>
        )}
      <Confirm
        open={openDeleteConfirm}
        size="mini"
        header="Do you want to delete this event?"
        content="This event will be deleted. And it can't be reversed."
        onCancel={() => setOpenDeleteConfirm(false)}
        onConfirm={handeleDeleteEvent}
      />
    </>
  );
}
