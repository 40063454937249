const CREATE_CLUB = "CREATE_CLUB";
const UPDATE_CLUB = "UPDATE_CLUB";
const REMOVE_CLUB = "REMOVE_CLUB";
const FETCH_CLUB = "FETCH_CLUB";
const FETCH_BLUE_CLUB = "FETCH_BLUE_CLUB";
const FETCH_YOUR_CLUB = "FETCH_YOUR_CLUB";
const LISTEN_TO_SELECTED_CLUB = "LISTEN_TO_SELECTED_CLUB";
const LISTEN_TO_SELECTED_CHANNEL = "LISTEN_TO_SELECTED_CHANNEL";
const LISTEN_TO_SELECTED_CLUB_MEMBERS = "LISTEN_TO_SELECTED_CLUB_MEMBERS";
const LISTEN_TO_SELECTED_CHANNEL_MEMBERS = "LISTEN_TO_SELECTED_CHANNEL_MEMBERS";
const CLEAR_SELECTED_CLUB = "CLEAR_SELECTED_CLUB";
const CLEAR_CLUBS = "CLEAR_CLUBS";
const CLEAR_YOUR_CLUBS = "CLEAR_YOUR_CLUBS";

export const YOUR_CLUBS_FETCHED = "YOUR_CLUBS_FETCHED";
export const CLUB_RETAIN_STATE = "CLUB_RETAIN_STATE";

export function addClubs(clubs) {
  return {
    type: FETCH_CLUB,
    payload: clubs,
  };
}

export function addBlueClubs(clubs) {
  return {
    type: FETCH_BLUE_CLUB,
    payload: clubs,
  };
}

export function addYourClubs(clubs) {
  return {
    type: FETCH_YOUR_CLUB,
    payload: clubs,
  };
}

export function listenToSelectedClub(club) {
  return {
    type: LISTEN_TO_SELECTED_CLUB,
    payload: club,
  };
}

export function listenToSelectedChannel(channel) {
  return {
    type: LISTEN_TO_SELECTED_CHANNEL,
    payload: channel,
  };
}

export function listenToSelectedClubMembers(members) {
  return {
    type: LISTEN_TO_SELECTED_CLUB_MEMBERS,
    payload: members,
  };
}

export function listenToSelectedChannelMembers(members) {
  return {
    type: LISTEN_TO_SELECTED_CHANNEL_MEMBERS,
    payload: members,
  };
}

export function clearSelectedClub() {
  return {
    type: CLEAR_SELECTED_CLUB,
  };
}

export function createClub(club) {
  return {
    type: CREATE_CLUB,
    payload: club,
  };
}

export function updateClub(club) {
  return {
    type: UPDATE_CLUB,
    payload: club,
  };
}

export function removeClub(clubId) {
  return {
    type: REMOVE_CLUB,
    payload: clubId,
  };
}

export function clearClubs() {
  return {
    type: CLEAR_CLUBS,
  };
}

export function clearYourClubs() {
  return {
    type: CLEAR_YOUR_CLUBS,
  };
}

const initialState = {
  clubs: [],
  blueClubs: [],
  yourClubs: [],
  selectedClub: null,
  selectedChannel: null,
  members: [],
  channelMembers: [],
  selectedClubFaqs: [],
  retainState: false,
  yourClubsFetched: false,
};

export default function clubReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_CLUB:
      return {
        ...state,
        clubs: [...state.clubs, payload],
      };
    case UPDATE_CLUB:
      return {
        ...state,
        clubs: [...state.clubs.filter((evt) => evt.id !== payload.id), payload],
      };
    case REMOVE_CLUB:
      return {
        ...state,
        clubs: [...state.clubs.filter((evt) => evt.id !== payload)],
      };
    case FETCH_CLUB:
      return {
        ...state,
        clubs: payload,
      };
    case FETCH_BLUE_CLUB:
      return {
        ...state,
        blueClubs: payload,
      };
    case FETCH_YOUR_CLUB:
      return {
        ...state,
        yourClubs: payload,
      };
    case LISTEN_TO_SELECTED_CLUB:
      return {
        ...state,
        selectedClub: payload,
      };
    case LISTEN_TO_SELECTED_CHANNEL:
      return {
        ...state,
        selectedChannel: payload,
      };
    case LISTEN_TO_SELECTED_CLUB_MEMBERS:
      return {
        ...state,
        members: payload,
      };
    case LISTEN_TO_SELECTED_CHANNEL_MEMBERS:
      return {
        ...state,
        channelMembers: payload,
      };
    case CLEAR_SELECTED_CLUB:
      return {
        ...state,
        selectedClub: null,
      };
    case CLEAR_CLUBS:
      return {
        ...state,
        clubs: [],
      };
    case CLEAR_YOUR_CLUBS:
      return {
        ...state,
        yourClubs: [],
      };
    case CLUB_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    case YOUR_CLUBS_FETCHED:
      return {
        ...state,
        yourClubsFetched: true,
      };
    default:
      return state;
  }
}
