import React, { useEffect, useState } from "react";
import {
  Button,
  FormCheckbox,
  FormGroup,
  FormRadio,
  Header,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import {
  fetchSingleEventApi,
  manageEventApi,
} from "../../../app/apiRequest/eventService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Link, Redirect, useHistory } from "react-router-dom";

export default function EventEligibilityForm({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedEvent } = useSelector((state) => state.event);
  const { error } = useSelector((state) => state.async);
  const [visibility, setVisibility] = useState(null);
  const [genderRadioValue, setGenderRadioValue] = useState(null);

  useEffect(() => {
    dispatch(fetchSingleEventApi(match.params.id));
  }, [dispatch, match.params.id]);

  const initialValues = selectedEvent;

  useEffect(() => {
    if (selectedEvent) {
      setVisibility({ name: selectedEvent.visibility });
      setGenderRadioValue({ name: selectedEvent.genderEligibility });
    }
  }, [selectedEvent]);

  const handleVisibilityChange = (e, { name }) => {
    setVisibility({ name });
  };

  const handleGenderRadioChange = (e, { name }) => {
    setGenderRadioValue({ name });
  };

  function handleDomainsCheckBox(values, name) {
    if (!values.eventDomain.includes(name)) {
      values.eventDomain.push(name);
    } else {
      const index = values.eventDomain.indexOf(name);
      if (index > -1) {
        values.eventDomain.splice(index, 1);
      }
    }
  }

  function handleCategoryCheckBox(values, name) {
    if (!values.eventCategory.includes(name)) {
      values.eventCategory.push(name);
    } else {
      const index = values.eventCategory.indexOf(name);
      if (index > -1) {
        values.eventCategory.splice(index, 1);
      }
    }
  }

  function handleOppurtunityCheckBox(values, name) {
    if (name === "everyOne") {
      values.opportunityFor = ["everyone"];
    } else if (!values.opportunityFor.includes(name)) {
      values.opportunityFor.push(name);
    } else {
      const index = values.opportunityFor.indexOf(name);
      if (index > -1) {
        values.opportunityFor.splice(index, 1);
      }
    }
  }

  if (!selectedEvent && !error)
    return <LoadingComponent content="Loadind event..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <Segment clearing style={{ marginTop: 75, marginRight: 20 }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            values.visibility = visibility.name;
            values.genderEligibility = genderRadioValue.name;
            dispatch(manageEventApi(values)).then(
              () => {
                history.push(
                  `/event/manage/registration/${selectedEvent?._id}`
                );
              },
              (error) => history.push("/error")
            );
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className="ui form">
            <Header color="teal" content="Event Eligiblity" />
            <FormGroup inline>
              <label>
                <strong>Visibility*</strong> (Event can be seen in)
              </label>
              <FormRadio
                label="Public"
                checked={visibility?.name === "public"}
                onChange={handleVisibilityChange}
                name="public"
              />
              <FormRadio
                label="Only in your college"
                checked={visibility?.name === "college"}
                onChange={handleVisibilityChange}
                name="college"
              />
              <FormRadio
                label="Only in your club"
                checked={visibility?.name === "club"}
                onChange={handleVisibilityChange}
                name="club"
              />
            </FormGroup>
            <Header as={"h4"} color="black" content="Gender" />
            <FormGroup inline>
              <FormRadio
                label="All"
                checked={genderRadioValue?.name === "all"}
                onChange={handleGenderRadioChange}
                name="all"
              />
              <FormRadio
                label="Male"
                checked={genderRadioValue?.name === "male"}
                onChange={handleGenderRadioChange}
                name="male"
              />
              <FormRadio
                label="Female"
                checked={genderRadioValue?.name === "female"}
                onChange={handleGenderRadioChange}
                name="female"
              />
              <FormRadio
                label="Transgender"
                checked={genderRadioValue?.name === "transgender"}
                onChange={handleGenderRadioChange}
                name="transgender"
              />
              <FormRadio
                label="Intersex"
                checked={genderRadioValue?.name === "intersex"}
                onChange={handleGenderRadioChange}
                name="intersex"
              />
              <FormRadio
                label="Non-binary"
                checked={genderRadioValue?.name === "nonbinary"}
                onChange={handleGenderRadioChange}
                name="nonbinary"
              />
              <FormRadio
                label="Others"
                checked={genderRadioValue?.name === "others"}
                onChange={handleGenderRadioChange}
                name="others"
              />
            </FormGroup>
            <Header
              as="h4"
              content="Domains : "
              style={{ marginTop: 10, marginRight: 10 }}
            />
            <FormGroup inline>
              <FormCheckbox
                name="Arts & Science"
                label="Arts & Science"
                defaultChecked={values.eventDomain.includes("Arts & Science")}
                onClick={(e, { name }) => handleDomainsCheckBox(values, name)}
              />
              <FormCheckbox
                name="Business"
                label="Business"
                defaultChecked={values.eventDomain.includes("Business")}
                onClick={(e, { name }) => handleDomainsCheckBox(values, name)}
              />
              <FormCheckbox
                name="Engineering"
                label="Engineering"
                defaultChecked={values.eventDomain.includes("Engineering")}
                onClick={(e, { name }) => handleDomainsCheckBox(values, name)}
              />
              <FormCheckbox
                name="Others"
                label="Others"
                defaultChecked={values.eventDomain.includes("Others")}
                onClick={(e, { name }) => handleDomainsCheckBox(values, name)}
              />
            </FormGroup>
            <Header
              as="h4"
              content="Categories : "
              style={{ marginTop: 10, marginRight: 10 }}
            />
            <FormGroup widths={8}>
              <FormCheckbox
                name="Article Writing"
                label="Article Writing"
                defaultChecked={values.eventCategory.includes(
                  "Article Writing"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Coding challange"
                label="Coding challange"
                defaultChecked={values.eventCategory.includes(
                  "Coding challange"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="College festival"
                label="College festival"
                defaultChecked={values.eventCategory.includes(
                  "College festival"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Conclave"
                label="Conclave"
                defaultChecked={values.eventCategory.includes("Conclave")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Conference"
                label="Conference"
                defaultChecked={values.eventCategory.includes("Conference")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Dance"
                label="Dance"
                defaultChecked={values.eventCategory.includes("Dance")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Data Analytics"
                label="Data Analytics"
                defaultChecked={values.eventCategory.includes("Data Analytics")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Data science"
                label="Data science"
                defaultChecked={values.eventCategory.includes("Data science")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <FormGroup widths={8}>
              <FormCheckbox
                name="Debates"
                label="Debates"
                defaultChecked={values.eventCategory.includes("Debates")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Designing"
                label="Designing"
                defaultChecked={values.eventCategory.includes("Designing")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Dramatics"
                label="Dramatics"
                defaultChecked={values.eventCategory.includes("Dramatics")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Entrepreneurship"
                label="Entrepreneurship"
                defaultChecked={values.eventCategory.includes(
                  "Entrepreneurship"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Fashion"
                label="Fashion"
                defaultChecked={values.eventCategory.includes("Fashion")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Fellowship"
                label="Fellowship"
                defaultChecked={values.eventCategory.includes("Fellowship")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Finance"
                label="Finance"
                defaultChecked={values.eventCategory.includes("Finance")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Hackathon"
                label="Hackathon"
                defaultChecked={values.eventCategory.includes("Hackathon")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <FormGroup widths={8}>
              <FormCheckbox
                name="Human Resource"
                label="Human Resource"
                defaultChecked={values.eventCategory.includes("Human Resource")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Literary"
                label="Literary"
                defaultChecked={values.eventCategory.includes("Literary")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Marketing"
                label="Marketing"
                defaultChecked={values.eventCategory.includes("Marketing")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Music"
                label="Music"
                defaultChecked={values.eventCategory.includes("Music")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Online Trading"
                label="Online Trading"
                defaultChecked={values.eventCategory.includes("Online Trading")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Panel discussion"
                label="Panel discussion"
                defaultChecked={values.eventCategory.includes(
                  "Panel discussion"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Photography"
                label="Photography"
                defaultChecked={values.eventCategory.includes("Photography")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Presentation"
                label="Presentation"
                defaultChecked={values.eventCategory.includes("Presentation")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <FormGroup widths={8}>
              <FormCheckbox
                name="Quiz"
                label="Quiz"
                defaultChecked={values.eventCategory.includes("Quiz")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Robotics"
                label="Robotics"
                defaultChecked={values.eventCategory.includes("Robotics")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Scholarship"
                label="Scholarship"
                defaultChecked={values.eventCategory.includes("Scholarship")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Social Media"
                label="Social Media &amp; Digital"
                defaultChecked={values.eventCategory.includes("Social Media")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Sports"
                label="Sports"
                defaultChecked={values.eventCategory.includes("Sports")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Startup fair"
                label="Startup fair"
                defaultChecked={values.eventCategory.includes("Startup fair")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Treasure hunt"
                label="Treasure hunt"
                defaultChecked={values.eventCategory.includes("Treasure hunt")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Workshops"
                label="Workshops"
                defaultChecked={values.eventCategory.includes("Workshops")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <Header as={"h4"} color="black" content="Opportunity open for" />
            <FormGroup inline>
              <FormCheckbox
                name="Everyone"
                label="Everyone"
                defaultChecked={values.opportunityFor.includes("everyone")}
                onClick={(e, { name }) =>
                  handleOppurtunityCheckBox(values, name)
                }
              />
              <FormCheckbox
                name="College Students"
                label="College Students"
                defaultChecked={values.opportunityFor.includes(
                  "College Students"
                )}
                onClick={(e, { name }) =>
                  handleOppurtunityCheckBox(values, name)
                }
              />
              <FormCheckbox
                name="Professionals"
                label="Professionals"
                defaultChecked={values.opportunityFor.includes("Professionals")}
                onClick={(e, { name }) =>
                  handleOppurtunityCheckBox(values, name)
                }
              />
              <FormCheckbox
                name="School Students"
                label="School Students"
                defaultChecked={values.opportunityFor.includes(
                  "School Students"
                )}
                onClick={(e, { name }) =>
                  handleOppurtunityCheckBox(values, name)
                }
              />
              <FormCheckbox
                name="Fresher"
                label="Fresher"
                defaultChecked={values.opportunityFor.includes("Fresher")}
                onClick={(e, { name }) =>
                  handleOppurtunityCheckBox(values, name)
                }
              />
              <FormCheckbox
                name="Others"
                label="Others"
                defaultChecked={values.opportunityFor.includes("Others")}
                onClick={(e, { name }) =>
                  handleOppurtunityCheckBox(values, name)
                }
              />
            </FormGroup>
            <Button
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              type="submit"
              floated="right"
              positive
              content="Save & Continue"
            />
            <Button
              disabled={isSubmitting}
              type="button"
              floated="right"
              content="Previous"
              as={Link}
              to={`/event/manage/details/${selectedEvent?._id}`}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
