import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import UnauthModal from "../../features/auth/UnauthModal";

export default function PrivateRoute({
  component: Component,
  prevLocation,
  ...rest
}) {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false); // eslint-disable-line no-unused-vars

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <UnauthModal {...props} setModalOpen={setModalOpen} />
      }
    />
  );
}
