import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClubNavMenu from "../ClubNavMenu";

export default function ClubMessageDashboard({ match }) {
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    if (match.params.id === selectedClub?._id) return;
    dispatch(fetchSingleClubApi(match.params.id));
  }, [dispatch, match.params.id, selectedClub]);

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  return (
    <div className="club-detailed-page">
      <ClubNavMenu club={selectedClub} />
      <div>
        
      </div>
    </div>
  );
}
