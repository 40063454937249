import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormCheckbox,
  FormGroup,
  FormRadio,
  Grid,
  Header,
  Image,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { toast } from "react-toastify";
import MyPlaceInput from "../../../app/common/form/MyPlaceInput";
import { getFile, isImage } from "../../../app/common/util/util";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import MyTextArea from "../../../app/common/form/MyTextArea";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import {
  createCollegeApi,
  fetchSingleCollegeApi,
  updateCollegeApi,
} from "../../../app/apiRequest/collegeFestService";
import { clearSelectedCollege } from "../collegeFestReducer";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { compressImg } from "../../../app/common/photos/compressImg";

export default function CollegeForm() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state) => state.modal);
  const { selectedCollege } = useSelector((state) => state.college);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const hiddenFileInput = useRef(null);
  const { loading } = useSelector((state) => state.async);

  useEffect(() => {
    if (modalProps == null) {
      dispatch(clearSelectedCollege());
      return;
    }
    dispatch(fetchSingleCollegeApi(modalProps._id));
  }, [dispatch, modalProps]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  const initialValues = selectedCollege ?? {
    collegeName: "",
    collegeImg:
      "https://learningx-s3.s3.ap-south-1.amazonaws.com/image_2_1.png",
    domain: [],
    email: "",
    website: "",
    instagram: "",
    linkedIn: "",
    restricted: false,
    emailDomain: "",
    description: "",
    city: {
      address: "",
      latLng: null,
    },
  };

  const [radioValue, setRadioValue] = useState(initialValues.restricted);

  const validationSchema = Yup.object({
    collegeName: Yup.string().required("You must provide a name"),
    email: Yup.string().email().required(),
    website: Yup.string().required(),
    city: Yup.object().shape({
      address: Yup.string().required("City is required"),
    }),
  });

  function handleCheckBox(values, name) {
    if (!values.domain.includes(name)) {
      values.domain.push(name);
    } else {
      const index = values.domain.indexOf(name);
      if (index > -1) {
        values.domain.splice(index, 1);
      }
    }
  }

  const handleRadioChange = (value) => {
    setRadioValue(value);
  };

  return (
    <>
      <ModalWrapper
        size="small"
        header={selectedCollege ? "Edit College Page" : "Create College Page"}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              values.restricted = radioValue;
              if (!radioValue) {
                values.emailDomain = "";
              }
              if (currentUserProfile.admin) {
                values.verified = true;
              }
              if (images.length > 0) {
                var data = new FormData();
                await compressImg(images[images.length - 1]).then(
                  async (compressedDataUrl) => {
                    let file = await getFile(compressedDataUrl, filename);
                    data.append("imageFile", file);
                  }
                );
                dispatch(uploadFileApi(data)).then((response) => {
                  dispatch(asyncActionFinish());
                  values.collegeImg = response[0].location;
                  if (modalProps !== null) {
                    dispatch(updateCollegeApi(values)).then(() => {
                      dispatch(closeModal());
                      toast.success("updated sucessfully!");
                    });
                  } else {
                    dispatch(createCollegeApi(values)).then(() => {
                      dispatch(closeModal());
                      toast.success("Page created sucessfully!");
                    });
                  }
                });
              } else {
                if (modalProps !== null) {
                  dispatch(updateCollegeApi(values)).then(() => {
                    dispatch(closeModal());
                    toast.success("updated sucessfully!");
                  });
                } else {
                  dispatch(createCollegeApi(values)).then(() => {
                    dispatch(closeModal());
                    toast.success("Page created sucessfully!");
                  });
                }
              }
            } catch (error) {
              dispatch(asyncActionError(error));
              toast.error(error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form className="ui form">
              {!selectedCollege && (
                <Header
                  sub
                  color="blue"
                  content="This page is only created by College official Id. And It will be live within 48 hours."
                  style={{ marginBottom: 20 }}
                />
              )}
              <Grid>
                <Grid.Column width={9}>
                  <MyTextInput
                    name="collegeName"
                    placeholder="College name"
                    label={"College Name"}
                  />
                  <MyTextInput
                    name="email"
                    placeholder="Contact email"
                    label={"Contact Email"}
                  />
                  <MyTextInput
                    name="website"
                    placeholder="Website"
                    label={"Website"}
                  />
                </Grid.Column>
                <Grid.Column width={7} textAlign="center">
                  <Image
                    style={{ marginTop: 20 }}
                    src={
                      images.length > 0
                        ? images[images.length - 1]
                        : values.collegeImg
                    }
                  />
                  <Button
                    icon="file image"
                    content="change image"
                    color="blue"
                    onClick={handleClick}
                    style={{ marginTop: 20 }}
                    type="button"
                  />
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </Grid.Column>
              </Grid>
              <FormGroup widths={2}>
                <MyTextInput
                  name="instagram"
                  placeholder="instagram"
                  label={"Instagram"}
                />
                <MyTextInput
                  name="linkedIn"
                  placeholder="linkedIn"
                  label={"LinkedIn"}
                />
              </FormGroup>
              <MyTextArea
                name="description"
                placeholder="Tell people about your college"
                label={"Description"}
                rows="3"
              />
              <FormGroup inline>
                <Header
                  as="h4"
                  content="Students to join club with college domain email-id : "
                  style={{ marginTop: 10, marginRight: 20 }}
                />
                <FormRadio
                  label="Yes"
                  checked={radioValue}
                  onChange={() => handleRadioChange(true)}
                />
                <FormRadio
                  label="No"
                  checked={!radioValue}
                  onChange={() => handleRadioChange(false)}
                />
              </FormGroup>
              {radioValue && (
                <MyTextInput
                  name="emailDomain"
                  placeholder="@iitbhu.ac.in"
                  label={"Email Domain (must contain @)"}
                />
              )}
              <Header as="h4" content="College Domain : " />
              <FormGroup inline>
                <FormCheckbox
                  name="Engineering"
                  label="Engineering"
                  defaultChecked={values.domain.includes("Engineering")}
                  onClick={(e, { name }) => handleCheckBox(values, name)}
                />
                <FormCheckbox
                  name="Management"
                  label="Management"
                  defaultChecked={values.domain.includes("Management")}
                  onClick={(e, { name }) => handleCheckBox(values, name)}
                />
                <FormCheckbox
                  name="Medical"
                  label="Medical"
                  defaultChecked={values.domain.includes("Medical")}
                  onClick={(e, { name }) => handleCheckBox(values, name)}
                />
                <FormCheckbox
                  name="Arts"
                  label="Arts"
                  defaultChecked={values.domain.includes("Arts")}
                  onClick={(e, { name }) => handleCheckBox(values, name)}
                />
                <FormCheckbox
                  name="Science"
                  label="Science"
                  defaultChecked={values.domain.includes("Science")}
                  onClick={(e, { name }) => handleCheckBox(values, name)}
                />
                <FormCheckbox
                  name="Commerce"
                  label="Commerce"
                  defaultChecked={values.domain.includes("Commerce")}
                  onClick={(e, { name }) => handleCheckBox(values, name)}
                />
              </FormGroup>
              <Header
                sub
                color="teal"
                content="College Location detail"
                style={{ marginBottom: 10 }}
              />
              <MyPlaceInput name="city" placeholder="City" />
              <Button
                loading={isSubmitting || loading}
                disabled={!isValid || isSubmitting || loading}
                type="submit"
                floated="right"
                positive
                content="Submit"
                style={{ marginBottom: 20 }}
              />
              <Button
                disabled={isSubmitting}
                type="button"
                floated="right"
                content="cancel"
                onClick={() => dispatch(closeModal())}
                style={{ marginBottom: 20 }}
              />
            </Form>
          )}
        </Formik>
        <PhotoCropper
          photoCropperOpen={photoCropperOpen}
          setPhotoCropperOpen={setPhotoCropperOpen}
          images={images}
          setImages={setImages}
          imagePreview={files}
          imageNo={0}
          editing={false}
          aspectRatio={2 / 1}
        />
      </ModalWrapper>
    </>
  );
}
