import React, { useEffect, useState } from "react";
import { Grid, Loader } from "semantic-ui-react";
import NotificationList from "./NotificationList";
import { useSelector } from "react-redux";
import NotificationItemPlaceholder from "./NotificationItemPlaceholder";
import { NOTIFICATION_RETAIN_STATE } from "./notificationReducer";
import { useDispatch } from "react-redux";
import FeaturedFeed from "../common/FeaturedFeed";
import { fetchNotificationsApi, markReadNotificationApi } from "../../app/apiRequest/notificationService";
import { Helmet } from "react-helmet";

export default function NotificationDashboard() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    isLoading,
    retainState,
    notifications,
    moreNotification,
    lastDocId,
  } = useSelector((state) => state.notification);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if (retainState) return;
    setLoadingInitial(true);
    dispatch(fetchNotificationsApi("")).then(() => {
      setLoadingInitial(false);
      dispatch(markReadNotificationApi());
    });
    return () => {
      dispatch({ type: NOTIFICATION_RETAIN_STATE });
    };
  }, [dispatch, retainState]);

  function handleFetchNextNotifications() {
    dispatch(fetchNotificationsApi(`?_id[$lt]=${lastDocId}`));
  }

  return (
    <>
      <Grid>
        <Grid.Column width={9} style={{ marginTop: 10 }}>
          {loadingInitial && (
            <>
              <NotificationItemPlaceholder />
              <NotificationItemPlaceholder />
            </>
          )}
          <NotificationList
            notifications={notifications}
            getNextNotifications={handleFetchNextNotifications}
            loading={isLoading}
            moreNotification={moreNotification}
          />
          <Loader active={isLoading} />
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={5}>
          <FeaturedFeed userId={user} />
        </Grid.Column>
      </Grid>
      <Helmet>
        <title>{"Notification"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </>
  );
}
