const POSTS_LOADING = "POSTS_LOADING";
const POSTS_FAILED = "POSTS_FAILED";
const ADD_POSTS = "ADD_POSTS";
const REMOVE_POST = "REMOVE_POST";
const SET_SAVE_POST = "SET_SAVE_POST";
const SET_UNSAVE_POST = "SET_UNSAVE_POST";
const SET_LIKE_POST = "SET_LIKE_POST";
const SET_UNLIKE_POST = "SET_UNLIKE_POST";
const LISTEN_TO_SELECTED_POST = "LISTEN_TO_SELECTED_POST";
const CLEAR_SELECTED_POST = "CLEAR_SELECTED_POST";
const CLEAR_POSTS = "CLEAR_POSTS";
const SET_FILTER = "SET_FILTER";
export const RETAIN_STATE = "RETAIN_STATE";

export const postsLoading = () => ({
  type: POSTS_LOADING,
});

export const postsFailed = (errmess) => ({
  type: POSTS_FAILED,
  payload: errmess,
});

export const addPosts = (posts, morePosts, lastPostId) => ({
  type: ADD_POSTS,
  payload: { posts, morePosts, lastPostId },
});

export function setFilter(value) {
  return function (dispatch) {
    dispatch(clearPosts());
    dispatch({ type: SET_FILTER, payload: value });
  };
}

export function listenToSelectedPost(post) {
  return {
    type: LISTEN_TO_SELECTED_POST,
    payload: post,
  };
}

export function clearSelectedPost() {
  return {
    type: CLEAR_SELECTED_POST,
  };
}

export function removePost(postId) {
  return {
    type: REMOVE_POST,
    payload: postId,
  };
}

export function setSavePost(postId, currentUserId) {
  return {
    type: SET_SAVE_POST,
    payload: { postId, currentUserId },
  };
}

export function setUnSavePost(postId, currentUserId) {
  return {
    type: SET_UNSAVE_POST,
    payload: { postId, currentUserId },
  };
}

export function setLikePost(postId, currentUserId) {
  return {
    type: SET_LIKE_POST,
    payload: { postId, currentUserId },
  };
}

export function setUnLikePost(postId, currentUserId) {
  return {
    type: SET_UNLIKE_POST,
    payload: { postId, currentUserId },
  };
}

export function clearPosts() {
  return {
    type: CLEAR_POSTS,
  };
}

const initialState = {
  isLoading: true,
  errMess: null,
  posts: [],
  morePosts: true,
  selectedPost: null,
  lastPostId: null,
  filter: "",
  retainState: false,
};

export default function postReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_POSTS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        posts: [...state.posts, ...payload.posts],
        morePosts: payload.morePosts,
        lastPostId: payload.lastPostId,
      };

    case POSTS_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case POSTS_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case REMOVE_POST:
      return {
        ...state,
        posts: [...state.posts.filter((post) => post._id !== payload)],
      };
    case SET_SAVE_POST:
      const index1 = state.posts.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray1 = [...state.posts];
      newArray1[index1].savedBy = [
        ...newArray1[index1].savedBy,
        payload.currentUserId,
      ];
      return {
        ...state,
        posts: newArray1,
      };
    case SET_UNSAVE_POST:
      const index2 = state.posts.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray2 = [...state.posts];
      newArray2[index2].savedBy = [
        ...newArray2[index2].savedBy.filter(
          (id) => id !== payload.currentUserId
        ),
      ];
      return {
        ...state,
        posts: newArray2,
      };
    case SET_LIKE_POST:
      const index3 = state.posts.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray3 = [...state.posts];
      newArray3[index3].likes = [
        ...newArray3[index3].likes,
        payload.currentUserId,
      ];
      return {
        ...state,
        posts: newArray3,
      };
    case SET_UNLIKE_POST:
      const index4 = state.posts.findIndex(
        (post) => post._id === payload.postId
      );
      const newArray4 = [...state.posts];
      newArray4[index4].likes = [
        ...newArray4[index4].likes.filter((id) => id !== payload.currentUserId),
      ];
      return {
        ...state,
        posts: newArray4,
      };
    case LISTEN_TO_SELECTED_POST:
      return {
        ...state,
        selectedPost: payload,
      };
    case CLEAR_SELECTED_POST:
      return {
        ...state,
        selectedPost: null,
      };
    case CLEAR_POSTS:
      return {
        ...state,
        posts: [],
        morePosts: true,
        retainState: false,
        lastPostId: null,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
