const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
const NOTIFICATIONS_FAILED = "NOTIFICATIONS_FAILED";
const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const UNREAD_NOTIFICATION_COUNT = "UNREAD_NOTIFICATION_COUNT";
export const NOTIFICATION_RETAIN_STATE = "NOTIFICATION_RETAIN_STATE";

export const notificationsLoading = () => ({
  type: NOTIFICATIONS_LOADING,
});

export const notificationsFailed = (errmess) => ({
  type: NOTIFICATIONS_FAILED,
  payload: errmess,
});

export const addNotifications = ({
  notifications,
  moreNotification,
  lastDocId,
}) => ({
  type: ADD_NOTIFICATIONS,
  payload: { notifications, moreNotification, lastDocId },
});

export function deleteNotification(notificationId) {
  return {
    type: DELETE_NOTIFICATION,
    payload: notificationId,
  };
}

export const unreadNotificationCount = (count) => ({
  type: UNREAD_NOTIFICATION_COUNT,
  payload: count,
});

const initialState = {
  isLoading: true,
  errMess: null,
  notifications: [],
  moreNotification: true,
  lastDocId: null,
  unreadCount: 0,
  retainState: false,
};

export default function notificationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        notifications: [...state.notifications, ...payload.notifications],
        moreNotification: payload.moreNotification,
        lastDocId: payload.lastDocId,
      };

    case NOTIFICATIONS_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case NOTIFICATIONS_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter((evt) => evt.id !== payload),
        ],
      };
    case NOTIFICATION_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    case UNREAD_NOTIFICATION_COUNT:
      return {
        ...state,
        unreadCount: payload,
      };
    default:
      return state;
  }
}
