import { toast } from "react-toastify";
import {
  loginError,
  receiveLogin,
  requestLogin,
} from "../../features/auth/authReducer";
import { closeModal, openModal } from "../common/modals/modalReducer";

export const loginUser = (creds, setErrors) => (dispatch) => {
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogin());

  return fetch(process.env.REACT_APP_API_BASE_URL + "users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creds),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.success && response.token) {
        if (response.user.deactivated) {
          dispatch(
            openModal({
              modalType: "ActivateAccountModal",
              modalProps: response.user,
            })
          );
        } else {
          // If login was successful, set the token in local storage
          localStorage.setItem("token", response.token);
          localStorage.setItem("creds", response.user._id);
          dispatch(receiveLogin(response));
          dispatch(closeModal());
        }
      } else if (response.success && !response.token) {
        toast.success("OTP has been sent!");
        dispatch(
          openModal({
            modalType: "VerificationForm",
            modalProps: { user: response.user, from: "login" },
          })
        );
      } else {
        var error = new Error("Error: " + response.err.message);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      setErrors({ auth: error.message });
    });
};

export const googleSignIn = (token) => (dispatch) => {
  dispatch(requestLogin());
  return fetch(process.env.REACT_APP_API_BASE_URL + "users/google/react/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.success && response.token) {
        if (response.user.deactivated) {
          dispatch(
            openModal({
              modalType: "ActivateAccountModal",
              modalProps: response.user,
            })
          );
        } else {
          // If login was successful, set the token in local storage
          localStorage.setItem("token", response.token);
          localStorage.setItem("creds", response.user._id);
          dispatch(receiveLogin(response));
          dispatch(closeModal());
        }
      } else {
        var error = new Error("Error: " + response.err.message);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      toast.error(error.message);
    });
};

export const azureSignIn = (token) => (dispatch) => {
  dispatch(requestLogin());
  return fetch(process.env.REACT_APP_API_BASE_URL + "users/azure/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ idToken: token }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.success && response.token) {
        if (response.user.deactivated) {
          dispatch(
            openModal({
              modalType: "ActivateAccountModal",
              modalProps: response.user,
            })
          );
        } else {
          // If login was successful, set the token in local storage
          localStorage.setItem("token", response.token);
          localStorage.setItem("creds", response.user._id);
          dispatch(receiveLogin(response));
          dispatch(closeModal());
        }
      } else {
        var error = new Error("Error: " + response.err.message);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      toast.error(error.message);
    });
};

export const forgotPasswordApi = (creds, setErrors) => (dispatch) => {
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogin());

  return fetch(process.env.REACT_APP_API_BASE_URL + "users/reset-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creds),
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.success) {
        toast.success("OTP has been sent!");
        dispatch(
          openModal({
            modalType: "ResetPasswordForm",
            modalProps: { user: response.user },
          })
        );
      } else {
        var error = new Error("Error " + response.status);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      setErrors({ auth: error.message });
    });
};

export const ResetPasswordApi = (creds, setErrors) => (dispatch) => {
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogin());

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "users/reset-password/" + creds.token,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(creds),
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.success && response.token) {
        // If login was successful, set the token in local storage
        localStorage.setItem("token", response.token);
        localStorage.setItem("creds", response.user._id);
        // Dispatch the success action
        dispatch(receiveLogin(response));
        dispatch(closeModal());
      } else {
        var error = new Error("Error " + response.message);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      setErrors({ auth: error.message });
    });
};
