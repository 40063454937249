import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Segment } from "semantic-ui-react";
import { fetchChatRoomApi } from "../../app/apiRequest/chatService";
import { openModal } from "../../app/common/modals/modalReducer";
import Chatdashboard from "./chats/ChatDashboard";
import UserFeedDashboard from "./userFeed/UserFeedDashboard";
import { Helmet } from "react-helmet";

export default function ChatPage({ match }) {
  const dispatch = useDispatch();
  const { selectedChat } = useSelector((state) => state.chats);

  useEffect(() => {
    if (!match.params.id || selectedChat) return;
    dispatch(fetchChatRoomApi(match.params.id, null));
  }, [dispatch, selectedChat, match.params.id]);

  return (
    <div>
      <UserFeedDashboard match={match} />
      {match.params.id ? (
        <Chatdashboard match={match} />
      ) : (
        <Segment placeholder compact textAlign="center" id="new-chat-select">
          <Header as="h2">
            Select a message
            <Header.Subheader>
              Choose from your existing conversations, or start a new one.
            </Header.Subheader>
          </Header>
          <Button
            onClick={() => dispatch(openModal({ modalType: "NewChatModal" }))}
            primary
          >
            New meassage
          </Button>
        </Segment>
      )}
      <Helmet>
        <title>{"Direct Message"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
