const FETCH_SESSION_REMINDER = "FETCH_SESSION_REMINDER";
const FETCH_EVENT_REMINDER = "FETCH_EVENT_REMINDER";
const TODAYS_SESSION = "TODAYS_SESSION";
export const SESSION_REMINDER_RETAIN_STATE = "SESSION_REMINDER_RETAIN_STATE";
export const EVENT_REMINDER_RETAIN_STATE = "EVENT_REMINDER_RETAIN_STATE";

export function fetchSessionReminder(sessions) {
  return {
    type: FETCH_SESSION_REMINDER,
    payload: sessions,
  };
}

export function fetchEventReminder(events) {
  return {
    type: FETCH_EVENT_REMINDER,
    payload: events,
  };
}

export function todaysSessions(count) {
  return {
    type: TODAYS_SESSION,
    payload: count,
  };
}

const initialState = {
  sessions: [],
  events: [],
  sessionRetainState: false,
  eventRetainState: false,
  todaySessions: 0,
};

export default function reminderReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_SESSION_REMINDER:
      return {
        ...state,
        sessions: [...state.sessions, ...payload],
      };
    case FETCH_EVENT_REMINDER:
      return {
        ...state,
        events: payload,
      };
    case SESSION_REMINDER_RETAIN_STATE:
      return {
        ...state,
        sessionRetainState: true,
      };
    case EVENT_REMINDER_RETAIN_STATE:
      return {
        ...state,
        eventRetainState: true,
      };
    case TODAYS_SESSION:
      return {
        ...state,
        todaySessions: state.todaySessions + payload ,
      };
    default:
      return state;
  }
}
