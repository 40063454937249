/* global google */
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormRadio,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import MyPlaceInput from "../../../app/common/form/MyPlaceInput";
import { getFile, isImage } from "../../../app/common/util/util";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import { createEventApi } from "../../../app/apiRequest/eventService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  fetchCollegeFestsApi,
  fetchSingleCollegeFestApi,
} from "../../../app/apiRequest/collegeFestService";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import { fetchSingleClubApi } from "../../../app/apiRequest/clubService";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { compressImg } from "../../../app/common/photos/compressImg";
import MyDateInput from "../../../app/common/form/MyDateInput";

export default function CreateEventForm({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [radioValue, setRadioValue] = useState({ name: "offline" });
  const [eventType, setEventType] = useState({ name: "contest" });
  const { selectedEvent } = useSelector((state) => state.event);
  const { loading, error } = useSelector((state) => state.async);
  const { selectedFest, fests } = useSelector((state) => state.college);
  const { selectedClub } = useSelector((state) => state.club);

  useEffect(() => {
    if (selectedEvent) {
      history.push(`/event/manage/registration/${selectedEvent?._id}`);
      return;
    }
    // eslint-disable-next-line
  }, [dispatch, selectedEvent]);

  useEffect(() => {
    if (location.search) {
      if (location.search.includes("club") && !selectedClub) {
        dispatch(fetchSingleClubApi(location.search.split("=")[1]));
      } else if (location.search.includes("festival") && !selectedFest) {
        dispatch(fetchSingleCollegeFestApi(location.search.split("=")[1]));
      } else if (location.search.includes("college")) {
        dispatch(fetchCollegeFestsApi(location.search.split("=")[1]));
      }
    } else {
      history.push("/error");
    }
    // eslint-disable-next-line
  }, [location.search, dispatch]);

  useEffect(() => {
    if (
      location.search &&
      selectedClub &&
      selectedClub.college_status === "verified" &&
      location.search.includes("club")
    ) {
      dispatch(fetchCollegeFestsApi(selectedClub?.college?._id));
    }
  }, [dispatch, location.search, selectedClub]);

  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  const initialValues = {
    eventTitle: "",
    eventImg:
      "https://learningx-s3.s3.ap-south-1.amazonaws.com/image_850_315.png",
    eventDomain: [],
    club: null,
    festival: null,
    location: "offline",
    city: {
      address: "",
      latLng: null,
    },
    venue: {
      address: "",
      latLng: null,
    },
  };

  var festsOption = [];
  fests.length > 0 &&
    fests.map((fest) =>
      festsOption.push({
        key: fest._id,
        text: fest.festName,
        value: fest._id,
        image: { avatar: true, src: fest.festImg },
      })
    );

  const validationSchema = Yup.object({
    eventTitle: Yup.string().required("You must provide a title"),
  });

  const handleRadioChange = (e, { name }) => {
    setRadioValue({ name });
  };

  const handleEventTypeChange = (e, { name }) => {
    setEventType({ name });
  };

  if (loading) return <LoadingComponent content={"Creating event..."} />;

  if (error) return <Redirect to="/error" />;

  return (
    <>
      <Segment clearing style={{ marginTop: 75, marginRight: 20 }}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              values.location = radioValue.name;
              values.eventType = eventType.name;
              if (
                location.search.includes("club") &&
                !selectedClub.learningXClub &&
                selectedClub.college_status === "verified"
              ) {
                values.college = selectedClub.college?._id;
                values.club = selectedClub._id;
              } else if (location.search.includes("festival")) {
                values.college = selectedFest.college?._id;
                values.festival = selectedFest._id;
              } else if (location.search.includes("college")) {
                values.college = location.search.split("=")[1];
              } else {
                values.club = selectedClub._id;
              }
              if (images.length > 0) {
                var data = new FormData();
                await compressImg(images[images.length - 1]).then(
                  async (compressedDataUrl) => {
                    let file = await getFile(compressedDataUrl, filename);
                    data.append("imageFile", file);
                  }
                );
                dispatch(uploadFileApi(data)).then((response) => {
                  dispatch(asyncActionFinish());
                  values.eventImg = response[0].location;
                  dispatch(createEventApi(values));
                });
              } else {
                dispatch(createEventApi(values));
              }
            } catch (error) {
              dispatch(asyncActionError(error));
              toast.error(error.message);
            } finally {
              setSubmitting(false);
              console.log(values);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form className="ui form">
              <Header color="teal" content="Event Details" />
              <Grid style={{ marginBottom: 20 }}>
                <Grid.Column width={9}>
                  <MyTextInput
                    name="eventTitle"
                    placeholder="Event title"
                    label={"Event Title*"}
                  />
                  {((location.search.includes("club") &&
                    selectedClub?.college_status === "verified") ||
                    location.search.includes("college")) &&
                    festsOption.length > 0 && (
                      <MySelectInput
                        name="festival"
                        placeholder="Fest Name"
                        options={festsOption}
                        label={"Festival Name (optional)"}
                      />
                    )}
                  <FormGroup inline>
                    <label>Event type :- </label>
                    <FormRadio
                      label="Contest event"
                      checked={eventType.name === "contest"}
                      onChange={handleEventTypeChange}
                      name="contest"
                    />
                    <FormRadio
                      label="Non-contest event"
                      checked={eventType.name === "entertainment"}
                      onChange={handleEventTypeChange}
                      name="entertainment"
                    />
                  </FormGroup>
                  <Header
                    sub
                    color="teal"
                    content="Event Date &amp; Location detail"
                    style={{ marginBottom: 10 }}
                  />
                  <FormGroup widths={"equal"}>
                    <MyDateInput
                      name="eventStartDate"
                      placeholderText={new Date().toString("MMM dd yyyy hh:mm")}
                      timeFormat="HH:mm"
                      showTimeSelect
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy  h:mm a"
                      autoComplete="off"
                      minDate={new Date()}
                      label={"Start Date & Time"}
                    />
                    <MyDateInput
                      name="eventEndDate"
                      placeholderText={new Date().toString("MMM dd yyyy hh:mm")}
                      timeFormat="HH:mm"
                      showTimeSelect
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy  h:mm a"
                      autoComplete="off"
                      minDate={new Date()}
                      label={"End Date & Time"}
                    />
                  </FormGroup>
                  <FormGroup inline>
                    <FormRadio
                      label="offline"
                      checked={radioValue.name === "offline"}
                      onChange={handleRadioChange}
                      name="offline"
                    />
                    <FormRadio
                      label="online"
                      checked={radioValue.name === "online"}
                      onChange={handleRadioChange}
                      name="online"
                    />
                  </FormGroup>
                  {radioValue.name === "offline" && (
                    <>
                      <MyPlaceInput name="city" placeholder="City" />
                      <MyPlaceInput
                        name="venue"
                        placeholder="Venue"
                        disabled={!values.city.latLng}
                        options={{
                          location: new google.maps.LatLng(values.city.latLng),
                          radius: 1000,
                          types: ["establishment"],
                        }}
                      />
                    </>
                  )}
                  {radioValue.name === "online" &&
                    eventType.name === "entertainment" && (
                      <>
                        <MyTextInput name="eventLink" placeholder="Event Link" />
                      </>
                    )}
                </Grid.Column>
                <Grid.Column width={7}>
                  <Segment textAlign="center">
                    <Image
                      style={{ width: 510, height: 170, marginTop: 20 }}
                      src={
                        images.length > 0
                          ? images[images.length - 1]
                          : values.eventImg
                      }
                    />
                    <p>Image dimensions:- 3 : 1</p>
                    <Button
                      basic
                      icon="file image"
                      content="Add image"
                      color="blue"
                      onClick={handleClick}
                      style={{ marginTop: 20 }}
                      type="button"
                    />
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
              <Button
                loading={isSubmitting || loading}
                disabled={!isValid || !dirty || isSubmitting || loading}
                type="submit"
                floated="right"
                positive
                content="Save &amp; Continue"
              />
              <Button
                disabled={isSubmitting}
                type="button"
                floated="right"
                content="cancel"
                as={Link}
                to={`/events`}
              />
            </Form>
          )}
        </Formik>
      </Segment>
      <PhotoCropper
        photoCropperOpen={photoCropperOpen}
        setPhotoCropperOpen={setPhotoCropperOpen}
        images={images}
        setImages={setImages}
        imagePreview={files}
        imageNo={0}
        editing={false}
        aspectRatio={3 / 1}
      />
    </>
  );
}
