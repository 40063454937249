import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { googleSignIn, loginUser } from "../../app/apiRequest/loginService";
import { useEffect } from "react";

export default function LoginForm() {
  const dispatch = useDispatch();

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      type: "standard",
      size: "medium",
      logo_alignment: "left",
      shape: "rectangular",
      text: "sign_in_with",
    });
    // eslint-disable-next-line
  }, []);

  function handleCallbackResponse(response) {
    dispatch(closeModal());
    dispatch(googleSignIn(response.credential)).then(() =>
      window.location.reload()
    );
  }

  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  return (
    <ModalWrapper size="tiny" header="Sign In">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            dispatch(loginUser(values, setErrors)).then(() =>
              window.location.reload()
            );
          } catch (error) {
            setErrors({ auth: "Error in Login server!" });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyTextInput
              name="username"
              placeholder="Email Address/ Mobile number"
            />
            <MyTextInput
              name="password"
              placeholder="Password"
              type="password"
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Login"
            />
          </Form>
        )}
      </Formik>
      <div style={{ marginTop: 20 }} id="signInDiv"></div>
      <Button
        content="Forgetten Password?"
        color="blue"
        style={{ marginTop: 20 }}
        onClick={() => dispatch(openModal({ modalType: "ForgotPasswordForm" }))}
      />
    </ModalWrapper>
  );
}
