import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { activateAccountApi } from "../../app/apiRequest/profileService";

export default function ActivateAccountModal() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.modal.modalProps);

  const handleCloseAccount = () => {
    dispatch(activateAccountApi(user));
  };

  return (
    <ModalWrapper size="small" header="Activate Account">
      <div>
        <div class="account-sub-header">
          You have closed your Account, To Login in your Account. You must
          Activate. <br /> You have 15 days from closing day to reopen your
          account.
          <br />
          Please click on Activate button below.
        </div>
        <button onClick={handleCloseAccount}>Activate Account</button>
      </div>
    </ModalWrapper>
  );
}
