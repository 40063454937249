import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  Dropdown,
  FormGroup,
  Image,
  Label,
  Loader,
} from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import { getFile, isImage } from "../../../app/common/util/util";
import { toast } from "react-toastify";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { useDispatch, useSelector } from "react-redux";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  fetchUserProfileApi,
  updateUserApi,
  updateUserProfileApi,
} from "../../../app/apiRequest/profileService";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import MyDateInput from "../../../app/common/form/MyDateInput";
import { format } from "date-fns";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { fetchCollegesApi } from "../../../app/apiRequest/collegeFestService";
import { compressImg } from "../../../app/common/photos/compressImg";

export default function EditProfileForm() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const { currentUserProfile, selectedUserProfile } = useSelector(
    (state) => state.profile
  );
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.async);
  const { colleges } = useSelector((state) => state.search);
  const hiddenFileInput = useRef(null);
  const [collegeError, setCollegeError] = useState(null);

  var collegeList = [];

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    dispatch(fetchCollegesApi(""));
    dispatch(fetchUserProfileApi(currentUserProfile?._id));
  }, [dispatch, currentUserProfile]);

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  const options = [
    { key: "m", text: "Male", value: "male" },
    { key: "f", text: "Female", value: "female" },
    { key: "o", text: "Other", value: "other" },
  ];

  colleges.length > 0 &&
    colleges.map((college) =>
      collegeList.push({
        key: college._id,
        text: college.collegeName,
        value: {
          id: college._id,
          restricted: college.restricted,
          emailDomain: college.emailDomain,
        },
        image: { avatar: true, src: college.collegeImg },
      })
    );

  const handleSelectCollege = (value) => {
    if (
      value.restricted &&
      !currentUserProfile?.username.includes(value.emailDomain)
    ) {
      setCollegeError(
        `To select this college, Signup with  ${value.emailDomain} email-id.`
      );
      toast.error(
        `To select this college, Signup with  ${value.emailDomain} email-id.`
      );
    } else setCollegeError(null);
  };

  if (!selectedUserProfile) return <Loader />;

  const initialValues = {
    _id: user,
    firstname: currentUserProfile?.firstname,
    lastname: currentUserProfile?.lastname,
    userImg: currentUserProfile?.userImg,
    gender: selectedUserProfile?.gender,
    bio: selectedUserProfile?.bio,
    currentLocation: selectedUserProfile?.currentLocation,
    birthday: selectedUserProfile?.birthday,
    website: selectedUserProfile?.website,
    phone: selectedUserProfile?.phone,
    college: currentUserProfile?.college,
    collegeName: selectedUserProfile?.collegeName,
  };

  return (
    <ModalWrapper size="small" header="Edit Profile">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          email: Yup.string().email(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (collegeError) {
              toast.error(collegeError);
              return;
            }
            if (images.length > 0) {
              var data = new FormData();
              await compressImg(images[images.length - 1]).then(
                async (compressedDataUrl) => {
                  let file = await getFile(compressedDataUrl, filename);
                  data.append("imageFile", file);
                }
              );
              dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                values.userImg = response[0].location;
                dispatch(updateUserApi(values)).then(() => {
                  dispatch(updateUserProfileApi(values));
                  setSubmitting(false);
                  dispatch(closeModal());
                });
              });
            }
            dispatch(updateUserApi(values)).then(() => {
              dispatch(updateUserProfileApi(values));
              setSubmitting(false);
              dispatch(closeModal());
            });
          } catch (error) {
            dispatch(asyncActionError(error));
            setErrors({ auth: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, isValid, dirty, errors }) => (
          <Form className="ui form">
            <Image
              size="tiny"
              circular
              src={
                images.length > 0 ? images[images.length - 1] : values.userImg
              }
              floated="left"
            />
            <Button
              icon="file image"
              content="Edit profile image"
              color="blue"
              onClick={handleClick}
              style={{ marginTop: 20 }}
              type="button"
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
            <FormGroup widths="equal" style={{ marginTop: 40 }}>
              <MyTextInput
                name="firstname"
                placeholder="First Name"
                label={"First Name"}
              />
              <MyTextInput
                name="lastname"
                placeholder="Last Name"
                label={"Last Name"}
              />
              <MySelectInput
                name="gender"
                placeholder="Gender"
                options={options}
                label={"Gender"}
              />
            </FormGroup>
            <p>
              <strong>Select College </strong>(*if not found your college then
              select 'other')
            </p>
            <Dropdown
              name="college"
              placeholder="Select College"
              onChange={(event, { value }) => {
                values.college = value.id;
                handleSelectCollege(value);
              }}
              fluid
              search
              selection
              defaultValue={{ id: values.college?._id } ?? { id: null }}
              options={collegeList}
              style={{ marginTop: -10, marginBottom: 10 }}
            />
            <MyTextArea name="bio" placeholder="Bio" rows="2" label={"Bio"} />
            <FormGroup widths={2}>
              <MyDateInput
                name="birthday"
                placeholderText={format(new Date(), "MMMM d, yyyy")}
                dateFormat="MMMM d, yyyy"
                autoComplete="off"
                maxDate={new Date()}
                showYearDropdown
                dropdownMode="select"
                label={"Date of Birth"}
              />
              <MyTextInput
                name="currentLocation"
                placeholder="Current location"
                label={"Current location"}
              />
            </FormGroup>
            <MyTextInput
              name="website"
              placeholder="Website"
              label={"Website"}
            />
            {/* <MyTextInput
              name="phone"
              placeholder="Mobile number"
              label={"Mobile number"}
            /> */}
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting || loading}
              disabled={!isValid || isSubmitting || loading}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Save Changes"
            />
          </Form>
        )}
      </Formik>
      <PhotoCropper
        photoCropperOpen={photoCropperOpen}
        setPhotoCropperOpen={setPhotoCropperOpen}
        images={images}
        setImages={setImages}
        imagePreview={files}
        imageNo={0}
        editing={false}
        aspectRatio={1}
      />
    </ModalWrapper>
  );
}
