import React from "react";
import { Header, Image, List, Container } from "semantic-ui-react";

export default function ProfileHeader({ profile }) {
  return (
    <>
      <Container>
        <Image
          floated="left"
          avatar
          size="small"
          src={profile.user.userImg || "/assets/user.png"}
          style={{ marginRight: 20 }}
        />
        <Header
          as="h1"
          style={{ display: "block", marginBottom: 20 }}
          content={profile.user.displayName}
          subheader={`@${profile.user.user_name}`}
        />
        {(profile.user.college?.collegeName || profile.collegeName) && (
          <List horizontal relaxed style={{ display: "block" }}>
            <List.Item>
              <List.Content>
                <List.Icon name="university" />
                {profile.user.college?.collegeName ?? profile.collegeName}
              </List.Content>
            </List.Item>
          </List>
        )}
        <List horizontal relaxed style={{ display: "block" }}>
          {profile.currentLocation && (
            <List.Item>
              <List.Content>
                <List.Icon name="marker" />
                {profile.currentLocation}
              </List.Content>
            </List.Item>
          )}
          {profile.email && (
            <List.Item>
              <List.Content>
                <List.Icon name="mail" />{" "}
                <a href={`mailto:${profile.email}`}>{profile.email}</a>
              </List.Content>
            </List.Item>
          )}
        </List>
        <List horizontal relaxed style={{ display: "block" }}>
          <List.Item>
            <List.Content>
              <List.Icon name="calendar" />{" "}
              {`joined ${new Date(profile.createdAt).toDateString()}`}
            </List.Content>
          </List.Item>
          {profile.website && (
            <List.Item>
              <List.Content>
                <List.Icon name="linkify" />{" "}
                <a href={profile.website}>{profile.website}</a>
              </List.Content>
            </List.Item>
          )}
        </List>
        <p style={{ marginTop: 20 }}>{profile.bio}</p>
      </Container>
    </>
  );
}
