import React from "react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { useSelector } from "react-redux";
import { Image, Segment } from "semantic-ui-react";
import { ControlBar, Player } from "video-react";

export default function FullSizeImgModal() {
  const { modalProps } = useSelector((state) => state.modal);
  return (
    <ModalWrapper size="tiny" header="">
      <Segment placeholder textAlign="center">
        {modalProps.video ? (
          <Player playsInline fluid={true}>
            <source src={modalProps.video} />
            <ControlBar autoHide={true} />
          </Player>
        ) : (
          <Image src={modalProps.images[0]} />
        )}
      </Segment>
    </ModalWrapper>
  );
}
