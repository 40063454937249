import React, { useState } from "react";
import { Button, Feed, Icon, Image } from "semantic-ui-react";
import { ControlBar, Player } from "video-react";
import { formatDistance } from "date-fns";
import GroupDiscussion from "./GroupDiscussion";
import displayLinks from "../../../../app/common/text/handleLinkClick";

export default function GroupDiscussionitem({ chat }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="post-container" style={{ margin: 5 }}>
      <Feed>
        <Feed.Event>
          <Feed.Label image={chat.sender.userImg || "/assets/user.png"} />
          <Feed.Content>
            <Feed.Summary>
              <Feed.User>{chat.sender.displayName}</Feed.User>
              <Feed.Date>
                {formatDistance(new Date(chat.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Feed.Date>
            </Feed.Summary>
            <Feed.Extra text as={"h4"}>
              {chat.title}
            </Feed.Extra>
            <Feed.Extra style={{ whiteSpace: "pre-line" }} text>
              {displayLinks(chat.chat)}
            </Feed.Extra>
            <Feed.Extra>
              {chat.file && chat.filetype === "video" && (
                <Player playsInline fluid={false} width={"45%"} height={290}>
                  <source src={chat.file} />
                  <ControlBar autoHide={true} />
                </Player>
              )}
              {chat.file && chat.filetype === "image" && (
                <Image src={chat.file} size="large" />
              )}
            </Feed.Extra>
            <Feed.Meta>
              <Feed.Like>
                <Icon name="chat" />
                {`${chat.repliedCount} messages`}
              </Feed.Like>
              <Button
                attached="right"
                content="Start discussion"
                color="blue"
                size="small"
                onClick={() => setOpenModal(true)}
              />
            </Feed.Meta>
          </Feed.Content>
        </Feed.Event>
      </Feed>
      {openModal && (
        <GroupDiscussion
          openModal={openModal}
          setOpenModal={setOpenModal}
          parentChat={chat}
        />
      )}
    </div>
  );
}
