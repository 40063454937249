import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, List, Popup } from "semantic-ui-react";
import { openModal } from "../../../../app/common/modals/modalReducer";
import { deleteSessionApi } from "../../../../app/apiRequest/channelService";

export default function SessionListItem({ session, isAdmin }) {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const { selectedClub, selectedChannel } = useSelector((state) => state.club);
  const handleItemClick = (e, { name }) => {
    setOpenPopup(false);
  };

  const extra = (
    <>
      <List>
        <List.Item
          icon="marker"
          content={session.location === "offline" ? session.venue : "online"}
        />
      </List>
      <>{new Date(session.startTime).toLocaleString()} </>
      <Popup
        trigger={
          <Button
            icon="ellipsis vertical"
            compact
            floated="right"
            onClick={() => setOpenPopup(true)}
          />
        }
        content={
          <div>
            {new Date(session.startTime) > new Date() ? (
              <div>
                {session.location === "online" && (
                  <a href={session.sessionLink} target="#blank">
                    <Button
                      fluid
                      name="joinSession"
                      content="Join session"
                      style={{ display: "block", marginBottom: 5 }}
                      onClick={() => handleItemClick}
                    />
                  </a>
                )}
                {isAdmin && (
                  <Button
                    fluid
                    name="editSession"
                    content="Edit Session"
                    style={{ display: "block", marginBottom: 5 }}
                    onClick={() => {
                      dispatch(
                        openModal({
                          modalType: "CreateSessionsForm",
                          modalProps: {
                            session: session,
                            club: selectedClub,
                            channel: selectedChannel,
                          },
                        })
                      );
                      setOpenPopup(false);
                    }}
                  />
                )}
              </div>
            ) : (
              <div>
                {isAdmin && (
                  <Button
                    fluid
                    name="editSession"
                    content="Edit Session"
                    style={{ display: "block", marginBottom: 5 }}
                    onClick={() => {
                      dispatch(
                        openModal({
                          modalType: "CreateSessionsForm",
                          modalProps: {
                            session: session,
                            club: selectedClub,
                            channel: selectedChannel,
                          },
                        })
                      );
                      setOpenPopup(false);
                    }}
                  />
                )}
                <a href={session.storedLink} target="#blank">
                  <Button
                    fluid
                    name="sessionLink"
                    content="Stored session Link"
                    style={{ display: "block", marginBottom: 5 }}
                    onClick={() => handleItemClick}
                  />
                </a>
                {isAdmin && (
                  <Button
                    fluid
                    name="updateSession"
                    content="Update stored Link"
                    style={{ display: "block", marginBottom: 5 }}
                    onClick={() => {
                      dispatch(
                        openModal({
                          modalType: "StoreSessionLink",
                          modalProps: { session: session },
                        })
                      );
                      setOpenPopup(false);
                    }}
                  />
                )}
              </div>
            )}
            {isAdmin && (
              <Button
                fluid
                name="deleteSession"
                content="Delete Session"
                style={{ display: "block" }}
                onClick={() => {
                  dispatch(deleteSessionApi(session));
                  setOpenPopup(false);
                }}
              />
            )}
          </div>
        }
        on="click"
        position="bottom center"
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onOpen={() => setOpenPopup(true)}
      />
    </>
  );

  return (
    <Card
      image={session.sessionImg}
      header={session.title}
      meta={session.club?.clubName}
      description={<p className="three-lines">{session.description}</p>}
      extra={extra}
    />
  );
}
