import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Label, Menu } from "semantic-ui-react";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignedOutMenu";
import { countUnreadNotificationsApi } from "../../app/apiRequest/notificationService";
import { countUnreadChatRoomApi } from "../../app/apiRequest/chatService";
import { setActiveMenu } from "../../app/async/asyncReducer";

export default function Navbar() {
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState("home");
  const { pathname } = useSelector((state) => state.router.location);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { unreadCount } = useSelector((state) => state.notification);
  const { unreadChatroom } = useSelector((state) => state.chats);

  useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(countUnreadNotificationsApi());
    dispatch(countUnreadChatRoomApi());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  const handleSetActiveItem = (e, { name }) => {
    dispatch(setActiveMenu(name));
  };

  return (
    <>
      {isAuthenticated ? <SignedInMenu /> : <SignedOutMenu />}
      {isAuthenticated && (
        <Menu icon="labeled" vertical fixed="left">
          <Menu.Item
            name="Clubs"
            active={activeItem.includes("club")}
            as={Link}
            to={`/clubs`}
            onClick={handleSetActiveItem}
          >
            <Icon name="group" />
            Clubs
          </Menu.Item>
          <Menu.Item
            name="Events"
            active={activeItem.includes("event")}
            as={Link}
            to={`/events`}
            onClick={handleSetActiveItem}
          >
            <Icon name="calendar plus outline" />
            Events
          </Menu.Item>
          <Menu.Item
            name="My Campus"
            active={activeItem.includes("campus")}
            as={Link}
            to={`/campus`}
            onClick={handleSetActiveItem}
          >
            <Icon name="university" />
            My Campus
          </Menu.Item>
          {/* <Menu.Item
            name="Feed"
            active={activeItem.includes("feed")}
            as={Link}
            to={`/feed`}
            onClick={handleSetActiveItem}
          >
            <Icon name="th large" />
            Feed
          </Menu.Item> */}
          <Menu.Item
            name="Chats"
            active={activeItem.includes("chat")}
            as={Link}
            to={"/chats"}
            onClick={handleSetActiveItem}
          >
            {unreadChatroom !== 0 && (
              <Label
                color="blue"
                size="mini"
                circular
                style={{ marginLeft: -10, marginBottom: 20 }}
              >
                {unreadChatroom}
              </Label>
            )}
            <Icon name="chat" />
            Chats
          </Menu.Item>
          <Menu.Item
            name="Notifications"
            active={activeItem.includes("notification")}
            as={Link}
            to={`/notifications`}
            onClick={handleSetActiveItem}
          >
            {unreadCount !== 0 && (
              <Label
                color="blue"
                size="mini"
                circular
                style={{ marginLeft: -10, marginBottom: 20 }}
              >
                {unreadCount}
              </Label>
            )}
            <Icon name="bell" />
            Notification
          </Menu.Item>
          <Menu.Item
            name="My Profile"
            active={activeItem.includes("profile")}
            as={Link}
            to={`/profile/${user}`}
            onClick={handleSetActiveItem}
          >
            <Icon name="user" />
            Profile
          </Menu.Item>
          <Dropdown item icon="ellipsis horizontal">
            <Dropdown.Menu>
              <Dropdown.Item
                as={"a"}
                href="/about"
                icon="info"
                text="About us"
              />
              <Dropdown.Item
                as={"a"}
                href="/privacy-policy"
                icon="privacy"
                text="Privacy policy"
              />
              <Dropdown.Item
                as={"a"}
                href="/terms-conditions"
                icon="file code"
                text="Terms &amp; condition"
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )}
    </>
  );
}
