import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button, Image } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import MyTextArea from "../../app/common/form/MyTextArea";
import { ControlBar, Player } from "video-react";
import { getFile, isImage, isVideo } from "../../app/common/util/util";
import { toast } from "react-toastify";
import { uploadFileApi } from "../../app/apiRequest/extraService";
import MyTextInput from "../../app/common/form/MyTextInput";
import {
  CreateChatRoomApi,
  sendChatApi,
} from "../../app/apiRequest/chatService";
import { closeModal } from "../../app/common/modals/modalReducer";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../app/async/asyncReducer";
import {
  sendChannelChatApi,
  uploadFilesToChannelApi,
} from "../../app/apiRequest/channelService";
import { compressImg } from "../../app/common/photos/compressImg";

export default function SendFileForm() {
  const dispatch = useDispatch();
  const { type, where, room, club, socket, activeUsers } = useSelector(
    (state) => state.modal.modalProps
  );
  const [file, setFile] = useState(null);
  const [fileObjectUrl, setFileObjectUrl] = useState(null);
  const { loading } = useSelector((state) => state.async);
  const currentUser = useSelector((state) => state.auth.user);
  const { selectedChat } = useSelector((state) => state.chats);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    if (type === "image") {
      if (isImage(file.name)) {
        setFile(file);
        setFileObjectUrl(URL.createObjectURL(file));
      } else {
        toast.error("select only video");
      }
    } else if (type === "video") {
      if (isVideo(file.name)) {
        setFile(file);
        setFileObjectUrl(URL.createObjectURL(file));
      } else {
        toast.error("select only video");
      }
    } else {
      setFile(file);
    }
  };

  return (
    <ModalWrapper size="tiny" header="upload file">
      <Formik
        initialValues={{ chat: "", filesLink: "" }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (type === "link") {
              values.filetype = type;
              values.channel = room;
              values.club = club;
              values.seenBy = [currentUser];
              dispatch(uploadFilesToChannelApi(values));
            } else {
              var data = new FormData();
              if (type === "image") {
                await compressImg(fileObjectUrl).then(
                  async (compressedDataUrl) => {
                    let imageFile = await getFile(compressedDataUrl, file.name);
                    data.append("imageFile", imageFile);
                  }
                );
              } else {
                data.append("imageFile", file);
              }
              await dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                console.log(response);
                values.filename = file.name;
                values.filesize = file.size;
                values.realFiletype = file.type;
                values.file = response[0].location;
                values.filetype = type;
                if (where === "discussion") {
                  values.channel = room;
                  values.club = club;
                  values.seenBy = activeUsers;
                  socket.emit("chatMessage", values);
                  sendChannelChatApi(values);
                } else if (where === "chat") {
                  values.room = room;
                  values.seenBy = selectedChat.users;
                  socket.emit("chatMessage", values);
                  if (selectedChat?.newChat) {
                    dispatch(
                      CreateChatRoomApi(
                        { users: [currentUser, selectedChat.users[1]._id] },
                        values
                      )
                    );
                  } else sendChatApi(values);
                } else {
                  values.channel = room;
                  values.club = club;
                  values.filesLink = response[0].location;
                  values.seenBy = [currentUser];
                  dispatch(uploadFilesToChannelApi(values));
                }
              });
            }
            console.log(values);
          } catch (error) {
            dispatch(asyncActionError(error));
            console.log(error);
          } finally {
            dispatch(closeModal());
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="ui form">
            {where !== "clubFiles" && (
              <MyTextArea
                name="chat"
                placeholder="write message here.."
                label={"Message"}
                rows={2}
              />
            )}
            {type !== "link" && (
              <div style={{ marginBottom: 16 }}>
                <label htmlFor="myfile">{`Select a ${type} :`}</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  onChange={handleFileChange}
                />
              </div>
            )}
            {type === "image" && <Image src={fileObjectUrl} />}
            {type === "video" && fileObjectUrl && (
              <Player playsInline fluid={false} width="100%" height={278}>
                <source src={fileObjectUrl} />
                <ControlBar autoHide={true} />
              </Player>
            )}
            {type === "link" && (
              <MyTextInput
                name="filesLink"
                placeholder="filesLink here..."
                label={"Link"}
              />
            )}
            <Button
              loading={isSubmitting || loading}
              disabled={
                !isValid ||
                isSubmitting ||
                (file === null && type !== "link") ||
                loading
              }
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Send"
              style={{ marginTop: 16 }}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
