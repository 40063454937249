import React, { useEffect } from "react";
import { Button, FormGroup, Header, Popup, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import MyDateInput from "../../../app/common/form/MyDateInput";
import {
  fetchSingleEventApi,
  manageEventApi,
} from "../../../app/apiRequest/eventService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { booleanData, roundTypeData } from "../../../app/api/eventArrays";

export default function EventRoundsForm({ match, location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedEvent } = useSelector((state) => state.event);
  const { error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleEventApi(match.params.id));
  }, [dispatch, match.params.id, location.pathname]);

  const initialValues =
    selectedEvent?.stages?.length > 0
      ? selectedEvent
      : {
          stages: [],
        };

  const validationSchema = Yup.object().shape({
    stages: Yup.array().of(
      Yup.object().shape({
        roundTitle: Yup.string()
          .required("Round title is required")
          .min(2, "Round title must be at least 2 characters"),
        startDate: Yup.string().required("Start date & time is required"),
        endDate: Yup.string().required("End date & time is required"),
        duration: Yup.number()
          .required("Duration is required")
          .min(1, "Duration must be at least 1 minute")
          .typeError("Duration must be a number"),
        description: Yup.string()
          .required("Description is required")
          .min(10, "Description must be at least 10 characters"),
      })
    ),
  });

  if (!selectedEvent && !error)
    return <LoadingComponent content="Loadind event..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <Segment clearing style={{ marginTop: 75, marginRight: 20 }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            values._id = selectedEvent?._id;
            dispatch(manageEventApi(values)).then(
              () => {
                history.push(`/event/manage/description/${selectedEvent?._id}`);
              },
              (error) => history.push("/error")
            );
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className="ui form">
            <FieldArray name="stages">
              {({ insert, remove, push }) => (
                <div style={{ marginBottom: 16 }}>
                  <Segment style={{ height: 65 }}>
                    <Header
                      style={{ marginTop: 5 }}
                      floated="left"
                      as={"h4"}
                      content="List all the rounds separately to give users a clear understanding of how they will proceed forward in this opportunity."
                    />
                    <Button
                      type="button"
                      content="Add Round"
                      floated="right"
                      color="blue"
                      onClick={() => {
                        push({
                          roundTitle: "",
                          startDate: "",
                          endDate: "",
                          duration: "",
                          description: "",
                          roundType: "",
                          platform: "",
                          eliminator: false,
                        });
                        window.scroll({
                          top: 1000,
                          behavior: "smooth",
                        });
                      }}
                    />
                  </Segment>
                  {values.stages?.length > 0 &&
                    values.stages.map((stage, index) => (
                      <Segment key={index}>
                        <Header
                          color="teal"
                          content={`Round ${index + 1}`}
                          floated="left"
                        />
                        <Button
                          type="button"
                          icon="close"
                          color="red"
                          floated="right"
                          size="mini"
                          onClick={() => remove(index)}
                        />
                        <MyTextInput
                          name={`stages.${index}.roundTitle`}
                          placeholder="Round Title"
                          label={"Round Title"}
                        />
                        <FormGroup widths="equal">
                          <MyDateInput
                            name={`stages.${index}.startDate`}
                            placeholderText={new Date()}
                            timeFormat="HH:mm"
                            showTimeSelect
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy  h:mm a"
                            autoComplete="off"
                            label={"Start Date & Time"}
                          />
                          <MyDateInput
                            name={`stages.${index}.endDate`}
                            placeholderText={new Date()}
                            timeFormat="HH:mm"
                            showTimeSelect
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy  h:mm a"
                            autoComplete="off"
                            label={"End Date & Time"}
                          />
                        </FormGroup>
                        <MyTextArea
                          name={`stages.${index}.description`}
                          placeholder="Tell people about this round."
                          rows="3"
                          label={"Discription"}
                        />
                        <FormGroup widths={"3"}>
                          <MyTextInput
                            name={`stages.${index}.duration`}
                            placeholder="60"
                            label={"Duration(time in minutes)"}
                          />
                          <MySelectInput
                            name={`stages.${index}.roundType`}
                            placeholder="Online"
                            options={roundTypeData}
                            label={"This round will be :-"}
                          />
                          <MySelectInput
                            name={`stages.${index}.eliminator`}
                            placeholder="Yes"
                            options={booleanData}
                            label={
                              <Popup
                                content="Shortlist candidates will be forwarded to next round"
                                trigger={
                                  <Header
                                    color="blue"
                                    icon="info circle"
                                    sub
                                    content="Elliminitaion Round"
                                    style={{ marginTop: 3 }}
                                  />
                                }
                              />
                            }
                          />
                        </FormGroup>
                      </Segment>
                    ))}
                </div>
              )}
            </FieldArray>
            <Button
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              type="submit"
              floated="right"
              positive
              content="Save &amp; Continue"
            />
            <Button
              disabled={isSubmitting}
              type="button"
              floated="right"
              content="Previous"
              as={Link}
              to={`/event/manage/registration/${selectedEvent?._id}`}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
