import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Confirm,
  Grid,
  Header,
  Label,
  Menu,
  Popup,
  Image,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import {
  cancelClubMemberRequestApi,
  deleteClubApi,
  updateClubApi,
} from "../../../app/apiRequest/clubService";
import { toast } from "react-toastify";
import { openModal } from "../../../app/common/modals/modalReducer";
import {
  SingleChannelMemberApi,
  countUnreadDiscussionsApi,
  countUnreadFilesApi,
  deleteChannelApi,
  deleteChannelMemberApi,
} from "../../../app/apiRequest/channelService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { listenToSelectedChannel } from "../clubReducer";
import { asyncActionError } from "../../../app/async/asyncReducer";

export default function ClubNavMenu({ club, channelId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState("chats");
  const [openPopup, setOpenPopup] = useState(false);
  const { loading, error } = useSelector((state) => state.async);
  const { pathname } = useSelector((state) => state.router.location);
  const { user } = useSelector((state) => state.auth);
  const { selectedClub, selectedChannel } = useSelector((state) => state.club);
  const [member, setMember] = useState(null);
  const [openLeaveClubConfirm, setOpenLeaveClubConfirm] = useState(false);
  const [openDeleteClubConfirm, setOpenDeleteClubConfirm] = useState(false);
  const [openLeaveChannelConfirm, setOpenLeaveChannelConfirm] = useState(false);
  const [openDeleteChannelConfirm, setOpenDeleteChannelConfirm] =
    useState(false);

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  useEffect(() => {
    dispatch(SingleChannelMemberApi(channelId, user)).then((response) => {
      if (response.length > 0) setMember(response[0]);
      else dispatch(asyncActionError({ msg: "You are not autharised!" }));
    });
  }, [channelId, user, dispatch]);

  useEffect(() => {
    for (const channel of selectedClub.channels) {
      if (channel._id === channelId) {
        dispatch(listenToSelectedChannel(channel));
        channel.chatCount = 0;
        channel.fileCount = 0;
      } else {
        dispatch(countUnreadDiscussionsApi(channel?._id)).then(
          (response) => {
            channel.chatCount = response.count;
          },
          (error) => console.log(error.message)
        );
        dispatch(countUnreadFilesApi(channel?._id)).then(
          (response) => {
            channel.fileCount = response.count;
          },
          (error) => console.log(error.message)
        );
      }
    }
  }, [dispatch, selectedClub, channelId]);

  const isMember = selectedChannel?.members?.includes(user);
  const isChannelAdmin = selectedChannel?.admin?.includes(user);
  const isClubAdmin = club?.admin.some((item) => item._id === user);
  const isChannelPrivate = selectedChannel?.privacy === "private";

  const handleVerifyClub = () => {
    dispatch(
      updateClubApi({ ...selectedClub, college_status: "verified" })
    ).then(() => {
      toast.success("updated sucessfully!");
    });
  };

  const handleUnverifyClub = () => {
    dispatch(
      updateClubApi({
        ...selectedClub,
        college_status: "rejected",
        college: null,
        privacy: "private",
      })
    ).then(() => {
      toast.success("updated sucessfully!");
    });
  };

  const handleAddAsUnofficialClub = () => {
    dispatch(updateClubApi({ ...club, college_status: "unverified" })).then(
      () => {
        toast.success("updated sucessfully!");
      }
    );
  };

  const handleLeaveclub = () => {
    cancelClubMemberRequestApi(member.club, member.user._id).then(() => {
      setOpenLeaveClubConfirm(false);
      history.push("/clubs");
      window.location.reload();
    });
  };

  const handleDeleteClub = () => {
    dispatch(deleteClubApi(selectedClub._id)).then(() => {
      setOpenDeleteClubConfirm(false);
      history.push("/clubs");
      window.location.reload();
    });
  };

  const handleLeaveChannel = () => {
    deleteChannelMemberApi(member.channel, member.user._id).then(() => {
      setOpenLeaveChannelConfirm(false);
      history.push("/clubs");
      window.location.reload();
    });
  };

  const handleDeleteChannel = () => {
    dispatch(deleteChannelApi(channelId)).then(() => {
      setOpenDeleteChannelConfirm(false);
      history.push("/clubs");
      window.location.reload();
    });
  };

  if ((loading && !selectedChannel) || (!error && !selectedChannel))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to={"/error"} />;

  return (
    <>
      <Grid>
        <Grid.Column width={3}>
          <div id="club-nav-menu">
            <Button
              content="Back"
              icon="angle left"
              style={{ margin: 20, display: "block" }}
              as={Link}
              to="/clubs"
            />
            {(isMember ||
              selectedClub.college?.admin.some((item) => item === user)) && (
              <Popup
                trigger={
                  <Button
                    icon="ellipsis vertical"
                    size="mini"
                    floated="right"
                    color="blue"
                    style={{ marginRight: 20 }}
                    onClick={() => setOpenPopup(true)}
                  />
                }
                content={
                  <div>
                    {isClubAdmin && (
                      <Button
                        fluid
                        name="manageClub"
                        content="Edit Club"
                        style={{ display: "block", marginBottom: 2 }}
                        onClick={() => {
                          setOpenPopup(false);
                          history.push(`/club/manage/clubInfo/${club._id}`);
                        }}
                      />
                    )}
                    {isClubAdmin && (
                      <Button
                        fluid
                        name="manageChannel"
                        content="Manage Channel"
                        style={{ display: "block", marginBottom: 2 }}
                        onClick={() => {
                          setOpenPopup(false);
                          history.push(`/club/manage/channels/${club._id}`);
                        }}
                      />
                    )}
                    {isClubAdmin && (
                      <Button
                        name="manageMember"
                        fluid
                        content="Manage members"
                        style={{ display: "block", marginBottom: 2 }}
                        onClick={() => {
                          setOpenPopup(false);
                          history.push(`/club/manage/members/${club._id}`);
                        }}
                      />
                    )}
                    {selectedClub.college?.admin.some(
                      (item) => item === user
                    ) &&
                      club.college_status !== "verified" && (
                        <Button
                          fluid
                          loading={loading}
                          content={"Verify college club"}
                          style={{ display: "block", marginBottom: 2 }}
                          onClick={() => {
                            setOpenPopup(false);
                            handleVerifyClub();
                          }}
                        />
                      )}
                    {selectedClub.college?.admin.some(
                      (item) => item === user
                    ) && (
                      <Button
                        fluid
                        loading={loading}
                        content={"Remove from College Page"}
                        style={{ display: "block", marginBottom: 2 }}
                        onClick={() => {
                          setOpenPopup(false);
                          handleUnverifyClub();
                        }}
                      />
                    )}
                    {selectedClub.college?.admin.some(
                      (item) => item === user
                    ) &&
                      club.college_status !== "unverified" && (
                        <Button
                          fluid
                          loading={loading}
                          content={"Add to college page as unofficial"}
                          style={{ display: "block", marginBottom: 2 }}
                          onClick={() => {
                            setOpenPopup(false);
                            handleAddAsUnofficialClub();
                          }}
                        />
                      )}
                    {!(isClubAdmin && club.admin.length === 1) && isMember && (
                      <Button
                        name="leaveClub"
                        fluid
                        content="Leave Club"
                        style={{ display: "block", marginBottom: 2 }}
                        onClick={() => {
                          setOpenPopup(false);
                          setOpenLeaveClubConfirm(true);
                        }}
                      />
                    )}
                    {isClubAdmin && (
                      <div>
                        <Button
                          name="deleteClub"
                          fluid
                          content="Delete Club"
                          style={{ display: "block" }}
                          onClick={() => {
                            setOpenPopup(false);
                            setOpenDeleteClubConfirm(true);
                          }}
                        />
                      </div>
                    )}
                  </div>
                }
                on="click"
                position="bottom center"
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                onOpen={() => setOpenPopup(true)}
              />
            )}
            <Header
              as="h3"
              content={club.clubName}
              style={{ margin: 20, overFlow: "auto" }}
            />
            <Menu secondary vertical fluid>
              {club.channels.map(
                (channel) =>
                  channel.members.includes(user) && (
                    <Menu.Item
                      key={channel._id}
                      active={channelId === channel._id}
                      onClick={() =>
                        (window.location = `/club/${channel.club}/discussion/${channel._id}`)
                      }
                      style={{ marginLeft: 16 }}
                    >
                      {channel.name}
                      {!(channel.chatCount + channel.fileCount === 0) && (
                        <Label color="blue" size="mini">
                          {channel.chatCount + channel.fileCount}
                        </Label>
                      )}
                      {channel.privacy === "private" && (
                        <Icon name="lock" style={{ marginLeft: 8 }} />
                      )}
                    </Menu.Item>
                  )
              )}
            </Menu>
          </div>
        </Grid.Column>
      </Grid>
      <div>
        <Menu pointing secondary fixed="top" id="channel-nav-menu">
          <Menu.Item header as={Link} to={`/club/about/${club._id}`}>
            <Image avatar spaced="right" src={club.clubImg} />
            {selectedChannel.name}
          </Menu.Item>
          <Menu.Item
            name="discussion"
            content="Chats"
            active={activeItem.includes("discussion")}
            as={Link}
            to={`/club/${club._id}/discussion/${selectedChannel._id}`}
          />
          <Menu.Item
            name="files"
            active={activeItem.includes("files")}
            as={Link}
            to={`/club/${club._id}/files/${selectedChannel._id}`}
          />
          <Menu.Item
            name="session"
            active={activeItem.includes("session")}
            as={Link}
            to={`/club/${club._id}/session/${selectedChannel._id}`}
          />
          <Menu.Item
            name="about"
            active={activeItem.includes("about")}
            as={Link}
            to={`/club/${club._id}/about/${selectedChannel._id}`}
          />
          <Dropdown item icon="ellipsis horizontal">
            <Dropdown.Menu>
              {!isChannelAdmin && (
                <Dropdown.Item
                  as={"a"}
                  href={`/club/${club._id}/channel/member/${channelId}`}
                  icon="users"
                  text="View members"
                />
              )}
              {isChannelAdmin && (
                <Dropdown.Item
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: "ChannelForm",
                        modalProps: {
                          channel: selectedChannel,
                        },
                      })
                    )
                  }
                  icon="edit"
                  text="Edit channel"
                />
              )}
              {isChannelAdmin && isChannelPrivate && (
                <Dropdown.Item
                  as={"a"}
                  href={`/club/${club._id}/channel/member/${channelId}`}
                  icon="users"
                  text="Manage members"
                />
              )}
              {!(isChannelAdmin && selectedChannel.admin.length === 1) &&
                isMember && (
                  <Dropdown.Item
                    onClick={() => {
                      setOpenLeaveChannelConfirm(true);
                    }}
                    icon="log out"
                    text="Leave channel"
                  />
                )}
              {isChannelAdmin && club.channels.size > 1 && (
                <Dropdown.Item
                  onClick={() => {
                    setOpenDeleteChannelConfirm(true);
                  }}
                  icon="trash"
                  text="delete channel"
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </div>
      <Confirm
        open={openLeaveClubConfirm}
        size="mini"
        onCancel={() => setOpenLeaveClubConfirm(false)}
        onConfirm={handleLeaveclub}
      />
      <Confirm
        open={openDeleteClubConfirm}
        size="mini"
        header="Do you want to delete this club?"
        content="All data of this club will be deleted. And it can't be reversed."
        onCancel={() => setOpenDeleteClubConfirm(false)}
        onConfirm={handleDeleteClub}
      />
      <Confirm
        open={openLeaveChannelConfirm}
        size="mini"
        onCancel={() => setOpenLeaveChannelConfirm(false)}
        onConfirm={handleLeaveChannel}
      />
      <Confirm
        open={openDeleteChannelConfirm}
        size="mini"
        header="Do you want to delete this channel?"
        content="All data of this channel will be deleted. And it can't be reversed."
        onCancel={() => setOpenDeleteChannelConfirm(false)}
        onConfirm={handleDeleteChannel}
      />
    </>
  );
}
