import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Container, Loader, Popup } from "semantic-ui-react";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import { openModal } from "../../../../app/common/modals/modalReducer";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClubNavMenu from "../ClubNavMenu";
import ClubFilesList from "./ClubFilesList";
import { clearFiles } from "./clubFilesReducer";
import { fetchChannelFilesApi } from "../../../../app/apiRequest/channelService";
import { listenToSelectedChannel } from "../../clubReducer";

export default function ClubFilesDashboard({ match }) {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const { loading, error } = useSelector((state) => state.async);
  const { user } = useSelector((state) => state.auth);
  const { selectedClub, selectedChannel } = useSelector((state) => state.club);
  const { isLoading, files, moreFiles, lastDocId } = useSelector(
    (state) => state.clubFiles
  );

  useEffect(() => {
    if (selectedClub?._id === match.params.clubId) {
      dispatch(clearFiles());
      dispatch(fetchChannelFilesApi(match.params.channelId, ""));
      return;
    }
    dispatch(fetchSingleClubApi(match.params.clubId)).then(() => {
      dispatch(clearFiles());
      dispatch(fetchChannelFilesApi(match.params.channelId, ""));
    });
    // eslint-disable-next-line
  }, [dispatch, match.params.channelId, match.params.clubId]);

  useEffect(() => {
    if (selectedClub) {
      for (const channel of selectedClub.channels) {
        if (channel._id === match.params.channelId)
          dispatch(listenToSelectedChannel(channel));
      }
    }
  }, [dispatch, selectedClub, match.params.channelId]);

  const handlePopupBtnClick = (e, { name }) => {
    setOpenPopup(false);
    dispatch(
      openModal({
        modalType: "SendFileForm",
        modalProps: {
          type: name,
          where: "clubFiles",
          room: match.params.channelId,
          club: match.params.clubId,
        },
      })
    );
  };

  function handleFetchNextFiles() {
    dispatch(
      fetchChannelFilesApi(match.params.channelId, "?_id[$lt]=" + lastDocId)
    );
  }
  if (loading || !selectedClub)
    return <LoadingComponent content="Loadind club..." />;

  if (error) return <Redirect to={"/error"} />;

  return (
    <div className="club-detailed-page">
      <ClubNavMenu club={selectedClub} channelId={match.params.channelId} />
      <Container>
        <div style={{ marginTop: 16 }}>
          <ClubFilesList
            files={files}
            getNextFiles={handleFetchNextFiles}
            loading={loading}
            moreFiles={moreFiles}
          />
        </div>
        <Loader active={isLoading} />
        {selectedChannel &&
          ((selectedChannel.permission === "private" &&
            selectedChannel.admin.includes(user)) ||
            selectedChannel.permission === "public") && (
            <Popup
              trigger={
                <Button
                  positive
                  icon="add"
                  size="small"
                  style={{ position: "fixed", bottom: 45, left: 400 }}
                />
              }
              content={
                <>
                  <Button
                    fluid
                    name="image"
                    icon="file image"
                    content="Image"
                    color="blue"
                    style={{ display: "block", marginBottom: 2 }}
                    onClick={handlePopupBtnClick}
                  />
                  <Button
                    fluid
                    name="video"
                    icon="file video"
                    content="Video"
                    color="blue"
                    style={{ display: "block", marginBottom: 2 }}
                    onClick={handlePopupBtnClick}
                  />
                  <Button
                    fluid
                    name="file"
                    icon="file alternate"
                    content="Document"
                    color="blue"
                    style={{ display: "block", marginBottom: 2 }}
                    onClick={handlePopupBtnClick}
                  />
                  <Button
                    fluid
                    name="link"
                    icon="linkify"
                    content="Link"
                    color="blue"
                    style={{ display: "block" }}
                    onClick={handlePopupBtnClick}
                  />
                </>
              }
              on="click"
              position="bottom left"
              open={openPopup}
              onClose={() => setOpenPopup(false)}
              onOpen={() => setOpenPopup(true)}
            />
          )}
      </Container>
    </div>
  );
}
