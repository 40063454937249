import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Header, Icon, Loader, Segment } from "semantic-ui-react";
import { countUnreadDiscussionsApi } from "../../../app/apiRequest/clubService";
import { addYourClubs, clearYourClubs } from "../clubReducer";
import ClubListItem from "./ClubListItem";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { Helmet } from "react-helmet";

export default function ClubDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { yourClubs, yourClubsFetched } = useSelector((state) => state.club);
  const { location } = useSelector((state) => state.router);
  const { loading } = useSelector((state) => state.async);
  const { currentUserProfile } = useSelector((state) => state.profile);

  useEffect(() => {
    if (yourClubs.length > 0 && yourClubsFetched) {
      for (const club of yourClubs) {
        dispatch(countUnreadDiscussionsApi(club?._id)).then(
          (chatCount) => {
            club.unreadCount = chatCount.count;
            dispatch(asyncActionFinish());
          },
          (err) => {
            console.log(err.message);
            dispatch(asyncActionError(err));
          }
        );
      }
      dispatch(clearYourClubs());
      dispatch(addYourClubs(yourClubs));
    }
    // eslint-disable-next-line
  }, [location, dispatch, yourClubsFetched]);

  const handleGoToCampus = () => {
    history.push("/campus");
  };

  if (!currentUserProfile || loading) return <Loader content="Loading ..." />;

  return (
    <>
      <div style={{ marginTop: 20 }}>
        {yourClubs.length > 0 ? (
          <div className="card-container">
            {yourClubs.length > 0 &&
              yourClubs.map((club) => (
                <ClubListItem club={club} key={club._id} />
              ))}
          </div>
        ) : (
          <Segment placeholder>
            <Header icon>
              <Icon name="dont" />
              {
                "You haven't joined any club. Join your College club, or Create your own club."
              }
            </Header>
            <Button onClick={handleGoToCampus} primary>
              Go to Your College Page
            </Button>
          </Segment>
        )}
      </div>
      {/* <Button
        name="visibleBlueClub"
        content="Club-Chat club"
        icon={visibleBlueClub ? "angle down" : "angle right"}
        labelPosition="left"
        style={{
          marginTop: 30,
          marginBottom: 20,
          color: "teal",
          background: "white",
          display: "block",
        }}
        onClick={handleToggleVisibility}
      />
      <Transition
        visible={visibleBlueClub}
        animation="scale"
        unmountOnHide={true}
        duration={500}
      >
        <div style={{ paddingBottom: 16 }}>
          <div className="card-container">
            {blueClubs.map((club) => (
              // !yourClubs.some((item) => item._id === club._id) &&
              <ClubListItem club={club} key={club._id} />
            ))}
          </div>
        </div>
      </Transition> */}
      <Helmet>
        <title>{"Club"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </>
  );
}
