export const learningXclubListArrays = [
    {
      key: "63b3f11258ee0158779255c3",
      text: "Entrepreneurship club",
      value: "63b3f11258ee0158779255c3",
    },
    {
      key: "63b3f07458ee0158779255c1",
      text: "Debate club",
      value: "63b3f07458ee0158779255c1",
    },
    {
      key: "63aff3fab316a1206fbde862",
      text: "Coding club",
      value: "63aff3fab316a1206fbde862",
    },
    {
      key: "63aff231b316a1206fbde860",
      text: "Finance club",
      value: "63aff231b316a1206fbde860",
    },
  ];
  