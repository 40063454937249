import React from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Label } from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { useDispatch, useSelector } from "react-redux";
import { updateClubApi } from "../../../app/apiRequest/clubService";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { toast } from "react-toastify";

export default function ClubfaqsForm() {
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const initialValues = {
    faqs: [
      { question: "", answer: "" },
      { question: "", answer: "" },
    ],
  };
  const validationSchema = Yup.object({
    question: Yup.string(),
    answer: Yup.string(),
  });

  return (
    <ModalWrapper
      size="large"
      header="Some frequently asked Questions and Answers."
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values._id = selectedClub?._id;
            dispatch(updateClubApi(values)).then(() => {
              dispatch(closeModal());
              toast.success("Club created successfully");
            });
          } catch (error) {
            setErrors({ auth: error.message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form className="ui form">
            <FieldArray name="faqs">
              {({ insert, remove, push }) => (
                <div>
                  {values.faqs?.length > 0 &&
                    values.faqs.map((faq, index) => (
                      <div key={index} style={{ marginBottom: 16 }}>
                        <MyTextArea
                          name={`faqs.${index}.question`}
                          placeholder={`Question ${index + 1}`}
                          label={`Question ${index + 1}`}
                          rows={2}
                        />
                        <MyTextArea
                          name={`faqs.${index}.answer`}
                          placeholder={`Answer ${index + 1}`}
                          label={`Answer ${index + 1}`}
                          rows={3}
                        />
                      </div>
                    ))}
                  <Button
                    content="Add more.."
                    floated="left"
                    type="button"
                    color="blue"
                    onClick={() => push({ question: "", answer: "" })}
                  />
                </div>
              )}
            </FieldArray>
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <div>
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                floated="right"
                color="teal"
                content="Done"
                icon="check"
                style={{ margin: 10 }}
              />
              <Button
                content="Skip"
                floated="right"
                type="button"
                style={{ margin: 10 }}
                onClick={() => dispatch(closeModal())}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
