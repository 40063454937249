const LISTEN_TO_CURRENT_USER_PROFILE = "LISTEN_TO_CURRENT_USER_PROFILE";
const LISTEN_TO_SELECTED_USER_PROFILE = "LISTEN_TO_SELECTED_USER_PROFILE";
const FETCH_REMINDER = "FETCH_REMINDER";
const FETCH_FEATURED_AD = "FETCH_FEATURED_AD";

export function listenToCurrentUserProfile(profile) {
  return {
    type: LISTEN_TO_CURRENT_USER_PROFILE,
    payload: profile,
  };
}

export function listenToSelectedUserProfile(profile) {
  return {
    type: LISTEN_TO_SELECTED_USER_PROFILE,
    payload: profile,
  };
}

export const addReminder = (reminder) => ({
  type: FETCH_REMINDER,
  payload: reminder,
});

export const addFeaturedAd = (ads) => ({
  type: FETCH_FEATURED_AD,
  payload: ads,
});

const initialState = {
  currentUserProfile: null,
  selectedUserProfile: null,
  reminder: [],
  feturedAd: [],
};

export default function ProfileReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LISTEN_TO_CURRENT_USER_PROFILE:
      return {
        ...state,
        currentUserProfile: payload,
      };
    case LISTEN_TO_SELECTED_USER_PROFILE:
      return {
        ...state,
        selectedUserProfile: payload,
      };
    case FETCH_REMINDER:
      return {
        ...state,
        reminder: payload,
      };
    case FETCH_FEATURED_AD:
      return {
        ...state,
        feturedAd: payload,
      };
    default: {
      return state;
    }
  }
}
