import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Label } from "semantic-ui-react";
import ModalWrapper from "../../../../app/common/modals/ModalWrapper";
import { useSelector } from "react-redux";
import { closeModal } from "../../../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { updateSessionApi } from "../../../../app/apiRequest/channelService";
import { toast } from "react-toastify";

export default function StoreSessionLink() {
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.modal.modalProps);
  const { selectedChannel } = useSelector((state) => state.club);

  return (
    <ModalWrapper size="small" header={`Link for ${session.title} round`}>
      <Formik
        initialValues= {{storedLink : session.storedLink}}
        validationSchema={Yup.object({
          storedLink: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            dispatch(updateSessionApi({...session, storedLink: values.storedLink}, selectedChannel)).then(() => {
                dispatch(closeModal());
                toast.success("Link updated!");
              });
          } catch (error) {
            console.log(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyTextInput name="storedLink" placeholder="" label={"Uploaded session Link"} />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 20 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Update link"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
