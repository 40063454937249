import { toast } from "react-toastify";
import {
  loginError,
  requestLogin,
  receiveLogin,
} from "../../features/auth/authReducer";
import { closeModal, openModal } from "../common/modals/modalReducer";

export const signupUser = (creds, setErrors) => (dispatch) => {
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogin());

  return fetch(process.env.REACT_APP_API_BASE_URL + "users/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creds),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.success) {
        toast.success("OTP has been sent!");
        dispatch(
          openModal({
            modalType: "VerificationForm",
            modalProps: { user: response.user, from: "signup" },
          })
        );
      } else {
        var error = new Error("Error: " + response.err.message);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(loginError(error.message));
      setErrors({ auth: error.message });
    });
};

export const otpValidation = (creds) => (dispatch) => {
  dispatch(requestLogin());

  return fetch(process.env.REACT_APP_API_BASE_URL + "users/verification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creds),
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (response.success) {
        // If login was successful, set the token in local storage
        localStorage.setItem("token", response.token);
        localStorage.setItem("creds", response.user._id);
        // Dispatch the success action
        dispatch(receiveLogin(response));
        dispatch(closeModal());
      } else {
        toast.error(response.status);
        var error = new Error("Error " + response.status);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => dispatch(loginError(error.message)));
};
