import React, { useEffect, useState } from "react";
import { Step } from "semantic-ui-react";
import { useSelector } from "react-redux";

export default function EventForm() {
  const [activeItem, setActiveItem] = useState("details");
  const { pathname } = useSelector((state) => state.router.location);
  const {selectedEvent} = useSelector((state) => state.event);

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  const steps1 = [
    {
      key: "details",
      icon: "info circle",
      title: "Event Details",
      active: activeItem.includes("details"),
    },
    {
      key: "registration",
      icon: "check circle outline",
      title: "Registration",
      active: activeItem.includes("registration"),
      disabled:
        activeItem.includes("details"),
    },
    {
      key: "rounds",
      icon: "tasks",
      title: "Event Rounds",
      active: activeItem.includes("rounds"),
      disabled:
        activeItem.includes("details") ||
        activeItem.includes("registration"),
    },
    {
      key: "description",
      icon: "bullhorn",
      title: "Rules & Regulation",
      active: activeItem.includes("description"),
      disabled:
        activeItem.includes("details") ||
        activeItem.includes("registration") ||
        activeItem.includes("rounds"),
    },
    {
      key: "reward",
      icon: "rupee sign",
      title: "Rewards & Recognition",
      active: activeItem.includes("reward"),
      disabled:
        activeItem.includes("details") ||
        activeItem.includes("registration") ||
        activeItem.includes("rounds") ||
        activeItem.includes("description"),
    },
  ];

  const steps2 = [
    {
      key: "details",
      icon: "info circle",
      title: "Event Details",
      active: activeItem.includes("details"),
    },
    {
      key: "registration",
      icon: "check circle outline",
      title: "Registration",
      active: activeItem.includes("registration"),
      disabled:
        activeItem.includes("details")
    },
    {
      key: "description",
      icon: "bullhorn",
      title: "Rules & Regulation",
      active: activeItem.includes("description"),
      disabled:
        activeItem.includes("details") ||
        activeItem.includes("registration")
    },
  ];

  return (
    <>
      <Step.Group
        items={selectedEvent?.eventType === "contest" ? steps1 : steps2}
        style={{ position: "fixed", top: 55 }}
      />
    </>
  );
}
