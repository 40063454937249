import React, { useEffect } from "react";
import { useField } from "formik";
import { FormField, Label } from "semantic-ui-react";

export default function ChatTextInput({ label, ...props }) {
  const [field, meta] = useField(props);

  // Initialize the number of rows to 1
  const [rows, setRows] = React.useState(1);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && rows < 5) {
      e.preventDefault();
      const input = e.target;
      const value = input.value;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Insert a newline character at the caret position
      input.value = value.substring(0, start) + "\n" + value.substring(end);
      input.selectionStart = input.selectionEnd = start + 1;

      // Trigger Formik's onChange manually
      field.onChange(e);

      // Update the number of rows
      setRows(Math.min(rows + 1, 5));
    } else if (e.key === "Backspace" && rows > 1) {
      // Decrease the number of rows when Backspace is pressed
      e.preventDefault();

      // Update the number of rows
      setRows(rows - 1);
    }
  };

  // Update the number of rows based on the content
  useEffect(() => {
    const textarea = document.querySelector(`textarea[name="${field.name}"]`);
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [field.value, field.name]);

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <textarea
        {...field}
        {...props}
        rows={rows}
        onKeyDown={handleKeyDown}
      />
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
}
