import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal, Button } from "semantic-ui-react";

export default function PhotoCropper({
  photoCropperOpen,
  setPhotoCropperOpen,
  images,
  setImages,
  imagePreview,
  imageNo,
  editing,
  aspectRatio,
}) {
  const cropperRef = useRef(null);
  var cropper = null;
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <Modal
      onClose={() => setPhotoCropperOpen(false)}
      open={photoCropperOpen}
      size="small"
    >
      <Modal.Header>Edit Image</Modal.Header>
      <Modal.Content>
      {aspectRatio === 4 / 3 ? (
          <Cropper
            src={imagePreview}
            style={{ height: "100%", width: "100%" }}
            // Cropper.js options
            guides={false}
            movable={false}
            zoomable={false}
            viewMode={0}
            dragMode="move"
            crop={onCrop}
            ref={cropperRef}
            autoCropArea={1}
          />
        ) : (
          <Cropper
            src={imagePreview}
            style={{ height: "100%", width: "100%" }}
            // Cropper.js options
            aspectRatio={aspectRatio}
            guides={false}
            movable={false}
            zoomable={false}
            viewMode={0}
            dragMode="move"
            crop={onCrop}
            ref={cropperRef}
            autoCropArea={1}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="check"
          content="Done"
          onClick={() => {
            setPhotoCropperOpen(false);
            editing
              ? setImages(
                  images.map((image) =>
                    images.indexOf(image) === imageNo
                      ? (image = cropper.getCroppedCanvas().toDataURL())
                      : image
                  )
                )
              : setImages((arr) => [
                  ...arr,
                  cropper
                    .getCroppedCanvas({
                      maxWidth: 1024,
                      maxHeight: 1024,
                    })
                    .toDataURL(),
                ]);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}
