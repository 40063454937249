import React, { useState, useEffect } from "react";
import { Grid, Header, Loader, Menu } from "semantic-ui-react";
import { useSelector } from "react-redux";
import FeaturedFeed from "../common/FeaturedFeed";
import ReminderSessionList from "./ReminderSessionList";
import ReminderEventList from "./ReminderEventList";
import {
  EVENT_REMINDER_RETAIN_STATE,
  SESSION_REMINDER_RETAIN_STATE,
} from "./reminderReducer";
import { useDispatch } from "react-redux";
import { fetchEventsApi } from "../../app/apiRequest/eventService";
import { fetchChannelSessionsApi } from "../../app/apiRequest/channelService";
import { Helmet } from "react-helmet";

export default function ReminderDashboard() {
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState("clubLiveSessions");
  const handleItemClick = (e, { name }) => setActiveItem(name);
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.async);
  const { yourClubs } = useSelector((state) => state.club);
  const { sessions, events, sessionRetainState, eventRetainState } =
    useSelector((state) => state.reminder);

  useEffect(() => {
    if (yourClubs.length === 0 || sessionRetainState) return;
    for (let i = 0; i < yourClubs.length; i++) {
      for (const channel of yourClubs[i].channels) {
        if (channel.members.includes(user)) {
          dispatch(
            fetchChannelSessionsApi(
              channel._id,
              `?startTime[$gte]=${new Date().toISOString()}`
            )
          );
        }
      }
    }
    return () => {
      dispatch({ type: SESSION_REMINDER_RETAIN_STATE });
    };
  }, [dispatch, sessionRetainState, yourClubs, user]);

  useEffect(() => {
    if (eventRetainState) return;
    dispatch(
      fetchEventsApi(
        `?registerdTeamLead=${user}&stages.endDate[$gte]=${new Date().toISOString()}`
      )
    );
    return () => {
      dispatch({ type: EVENT_REMINDER_RETAIN_STATE });
    };
  }, [dispatch, eventRetainState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid>
        <Grid.Column width={9}>
          <Header content="Upcoming" icon="clock outline" />
          <Menu secondary>
            <Menu.Item
              name="clubLiveSessions"
              active={activeItem === "clubLiveSessions"}
              onClick={handleItemClick}
            />
            <Menu.Item
              name="joinedEvents"
              active={activeItem === "joinedEvents"}
              onClick={handleItemClick}
            />
          </Menu>
          {activeItem === "clubLiveSessions" ? (
            <ReminderSessionList sessions={sessions} />
          ) : (
            <ReminderEventList events={events} />
          )}
          <Loader active={loading} />
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={5}>
          <FeaturedFeed userId={user} />
        </Grid.Column>
      </Grid>
      <Helmet>
        <title>{"Reminder"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </>
  );
}
