import { LOCATION_CHANGE } from "connected-react-router";
import jwt_decode from "jwt-decode";

const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const LOGOUT_REQUEST = "LOGOUT_REQUEST";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const IS_MOBILE_DEVICE = "IS_MOBILE_DEVICE";

export const verifyAuth = () => (dispatch) => {
  var token = localStorage.getItem("token");
  if (token) {
    var decoded = jwt_decode(token);
    if (decoded.exp < Date.now() / 1000) dispatch(logoutUser());
  }
};

export const checkMobileDivice = (os) => {
  return {
    type: IS_MOBILE_DEVICE,
    os: os,
  };
};

export const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const receiveLogin = (response) => {
  return {
    type: LOGIN_SUCCESS,
    token: response.token,
    user: response.user._id,
    deactivated: response.user.deactivated,
  };
};

export const loginError = (message) => {
  return {
    type: LOGIN_FAILURE,
    message,
  };
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

// Logs the user out
export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  localStorage.removeItem("token");
  localStorage.removeItem("creds");
  dispatch(receiveLogout());
};

const initialState = {
  isLoading: false,
  isAuthenticated: localStorage.getItem("token") ? true : false,
  token: localStorage.getItem("token"),
  user: localStorage.getItem("creds"),
  errMess: null,
  prevLocation: null,
  currentLocation: null,
  isMobileDevice: false,
  os: "desktop",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: !action.deactivated,
        errMess: "",
        token: action.token,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMess: action.message,
      };
    case LOGOUT_REQUEST:
      return { ...state, isLoading: true };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        token: null,
        user: null,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        prevLocation: state.currentLocation,
        currentLocation: action.payload.location,
      };
    case IS_MOBILE_DEVICE:
      return {
        ...state,
        isMobileDevice: true,
        os: action.os,
      };
    default:
      return state;
  }
}
