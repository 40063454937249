import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import EventList from "./EventList";
import { useSelector } from "react-redux";
import EventListItemPlaceholder from "./EventListItemPlaceholder";
import { useDispatch } from "react-redux";
import { EVENT_RETAIN_STATE } from "../eventReducer";
import { fetchEventsApi } from "../../../app/apiRequest/eventService";
import { EVENT_FEED_RETAIN_STATE, fetchEventFeed } from "../eventFeedReducer";
import { Helmet } from "react-helmet";

export default function EventDashboard({ isFeed }) {
  const dispatch = useDispatch();
  const { events, moreEvents, query, lastDocId, retainState } = useSelector(
    (state) => state.event
  );
  const {
    isFeedLoading,
    eventFeed,
    moreFeed,
    feedQuery,
    lastFetchedIds,
    feedRetainState,
  } = useSelector((state) => state.eventFeed);
  const { loading } = useSelector((state) => state.async);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if (!currentUserProfile) return;
    if (feedRetainState || !isFeed) return;
    setLoadingInitial(true);
    dispatch(
      fetchEventFeed({
        lastFetchedIds: [],
        collegeId: currentUserProfile?.college?._id,
        query: feedQuery,
      })
    ).then(() => {
      console.log(feedQuery);
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: EVENT_FEED_RETAIN_STATE });
    };
    // eslint-disable-next-line
  }, [dispatch, feedRetainState, currentUserProfile, feedQuery]);

  useEffect(() => {
    if (retainState || isFeed) return;
    setLoadingInitial(true);
    dispatch(fetchEventsApi(query)).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: EVENT_RETAIN_STATE });
    };
    // eslint-disable-next-line
  }, [dispatch, retainState, query]);

  function handleFetchNextEvents() {
    if (isFeed)
      dispatch(
        fetchEventFeed({
          lastFetchedIds: lastFetchedIds,
          collegeId: currentUserProfile?.college?._id,
          query: feedQuery,
        })
      );
    else dispatch(fetchEventsApi(`${query}&_id[$lt]=${lastDocId}`));
  }

  return (
    <div className="left-section">
      {loadingInitial && (
        <>
          <EventListItemPlaceholder />
          <EventListItemPlaceholder />
        </>
      )}
      <EventList
        events={isFeed ? eventFeed : events}
        getNextEvents={handleFetchNextEvents}
        loading={isFeed ? isFeedLoading : loading}
        moreEvents={isFeed ? moreFeed : moreEvents}
      />
      <Loader active={isFeed ? isFeedLoading : loading} />
      <Helmet>
        <title>{"Event"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
