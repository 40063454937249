import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Popup, Feed } from "semantic-ui-react";
import {
  cancelClubMemberRequestApi,
  manageMembersApi,
} from "../../../../app/apiRequest/clubService";

export default function ClubMemberItem({ member, adminCount }) {
  const history = useHistory();
  const [openPopup, setOpenPopup] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { selectedClub } = useSelector((state) => state.club);

  const isAdmin = selectedClub?.admin.some((item) => item._id === user);

  const handleMember = (active, admin, decline, msg) => {
    if (decline) {
      cancelClubMemberRequestApi(member.club, member.user._id).then(() => {
        window.location.reload();
      });
    } else {
      manageMembersApi({
        ...member,
        active: active,
        admin: admin,
        msg: msg,
      }).then(() => {
        window.location.reload();
      });
    }
    setOpenPopup(false);
  };

  return (
    <>
      <Feed style={{ margin: 12 }}>
        <Feed.Event>
          <Feed.Label>
            <img src={member.user.userImg} alt="" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              <Feed.User
                as={"a"}
                onClick={() => history.push(`/profile/${member.user._id}`)}
              >
                {member.user.displayName}
              </Feed.User>
              <Feed.Date>
                {isAdmin && (
                  <Popup
                    trigger={
                      <Button
                        icon="ellipsis horizontal"
                        size="mini"
                        style={{ marginLeft: 16, marginTop: -16 }}
                      />
                    }
                    content={
                      <div>
                        {member.admin && adminCount > 1 && (
                          <div>
                            <Button
                              fluid
                              content="remove from admin"
                              onClick={() =>
                                handleMember(
                                  true,
                                  false,
                                  false,
                                  "removed you from club admin"
                                )
                              }
                              style={{ display: "block", marginBottom: 5 }}
                            />
                          </div>
                        )}
                        {!member.active && (
                          <div>
                            <Button
                              fluid
                              content="Accept request"
                              onClick={() => {
                                member.isNewMember = true;
                                handleMember(
                                  true,
                                  false,
                                  false,
                                  "accepted your request to join club."
                                );
                              }}
                              style={{ display: "block", marginBottom: 5 }}
                            />
                            <Button
                              fluid
                              content="Decline request"
                              onClick={() =>
                                handleMember(false, false, true, "")
                              }
                              style={{ display: "block" }}
                            />
                          </div>
                        )}
                        {member.active && !member.admin && (
                          <div>
                            <Button
                              fluid
                              content="make admin"
                              onClick={() =>
                                handleMember(
                                  true,
                                  true,
                                  false,
                                  "made you club admin."
                                )
                              }
                              style={{ display: "block", marginBottom: 5 }}
                            />
                            <Button
                              fluid
                              content="remove from club"
                              onClick={() =>
                                handleMember(false, false, true, "removed")
                              }
                              style={{ display: "block" }}
                            />
                          </div>
                        )}
                      </div>
                    }
                    on="click"
                    position="right center"
                    open={openPopup}
                    onClose={() => setOpenPopup(false)}
                    onOpen={() => setOpenPopup(true)}
                  />
                )}
              </Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </>
  );
}
