import React, { useEffect } from "react";
import { Button, FormGroup, Header, Popup, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { toast } from "react-toastify";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  fetchSingleEventApi,
  manageEventApi,
} from "../../../app/apiRequest/eventService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Link, Redirect, useHistory } from "react-router-dom";

export default function EventDescriptionForm({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedEvent } = useSelector((state) => state.event);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleEventApi(match.params.id));
  }, [dispatch, match.params.id]);

  const initialValues = selectedEvent;

  const validationSchema = Yup.object({
    description: Yup.string().required("You must provide Description"),
  });

  if (!selectedEvent || loading)
    return <LoadingComponent content="Loadind event..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <Segment clearing style={{ marginTop: 75, marginRight: 20 }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            values._id = selectedEvent?._id;
            if (values.eventType === "entertainment") {
              values.stepsDone = 6;
            }
            dispatch(manageEventApi(values)).then(
              () => {
                if (selectedEvent?.eventType === "contest") {
                  history.push(`/event/manage/reward/${selectedEvent?._id}`);
                } else {
                  history.push(`/events`);
                }
              },
              (error) => history.push("/error")
            );
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className="ui form">
            <Header content="About Opportunity" color="blue" />
            <p>
              This field helps you to mention the details of the opportunity you
              are listing. It is better to include Rules, Eligibility, Process,
              Format, etc. in order to get the opportunity approved. The more
              details, the better! (Minimum Word Limit: 500)
            </p>
            <MyTextArea
              name="description"
              placeholder="This field helps you to mention the details of the opportunity you are listing. It is better to include Rules, Eligibility, Process, Format, etc., in order to get the opportunity approved. The more details, the better!"
              rows="5"
              label={"Description"}
            />
            <FieldArray name="guidelines">
              {({ insert, remove, push }) => (
                <div>
                  <FormGroup inline>
                    <Header
                      as={"h4"}
                      content="Rules & Guidelines:"
                      style={{ marginRight: 20, marginBottom: -2 }}
                    />
                    <Popup
                      content="Add more Guidelines"
                      on={"hover"}
                      trigger={
                        <Button
                          icon="add"
                          color="blue"
                          type="button"
                          size="mini"
                          onClick={() => push({ guideline: "" })}
                        />
                      }
                    />
                  </FormGroup>
                  {values.guidelines?.length > 0 &&
                    values.guidelines.map((guideline, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ flexGrow: 1 }}>
                          <MyTextInput
                            name={`guidelines.${index}.guideline`}
                            placeholder={`${index + 1}.`}
                            style={{ width: "100%" }} // Ensure the input takes the full width of its container
                          />
                        </div>
                        <Button
                          type="button"
                          icon="close"
                          color="red"
                          size="mini"
                          onClick={() => remove(index)}
                          style={{ marginLeft: "10px" }} // Add some space between input and button
                        />
                      </div>
                    ))}
                </div>
              )}
            </FieldArray>
            <Button
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              type="submit"
              floated="right"
              positive
              content="Save &amp; Continue"
              style={{ marginTop: 40 }}
            />
            <Button
              disabled={isSubmitting}
              type="button"
              floated="right"
              content="Previous"
              as={Link}
              to={
                values.eventType === "contest"
                  ? `/event/manage/rounds/${selectedEvent?._id}`
                  : `/event/manage/registration/${selectedEvent?._id}`
              }
              style={{ marginTop: 40 }}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
