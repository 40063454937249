import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import modalReducer from "../common/modals/modalReducer";
import asyncReducer from "../async/asyncReducer";
import authReducer from "../../features/auth/authReducer";
import postReducer from "../../features/posts/postReducer";
import commentReducer from "../../features/posts/commentReducer";
import profileReducer from "../../features/profile/profileReducer";
import eventReducer from "../../features/events/eventReducer"
import clubReducer from "../../features/club/clubReducer";
import discussionReducer from "../../features/club/clubDetailed/discussion/discussionReducer";
import clubFilesReducer from "../../features/club/clubDetailed/files/clubFilesReducer";
import clubSessionReducer from "../../features/club/clubDetailed/sessions/clubSessionReducer";
import notificationReducer from "../../features/notification/notificationReducer";
import reminderReducer from "../../features/reminder/reminderReducer";
import collegeFestReducer from "../../features/college/collegeFestReducer";
import searchReducer from "../../features/search/searchReducer";
import homeFeedReducer from "../../features/home/homeFeedReducer";
import chatReducer from "../../features/chat/chatReducer";
import groupDiscussionReducer from "../../features/club/clubDetailed/discussion/groupDiscussionReducer";
import eventFeedReducer from "../../features/events/eventFeedReducer";

const rootReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    modal: modalReducer,
    async: asyncReducer,
    auth: authReducer,
    profile: profileReducer,
    feed: homeFeedReducer,
    post: postReducer,
    comment: commentReducer,
    college: collegeFestReducer,
    club: clubReducer,
    discussion: discussionReducer,
    groupChat: groupDiscussionReducer,
    clubFiles: clubFilesReducer,
    session: clubSessionReducer,
    eventFeed: eventFeedReducer,
    event: eventReducer,
    notification: notificationReducer,
    chats: chatReducer,
    reminder: reminderReducer,
    search: searchReducer,
  });

export default rootReducers;
