const DISCUSSIONS_LOADING = "DISCUSSIONS_LOADING";
const DISCUSSIONS_FAILED = "DISCUSSIONS_FAILED";
const ADD_DISCUSSIONS = "ADD_DISCUSSIONS";
const SEND_DISCUSSION = "SEND_DISCUSSION";
const UPDATE_DISCUSSION = "UPDATE_DISCUSSION";
const CLEAR_DISCUSSIONS = "CLEAR_DISCUSSIONS";
const DISCUSSION_RETAIN_STATE = "DISCUSSION_RETAIN_STATE";
const UNREAD_DISCUSSION = "UNREAD_DISCUSSION";

export const discussionsLoading = () => ({
  type: DISCUSSIONS_LOADING,
});

export const discussionsFailed = (errmess) => ({
  type: DISCUSSIONS_FAILED,
  payload: errmess,
});

export const addChats = (chats, moreChats, lastDocId) => ({
  type: ADD_DISCUSSIONS,
  payload: { chats, moreChats, lastDocId },
});

export function sendChat(chat) {
  return {
    type: SEND_DISCUSSION,
    payload: chat,
  };
}

export function updateChat(chat) {
  return {
    type: SEND_DISCUSSION,
    payload: chat,
  };
}

export function chatRetainState(channel) {
  return {
    type: DISCUSSION_RETAIN_STATE,
    payload: channel,
  };
}

export function clearChats() {
  return {
    type: CLEAR_DISCUSSIONS,
  };
}

export function unreadDiscussionApi(count) {
  return {
    type: UNREAD_DISCUSSION,
    payload: count,
  };
}

const initialState = {
  channel: null,
  chats: [],
  isLoading: false,
  errMess: null,
  moreChats: true,
  lastDocId: null,
  retainState: false,
  unreadDiscussion: 0,
};

export default function discussionReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case DISCUSSIONS_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case DISCUSSIONS_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case ADD_DISCUSSIONS:
      return {
        ...state,
        chats: [...state.chats, ...payload.chats],
        moreChats: payload.moreChats,
        lastDocId: payload.lastDocId,
        isLoading: false,
        errMess: null,
      };
    case SEND_DISCUSSION:
      return {
        ...state,
        chats: [payload, ...state.chats],
      };
    case UPDATE_DISCUSSION:
      return {
        ...state,
        chats: [
          payload,
          ...state.chats.filter((chat) => chat._id !== payload._id),
        ],
      };
    case CLEAR_DISCUSSIONS:
      return {
        ...state,
        chats: [],
        moreChats: true,
        lastDocId: null,
      };
    case DISCUSSION_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
        channel: payload,
      };
    case UNREAD_DISCUSSION:
      return {
        ...state,
        unreadDiscussion: payload,
      };
    default:
      return state;
  }
}
