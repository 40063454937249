import React, { useEffect, useState } from "react";
import {
  Button,
  FormCheckbox,
  FormGroup,
  FormRadio,
  Header,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { toast } from "react-toastify";
import MyDateInput from "../../../app/common/form/MyDateInput";
import {
  fetchSingleEventApi,
  manageEventApi,
} from "../../../app/apiRequest/eventService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Link, Redirect, useHistory } from "react-router-dom";

export default function EventRegistrationForm({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedEvent } = useSelector((state) => state.event);
  const { error } = useSelector((state) => state.async);
  const [takeRegistration, setTakeRegistration] = useState(true);
  const [onAnotherPlatform, setOnAnotherPlatform] = useState(null);
  const [partRadioValue, setPartRadioValue] = useState(null);
  const [isPayment, setIsPayment] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [visibility, setVisibility] = useState(null);

  useEffect(() => {
    dispatch(fetchSingleEventApi(match.params.id));
  }, [dispatch, match.params.id]);

  const initialValues = selectedEvent;

  useEffect(() => {
    if (selectedEvent) {
      setVisibility({ name: selectedEvent.visibility });
      setTakeRegistration(selectedEvent.takeRegistration);
      setOnAnotherPlatform({ name: selectedEvent.registrationPlace });
      setPartRadioValue({ name: selectedEvent.participation });
      setIsPayment({ name: selectedEvent.registrationCharge });
      setPaymentType({ name: selectedEvent.payment });
    }
  }, [selectedEvent]);

  const validationSchema = Yup.object({
    minSizeTeam: Yup.number(),
    maxSizeTeam: Yup.number(),
    registrationCount: Yup.number(),
    registrationFee: Yup.number(),
  });

  const handleVisibilityChange = (e, { name }) => {
    setVisibility({ name });
  };

  const handleTakeRegistration = (e, { value }) => {
    setTakeRegistration(value);
  };

  const handleRegistratinPlaceChange = (e, { name }) => {
    setOnAnotherPlatform({ name });
  };

  const handlePartRadioChange = (e, { name }) => {
    setPartRadioValue({ name });
  };

  const handleIsPaymentChange = (e, { name }) => {
    setIsPayment({ name });
  };

  const handlePaymentTypeChange = (e, { name }) => {
    setPaymentType({ name });
  };

  function handleCategoryCheckBox(values, name) {
    if (!values.eventCategory.includes(name)) {
      values.eventCategory.push(name);
    } else {
      const index = values.eventCategory.indexOf(name);
      if (index > -1) {
        values.eventCategory.splice(index, 1);
      }
    }
  }

  if (!selectedEvent && !error)
    return <LoadingComponent content="Loadind event..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <Segment clearing style={{ marginTop: 75, marginRight: 20 }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            values.visibility = visibility.name;
            values.takeRegistration = takeRegistration;
            values.registrationPlace = onAnotherPlatform.name;
            values.participation = partRadioValue.name;
            values.registrationCharge = isPayment.name;
            values.payment = paymentType.name;
            if (visibility.name === "club") {
              values.college = null;
            }
            dispatch(manageEventApi(values)).then(
              () => {
                if (values.eventType === "contest") {
                  history.push(`/event/manage/rounds/${selectedEvent?._id}`);
                } else {
                  history.push(
                    `/event/manage/description/${selectedEvent?._id}`
                  );
                }
              },
              (error) => history.push("/error")
            );
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className="ui form">
            <Header color="teal" content="Registration &amp; Eligiblity" />
            <FormGroup inline>
              <label>
                <strong>Visibility*</strong> (Event can be seen in)
              </label>
              <FormRadio
                label="Public"
                checked={visibility?.name === "public"}
                onChange={handleVisibilityChange}
                name="public"
              />
              <FormRadio
                label="Only in your college"
                checked={visibility?.name === "college"}
                onChange={handleVisibilityChange}
                name="college"
              />
              {values.club !== null && (
                <FormRadio
                  label="Only in your club"
                  checked={visibility?.name === "club"}
                  onChange={handleVisibilityChange}
                  name="club"
                />
              )}
            </FormGroup>
            <FormGroup inline>
              <label>Take registrations For this Event :- </label>
              <FormRadio
                label="Yes"
                checked={takeRegistration}
                onChange={handleTakeRegistration}
                value={true}
              />
              <FormRadio
                label="No"
                checked={!takeRegistration}
                onChange={handleTakeRegistration}
                value={false}
              />
            </FormGroup>
            {takeRegistration && (
              <div>
                <FormGroup inline>
                  <label>
                    Take registrations on another platform/website :-{" "}
                  </label>
                  <FormRadio
                    label="Yes"
                    checked={onAnotherPlatform?.name === "out"}
                    onChange={handleRegistratinPlaceChange}
                    name="out"
                  />
                  <FormRadio
                    label="No"
                    checked={onAnotherPlatform?.name === "on"}
                    onChange={handleRegistratinPlaceChange}
                    name="on"
                  />
                </FormGroup>
                {onAnotherPlatform?.name === "out" && (
                  <MyTextInput
                    name="registrationLink"
                    placeholder=""
                    label={"Registration Link *"}
                  />
                )}
                <FormGroup widths={"equal"}>
                  <MyDateInput
                    name="registrationStartDate"
                    placeholderText={new Date().toDateString(
                      "MMM dd yyyy hh:mm"
                    )}
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy  h:mm a"
                    autoComplete="off"
                    minDate={new Date()}
                    label={"Registration Start Date & Time"}
                  />
                  <MyDateInput
                    name="registrationEndDate"
                    placeholderText={new Date().toLocaleString()}
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy  h:mm a"
                    autoComplete="off"
                    minDate={new Date()}
                    label={"Registration End Date & Time"}
                  />
                </FormGroup>
                <FormGroup inline>
                  <label>Participation :- </label>
                  <FormRadio
                    label="individual"
                    checked={partRadioValue?.name === "individual"}
                    onChange={handlePartRadioChange}
                    name="individual"
                  />
                  <FormRadio
                    label="Participation as a team"
                    checked={partRadioValue?.name === "team"}
                    onChange={handlePartRadioChange}
                    name="team"
                  />
                </FormGroup>
                {partRadioValue?.name === "team" && (
                  <FormGroup widths="equal">
                    <MyTextInput
                      name="minSizeTeam"
                      placeholder="1"
                      label={"Min. Team size"}
                    />
                    <MyTextInput
                      name="maxSizeTeam"
                      placeholder="2"
                      label={"Max. Team size"}
                    />
                  </FormGroup>
                )}
                <FormGroup inline>
                  <label>
                    Does this opportunity have a registration fee? :-{" "}
                  </label>
                  <FormRadio
                    label="Yes"
                    checked={isPayment?.name === "paid"}
                    onChange={handleIsPaymentChange}
                    name="paid"
                  />
                  <FormRadio
                    label="No"
                    checked={isPayment?.name === "free"}
                    onChange={handleIsPaymentChange}
                    name="free"
                  />
                </FormGroup>
                {isPayment?.name === "paid" && (
                  <FormGroup inline>
                    <label>Payment :- </label>
                    <FormRadio
                      label="Team"
                      checked={paymentType?.name === "perTeam"}
                      onChange={handlePaymentTypeChange}
                      name="perTeam"
                    />
                    <FormRadio
                      label="Per Team Member"
                      checked={paymentType?.name === "perMember"}
                      onChange={handlePaymentTypeChange}
                      name="perMember"
                    />
                  </FormGroup>
                )}
                {isPayment?.name === "paid" && (
                  <MyTextInput
                    name="registrationFee"
                    placeholder="0"
                    label={"Fee/ Amount (in INR) *"}
                  />
                )}
              </div>
            )}
            <Header
              as="h4"
              content="Categories : "
              style={{ marginTop: 10, marginRight: 10 }}
            />
            <FormGroup widths={8}>
              <FormCheckbox
                name="Article Writing"
                label="Article Writing"
                defaultChecked={values.eventCategory.includes(
                  "Article Writing"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Coding challange"
                label="Coding challange"
                defaultChecked={values.eventCategory.includes(
                  "Coding challange"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="College festival"
                label="College festival"
                defaultChecked={values.eventCategory.includes(
                  "College festival"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Conclave"
                label="Conclave"
                defaultChecked={values.eventCategory.includes("Conclave")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Conference"
                label="Conference"
                defaultChecked={values.eventCategory.includes("Conference")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Dance"
                label="Dance"
                defaultChecked={values.eventCategory.includes("Dance")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Data Analytics"
                label="Data Analytics"
                defaultChecked={values.eventCategory.includes("Data Analytics")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Data science"
                label="Data science"
                defaultChecked={values.eventCategory.includes("Data science")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <FormGroup widths={8}>
              <FormCheckbox
                name="Debates"
                label="Debates"
                defaultChecked={values.eventCategory.includes("Debates")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Designing"
                label="Designing"
                defaultChecked={values.eventCategory.includes("Designing")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Dramatics"
                label="Dramatics"
                defaultChecked={values.eventCategory.includes("Dramatics")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Entrepreneurship"
                label="Entrepreneurship"
                defaultChecked={values.eventCategory.includes(
                  "Entrepreneurship"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Fashion"
                label="Fashion"
                defaultChecked={values.eventCategory.includes("Fashion")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Fellowship"
                label="Fellowship"
                defaultChecked={values.eventCategory.includes("Fellowship")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Finance"
                label="Finance"
                defaultChecked={values.eventCategory.includes("Finance")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Hackathon"
                label="Hackathon"
                defaultChecked={values.eventCategory.includes("Hackathon")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <FormGroup widths={8}>
              <FormCheckbox
                name="Human Resource"
                label="Human Resource"
                defaultChecked={values.eventCategory.includes("Human Resource")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Literary"
                label="Literary"
                defaultChecked={values.eventCategory.includes("Literary")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Marketing"
                label="Marketing"
                defaultChecked={values.eventCategory.includes("Marketing")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Music"
                label="Music"
                defaultChecked={values.eventCategory.includes("Music")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Online Trading"
                label="Online Trading"
                defaultChecked={values.eventCategory.includes("Online Trading")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Panel discussion"
                label="Panel discussion"
                defaultChecked={values.eventCategory.includes(
                  "Panel discussion"
                )}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Photography"
                label="Photography"
                defaultChecked={values.eventCategory.includes("Photography")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Presentation"
                label="Presentation"
                defaultChecked={values.eventCategory.includes("Presentation")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <FormGroup widths={8}>
              <FormCheckbox
                name="Quiz"
                label="Quiz"
                defaultChecked={values.eventCategory.includes("Quiz")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Robotics"
                label="Robotics"
                defaultChecked={values.eventCategory.includes("Robotics")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Scholarship"
                label="Scholarship"
                defaultChecked={values.eventCategory.includes("Scholarship")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Social Media"
                label="Social Media &amp; Digital"
                defaultChecked={values.eventCategory.includes("Social Media")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Sports"
                label="Sports"
                defaultChecked={values.eventCategory.includes("Sports")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Startup fair"
                label="Startup fair"
                defaultChecked={values.eventCategory.includes("Startup fair")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Treasure hunt"
                label="Treasure hunt"
                defaultChecked={values.eventCategory.includes("Treasure hunt")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
              <FormCheckbox
                name="Workshops"
                label="Workshops"
                defaultChecked={values.eventCategory.includes("Workshops")}
                onClick={(e, { name }) => handleCategoryCheckBox(values, name)}
              />
            </FormGroup>
            <Button
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              type="submit"
              floated="right"
              positive
              content="Save & Continue"
            />
            <Button
              disabled={isSubmitting}
              type="button"
              floated="right"
              content="Previous"
              as={Link}
              to={`/event/manage/details/${selectedEvent?._id}`}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
