import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button, Label } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordApi } from "../../app/apiRequest/loginService";

export default function ResetPasswordForm() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.modal.modalProps);

  const initialValues = {
    token: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    token: Yup.number().min(6, "Must contain 6 characters").required(),
    password: Yup.string()
      .min(6, "Must contain 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(),
  });

  return (
    <ModalWrapper size="tiny" header="Reset Password">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values.user = user;
            dispatch(ResetPasswordApi(values, setErrors));
          } catch (error) {
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <p>*it may take 1-2 minutes</p>
            <MyTextInput name="token" placeholder="OTP" label={"OTP"} />
            <MyTextInput
              name="password"
              type="password"
              placeholder="****"
              label={"Password"}
            />
            <MyTextInput
              name="confirmPassword"
              placeholder="****"
              label={"Confirm Password"}
              style={{ marginBottom: 20 }}
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 20 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Reset Password"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
