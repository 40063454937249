import React from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { useDispatch, useSelector } from "react-redux";
import { updateClubApi } from "../../../app/apiRequest/clubService";
import { closeModal, openModal } from "../../../app/common/modals/modalReducer";

export default function ClubDescriptionForm() {
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const initialValues = {
    instagram: "",
    linkedIn: "",
    description: "",
    learnings: [
      { learning: "" },
      { learning: "" },
      { learning: "" },
      { learning: "" },
      { learning: "" },
      { learning: "" },
    ],
  };
  const validationSchema = Yup.object({
    description: Yup.string().required(),
    learning: Yup.string(),
  });

  return (
    <ModalWrapper size="small" header="Description of club">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values._id = selectedClub?._id;
            dispatch(updateClubApi(values)).then(() => {
              dispatch(closeModal());
              dispatch(openModal({ modalType: "ClubfaqsForm" }));
            });
          } catch (error) {
            setErrors({ auth: error.message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form className="ui form">
            <MyTextInput
              name="linkedIn"
              placeholder="linkedIn"
              label={"LinkedIn"}
            />
            <MyTextInput
              name="instagram"
              placeholder="instagram"
              label={"Instagram"}
            />
            <MyTextArea
              name="description"
              placeholder="Let people know what this club is all about"
              label={"Desccription"}
              rows={6}
            />
            <FieldArray name="learnings">
              {({ insert, remove, push }) => (
                <div style={{ marginBottom: 16 }}>
                  <Header
                    as={"h4"}
                    content="What will people learn from this club?"
                    style={{ marginRight: 20, marginBottom: -2 }}
                  />
                  {values.learnings?.length > 0 &&
                    values.learnings.map((learning, index) => (
                      <div key={index}>
                        <MyTextInput
                          name={`learnings.${index}.learning`}
                          placeholder={`${index + 1}.`}
                        />
                      </div>
                    ))}
                </div>
              )}
            </FieldArray>
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <div>
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                floated="right"
                color="teal"
                content="Next"
                style={{ margin: 10 }}
              />
              <Button
                content="Skip"
                floated="right"
                type="button"
                style={{ margin: 10 }}
                onClick={() =>
                  dispatch(openModal({ modalType: "ClubfaqsForm" }))
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
