import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "semantic-ui-react";
import * as Yup from "yup";
import {
  createPostCommentApi
} from "../../../app/apiRequest/postService";

export default function PostCommentForm({ post, parentCommentId, closeForm }) {
  const dispatch = useDispatch();
  const initialValues = {
    comment: "",
    parentCommentId: parentCommentId,
    post: post._id,
    commentsCount: post.commentsCount
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          comment: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await dispatch(createPostCommentApi(values, post));
          } catch (error) {
            toast.error(error);
          } finally {
            resetForm();
            setSubmitting(false);
            closeForm({ open: false, commentId: null });
          }
        }}
      >
        {({ isSubmitting, handleSubmit, isValid }) => (
          <Form className="ui form">
            <Field name="comment">
              {({ field }) => (
                <div style={{ position: "relative" }}>
                  <Loader active={isSubmitting} />
                  <textarea
                    rows="2"
                    {...field}
                    placeholder="Enter your comment (Enter to submit, SHIFT + Enter for new line)"
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && e.shiftKey) {
                        return;
                      }
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        isValid && handleSubmit();
                      }
                    }}
                  />
                </div>
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </>
  );
}
