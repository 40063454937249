import React, { useState } from "react";
import { Button, Header, Image, Input, List } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanSearchQuery,
  finishSearch,
  startSearch,
} from "../search/searchReducer";
import { fetchProfilesApi } from "../../app/apiRequest/profileService";
import { asyncActionError } from "../../app/async/asyncReducer";
import { useEffect } from "react";
import { fetchChatRoomApi } from "../../app/apiRequest/chatService";
import cuid from "cuid";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function NewChatModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, results } = useSelector((state) => state.search);
  const { userfeed } = useSelector((state) => state.chats);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [testProfile, setTestProfiles] = useState([]);

  const timeoutRef = React.useRef();

  useEffect(() => {
    dispatch(fetchProfilesApi(""))
      .then((response) => {
        var result = [];
        for (let i of response) {
          if (
            !userfeed.some((item) =>
              item.users.some((el) => el._id === i._id)
            ) &&
            result.length < 8
          ) {
            result.push(i);
          }
        }
        dispatch(finishSearch(result));
        setTestProfiles(result);
      })
      .catch((error) => dispatch(asyncActionError(error.message)));
  }, [dispatch, userfeed]);

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch(startSearch(data.value));

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch(cleanSearchQuery());
          dispatch(finishSearch(testProfile));
          return;
        }

        dispatch(fetchProfilesApi(`?$text[$search]=${data.value}`))
          .then((response) => {
            var result = [];
            for (let i of response) {
              if (
                !userfeed.some((item) =>
                  item.users.some((el) => el._id === i._id)
                )
              ) {
                result.push(i);
              }
            }
            dispatch(finishSearch(result));
          })
          .catch((error) => dispatch(asyncActionError(error.message)));

        // const re = new RegExp(_.escapeRegExp(data.value), "i");
        // const result = a.filter((el) => re.test(el.title));

        // dispatch(finishSearch(result));
      }, 300);
    },
    // eslint-disable-next-line
    [loading, dispatch]
  );

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMessageBtn = (user) => {
    const randomString = cuid();
    const chatRoom = {
      _id: randomString,
      users: [currentUserProfile, user],
      lastChat: "",
      newChat: true,
      unreadCount: 0,
      blockedBy: [],
    };
    dispatch(fetchChatRoomApi(user._id, chatRoom)).then(() => {
      history.push(`/chats/${user._id}`);
      // window.location = `/chats/${user._id}`;
    });
  };

  return (
    <ModalWrapper size="tiny" header="New message">
      <Input
        fluid
        icon="search"
        onChange={handleSearchChange}
        placeholder="Search username or displayName..."
      />
      {results.length > 0 ? (
        results.map((user) => (
          <List key={user._id} selection>
            <List.Item>
              <List.Content floated="right">
                <Button
                  compact
                  color="blue"
                  icon="chat"
                  onClick={() => handleMessageBtn(user)}
                />
              </List.Content>
              <Image avatar src={user.userImg} />
              <List.Content>
                <List.Header className="twenty-chars">
                  {user.displayName}
                </List.Header>
                {user.user_name}
              </List.Content>
            </List.Item>
          </List>
        ))
      ) : (
        <Header>No result found</Header>
      )}
    </ModalWrapper>
  );
}
