import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Divider, Label, Header } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";
import ExplorePage from "../explore/ExplorePage";
import { googleSignIn, loginUser } from "../../app/apiRequest/loginService";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import FooterPage from "../more/FooterPage";

export default function AuthPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) return;
    history.push("/clubs");
  }, [isAuthenticated, history]);

  useEffect(() => {
    const google = window.google;
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      type: "standard",
      size: "medium",
      logo_alignment: "left",
      shape: "rectangular",
      text: "sign_in_with",
    });
    // eslint-disable-next-line
  }, []);

  function handleCallbackResponse(response) {
    //console.log(response.credential);
    dispatch(googleSignIn(response.credential));
  }

  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  return (
    <div className="main-section">
      <div
        style={{ flex: 1, padding: "2%", marginTop: "13%", marginBottom: "7%" }}
      >
        <Header
          as="h1"
          content="Welcome to Club-Chat!"
          icon="users"
          color="blue"
        />
        <Header
          as="h3"
          content="We are a platform designed specifically for college clubs and
                organizations. We understand the challenges that come with running
                a club and aim to make it easier for you to connect with members,
                plan events, and share updates. Our goal is to support and empower
                college clubs by providing them with the tools they need to
                thrive. We hope you find our platform useful and we look forward
                to supporting you and your club!"
        />
        <Button
          content="Explore"
          icon="medrt"
          color="teal"
          onClick={() =>
            window.scroll({
              top: 1000,
              behavior: "smooth",
            })
          }
        />
      </div>
      <div
        style={{ flex: 1, padding: "10%", marginTop: "5%", marginBottom: "7%" }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            try {
              dispatch(loginUser(values, setErrors));
            } catch (error) {
              setErrors({ auth: "Error in Login server!" });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui form">
              <MyTextInput
                name="username"
                placeholder="Email Address/ Mobile number"
              />
              <MyTextInput
                name="password"
                placeholder="Password"
                type="password"
              />
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                fluid
                size="large"
                color="teal"
                content="Login"
              />
            </Form>
          )}
        </Formik>
        <Button
          floated="right"
          content="Forgetten Password?"
          color="blue"
          style={{ marginTop: 20 }}
          onClick={() =>
            dispatch(openModal({ modalType: "ForgotPasswordForm" }))
          }
        />
        <Divider horizontal style={{ marginTop: 80 }}>
          Or
        </Divider>
        <div style={{ float: "right", marginTop: 20 }} id="signInDiv"></div>

        <Button
          floated="left"
          color="facebook"
          style={{ marginTop: 20 }}
          content="Create Your Account"
          onClick={() => dispatch(openModal({ modalType: "RegisterForm" }))}
        />
      </div>
      <ExplorePage />
      <FooterPage />
    </div>
  );
}
