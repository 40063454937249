import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Label } from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { useSelector } from "react-redux";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import { manageEventApi } from "../../../app/apiRequest/eventService";
import { toast } from "react-toastify";
import { asyncActionError, asyncActionFinish } from "../../../app/async/asyncReducer";

export default function EventResultForm() {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState("");
  const { modalProps } = useSelector((state) => state.modal);
  const { loading } = useSelector((state) => state.async);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e+7) toast.error("File size must be less than 50MB !");
    setFile(file);
    setFilename(file.name)
  };

  var roundsOption = [];
  modalProps.stages.length > 0 &&
    modalProps.stages.map((stage) =>
      roundsOption.push({
        key: stage._id,
        text: stage.roundTitle,
        value: stage.round,
      })
    );

  return (
    <ModalWrapper size="small" header="Result of This event">
      <Formik
        initialValues={modalProps}
        validationSchema={Yup.object({
          round: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            var data = new FormData();
            data.append("imageFile", file);
            dispatch(uploadFileApi(data)).then((response) => {
              dispatch(asyncActionFinish());
              values.results.push({
                round : values.round,
                file : response[0].location,
                filename: filename,
              })
              values.msg = `uploads result of round ${values.round}.`;
              dispatch(manageEventApi(values));
              dispatch(closeModal());
            });
          } catch (error) {
            dispatch(asyncActionError(error));
            console.log(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MySelectInput
              name="round"
              placeholder="Round name"
              options={roundsOption}
              label={"Result of which round"}
            />
            <label htmlFor="myfile">Select a file</label>
            <input
              type="file"
              id="myfile"
              name="myfile"
              onChange={handleFileChange}
              style={{ marginBottom: 20 }}
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 20 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting || loading}
              disabled={!isValid || !dirty || isSubmitting || loading}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Post Result"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
