import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, FormRadio, Image, Label } from "semantic-ui-react";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import ModalWrapper from "../../../../app/common/modals/ModalWrapper";
import PhotoCropper from "../../../../app/common/photos/PhotoCropper";
import { getFile, isImage } from "../../../../app/common/util/util";
import { toast } from "react-toastify";
import MyTextArea from "../../../../app/common/form/MyTextArea";
import MyDateInput from "../../../../app/common/form/MyDateInput";
import { uploadFileApi } from "../../../../app/apiRequest/extraService";
import {
  createSessionApi,
  updateSessionApi,
} from "../../../../app/apiRequest/channelService";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../app/common/modals/modalReducer";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../../app/async/asyncReducer";
import { compressImg } from "../../../../app/common/photos/compressImg";

export default function CreateSessionsForm() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const [radioValue, setRadioValue] = useState({ name: "offline" });
  const { club, channel, session } = useSelector(
    (state) => state.modal.modalProps
  );
  const { loading } = useSelector((state) => state.async);
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  const initialValues = session ?? {
    title: "",
    sessionImg:
      "https://learningx-s3.s3.ap-south-1.amazonaws.com/image_2_1.png",
    club: club._id,
    channel: channel._id,
    venue: "",
    sessionLink: "",
    description: "",
    duration: 60,
  };
  const validationSchema = Yup.object({
    title: Yup.string().required(),
    duration: Yup.number().required(),
    startTime: Yup.string().required(),
  });

  useEffect(() => {
    if (session) {
      setRadioValue({ name: session.location });
    }
  }, [session]);

  const handleRadioChange = (e, { name }) => {
    setRadioValue({ name });
  };

  return (
    <ModalWrapper size="small" header="Create Live session">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values.location = radioValue.name;
            if (images.length > 0) {
              var data = new FormData();
              await compressImg(images[images.length - 1]).then(
                async (compressedDataUrl) => {
                  let file = await getFile(compressedDataUrl, filename);
                  data.append("imageFile", file);
                }
              );
              dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                values.sessionImg = response[0].location;
                if (session) {
                  dispatch(updateSessionApi(values, channel)).then(() => {
                    dispatch(closeModal());
                  });
                } else {
                  dispatch(createSessionApi(values, channel)).then(() => {
                    dispatch(closeModal());
                  });
                }
              });
            } else {
              if (session) {
                dispatch(updateSessionApi(values, channel)).then(() => {
                  dispatch(closeModal());
                });
              } else {
                dispatch(createSessionApi(values, channel)).then(() => {
                  dispatch(closeModal());
                });
              }
            }
            setSubmitting(false);
          } catch (error) {
            dispatch(asyncActionError(error));
            console.log(error.toString());
            setErrors({ auth: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, values, errors }) => (
          <Form className="ui form">
            <Image
              style={{ width: 300, height: 150 }}
              src={
                images.length > 0
                  ? images[images.length - 1]
                  : values.sessionImg ||
                    "https://learningx-s3.s3.ap-south-1.amazonaws.com/image_2_1.png"
              }
              floated="left"
            />
            <Button
              icon="file image"
              content="Add session image"
              color="blue"
              onClick={handleClick}
              style={{ marginTop: 50 }}
              type="button"
            />
            <p>Image dimensions:- (2 : 1)</p>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
            <MyTextInput
              name="title"
              placeholder="Session Title"
              label={"Session Title"}
            />
            <MyTextArea
              name="description"
              placeholder="Description"
              label={"Description"}
              rows={2}
            />
            <FormGroup widths={"equal"}>
              <MyDateInput
                name="startTime"
                placeholderText="Sessions Time &amp; Date"
                timeFormat="HH:mm"
                showTimeSelect
                timeCaption="time"
                dateFormat="MMMM d, yyyy  h:mm a"
                autoComplete="off"
                label={"Sessions Time & Date"}
              />
              <MyTextInput
                name="duration"
                placeholder="60"
                input={Yup.number}
                label={"Duration(in minutes)"}
              />
            </FormGroup>
            <FormGroup inline>
              <label>Location</label>
              <FormRadio
                label="offline"
                checked={radioValue.name === "offline"}
                onChange={handleRadioChange}
                name="offline"
              />
              <FormRadio
                label="online"
                checked={radioValue.name === "online"}
                onChange={handleRadioChange}
                name="online"
              />
            </FormGroup>
            {radioValue.name === "offline" ? (
              <MyTextInput
                name="venue"
                placeholder="venue"
                label={"Session venue"}
              />
            ) : (
              <MyTextInput
                name="sessionLink"
                placeholder="Sessions link"
                label={"Sessions Link"}
              />
            )}
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <div>
              <Button
                loading={isSubmitting || loading}
                disabled={!isValid || isSubmitting || loading}
                type="submit"
                floated="right"
                color="teal"
                content={session ? "Update session" : "Create session"}
                style={{ margin: 10 }}
              />
              <Button
                content="Cancel"
                floated="right"
                type="button"
                style={{ margin: 10 }}
                onClick={() => dispatch(closeModal())}
              />
            </div>
          </Form>
        )}
      </Formik>
      <PhotoCropper
        photoCropperOpen={photoCropperOpen}
        setPhotoCropperOpen={setPhotoCropperOpen}
        images={images}
        setImages={setImages}
        imagePreview={files}
        imageNo={0}
        editing={false}
        aspectRatio={2 / 1}
      />
    </ModalWrapper>
  );
}
