import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Header, List, Menu } from "semantic-ui-react";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import EventDashboard from "../../../events/eventDashboard/EventDashboard";
import ClubNavMenu from "../ClubNavMenu";
import ClubAboutHeader from "./ClubAboutHeader";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import displayLinks from "../../../../app/common/text/handleLinkClick";
import { setEventFilter } from "../../../events/eventReducer";

export default function ClubAboutPage({ match }) {
  const [activeItem, setActiveItem] = useState(null);
  const [panels, setPenals] = useState([]);
  const [channel, setChannel] = useState(null);
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const { user } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.clubId));
  }, [dispatch, match.params.clubId]);

  useEffect(() => {
    if (selectedClub) {
      var query = "?stepsDone=6&club=" + match.params.clubId;
      if (selectedClub.admin.includes(user)) {
        query = "?club=" + match.params.clubId;
      }
      setActiveItem("events");
      dispatch(setEventFilter(query));
      selectedClub.faqs.map((faq) =>
        setPenals((oldArray) => [
          ...oldArray,
          {
            key: faq._id,
            title: faq.question,
            content: displayLinks(faq.answer),
          },
        ])
      );
    }
  }, [selectedClub, dispatch, match.params.clubId, user]);

  useEffect(() => {
    if (selectedClub && selectedClub.members.includes(user)) {
      for (const channel of selectedClub.channels) {
        if (channel.members.includes(user)) {
          setChannel(channel._id);
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedClub]);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <div
      className={
        match.params.channelId ? "club-detailed-page" : "club-about-page"
      }
    >
      {match.params.channelId && (
        <ClubNavMenu club={selectedClub} channelId={match.params.channelId} />
      )}
      <ClubAboutHeader
        club={selectedClub}
        channelId={match.params.channelId ?? channel}
        isAdmin={selectedClub?.admin.some((item) => item._id === user) ?? false}
      />
      <div style={{ marginTop: 32 }}>
        <Menu pointing secondary>
          <Menu.Item
            name="events"
            active={activeItem === "events"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="faqs"
            active={activeItem === "faqs"}
            onClick={handleItemClick}
            content="FAQs"
          />
        </Menu>
      </div>
      {activeItem === "events" && (
        <div style={{ marginTop: 10 }}>
          <EventDashboard isFeed={false} />
        </div>
      )}
      {activeItem === "faqs" && (
        <div style={{ marginTop: 16, marginLeft: 8 }}>
          <Header content="What will you learn?" />
          <List bulleted>
            {selectedClub.learnings.map(
              (learning, i) =>
                learning.learning !== "" && (
                  <List.Item content={learning.learning} key={i} />
                )
            )}
          </List>
          <Header>Some important frequently asked questions</Header>
          <Accordion
            panels={panels}
            defaultActiveIndex={[0]}
            exclusive={false}
            fluid
          />
        </div>
      )}
      <Helmet>
        <title>{selectedClub.clubName}</title>
        <meta name="description" content={selectedClub.description} />
        <meta property="og:title" content={selectedClub.clubName} />
        <meta property="og:description" content={selectedClub.description} />
        <meta property="og:image" content={selectedClub.clubImg} />
      </Helmet>
    </div>
  );
}
