import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Image, Item, List, Popup } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { clearSelectedEvent } from "../../events/eventReducer";
import UnauthModal from "../../auth/UnauthModal";

export default function CollegeHeader({ college }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { user, currentLocation } = useSelector((state) => state.auth);
  const [meta, setMeta] = useState("");
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleCreateEvent = () => {
    dispatch(clearSelectedEvent());
    history.push(`/event/create/details?college=${college?._id}`);
  };

  useEffect(() => {
    for (const domain of college.domain) {
      setMeta((a) => a + domain + " | ");
    }
  }, [college]);

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      {currentLocation.pathname !== "/campus" && (
        <Button
          icon="arrow left"
          floated="left"
          style={{ top: 78, position: "fixed", color: "blue" }}
          onClick={() => history.goBack()}
        />
      )}
      <div
        style={{
          marginLeft: currentLocation.pathname === "/campus" ? 0 : 50,
          marginRight: 50,
        }}
      >
        <Item.Group>
          <Item>
            <Item.Content>
              <Image
                src={college.collegeImg}
                size="medium"
                floated="right"
                style={{ marginTop: 16 }}
              />
              <Item.Meta style={{ color: "blue", display: "block" }}>
                {meta}
              </Item.Meta>
              <Item.Header style={{ maxWidth: 600 }}>
                {college.collegeName}
              </Item.Header>
              <Item.Description>
                <p style={{ width: 600 }}>{college.description}</p>
                <List horizontal relaxed style={{ display: "block" }}>
                  {college.website && (
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkify" />{" "}
                        <a
                          href={college.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {college.website}
                        </a>
                      </List.Content>
                    </List.Item>
                  )}
                  <List.Item>
                    <List.Content>
                      <List.Icon name="mail" />{" "}
                      <a
                        href={`mailto:${college.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {college.email}
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
                {college.instagram && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="instagram" />{" "}
                        <a href={college.instagram} target="#blank">
                          {college.instagram}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                {college.linkedIn && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkedin" />{" "}
                        <a href={college.linkedIn} target="#blank">
                          {college.linkedIn}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                <List horizontal relaxed style={{ display: "block" }}>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="marker" />
                      {college.city.address}
                    </List.Content>
                  </List.Item>
                </List>
              </Item.Description>
              <Item.Extra>
                <div>
                  {college.admin.some((item) => item._id === user) ? (
                    <>
                      <Button
                        content="Create Fest"
                        color="blue"
                        icon="add"
                        style={{ marginTop: 30 }}
                        onClick={() =>
                          dispatch(
                            openModal({
                              modalType: "FestivalForm",
                              modalProps: { college: college._id },
                            })
                          )
                        }
                      />
                      <Button
                        content="Create Club"
                        icon="add"
                        color="blue"
                        style={{ marginTop: 30 }}
                        onClick={() =>
                          dispatch(
                            openModal({
                              modalType: "CreateClubForm",
                              modalProps: { college: college._id },
                            })
                          )
                        }
                      />
                      <Button
                        content="Create Event"
                        icon="add"
                        color="blue"
                        style={{ marginTop: 30 }}
                        onClick={handleCreateEvent}
                      />
                      <Popup
                        trigger={
                          <Button
                            color="blue"
                            icon="ellipsis vertical"
                            style={{ marginTop: 30 }}
                            onClick={() => setOpenPopup(true)}
                          />
                        }
                        content={
                          <>
                            <Button
                              fluid
                              name="manageAdmin"
                              icon="setting"
                              content="Manage Admin"
                              style={{ display: "block", marginBottom: 5 }}
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalType: "ManageAdmin",
                                    modalProps: {
                                      admin: college.admin,
                                      type: "college",
                                      selected: college,
                                    },
                                  })
                                );
                                setOpenPopup(false);
                              }}
                            />
                            <Button
                              name="editPage"
                              fluid
                              icon="edit"
                              content="Edit Page"
                              style={{ display: "block" }}
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalType: "CollegeForm",
                                    modalProps: {
                                      _id: college._id,
                                    },
                                  })
                                );
                                setOpenPopup(false);
                              }}
                            />
                          </>
                        }
                        on="click"
                        position="bottom left"
                        open={openPopup}
                        onClose={() => setOpenPopup(false)}
                        onOpen={() => setOpenPopup(true)}
                      />
                    </>
                  ) : (
                    <div>
                      <Button
                        color="blue"
                        icon="eye"
                        content="View Admin"
                        style={{ marginTop: 30 }}
                        onClick={() => {
                          dispatch(
                            openModal({
                              modalType: "ViewAdminModal",
                              modalProps: {
                                admin: college.admin,
                              },
                            })
                          );
                        }}
                      />
                      <Button
                        color="blue"
                        content="Report this college"
                        icon="exclamation triangle"
                        style={{ marginTop: 30 }}
                        onClick={() => {
                          if (!isAuthenticated) {
                            setModalOpen(true);
                          } else {
                            dispatch(
                              openModal({
                                modalType: "ReportForm",
                                modalProps: {
                                  type: "college",
                                  id: college._id,
                                },
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </div>
    </>
  );
}
