import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { contactUsApi, uploadFileApi } from "../../../app/apiRequest/extraService";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { closeModal } from "../../../app/common/modals/modalReducer";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { asyncActionError, asyncActionFinish } from "../../../app/async/asyncReducer";

export default function ContactUsForm() {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const { loading } = useSelector((state) => state.async);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    details: ""
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    details: Yup.string().required(),
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e+7) toast.error("File size must be less than 50MB !");
    setFile(file);
  };

  const handleCancleBtn = () => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="tiny" header="Contact us">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            if (file) {
              var data = new FormData();
              data.append("imageFile", file);
              await dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                values.file = response[0].location;
                dispatch(contactUsApi(values));
              });
            } else {
              dispatch(contactUsApi(values));
            }
          } catch (error) {
            dispatch(asyncActionError(error))
            console.log(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="ui form">
            <MyTextInput name="name" placeholder="your name" label={"Name"} />
            <MyTextInput
              name="email"
              placeholder="your email"
              label={"Email"}
            />
            <MyTextInput
              name="phone"
              placeholder="whats app number"
              label={"Whats app number"}
            />
            <MyTextArea
              name="details"
              placeholder="Please include as much information as possible..."
              label={"Reason"}
              rows={5}
            />
            <div style={{ marginBottom: 16 }}>
              <label htmlFor="myfile">{`Add a file (optional)`}</label>
              <input
                type="file"
                id="myfile"
                name="myfile"
                onChange={handleFileChange}
              />
            </div>
            <Button
              loading={isSubmitting || loading}
              disabled={!isValid || !dirty || isSubmitting || loading}
              floated="right"
              type="submit"
              color="blue"
              content="Submit"
              style={{ margin: 10 }}
            />
            <Button
              loading={isSubmitting}
              floated="right"
              type="button"
              content="Cancel"
              color="grey"
              onClick={handleCancleBtn}
              style={{ margin: 10 }}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
