import React from "react";


export default function displayMentionsAndLinks(message) {
  const mentionRegex = /@<a href="profile:\/\/([a-zA-Z0-9]+)">([^<]+)<\/a>/g;
  const linkRegex = /https?:\/\/[^\s]+/g;

  const handleMentionClick = (userId) => {
    window.open(`/profile/${userId}`, "_blank");
  };

  const processMentionsAndLinks = (text) => {
    let elements = [];
    let lastIndex = 0;

    // Process mentions
    text.replace(mentionRegex, (match, userId, mention, offset) => {
      if (lastIndex < offset) {
        elements.push(text.substring(lastIndex, offset));
      }
      elements.push(
        <span
          key={userId}
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => handleMentionClick(userId)}
        >
          {mention}
        </span>
      );
      lastIndex = offset + match.length;
    });

    if (lastIndex < text.length) {
      elements.push(text.substring(lastIndex));
    }

    let finalElements = [];
    lastIndex = 0;

    // Process links within the previously processed text
    elements.forEach((element, index) => {
      if (typeof element === "string") {
        element.replace(linkRegex, (match, offset) => {
          if (lastIndex < offset) {
            finalElements.push(element.substring(lastIndex, offset));
          }
          finalElements.push(
            <a
              key={offset + index}
              href={match}
              style={{ color: "blue" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {match}
            </a>
          );
          lastIndex = offset + match.length;
        });

        if (lastIndex < element.length) {
          finalElements.push(element.substring(lastIndex));
        }

        lastIndex = 0; // Reset lastIndex for next element
      } else {
        finalElements.push(element);
      }
    });

    return finalElements;
  };

  return <div>{processMentionsAndLinks(message)}</div>;
};
