import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { chatRetainState, createChat } from "../chatReducer";
import Chat from "./Chat";
import { toast } from "react-toastify";
import MyTextInput from "../../../app/common/form/MyTextInput";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { io } from "socket.io-client";
import { isValidHttpUrl } from "../../../app/common/util/util";
import { openModal } from "../../../app/common/modals/modalReducer";
import { Button, Popup, List, Image, Header } from "semantic-ui-react";
import {
  CreateChatRoomApi,
  fetchChatsApi,
  markReadChatApi,
  sendChatApi,
} from "../../../app/apiRequest/chatService";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRef } from "react";

export default function Chatdashboard({ match }) {
  const dispatch = useDispatch();
  const socket = useRef();
  const [openPopup, setOpenPopup] = useState(false);
  const { loading, error } = useSelector((state) => state.async);
  const currentUser = useSelector((state) => state.auth.user);
  const { chats, selectedChat, isLoading, lastDocId, moreChats, room } =
    useSelector((state) => state.chats);

  const [senderIndex, setSenderIndex] = useState(null);
  const [userRoomCount, setUserRoomCount] = useState(1);

  useEffect(() => {
    if (!selectedChat) return;
    if (match.params.id === selectedChat.users[0]._id) {
      setSenderIndex(1);
    } else {
      setSenderIndex(0);
    }
  }, [selectedChat, match.params.id]);

  useEffect(() => {
    if (selectedChat && senderIndex !== null) {
      socket.current = io(process.env.REACT_APP_API_BASE_URL);
      socket.current.emit("joinRoom", {
        sender: selectedChat.users[senderIndex],
        room: selectedChat._id,
      });
    }
  }, [selectedChat, senderIndex]);

  useEffect(() => {
    if (!selectedChat || room === selectedChat._id) return;
    dispatch(fetchChatsApi(selectedChat._id, "")).then(() => {
      dispatch(markReadChatApi(selectedChat._id));
    });
    return () => {
      dispatch(chatRetainState(selectedChat._id));
    };
  }, [dispatch, selectedChat, room]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("message", (message) => {
        console.log(message);
        dispatch(createChat(message));
      });
    }
    // eslint-disable-next-line
  }, [dispatch, socket.current]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("roomUsers", ({ room, users }) => {
        if (users.length > 1) setUserRoomCount(2);
        else setUserRoomCount(1);
        console.log(users, room);
      });
    }
    // eslint-disable-next-line
  }, [dispatch, socket.current]);

  const handlePopupBtnClick = (e, { name }) => {
    setOpenPopup(false);
    dispatch(
      openModal({
        modalType: "SendFileForm",
        modalProps: {
          type: name,
          where: "chat",
          room: selectedChat._id,
          socket: socket.current,
        },
      })
    );
  };

  function handleFetchNextChats() {
    dispatch(fetchChatsApi(selectedChat._id, `?_id[$lt]=${lastDocId}`));
  }

  if ((loading && !selectedChat) || (!selectedChat && !error))
    return <LoadingComponent content="Loading chats..." />;

  if (error) return <Redirect to={"/error"} />;

  const initialValues = {
    chat: "",
    filetype: "text",
    room: selectedChat._id,
  };

  return (
    <div id="chat-page">
      <div id="chat-scrollable-div">
        {chats.length > 0 && (
          <InfiniteScroll
            dataLength={chats.length}
            next={handleFetchNextChats}
            style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
            inverse={true} //
            hasMore={!isLoading && moreChats}
            loader={<h4>Loading...</h4>}
            initialScrollY={0}
            scrollableTarget="chat-scrollable-div"
          >
            {chats.map((chat) => (
              <Chat chat={chat} key={chat._id} />
            ))}
          </InfiniteScroll>
        )}
      </div>
      <div className="chat-fixed-top">
        <List relaxed>
          <List.Item
            as={Link}
            to={`/profile/${
              selectedChat?.users[Math.abs(senderIndex - 1)]._id
            }`}
          >
            <Image
              src={
                selectedChat?.users[Math.abs(senderIndex - 1)].userImg ||
                "/assets/user.png"
              }
              size="mini"
              avatar
              verticalAlign="middle"
            />
            <List.Content>
              <List.Header>
                {selectedChat?.users[Math.abs(senderIndex - 1)].displayName}
              </List.Header>
              <List.Description>
                {selectedChat?.users[Math.abs(senderIndex - 1)].user_name}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </div>
      {selectedChat?.blockedBy.length === 0 ? (
        <div className="chat-fixed-form">
          <Popup
            trigger={<Button color="blue" icon="paperclip" />}
            content={
              <>
                <Button
                  fluid
                  name="image"
                  icon="file image"
                  content="Image"
                  color="blue"
                  style={{ display: "block", marginBottom: 2 }}
                  onClick={handlePopupBtnClick}
                />
                <Button
                  fluid
                  name="video"
                  icon="file video"
                  content="Video"
                  color="blue"
                  style={{ display: "block", marginBottom: 2 }}
                  onClick={handlePopupBtnClick}
                />
                <Button
                  fluid
                  name="file"
                  icon="file alternate"
                  content="Document"
                  color="blue"
                  style={{ display: "block" }}
                  onClick={handlePopupBtnClick}
                />
              </>
            }
            on="click"
            position="bottom left"
            open={openPopup}
            onClose={() => setOpenPopup(false)}
            onOpen={() => setOpenPopup(true)}
          />
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                if (isValidHttpUrl(values.chat)) {
                  values.filetype = "link";
                }
                if (userRoomCount === 1) {
                  values.seenBy = [currentUser];
                } else {
                  values.seenBy = selectedChat.users;
                }
                socket.current.emit("chatMessage", values);
                if (selectedChat.newChat) {
                  dispatch(
                    CreateChatRoomApi(
                      { users: [currentUser, match.params.id] },
                      values
                    )
                  );
                } else {
                  sendChatApi(values);
                }
                resetForm();
              } catch (error) {
                toast.error(error);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form style={{ display: "flex" }}>
                <MyTextInput
                  className="chat-mytextinput"
                  name="chat"
                  placeholder="write message here..."
                />
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  color="blue"
                  icon="send"
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="chat-fixed-form">
          <Header as="h3" color="blue" style={{ marginLeft: 16 }}>
            You have{" "}
            {selectedChat?.blockedBy.includes(currentUser)
              ? "blocked "
              : "been blocked by "}
            {selectedChat?.users[Math.abs(senderIndex - 1)].displayName}
          </Header>
        </div>
      )}
    </div>
  );
}
