import {
  addChats,
  chatsFailed,
  chatsLoading,
  fetchUserFeed,
  listenToselectedChat,
  unreadChatroom,
} from "../../features/chat/chatReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const CreateChatRoomApi = (room, chat) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "chats/room", {
    method: "POST",
    body: JSON.stringify(room),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      chat.room = response._id;
      dispatch(sendChatApi(chat));
    })
    .catch((error) => {
      console.log("send chat ", error.message);
    });
};

export const fetchUserFeedApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + `chats/room?users=${query}`,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(fetchUserFeed(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const countUnreadChatRoomApi = () => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "chats/room/count", {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(unreadChatroom(response.count)))
    .catch((error) => console.log(error.message));
};

export const fetchChatRoomApi = (query, chatRoom) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");
  const currentUser = localStorage.getItem("creds");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      `chats/room?users[$all]=${query}&users[$all]=${currentUser}`,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (response.length > 0) dispatch(listenToselectedChat(response[0]));
      else {
        dispatch(listenToselectedChat(chatRoom));
      }
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const updateUserRoomApi = (room) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "chats/room/" + room._id, {
    method: "PUT",
    body: JSON.stringify(room),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("userfeed ", error.message);
    });
};

export const sendChatApi = (chat) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "chats/" + chat.room, {
    method: "POST",
    body: JSON.stringify(chat),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("send chat ", error.message);
      alert("message can't be send\nError: " + error.message);
    });
};

export const fetchChatsApi = (roomId, query) => (dispatch) => {
  dispatch(chatsLoading());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "chats/" + roomId + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(
        addChats(response.chats, response.moreChats, response.lastDocId)
      );
    })
    .catch((error) => dispatch(chatsFailed(error.message)));
};

export const countUnreadChatsApi = (room) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "chats/" + room + "/count",
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const markReadChatApi = (roomId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "chats/" + roomId, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(unreadChatroom(0));
    })
    .catch((error) => {
      console.log("Notification updated ", error.message);
    });
};
