const FETCH_COMMENTS = "FETCH_COMMENTS";
const CREATE_COMMENT = "CREATE_COMMENT";
const UPDATE_COMMENT = "UPDATE_COMMENT";
const DELETE_COMMENT = "DELETE_COMMENT";
const CLEAR_COMMENTS = "CLEAR_COMMENTS";
const DISPATCH_POST = "DISPATCH_POST";

export function dispatchSelectedPost(postId) {
  return {
    type: DISPATCH_POST,
    payload: postId,
  };
}

export const addComments = (comments, moreComments, lastDocId) => ({
  type: FETCH_COMMENTS,
  payload: { comments, moreComments, lastDocId },
});

export function createComment(comment) {
  return {
    type: CREATE_COMMENT,
    payload: comment,
  };
}

export function updateComment(comment) {
  return {
    type: UPDATE_COMMENT,
    payload: comment,
  };
}

export function deleteComment(commentId) {
  return {
    type: DELETE_COMMENT,
    payload: commentId,
  };
}

export function clearComments() {
  return {
    type: CLEAR_COMMENTS,
  };
}

const initialState = {
  selectedPost: null,
  comments: [],
  moreComments: false,
  lastDocId: null,
};

export default function commentReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_COMMENT:
      return {
        ...state,
        comments: [payload, ...state.comments],
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        comments: [
          ...state.comments.filter((evt) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMMENT:
      return {
        ...state,
        comments: [...state.comments.filter((evt) => evt.id !== payload)],
      };
    case FETCH_COMMENTS:
      return {
        ...state,
        comments: [...state.comments, ...payload.comments],
        moreComments: payload.moreComments,
        lastDocId: payload.lastDocId,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
        moreComments: true,
      };
    case DISPATCH_POST:
      return {
        ...state,
        selectedPost: payload,
      };
    default:
      return state;
  }
}
