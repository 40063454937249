import React, { useEffect, useState } from "react";
import {
  Button,
  Confirm,
  Dropdown,
  DropdownMenu,
  Header,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { openModal } from "../../../../app/common/modals/modalReducer";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { deleteChannelApi } from "../../../../app/apiRequest/channelService";

export default function ManageChannels({ match }) {
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);
  const { user } = useSelector((state) => state.auth);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.id));
  }, [dispatch, match.params.id]);

  const handleDeleteChannel = (channelId) => {
    dispatch(deleteChannelApi(channelId)).then(() => {
      setOpenDeleteConfirm(false);
    });
  };

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <div style={{ marginLeft: 16, marginTop: 70, paddingBottom: 100 }}>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Header
            content="Manage channel"
            style={{ marginRight: 20, marginTop: 10 }}
          />
          <Button
            icon="add"
            content="Add channel"
            type="button"
            color="blue"
            onClick={() =>
              dispatch(
                openModal({
                  modalType: "ChannelForm",
                  modalProps: { club: selectedClub },
                })
              )
            }
          />
        </div>
        {selectedClub?.channels.map((channel) => (
          <div
            key={channel._id}
            style={{
              gap: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Header as="h3" style={{ marginTop: 16 }}>
              {channel.name}
            </Header>
            {channel.members.includes(user) && (
              <Dropdown
                compact
                pointing
                button
                className="icon"
                floating
                icon="ellipsis horizontal"
              >
                <DropdownMenu>
                  <Dropdown.Item
                    as={"a"}
                    href={`/club/${channel.club}/discussion/${channel._id}`}
                    icon="info circle"
                    text="Club Info"
                  />
                  {(!channel.admin.includes(user) ||
                    channel.privacy === "private") && (
                    <Dropdown.Item
                      as={"a"}
                      href={`/club/${channel.club}/channel/member/${channel._id}`}
                      icon="users"
                      text="View members"
                    />
                  )}

                  {channel.admin.includes(user) && (
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(
                          openModal({
                            modalType: "ChannelForm",
                            modalProps: { channel: channel },
                          })
                        )
                      }
                      icon="edit"
                      text="Edit channel"
                    />
                  )}
                  {channel.admin.includes(user) &&
                    channel.privacy === "private" && (
                      <Dropdown.Item
                        as={"a"}
                        href={`/club/${channel.club}/channel/member/${channel._id}`}
                        icon="users"
                        text="Manage members"
                      />
                    )}
                  {channel.admin.includes(user) &&
                    selectedClub.channels.length > 1 && (
                      <Dropdown.Item
                        onClick={() => {
                          setOpenDeleteConfirm(true);
                          setId(channel._id);
                        }}
                        icon="trash"
                        text="delete channel"
                      />
                    )}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        ))}
        <Confirm
          open={openDeleteConfirm}
          size="mini"
          header="Do you want to delete this channel?"
          content="All data of this channel will be deleted. And it can't be reversed."
          onCancel={() => setOpenDeleteConfirm(false)}
          onConfirm={() => handleDeleteChannel(id)}
        />
      </div>
    </div>
  );
}
