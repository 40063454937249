import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostDashboard from "../posts/postDashboard/PostDashboard";
import FeaturedFeed from "../common/FeaturedFeed";
import { fetchFeed, HOME_RETAIN_STATE } from "./homeFeedReducer";
import { Helmet } from "react-helmet";

export default function HomePage() {
  const dispatch = useDispatch();
  const [loadingInitial, setLoadingInitial] = useState(false);
  const { retainState } = useSelector((state) => state.feed);
  const { user } = useSelector((state) => state.auth);
  const { yourClubs, yourClubsFetched } = useSelector((state) => state.club);

  useEffect(() => {
    if (retainState || !yourClubsFetched) return;
    const clubsIds = yourClubs.map(function (el) {
      return el._id;
    });
    setLoadingInitial(true);
    dispatch(fetchFeed({lastFetchedIds : [], clubs: clubsIds})).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: HOME_RETAIN_STATE });
    };
  }, [dispatch, user, retainState, yourClubsFetched]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main-section">
      <div className="left-section">
        <PostDashboard isFeed={true} loadingInitial={loadingInitial} />
      </div>
      <div className="right-section" style={{marginRight: 50}}>
        <FeaturedFeed userId={user} />
      </div>
      <Helmet>
        <title>{"Feed"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
