const CREATE_EVENT = "CREATE_EVENT";
const FETCH_EVENTS = "FETCH_EVENTS";
const LISTEN_TO_EVENT_CHAT = "LISTEN_TO_EVENT_CHAT";
const LISTEN_TO_SELECTED_EVENT = "LISTEN_TO_SELECTED_EVENT";
const LISTEN_TO_REGISTERED_TEAMS = "LISTEN_TO_REGISTERED_TEAMS";
const LISTEN_TO_SELECTED_TEAM = "LISTEN_TO_SELECTED_TEAM";
const UPDATE_REEGISTERED_TEAM = "UPDATE_REEGISTERED_TEAM";
const CLEAR_SELECTED_EVENT = "CLEAR_SELECTED_EVENT";
const CLEAR_EVENTS = "CLEAR_EVENTS";
const SET_EVENT_FILTER = "SET_EVENT_FILTER";
const CREATE_EVENT_COMMENT = "CREATE_EVENT_COMMENT";
const UPDATE_EVENT_COMMENT = "UPDATE_EVENT_COMMENT";
const DELETE_EVENT_COMMENT = "DELETE_EVENT_COMMENT";
export const EVENT_RETAIN_STATE = "EVENT_RETAIN_STATE";
export const CLEAR_EVENT_COMMENTS = "CLEAR_EVENT_COMMENTS";

export function addEvents({ events, moreEvents, lastDocId }) {
  return {
    type: FETCH_EVENTS,
    payload: { events, moreEvents, lastDocId },
  };
}

export function setEventFilter(value) {
  return function (dispatch) {
    dispatch(clearEvents());
    dispatch({ type: SET_EVENT_FILTER, payload: value });
  };
}

export function listenToSelectedEvent(event) {
  return {
    type: LISTEN_TO_SELECTED_EVENT,
    payload: event,
  };
}

export function listenToRegisteredTeams(teams) {
  return {
    type: LISTEN_TO_REGISTERED_TEAMS,
    payload: teams,
  };
}

export function listenToSelectedTeam(team) {
  return {
    type: LISTEN_TO_SELECTED_TEAM,
    payload: team,
  };
}

export function updateRegisteredTeam(team, index) {
  return {
    type: UPDATE_REEGISTERED_TEAM,
    payload: { team, index },
  };
}

export function clearSelectedEvent() {
  return {
    type: CLEAR_SELECTED_EVENT,
  };
}

export function createEvent(event) {
  return {
    type: CREATE_EVENT,
    payload: event,
  };
}

export function listenToEventChat(comments) {
  return {
    type: LISTEN_TO_EVENT_CHAT,
    payload: comments,
  };
}

export function clearEvents() {
  return {
    type: CLEAR_EVENTS,
  };
}

export function createEventComment(comment) {
  return {
    type: CREATE_EVENT_COMMENT,
    payload: comment,
  };
}

export function updateEventComment(comment) {
  return {
    type: UPDATE_EVENT_COMMENT,
    payload: comment,
  };
}

export function deleteEventComment(commentId) {
  return {
    type: DELETE_EVENT_COMMENT,
    payload: commentId,
  };
}

const initialState = {
  events: [],
  comments: [],
  moreEvents: true,
  selectedEvent: null,
  teams: [],
  registeredTeam: null,
  lastDocId: null,
  query: `?stepsDone=6&registrationEndDate[$gte]=${new Date().toISOString()}`,
  retainState: false,
};

export default function eventReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_EVENT:
      return {
        ...state,
        events: [...state.events, payload],
      };
    case FETCH_EVENTS:
      return {
        ...state,
        events: [...state.events, ...payload.events],
        moreEvents: payload.moreEvents,
        lastDocId: payload.lastDocId,
      };
    case LISTEN_TO_EVENT_CHAT:
      return {
        ...state,
        comments: payload,
      };
    case CREATE_EVENT_COMMENT:
      return {
        ...state,
        comments: [...state.comments, payload],
      };
    case UPDATE_EVENT_COMMENT:
      return {
        ...state,
        comments: [
          payload,
          ...state.comments.filter(
            (comment) => comment.commentId !== payload.commentId
          ),
        ],
      };
    case DELETE_EVENT_COMMENT:
      return {
        ...state,
        comments: [
          ...state.comments.filter((comment) => comment.commentId !== payload),
        ],
      };
    case CLEAR_EVENT_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: payload,
      };
    case LISTEN_TO_REGISTERED_TEAMS:
      return {
        ...state,
        teams: payload,
      };
    case LISTEN_TO_SELECTED_TEAM:
      return {
        ...state,
        registeredTeam: payload,
      };
    case UPDATE_REEGISTERED_TEAM:
      const newTeams = [...state.teams];
      newTeams[payload.index] = payload.team;
      return {
        ...state,
        teams: newTeams,
      };
    case CLEAR_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: null,
      };
    case CLEAR_EVENTS:
      return {
        ...state,
        events: [],
        moreEvents: true,
        lastDocId: null,
        retainState: false,
      };
    case SET_EVENT_FILTER:
      return {
        ...state,
        query: payload,
      };
    case EVENT_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
