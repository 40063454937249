import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Image, Dropdown } from "semantic-ui-react";

export default function ClubListItem({ club, isInCollegPage = false }) {
  // var meta;
  // if (club.learningXClub) meta = "LearningX club";
  // else if (club.college) meta = club.college.collegeName;
  // else meta = "";

  const [link, setLink] = useState(`/club/about/${club._id}`);

  const { user } = useSelector((state) => state.auth);

  const isMember = club?.members?.includes(user);
  const isAdmin = club?.admin?.includes(user);

  useEffect(() => {
    if (isMember) {
      for (const channel of club.channels) {
        if (channel.members.includes(user)) {
          setLink(`/club/${club._id}/discussion/${channel._id}`);
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [isMember]);

  return (
    <div className="card-item">
      {club.unreadCount !== 0 && club.unreadCount && (
        <div class="card-label">{club.unreadCount}</div>
      )}
      {isInCollegPage ? (
        <Image
          label={{
            color:
              club.college_status === "verified"
                ? "blue"
                : club.college_status === "unverified"
                ? "grey"
                : "red",
            content:
              club.college_status === "verified"
                ? "verified"
                : club.college_status === "unverified"
                ? "unofficial"
                : "Not verified",
            ribbon: "right",
          }}
          src={club.clubImg}
          alt="img"
          as={"a"}
          href={link}
        />
      ) : (
        <Image src={club.clubImg} as={"a"} alt="img" href={link} />
      )}

      <div className="card-content">
        <h5>{club.clubName}</h5>
      </div>
      <div className="card-options">
        <Dropdown item icon="ellipsis horizontal">
          <Dropdown.Menu>
            <Dropdown.Item
              as={"a"}
              href={`/club/about/${club._id}`}
              icon="info circle"
              text="Club Info"
            />
            {isMember && !isAdmin && (
              <Dropdown.Item
                as={"a"}
                href={`/club/${club._id}/member/${club.channels[0]._id}`}
                icon="users"
                text="View members"
              />
            )}
            {isAdmin && (
              <Dropdown.Item
                as={"a"}
                href={`/club/manage/clubInfo/${club._id}`}
                icon="edit"
                text="Edit club"
              />
            )}
            {isAdmin && (
              <Dropdown.Item
                as={"a"}
                href={`/club/manage/channels/${club._id}`}
                icon="setting"
                text="Manage channels"
              />
            )}
            {isAdmin && (
              <Dropdown.Item
                as={"a"}
                href={`/club/manage/members/${club._id}`}
                icon="users"
                text="Manage members"
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
