import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { fetchClubsApi } from "../../../app/apiRequest/clubService";
import { fetchSingleCollegeApi } from "../../../app/apiRequest/collegeFestService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import EventDashboard from "../../events/eventDashboard/EventDashboard";
import { setEventFilter } from "../../events/eventReducer";
import FestDashboard from "../../festival/festivalDashboard/FestDashboard";
import CollegeHeader from "./CollegeHeader";
import ClubListItem from "../../club/clubDashboard/ClubListItem";

export default function CollegePage({ match, location }) {
  const [activeItem, setActiveItem] = useState("clubs");
  const dispatch = useDispatch();
  const { selectedCollege } = useSelector((state) => state.college);
  const { loading, error } = useSelector((state) => state.async);
  const { clubs } = useSelector((state) => state.club);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (match.params.id === selectedCollege?._id) return;
    dispatch(fetchSingleCollegeApi(match.params.id));
  }, [dispatch, match.params.id, selectedCollege]);

  useEffect(() => {
    if (selectedCollege && match.params.id === selectedCollege?._id) {
      let query = "?college=" + match.params.id + "&college_status=verified";
      if (selectedCollege?.admin.some((item) => item._id === user)) {
        query = "?college=" + match.params.id;
      }
      dispatch(fetchClubsApi(query));
    }
    // eslint-disable-next-line
  }, [dispatch, match.params.id, selectedCollege]);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    if (name === "events") {
      var query = "?stepsDone=6&college=" + match.params.id;
      if (selectedCollege?.admin.some((item) => item._id === user)) {
        query = "?college=" + match.params.id;
      }
      dispatch(setEventFilter(query));
    }
  };

  if ((loading && !selectedCollege) || (!selectedCollege && !error))
    return <LoadingComponent content="Loading college..." />;

  // var isAdmin = selectedCollege.admin.some((item) => item._id === user);

  return (
    <div style={{ marginRight: 20 }}>
      <CollegeHeader college={selectedCollege} />
      <div style={{ marginTop: 10 }}>
        <Menu pointing secondary>
          <Menu.Item
            name="clubs"
            active={activeItem === "clubs"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="festivals"
            active={activeItem === "festivals"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="events"
            active={activeItem === "events"}
            onClick={handleItemClick}
          />
        </Menu>
      </div>
      {activeItem === "clubs" && (
        <div style={{ marginTop: 10 }}>
          <div className="card-container">
            {clubs.map(
              (club) =>
                club.college_status === "verified" && (
                  <ClubListItem
                    club={club}
                    key={club._id}
                    isInCollegPage={true}
                  />
                )
            )}
            {clubs.map(
              (club) =>
                club.college_status !== "verified" && (
                  <ClubListItem
                    club={club}
                    key={club._id}
                    isInCollegPage={true}
                  />
                )
            )}
          </div>
        </div>
      )}
      {activeItem === "festivals" && (
        <div style={{ marginTop: 10 }}>
          <FestDashboard college={selectedCollege} />
        </div>
      )}
      {activeItem === "events" && (
        <div style={{ marginTop: 10 }}>
          <EventDashboard isFeed={false} />
        </div>
      )}
    </div>
  );
}
