import React from "react";
import { useSelector } from "react-redux";
import EventFilters from "./EventFilters";
import EventDashboard from "./EventDashboard";

export default function EventPage() {
  const { isFeedLoading } = useSelector((state) => state.eventFeed);

  return (
    <div className="main-section">
      <EventDashboard isFeed={true} />
      <div className="right-section" style={{ width: 400 }}>
        <EventFilters loading={isFeedLoading} />
      </div>
    </div>
  );
}
