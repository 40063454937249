import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";
import { Button } from "semantic-ui-react";

export default function SignedOutMenu() {
  const dispatch = useDispatch();
  return (
    <nav>
      <a class="menu-item" href="/">
        <img src="/logo.png" alt="logo" class="logo" />
        Club-Chat
      </a>
      <div style={{ position: "absolute", right: "10%" }}>
        <Button
          color="blue"
          content="Login"
          onClick={() => dispatch(openModal({ modalType: "LoginForm" }))}
        />
        <Button
          color="blue"
          content="Register"
          style={{ marginLeft: "0.5em" }}
          onClick={() => dispatch(openModal({ modalType: "RegisterForm" }))}
        />
      </div>
    </nav>
  );
}
