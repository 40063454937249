import React from "react";
import { Link } from "react-router-dom";
import { Grid, Header, Image, Label, List, Segment } from "semantic-ui-react";

export default function EventListItem({ event }) {
  var hostedBy = "";
  var lastDate = "";
  if (event.college) hostedBy = event.college?.collegeName;
  else if (event.club) hostedBy = event.club?.clubName;

  if (event.takeRegistration) lastDate = event.registrationEndDate;
  else lastDate = event.eventStartDate;
  return (
    <Segment>
      <Grid as={Link} to={`/events/${event._id}`}>
        <Grid.Column width={6}>
          <Image
            src={
              event.eventImg ||
              "https://learningx-s3.s3.ap-south-1.amazonaws.com/image_850_315.png"
            }
            style={{ height: 125 }}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Header as="h2">
            {event.eventTitle}
            <Header.Subheader>{hostedBy}</Header.Subheader>
          </Header>
          <List>
            <List.Item>
              <List.Icon name="clock outline" />
              <List.Content>
                {new Date(lastDate).toDateString("MMM dd yyyy")}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="eye" />
              <List.Content>{event.views} impressions</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Icon name="trophy" style={{ marginRight: 10 }} />
                {event.eventType === "entertainment"
                  ? "N/A"
                  : event.totalRewards}
                <Label
                  style={{ marginLeft: 20 }}
                  content={
                    event.stepsDone !== 6
                      ? "Not Completed"
                      : event.eventType === "entertainment"
                      ? "Non-Contest"
                      : "Contest"
                  }
                  color={
                    event.stepsDone !== 6
                      ? "red"
                      : event.eventType === "entertainment"
                      ? "green"
                      : "blue"
                  }
                />
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
