import { Form, Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClubNavMenu from "../ClubNavMenu";
import { clearChats, chatRetainState, sendChat } from "./discussionReducer";
import Discussionitem from "./DiscussionItem";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { isValidHttpUrl } from "../../../../app/common/util/util";
import { openModal } from "../../../../app/common/modals/modalReducer";
import { Button, Header, Popup } from "semantic-ui-react";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import InfiniteScroll from "react-infinite-scroll-component";
import { Redirect } from "react-router-dom";
import GroupDiscussionitem from "./GroupDiscussionItem";
import ChatTextInput from "../../../../app/common/form/ChatTextInput";
import {
  fetchChannelChatsApi,
  sendChannelChatApi,
} from "../../../../app/apiRequest/channelService";
import { listenToSelectedChannel } from "../../clubReducer";
import { Helmet } from "react-helmet";

export default function DiscussionPage({ match }) {
  const socket = useRef();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const { selectedClub, selectedChannel } = useSelector((state) => state.club);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { loading, error } = useSelector((state) => state.async);
  const { user } = useSelector((state) => state.auth);
  const { channel, chats, isLoading, moreChats, lastDocId, retainState } =
    useSelector((state) => state.discussion);

  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    if (currentUserProfile) {
      socket.current = io(process.env.REACT_APP_API_BASE_URL);
      socket.current.emit("joinRoom", {
        sender: currentUserProfile,
        room: match.params.channelId,
      });
    }
  }, [match.params.channelId, currentUserProfile]);

  useEffect(() => {
    if (channel === match.params.channelId) return;
    dispatch(fetchSingleClubApi(match.params.clubId)).then(() => {
      dispatch(clearChats());
      dispatch(
        fetchChannelChatsApi(
          match.params.channelId,
          "?parentChatId[$exists]=false"
        )
      );
    });
    return () => {
      dispatch(chatRetainState(match.params.channelId));
    };
    // eslint-disable-next-line
  }, [dispatch, match.params.channelId, retainState]);

  useEffect(() => {
    if (socket.current) {
      console.log(socket);
      socket.current.on("message", (message) => {
        message.repliedCount = 0;
        message.club = match.params.clubId;
        message.channel = match.params.channelId;
        console.log(message);
        dispatch(sendChat(message));
      });
    }
    // eslint-disable-next-line
  }, [dispatch, socket.current]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("roomUsers", ({ room, users }) => {
        const newActiveUserIds = [];
        for (const active of users) {
          newActiveUserIds.push(active.sender._id);
        }
        setActiveUsers([...activeUsers, ...newActiveUserIds]);
      });
    }
    // eslint-disable-next-line
  }, [dispatch, socket.current]);

  useEffect(() => {
    if (selectedClub) {
      for (const channel of selectedClub.channels) {
        if (channel._id === match.params.channelId)
          dispatch(listenToSelectedChannel(channel));
      }
    }
  }, [dispatch, selectedClub, match.params.channelId]);

  const handlePopupBtnClick = (e, { name }) => {
    setOpenPopup(false);
    dispatch(
      openModal({
        modalType: "SendFileForm",
        modalProps: {
          type: name,
          where: "discussion",
          room: match.params.channelId,
          club: match.params.clubId,
          socket: socket.current,
        },
      })
    );
  };

  function handleFetchNextChats() {
    dispatch(
      fetchChannelChatsApi(
        match.params.channelId,
        `?parentChatId[$exists]=false&_id[$lt]=${lastDocId}`
      )
    );
  }

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to={"/error"} />;

  const initialValues = {
    chat: "",
    club: selectedClub?._id,
    channel: match.params.channelId,
    filetype: "text",
  };

  return (
    <div className="club-detailed-page">
      <ClubNavMenu
        club={selectedClub}
        channelId={match.params.channelId}
        socket={socket}
      />
      <div id="club-chat-scrollable-div">
        {chats.length > 0 && (
          <InfiniteScroll
            dataLength={chats.length}
            next={handleFetchNextChats}
            style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
            inverse={true} //
            hasMore={!isLoading && moreChats}
            loader={<h4>Loading...</h4>}
            initialScrollY={0}
            scrollableTarget="club-chat-scrollable-div"
          >
            {chats.map((chat) => (
              <div key={chat._id} style={{ overflow: "hidden" }}>
                {chat.title ? (
                  <GroupDiscussionitem chat={chat} />
                ) : (
                  <Discussionitem chat={chat} />
                )}
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
      {selectedChannel &&
      selectedChannel.permission === "private" &&
      !selectedChannel.admin.includes(user) ? (
        <div className="chat-fixed-form">
          <Header as="h3" color="blue" style={{ marginLeft: 16 }}>
            Only <strong>admin</strong> can send messages
          </Header>
        </div>
      ) : (
        <div className="chat-fixed-form">
          <Popup
            content="Start a discussion"
            trigger={
              <Button
                positive
                icon="add"
                size="small"
                style={{ marginRight: 10 }}
                onClick={() =>
                  dispatch(
                    openModal({
                      modalType: "DiscussionForm",
                      modalProps: {
                        room: match.params.channelId,
                        club: match.params.clubId,
                        socket: socket.current,
                        activeUsers: activeUsers,
                      },
                    })
                  )
                }
              />
            }
          />
          <Popup
            trigger={<Button color="blue" icon="paperclip" />}
            content={
              <>
                <Button
                  fluid
                  name="image"
                  icon="file image"
                  content="Image"
                  color="blue"
                  style={{ display: "block", marginBottom: 2 }}
                  onClick={handlePopupBtnClick}
                />
                <Button
                  fluid
                  icon="file video"
                  content="Video"
                  color="blue"
                  style={{ display: "block", marginBottom: 2 }}
                  onClick={handlePopupBtnClick}
                />
                <Button
                  fluid
                  name="file"
                  icon="file alternate"
                  content="Document"
                  color="blue"
                  style={{ display: "block" }}
                  onClick={handlePopupBtnClick}
                />
              </>
            }
            on="click"
            position="bottom left"
            open={openPopup}
            onClose={() => setOpenPopup(false)}
            onOpen={() => setOpenPopup(true)}
          />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                if (isValidHttpUrl(values.chat)) {
                  values.filetype = "link";
                }
                values.seenBy = activeUsers;
                console.log(activeUsers);
                socket.current.emit("chatMessage", values);
                sendChannelChatApi(values);
                resetForm();
              } catch (error) {
                toast.error(error);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form style={{ display: "flex" }}>
                <ChatTextInput
                  className="chat-mytextinput"
                  name="chat"
                  placeholder="write message here..."
                />
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  color="blue"
                  icon="send"
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
      <Helmet>
        <title>{selectedClub.clubName}</title>
        <meta name="description" content={selectedClub.description} />
        <meta property="og:title" content={selectedClub.clubName} />
        <meta property="og:description" content={selectedClub.description} />
        <meta property="og:image" content={selectedClub.clubImg} />
      </Helmet>
    </div>
  );
}
