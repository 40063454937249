import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  FormGroup,
  FormRadio,
  Header,
  Image,
  Label,
} from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import { getFile, isImage } from "../../../app/common/util/util";
import { toast } from "react-toastify";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSingleClubApi,
  updateClubApi,
} from "../../../app/apiRequest/clubService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Redirect } from "react-router-dom";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import { clubCategoryData } from "../../../app/api/clubArrays";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { compressImg } from "../../../app/common/photos/compressImg";

export default function EditClubInfo({ match }) {
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);
  const [radioValue, setRadioValue] = useState({ name: "private" });
  const { currentUserProfile } = useSelector((state) => state.profile);

  useEffect(() => {
    if (currentUserProfile) {
      dispatch(fetchSingleClubApi(match.params.id)).then(() => {
        setRadioValue({ name: selectedClub?.privacy });
      });
    }
    // eslint-disable-next-line
  }, [dispatch, match.params.id, currentUserProfile]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  const handleRadioChange = (e, { name }) => {
    setRadioValue({ name });
  };

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <div style={{ marginLeft: 16, marginTop: 70, paddingBottom: 100 }}>
      <div>
        <Header content="Edit Club Info" />
        <Formik
          initialValues={selectedClub}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              if (values.college_status !== "verified") {
                values.privacy = radioValue.name;
                if (values.privacy === "public") {
                  values.college = currentUserProfile.college?._id;
                } else {
                  values.college = null;
                }
              }
              if (images.length > 0) {
                var data = new FormData();
                await compressImg(images[images.length - 1]).then(
                  async (compressedDataUrl) => {
                    let file = await getFile(compressedDataUrl, filename);
                    data.append("imageFile", file);
                  }
                );
                dispatch(uploadFileApi(data)).then((response) => {
                  dispatch(asyncActionFinish());
                  values.clubImg = response[0].location;
                  dispatch(updateClubApi(values)).then(() => {
                    toast.success("updated!!");
                  });
                });
              } else {
                dispatch(updateClubApi(values)).then(() => {
                  toast.success("updated!!");
                });
              }
            } catch (error) {
              dispatch(asyncActionError(error));
              setErrors({ auth: error.message });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui form">
              <Image
                style={{ width: 159, height: 150 }}
                src={
                  images.length > 0 ? images[images.length - 1] : values.clubImg
                }
                floated="left"
              />
              <Button
                icon="file image"
                content="Add club image"
                color="blue"
                onClick={handleClick}
                style={{ marginTop: 50 }}
                type="button"
              />
              <p>Image dimensions:- (1 : 1)</p>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <MyTextInput
                name="clubName"
                placeholder="Club name"
                label={"Club Name"}
              />
              <MySelectInput
                name="category"
                placeholder="Choose club category"
                options={clubCategoryData}
                label={"Club Category"}
              />
              {currentUserProfile.college &&
                currentUserProfile.college?._id !==
                  process.env.REACT_APP_OTHER_COLLEGE_ID &&
                !selectedClub.learningXClub && (
                  <FormGroup inline>
                    <label>Do you want to add to college page</label>
                    <FormRadio
                      label="Yes"
                      checked={radioValue.name === "public"}
                      onChange={handleRadioChange}
                      name="public"
                    />
                    <FormRadio
                      label="No"
                      checked={radioValue.name === "private"}
                      onChange={handleRadioChange}
                      name="private"
                    />
                  </FormGroup>
                )}
              <MyTextInput
                name="email"
                placeholder="Contact email"
                label={"Contact Email"}
              />
              <MyTextInput
                name="website"
                placeholder="Website"
                label={"Website"}
              />
              <MyTextInput
                name="instagram"
                placeholder="instagram"
                label={"Instagram"}
              />
              <MyTextInput
                name="linkedIn"
                placeholder="linkedIn"
                label={"LinkedIn"}
              />
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <div>
                <Button
                  loading={isSubmitting || loading}
                  disabled={!isValid || isSubmitting || loading}
                  type="submit"
                  floated="right"
                  color="teal"
                  content="Save"
                  style={{ margin: 10 }}
                />
                <Button
                  content="Cancel"
                  floated="right"
                  type="button"
                  style={{ margin: 10 }}
                  onClick={() => window.history.back()}
                />
              </div>
            </Form>
          )}
        </Formik>
        <PhotoCropper
          photoCropperOpen={photoCropperOpen}
          setPhotoCropperOpen={setPhotoCropperOpen}
          images={images}
          setImages={setImages}
          imagePreview={files}
          imageNo={0}
          editing={false}
          aspectRatio={1 / 1}
        />
      </div>
    </div>
  );
}
