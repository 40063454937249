import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Item, Header } from "semantic-ui-react";
import { fetchFeaturedAdApi } from "../../app/apiRequest/extraService";
import FeaturedAdItem from "./FeaturedAdItem";

export default function FeaturedFeed({ userId }) {
  const dispatch = useDispatch();
  const { feturedAd } = useSelector((state) => state.profile);

  useEffect(() => {
    if (feturedAd.length > 0) return;
    dispatch(fetchFeaturedAdApi(userId));
    // eslint-disable-next-line
  }, [dispatch, userId]);

  return (
    <div>
      <Header
        textAlign="center"
        content="Featured opportunities"
        color="blue"
      />

      <Item.Group>
        {feturedAd.map((ad, i) => (
          <FeaturedAdItem key={i} ad={ad} />
        ))}
      </Item.Group>
    </div>
  );
}
