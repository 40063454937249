import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Header,
  Icon,
  Menu,
  Segment,
  SegmentInline,
} from "semantic-ui-react";
import { fetchClubsApi } from "../../app/apiRequest/clubService";
import { fetchSingleCollegeApi } from "../../app/apiRequest/collegeFestService";
import LoadingComponent from "../../app/layout/LoadingComponent";
import EventDashboard from "../events/eventDashboard/EventDashboard";
import { setEventFilter } from "../events/eventReducer";
import FestDashboard from "../festival/festivalDashboard/FestDashboard";
import CollegeHeader from "./collegeDashboard/CollegeHeader";
import ClubListItem from "../club/clubDashboard/ClubListItem";
import { openModal } from "../../app/common/modals/modalReducer";
import { Helmet } from "react-helmet";

export default function CampusPage() {
  const [activeItem, setActiveItem] = useState("clubs");
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { selectedCollege } = useSelector((state) => state.college);
  const { loading, error } = useSelector((state) => state.async);
  const { clubs } = useSelector((state) => state.club);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!currentUserProfile) return;
    if (currentUserProfile.college) {
      dispatch(fetchSingleCollegeApi(currentUserProfile.college._id));
    }
  }, [dispatch, currentUserProfile]);

  useEffect(() => {
    if (
      selectedCollege &&
      currentUserProfile.college._id === selectedCollege?._id
    ) {
      let query =
        "?college=" +
        currentUserProfile.college._id +
        "&college_status=verified";
      if (selectedCollege?.admin.some((item) => item._id === user)) {
        query = "?college=" + currentUserProfile.college._id;
      }
      dispatch(fetchClubsApi(query));
    }
    // eslint-disable-next-line
  }, [dispatch, currentUserProfile, selectedCollege]);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    if (name === "events" && currentUserProfile?.college) {
      var query = "?stepsDone=6&college=" + currentUserProfile.college._id;
      if (selectedCollege?.admin.some((item) => item._id === user)) {
        query = "?college=" + currentUserProfile.college._id;
      }
      dispatch(setEventFilter(query));
    }
  };

  const handleSelectCollege = () => {
    dispatch(
      openModal({
        modalType: "SelectCollegeModal",
      })
    );
  };

  const handleCreatePage = () => {
    dispatch(
      openModal({
        modalType: "CollegeForm",
      })
    );
  };

  if (!currentUserProfile) {
    return <LoadingComponent content="Loading profile..." />;
  }

  if (
    (loading && currentUserProfile.college && !selectedCollege) ||
    (!selectedCollege && currentUserProfile.college && !error)
  )
    return <LoadingComponent content="Loading college..." />;

  if (
    !currentUserProfile?.college ||
    currentUserProfile?.college?._id === process.env.REACT_APP_OTHER_COLLEGE_ID
  ) {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="dont" />
          {currentUserProfile?.college
            ? "Please check your college in Select College again. If not found, then create page."
            : "You haven't selected any college"}
        </Header>
        <SegmentInline>
          <Button onClick={handleSelectCollege} primary>
            Select College
          </Button>
          {currentUserProfile?.college && (
            <Button onClick={handleCreatePage}>Create Page</Button>
          )}
        </SegmentInline>
      </Segment>
    );
  }

  return (
    <div style={{ marginRight: 20 }}>
      <CollegeHeader college={selectedCollege} />
      <div style={{ marginTop: 10 }}>
        <Menu pointing secondary>
          <Menu.Item
            name="clubs"
            active={activeItem === "clubs"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="festivals"
            active={activeItem === "festivals"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="events"
            active={activeItem === "events"}
            onClick={handleItemClick}
          />
        </Menu>
      </div>
      {activeItem === "clubs" && (
        <div style={{ marginTop: 10 }}>
          <div className="card-container">
            {clubs.map(
              (club) =>
                club.college_status === "verified" && (
                  <ClubListItem
                    club={club}
                    key={club._id}
                    isInCollegPage={true}
                  />
                )
            )}
            {clubs.map(
              (club) =>
                club.college_status !== "verified" && (
                  <ClubListItem
                    club={club}
                    key={club._id}
                    isInCollegPage={true}
                  />
                )
            )}
          </div>
        </div>
      )}
      {activeItem === "festivals" && (
        <div style={{ marginTop: 10 }}>
          <FestDashboard college={selectedCollege} />
        </div>
      )}
      {activeItem === "events" && (
        <div style={{ marginTop: 10 }}>
          <EventDashboard isFeed={false} />
        </div>
      )}
      <Helmet>
        <title>{"My campus"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
