import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Label } from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { useSelector } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import { manageEventApi } from "../../../app/apiRequest/eventService";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function RoundLinkModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state) => state.modal);

  return (
    <ModalWrapper size="small" header={`Link for ${modalProps.title} round`}>
      <Formik
        initialValues={modalProps.event}
        validationSchema={Yup.object({
          link: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            values.stages[modalProps.round - 1].link = values.link;
            values.msg = `updated link of round ${modalProps.title}.`;
            dispatch(manageEventApi(values));
            dispatch(closeModal());
          } catch (error) {
            console.log(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyTextInput
              name="link"
              defaultValue={values.stages[modalProps.round - 1].link ?? ""}
              label={"Link"}
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 20 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Update link"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
