import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";
import { toast } from "react-toastify";
import { Button as ADButton, Dropdown } from "antd";
import {
  HeartTwoTone,
  CommentOutlined,
  ShareAltOutlined,
  SaveOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  SecurityScanTwoTone,
  CopyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import {
  removePost,
  setLikePost,
  setSavePost,
  setUnLikePost,
  setUnSavePost,
} from "../postReducer";
import {
  removePostFeed,
  setLikePostFeed,
  setUnLikePostFeed,
  setSavePostFeed,
  setUnSavePostFeed,
} from "../../home/homeFeedReducer";
import {
  deletePostApi,
  fetchSinglePostApi,
  updatePostApi,
} from "../../../app/apiRequest/postService";
import { Icon, Image } from "semantic-ui-react";
import displayLinks from "../../../app/common/text/handleLinkClick";

export default function PostListItem({ post, isFeed }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openPopup, setOpenPopup] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showFullContent, setShowFullContent] = useState(false);
  const [showSharedFullContent, setShowSharedFullContent] = useState(false);

  var profileId = `/error`;
  var displayname = "user";
  var displayImg =
    "https://react.semantic-ui.com/images/avatar/small/elliot.jpg";
  var collegeName = "";
  if (post.user) {
    profileId = `/profile/${post.user._id}`;
    displayname = post.user?.displayName;
    displayImg = post.user.userImg;
    if (post.privacy) {
      collegeName = (
        <div>
          <Icon name="lock" />
          {post.privacy.clubName}
        </div>
      );
    } else {
      collegeName =
        post.user.college &&
        post.user.college?._id !== process.env.REACT_APP_OTHER_COLLEGE_ID
          ? post.user.college?.collegeName
          : post.user.user_name;
    }
  } else if (post.club) {
    profileId = `/club/about/${post.club._id}`;
    displayname = post.club.clubName;
    displayImg = post.club.clubImg;
    if (post.privacy) {
      collegeName = (
        <div>
          <Icon name="lock" />
          {post.privacy.clubName}
        </div>
      );
    } else if (post.club.learningXClub) collegeName = "LearningX";
    else collegeName = post.club.college?.collegeName ?? post.club.category;
  } else if (post.fest) {
    profileId = `/club/fest/${post.fest._id}`;
    displayname = post.fest.festName;
    displayImg = post.fest.festImg;
    if (post.privacy) {
      collegeName = (
        <div>
          <Icon name="lock" />
          {post.privacy.clubName}
        </div>
      );
    } else {
      collegeName = post.fest.college?.collegeName;
    }
  }

  var sharedUsername = "user";
  var sharedUserImg =
    "https://react.semantic-ui.com/images/avatar/small/elliot.jpg";
  if (post.sharedPost && post.sharedPost.user) {
    sharedUsername = post.sharedPost.user?.displayName;
    sharedUserImg = post.sharedPost.user.userImg;
  } else if (post.sharedPost && post.sharedPost.club) {
    sharedUsername = post.sharedPost.club.clubName;
    sharedUserImg = post.sharedPost.club.clubImg;
  } else if (post.sharedPost && post.sharedPost.fest) {
    sharedUsername = post.sharedPost.fest.festName;
    sharedUserImg = post.sharedPost.fest.festImg;
  }

  async function handleLikeBtn() {
    setLoading(true);
    try {
      if (post.likes.includes(user)) {
        updatePostApi({
          _id: post._id,
          likes: [...post.likes.filter((id) => id !== user)],
        }).then(() => {
          if (isFeed) dispatch(setUnLikePostFeed(post._id, user));
          else dispatch(setUnLikePost(post._id, user));
        });
      } else {
        updatePostApi({ _id: post._id, likes: [...post.likes, user] }).then(
          () => {
            if (isFeed) dispatch(setLikePostFeed(post._id, user));
            else dispatch(setLikePost(post._id, user));
          }
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleSaveBtn() {
    setLoading(true);
    try {
      if (post.savedBy.includes(user)) {
        updatePostApi({
          _id: post._id,
          savedBy: [...post.savedBy.filter((id) => id !== user)],
        }).then(() => {
          if (isFeed) dispatch(setUnSavePostFeed(post._id, user));
          else dispatch(setUnSavePost(post._id, user));
        });
      } else {
        updatePostApi({ _id: post._id, savedBy: [...post.savedBy, user] }).then(
          () => {
            if (isFeed) dispatch(setSavePostFeed(post._id, user));
            else dispatch(setSavePost(post._id, user));
          }
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setOpenPopup(false);
    }
  }

  async function handleDeleteBtn() {
    setLoading(true);
    try {
      dispatch(deletePostApi(post._id)).then(() => {
        if (isFeed) dispatch(removePostFeed(post._id));
        else dispatch(removePost(post._id));
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setOpenPopup(false);
    }
  }

  async function handleHideBtn() {
    setLoading(true);
    try {
      if (isFeed) dispatch(removePostFeed(post._id));
      else dispatch(removePost(post._id));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setOpenPopup(false);
    }
  }

  const handleEditBtn = () => {
    setOpenPopup(false);
    dispatch(fetchSinglePostApi(post._id)).then(() =>
      dispatch(
        openModal({
          modalType: "CreatePostForm",
          modalProps: {
            userId: post.user?._id ?? post.club?._id ?? post.fest?._id,
            displayName:
              post.user?.displayName ??
              post.club?.clubName ??
              post.fest?.festName,
            displayImg:
              post.user?.userImg ?? post.club?.clubImg ?? post.fest?.festImg,
            todo: "edit",
          },
        })
      )
    );
  };

  const handleFeedShareBtn = () => {
    dispatch(fetchSinglePostApi(post._id)).then(() =>
      dispatch(
        openModal({
          modalType: "CreatePostForm",
          modalProps: {
            userId: user,
            displayName: currentUserProfile.displayName,
            displayImg: currentUserProfile.userImg,
            todo: "share",
            postId: post._id,
            postBy: "user",
          },
        })
      )
    );
  };
  const handleClubShareBtn = () => {
    dispatch(
      openModal({
        modalType: "ClubListModal",
        modalProps: post._id,
      })
    );
  };
  const handleMessageShareBtn = () => {
    dispatch(
      openModal({
        modalType: "SharePostToChatModal",
        modalProps: {
          link: `${process.env.REACT_APP_DOMAIN_URL}posts/${post._id}`,
        },
      })
    );
  };

  function copyToClipboard() {
    setOpenPopup(false);
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_DOMAIN_URL}posts/${post._id}`)
      .then(() => {
        // Alert the user that the action took place.
        // Nobody likes hidden stuff being done under the hood!
        alert("Copied to clipboard");
      });
  }

  const prevSlide = () => {
    console.log(currentSlide);
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const nextSlide = () => {
    console.log(currentSlide);
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const shareDropdownItems = [
    { label: "share to feed", action: handleFeedShareBtn },
    { label: "share to club", action: handleClubShareBtn },
    { label: "share in message", action: handleMessageShareBtn },
  ];

  const editUserMenuItems = [
    { label: "edit post", action: handleEditBtn, icon: <EditOutlined /> },
    { label: "delete post", action: handleDeleteBtn, icon: <DeleteOutlined /> },
    {
      label: "copy link",
      action: () => copyToClipboard(),
      icon: <CopyOutlined />,
    },
  ];

  const editMenuItems = [
    {
      label: `${post.savedBy.includes(user) ? "unsave" : "save"} post`,
      action: handleSaveBtn,
      icon: <SaveOutlined />,
    },
    {
      label: "hide post",
      action: handleHideBtn,
      icon: <EyeInvisibleOutlined />,
    },
    {
      label: "report post",
      action: () => {
        setOpenPopup(false);
        dispatch(
          openModal({
            modalType: "ReportForm",
            modalProps: {
              type: "post",
              id: post._id,
            },
          })
        );
      },
      icon: <SecurityScanTwoTone />,
    },
    {
      label: "copy link",
      action: () => copyToClipboard(),
      icon: <CopyOutlined />,
    },
  ];

  const sharedDropDownMenu = shareDropdownItems.map((item) => {
    return {
      key: Math.random(),
      label: (
        <ADButton type="text" size="small" onClick={item.action}>
          {item.label}
        </ADButton>
      ),
    };
  });

  const getPostMenuOptions = useMemo(() => {
    let validOption = [];

    if (
      user === post.user?._id ||
      post.club?.admin?.includes(user) ||
      post.fest?.admin?.includes(user)
    ) {
      validOption = editUserMenuItems;
    } else {
      validOption = editMenuItems;
    }

    const menuOptions = validOption.map((item) => {
      return {
        key: Math.random(),
        label: (
          <ADButton
            type="text"
            size="small"
            onClick={item.action}
            icon={item.icon}
          >
            {item.label}
          </ADButton>
        ),
      };
    });
    return menuOptions;
    // eslint-disable-next-line
  }, [editMenuItems, editUserMenuItems, user]);

  // Function to toggle full content
  const toggleFullContent = () => {
    setShowFullContent(!showFullContent);
  };
  const toggleSharedFullContent = () => {
    setShowSharedFullContent(!showSharedFullContent);
  };

  return (
    <div className="post-container">
      <div className="post-header">
        <div className="avatar">
          <img src={displayImg} alt="Author Avatar" />
          <div className="author-name">
            <a href={profileId}>{displayname}</a>
            <br /> {collegeName}
          </div>
        </div>
        <Dropdown
          menu={{ items: getPostMenuOptions }}
          onOpenChange={(val) => setOpenPopup(val)}
        >
          <ADButton
            icon={openPopup ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            type="text"
            size="small"
          />
        </Dropdown>
      </div>
      <div className="post-content">
        <div className="content-preview">
          {/* Conditionally render content based on showFullContent state */}
          {showFullContent ? (
            <p>{displayLinks(post.text)}</p>
          ) : (
            <p>
              {post.text.length > 300 // Adjust the character limit as needed
                ? displayLinks(`${post.text.slice(0, 300)}...`)
                : displayLinks(post.text)}
            </p>
          )}
        </div>
        {/* Show the "See More" link only if content exceeds character limit */}
        {post.text.length > 300 && (
          // eslint-disable-next-line
          <a className="see-more-link" onClick={toggleFullContent}>
            {showFullContent ? "See Less" : "See More"}
          </a>
        )}
      </div>

      {post.images?.length > 0 && (
        <div className="post-slideshow">
          {post.images?.length === 1 ? (
            <Image
              src={post.images[currentSlide]}
              alt={`Slide ${currentSlide + 1}`}
            />
          ) : (
            <Image
              style={{ width: 500, height: 500, objectFit: "cover" }}
              src={post.images[currentSlide]}
              alt={`Slide ${currentSlide + 1}`}
            />
          )}

          {post.images?.length > 1 && (
            <div className="slide-nav">
              <span className="slide-counter">
                {currentSlide + 1} / {post.images.length}
              </span>
              <button
                className="prev-slide"
                onClick={prevSlide}
                style={{
                  visibility: currentSlide === 0 ? "hidden" : "visible",
                }}
              >
                &#10094;
              </button>
              <button
                className="next-slide"
                onClick={nextSlide}
                style={{
                  visibility:
                    currentSlide === post.images.length - 1
                      ? "hidden"
                      : "visible",
                }}
              >
                &#10095;
              </button>
            </div>
          )}
        </div>
      )}
      {post.videofile && (
        <div className="post-video">
          <video controls>
            <source src={post.videofile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {post.sharedPost && (
        <div
          className="post-container"
          style={{ maxWidth: 500, marginLeft: 50 }}
          onClick={() => history.push(`/posts/${post.sharedPost?._id}`)}
        >
          <div className="post-header">
            <div className="avatar">
              <img src={sharedUserImg} alt="Author Avatar" />
              <div className="author-name">
                <a
                  href={
                    post.sharedPost.user
                      ? `/profile/${post.sharedPost.user?._id}`
                      : `club/about/${post.sharedPost.club?._id}`
                  }
                >
                  {sharedUsername}
                </a>
              </div>
            </div>
            {/* <div style={{ marginLeft: 20 }}>
              {formatDistance(new Date(post.sharedPost.createdAt), new Date(), {
                addSuffix: true,
              })}
            </div> */}
          </div>
          <div className="post-content">
            <div className="content-preview">
              {/* Conditionally render content based on showFullContent state */}
              {showSharedFullContent ? (
                <p>{displayLinks(post.sharedPost.text)}</p>
              ) : (
                <p>
                  {post.sharedPost.text.length > 200 // Adjust the character limit as needed
                    ? displayLinks(`${post.sharedPost.text.slice(0, 200)}...`)
                    : displayLinks(post.sharedPost.text)}
                </p>
              )}
            </div>
            {/* Show the "See More" link only if content exceeds character limit */}
            {post.sharedPost.text.length > 200 && (
              // eslint-disable-next-line
              <a className="see-more-link" onClick={toggleSharedFullContent}>
                {showSharedFullContent ? "See Less" : "See More"}
              </a>
            )}
          </div>

          {post.sharedPost.images?.length > 0 && (
            <div className="post-slideshow">
              {post.sharedPost.images?.length === 1 ? (
                <Image
                  id="slide-image"
                  src={post.sharedPost.images[currentSlide]}
                  alt={`Slide ${currentSlide + 1}`}
                />
              ) : (
                <Image
                  style={{ width: 400, height: 400, objectFit: "cover" }}
                  src={post.sharedPost.images[currentSlide]}
                  alt={`Slide ${currentSlide + 1}`}
                />
              )}
              {/* <img
                id="slide-image"
                src={post.sharedPost.images[currentSlide]}
                alt={`Slide ${currentSlide + 1}`}
              /> */}

              {post.sharedPost.images?.length > 1 && (
                <div className="slide-nav">
                  <button
                    className="prev-slide"
                    onClick={prevSlide}
                    style={{
                      visibility: currentSlide === 0 ? "hidden" : "visible",
                    }}
                  >
                    &#10094;
                  </button>
                  <button
                    className="next-slide"
                    onClick={nextSlide}
                    style={{
                      visibility:
                        currentSlide === post.images.length - 1
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    &#10095;
                  </button>
                </div>
              )}
            </div>
          )}
          {post.sharedPost.videofile && (
            <div className="post-video">
              <video id="shared-video" controls>
                <source src={post.sharedPost.videofile} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      )}
      <div className="post-footer">
        <div>
          <ADButton
            loading={loading}
            onClick={handleLikeBtn}
            className="buttonClassname"
            size="small"
            icon={
              post.likes.includes(user) ? (
                <HeartTwoTone twoToneColor="#eb2f96" />
              ) : (
                <HeartOutlined />
              )
            }
            type="text"
          >
            {`${post.likes.length}  ${post.likes.length ? "likes" : "like"}`}
          </ADButton>
          <ADButton
            icon={<CommentOutlined />}
            onClick={() => history.push(`/posts/${post._id}`)}
            type="text"
            size="small"
          >
            {`${post.commentsCount}  comments`}
          </ADButton>
          {post.sharedPost == null && (
            <Dropdown menu={{ items: sharedDropDownMenu }}>
              <ADButton icon={<ShareAltOutlined />} type="text" size="small">
                share
              </ADButton>
            </Dropdown>
          )}
        </div>
        {/* <div className="time">
          {formatDistance(new Date(post.createdAt), new Date(), {
            addSuffix: true,
          })}
        </div> */}
      </div>
    </div>
  );
}
