import { Button, Grid } from "semantic-ui-react";
import React from "react";
import { useSelector } from "react-redux";

export default function FooterPage() {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <div className="footer" style={{ marginLeft: isAuthenticated ? 120 : 0 }}>
      <Grid stackable columns={2} className="footer-grid">
        <Grid.Column width={8}>
          <div className="footer-text">
            <h2>Contact Us</h2>
            <p className="info">
              For more information about our programs and partnerships, please
              visit our website or contact us at contact@clubchat.live
            </p>
            <h2>Our Values</h2>
            <ul className="info">
              <li>
                Innovation: We strive to be at the forefront of educational
                technology, continually improving our offerings to meet the
                evolving needs of learners.
              </li>
              <li>
                Accessibility: We are committed to making education accessible
                to all, regardless of geographic or socioeconomic barriers.
              </li>
              <li>
                Collaboration: We believe in the power of collaboration and work
                closely with educators, institutions, and technology partners to
                create the best learning experiences.
              </li>
            </ul>
            <div className="footer-end">
              © 2024, Clubsphere Online Innovation Pvt. Ltd. All Rights Reserved.
            </div>
          </div>
        </Grid.Column>

        <Grid.Column width={8}>
          <div className="footer-right">
            <div className="dld">
              <h2>DOWNLOAD THE APP</h2>
              <div className="img">
                <a
                  href="https://apps.apple.com/in/app/learningx-college-clubs/id6612034727"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width={120} src="/images/images 1.svg" alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=in.learningx.club_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width={120} src="/images/images 2.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="social">
              <h2>Social</h2>
              <div className="social-buttons">
                <a
                  href="https://www.facebook.com/learningx.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="facebook" icon="facebook" />
                </a>
                <a
                  href="https://twitter.com/learningX_in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="twitter" icon="twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/learningx-in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="linkedin" icon="linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/learningx_in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="instagram" icon="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/@learningx_in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="youtube" icon="youtube" />
                </a>
              </div>
            </div>

            <div className="link">
              <div className="footer-comp">
                <h3>Company</h3>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/terms-conditions">Terms and Conditions</a>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}
