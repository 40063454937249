import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { closeAccountApi } from "../../app/apiRequest/profileService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { closeModal } from "../../app/common/modals/modalReducer";

export default function CloseAccountModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUserProfile } = useSelector((state) => state.profile);

  const handleCloseAccount = () => {
    dispatch(closeAccountApi(currentUserProfile._id)).then(() => {
      dispatch(closeModal());
      history.push("/");
    });
  };

  return (
    <ModalWrapper size="small" header="Close Account">
      <div>
        <div class="account-sub-header">
          {currentUserProfile.displayName}, we’re sorry to see you go
          <br />
          Just a quick reminder, closing your account means you’ll lose all your
          data on Club-Chat.
          <br />
          You’ll also lose any recommendations and endorsements you’ve given or
          received.
        </div>
        <div class="profile">
          <img src={currentUserProfile?.userImg} alt="img" />
          <div class="profile-info">
            <div class="name">{currentUserProfile?.displayName}</div>
          </div>
        </div>
        <button onClick={handleCloseAccount}>Continue</button>
      </div>
    </ModalWrapper>
  );
}
