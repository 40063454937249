import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export default function ManageClub() {
  const [activeItem, setActiveItem] = useState("clubInfo");
  const { pathname } = useSelector((state) => state.router.location);
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <div id="manage-club-menu">
      <Menu pointing secondary size="huge">
        <Menu.Item
          header
          as={Link}
          to={`/club/${selectedClub?._id}/discussion/${selectedClub.channels[0]._id}`}
        >
          <Image avatar spaced="right" src={selectedClub.clubImg} />
          {selectedClub.clubName}
        </Menu.Item>
        <Menu.Item
          name="editClubInfo"
          active={activeItem.includes("clubInfo")}
          as={Link}
          to={`/club/manage/clubInfo/${selectedClub?._id}`}
          color="blue"
        />
        <Menu.Item
          name="editClubDescription"
          active={activeItem.includes("clubDescription")}
          as={Link}
          to={`/club/manage/clubDescription/${selectedClub?._id}`}
          color="blue"
        />
        <Menu.Item
          name="editClubFaqs"
          active={activeItem.includes("clubFaqs")}
          as={Link}
          to={`/club/manage/clubFaqs/${selectedClub?._id}`}
          color="blue"
        />
        <Menu.Item
          name="members"
          active={activeItem.includes("members")}
          as={Link}
          to={`/club/manage/members/${selectedClub?._id}`}
          color="blue"
        />
        <Menu.Item
          name="channels"
          active={activeItem.includes("channels")}
          as={Link}
          to={`/club/manage/channels/${selectedClub?._id}`}
          color="blue"
        />
      </Menu>
    </div>
  );
}
