import React from "react";
import { Loader } from "semantic-ui-react";
import PostList from "./PostList";
import { useSelector } from "react-redux";
import PostListItemPlaceholder from "./PostListItemPlaceholder";
import { useDispatch } from "react-redux";
import { fetchFeed } from "../../home/homeFeedReducer";
import { fetchPostsApi } from "../../../app/apiRequest/postService";

export default function PostDashboard({ isFeed, loadingInitial }) {
  const dispatch = useDispatch();
  const { isFeedLoading, feed, moreFeed, lastFetchedIds } = useSelector(
    (state) => state.feed
  );
  const { isLoading, posts, filter, morePosts, lastPostId } = useSelector(
    (state) => state.post
  );
  const { yourClubs } = useSelector((state) => state.club);

  function handleFetchNextPosts() {
    const clubsIds = yourClubs.map(function (el) {
      return el._id;
    });
    if (isFeed) dispatch(fetchFeed({ lastFetchedIds: lastFetchedIds, clubs: clubsIds }));
    else dispatch(fetchPostsApi({filter: filter, clubs: clubsIds, lastDocId: lastPostId}));
  }

  return (
    <div>
      {loadingInitial && (
        <>
          <PostListItemPlaceholder />
          <PostListItemPlaceholder />
        </>
      )}
      <PostList
        posts={isFeed ? feed : posts}
        getNextPosts={handleFetchNextPosts}
        loading={isFeed ? isFeedLoading : isLoading}
        morePosts={isFeed ? moreFeed : morePosts}
        isFeed={isFeed}
      />
      <Loader active={isFeed ? isFeedLoading : isLoading} />
    </div>
  );
}
