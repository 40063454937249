import React from "react";

export default function displayLinks(message) {
  const linkRegex = /https?:\/\/[^\s]+/g;

  const processLinks = (text) => {
    let elements = [];
    let lastIndex = 0;

    text.replace(linkRegex, (match, offset) => {
      if (lastIndex < offset) {
        elements.push(text.substring(lastIndex, offset));
      }
      elements.push(
        <a
          key={offset}
          href={match}
          style={{ color: "blue" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {match}
        </a>
      );
      lastIndex = offset + match.length;
    });

    if (lastIndex < text.length) {
      elements.push(text.substring(lastIndex));
    }

    return elements;
  };

  return <div>{processLinks(message)}</div>;
}
