import React from "react";
import { useSelector } from "react-redux";
import EventDetailedChat from "./EventDetailedChat";
import EventDetailedHeader from "./EventDetailedHeader";
import EventDetailedInfo from "./EventDetailedInfo";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import EventDetailedStage from "./EventDetailedStage";
import {
  fetchEventCommentsApi,
  fetchSingleEventApi,
} from "../../../app/apiRequest/eventService";
import { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";

export default function EventDetailedPage({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const event = useSelector((state) => state.event.selectedEvent);
  const { loading, error } = useSelector((state) => state.async);
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (match.params.id === event?._id) return;
    dispatch(fetchSingleEventApi(match.params.id));
    dispatch(fetchEventCommentsApi(match.params.id));
  }, [dispatch, match.params.id, event]);

  const isAdmin = isAuthenticated
    ? event?.admin.some((item) => item._id === user)
    : false;

  if (loading || (!event && !error))
    return <LoadingComponent content="Loadind event..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <div style={{ marginRight: 20 }}>
      <Helmet>
        <title>{event.eventTitle}</title>
        <meta name="description" content={event.description} />
        <meta property="og:title" content={event.eventTitle} />
        <meta property="og:description" content={event.description} />
        <meta property="og:image" content={event.eventImg} />
      </Helmet>
      <EventDetailedHeader event={event} isAdmin={isAdmin} />
      {event.eventType === "contest" && event.stages.length > 0 && (
        <EventDetailedStage event={event} isAdmin={isAdmin} />
      )}
      <EventDetailedInfo event={event} />
      <EventDetailedChat eventId={event._id} />
      {isAuthenticated && (
        <Button
          icon="arrow left"
          content={event.eventTitle}
          labelPosition="left"
          floated="left"
          style={{ top: 67, left: 125, position: "fixed", color: "blue" }}
          onClick={() => history.goBack()}
        />
      )}
    </div>
  );
}
