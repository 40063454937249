/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import FooterPage from "../more/FooterPage";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  azureSignIn,
  googleSignIn,
  loginUser,
} from "../../app/apiRequest/loginService";
import { Button, Icon, Label } from "semantic-ui-react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { openModal } from "../../app/common/modals/modalReducer";
import ExplorePage from "../explore/ExplorePage";
import { PublicClientApplication } from "@azure/msal-browser";

export default function LoginHomePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, token } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);

  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const response = await msalInstance.handleRedirectPromise();
        if (response) {
          console.log("Authentication successful:", response);
          dispatch(azureSignIn(response.idToken));
        } else {
          console.log("No response from authentication.");
        }
      } catch (error) {
        console.error("Error handling redirect response:", error);
        // Handle error, such as displaying an error message to the user
      }
    };

    handleRedirect();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      if (token) {
        dispatch(
          openModal({
            modalType: "ActivateAccountModal",
            modalProps: currentUserProfile,
          })
        );
      } else return;
    } else {
      history.push("/clubs");
    }
    // eslint-disable-next-line
  }, [isAuthenticated, history, token]);

  useEffect(() => {
    const google = window.google;
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
      use_fedcm_for_prompt: true, // Enable FedCM for the prompt
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      type: "standard",
      size: "large",
      shape: "rectangular",
      text: "signin",
      width: "120",
      theme: "outline",
    });
    // eslint-disable-next-line
  }, []);

  function handleCallbackResponse(response) {
    dispatch(googleSignIn(response.credential));
  }

  const handleForgotPassBtn = () => {
    dispatch(openModal({ modalType: "ForgotPasswordForm" }));
  };

  const handleRegisterBtn = () => {
    dispatch(openModal({ modalType: "RegisterForm" }));
  };

  const handleAzureSignIn = async () => {
    history.push("/");

    try {
      msalInstance.loginRedirect({
        redirectUri: process.env.REACT_APP_DOMAIN_URL,
        scopes: ["openid", "profile", "User.Read", "email", "offline_access"],
      });
    } catch (error) {
      console.error("Error during authentication:", error);
      // Handle error, such as showing an error message to the user
    }
  };

  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  return (
    <div>
      <div className="login-container flex">
        <nav>
          <a className="menu-item" href="/">
            <img src="/logo.png" alt="logo" className="logo" />
            Club-Chat
          </a>
          <div className="nav-links">
            <pre
              onClick={() =>
                dispatch(openModal({ modalType: "ContactUsForm" }))
              }
            >
              Contact
            </pre>
            <pre onClick={() => history.push("/about")}>About Us</pre>
          </div>
        </nav>

        <div className="login-content flex">
          <div className="contentDiv flex">
            <h2 className="contentText">Discover college, clubs and events</h2>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              try {
                dispatch(loginUser(values, setErrors));
              } catch (error) {
                setErrors({ auth: "Error in Login server!" });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, isValid, dirty, errors }) => (
              <Form className="ui login form">
                <MyTextInput name="username" placeholder="Email Address" />
                <MyTextInput
                  name="password"
                  placeholder="Password"
                  type="password"
                />
                {errors.auth && (
                  <Label
                    basic
                    color="red"
                    style={{ marginBottom: 10 }}
                    content={errors.auth}
                  />
                )}
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  fluid
                  size="large"
                  color="blue"
                  content="Login"
                />
              </Form>
            )}
          </Formik>
          <div className="links flex">
            <a href="#" onClick={handleForgotPassBtn}>
              Forget your password?
            </a>
            <p id="loginWith">or login with</p>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: -10 }}
            >
              <div id="signInDiv" style={{ marginRight: "10px" }} />{" "}
              {/* Add margin if needed */}
              <Button color="black" basic onClick={handleAzureSignIn}>
                <Icon name="microsoft" /> Outlook
              </Button>
            </div>
            <p>
              Don't have an account?{" "}
              <a href="#" onClick={handleRegisterBtn}>
                create now
              </a>
            </p>
          </div>
        </div>
      </div>
      <ExplorePage />
      <FooterPage />
    </div>
  );
}
