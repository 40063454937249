import React from "react";
import { Image, List } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { closeModal } from "../../app/common/modals/modalReducer";

export default function ViewAdminModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { admin } = useSelector((state) => state.modal.modalProps);

  const handleListItemClick = (id) => {
    dispatch(closeModal());
    history.push(`/profile/${id}`);
  };

  return (
    <ModalWrapper size="tiny" header="View Admin">
      {admin?.length > 0 &&
        admin.map((user) => (
          <List key={user._id} selection>
            <List.Item onClick={() => handleListItemClick(user._id)}>
              <Image avatar src={user.userImg} />
              <List.Content>
                <List.Header>{user.displayName}</List.Header>
                {user.user_name}
              </List.Content>
            </List.Item>
          </List>
        ))}
    </ModalWrapper>
  );
}
