export async function compressImg(url, maxSizeKB = 200, quality = 0.9) {
    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = 'Anonymous'; // Set crossOrigin to handle CORS
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.src = src;
        });
    };

    const image = await loadImage(url);

    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxSizeBytes = maxSizeKB * 1024; // Convert maxSizeKB to bytes
        let compressedDataUrl = null;

        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const checkSizeAndCompress = () => {
            const dataUrl = canvas.toDataURL('image/jpeg', quality);
            const fileSizeBytes = dataUrl.length;

            if (fileSizeBytes > maxSizeBytes && quality > 0) {
                quality -= 0.1; // Adjust compression quality
                checkSizeAndCompress();
            } else {
                compressedDataUrl = dataUrl;
                resolve(compressedDataUrl);
            }
        };

        checkSizeAndCompress();
    });
}
