import React, { useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  fetchSingleClubApi,
  updateClubApi,
} from "../../../app/apiRequest/clubService";
import { toast } from "react-toastify";

export default function EditClubDescription({ match }) {
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.id));
  }, [dispatch, match.params.id]);

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  //if (error) return <Redirect to="/error" />;

  return (
    <div
      style={{ marginLeft: 16, marginTop: 70, paddingBottom: 100 }}
    >
      <div>
        <Header content="Edit Club Description" />
        <Formik
          initialValues={selectedClub}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              dispatch(updateClubApi(values)).then(() => {
                toast.success("updated!!");
              });
            } catch (error) {
              setErrors({ auth: error.message });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors, values }) => (
            <Form className="ui form">
              <MyTextArea
                name="description"
                placeholder="Let people know what this club is all about"
                label={"Desccription"}
                rows={6}
              />
              <FieldArray name="learnings">
                {({ insert, remove, push }) => (
                  <div style={{ marginBottom: 16 }}>
                    <Header
                      as={"h4"}
                      content="What will people learn from this club?"
                      style={{ marginRight: 20, marginBottom: -2 }}
                    />
                    {values.learnings?.length > 0 &&
                      values.learnings.map((learning, index) => (
                        <div key={index}>
                          <MyTextInput
                            name={`learnings.${index}.learning`}
                            placeholder={`${index + 1}.`}
                          />
                        </div>
                      ))}
                    {values.learnings.length < 6 && (
                      <Button
                        content="Add more.."
                        floated="left"
                        type="button"
                        color="blue"
                        style={{ marginTop: 10 }}
                        onClick={() => push({ learning: "" })}
                      />
                    )}
                  </div>
                )}
              </FieldArray>
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <div>
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  floated="right"
                  color="teal"
                  content="Save"
                  style={{ margin: 10 }}
                />
                <Button
                  content="Cancel"
                  floated="right"
                  type="button"
                  style={{ margin: 10 }}
                  onClick={() => window.history.back()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
