import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  Button,
  FormGroup,
  Grid,
  Header,
  Label,
  Segment,
} from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { registerTeamApi } from "../../../app/apiRequest/eventService";
import { toast } from "react-toastify";
import { closeModal } from "../../../app/common/modals/modalReducer";

export default function UserRegistrationForm() {
  const dispatch = useDispatch();
  const { selectedEvent } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.auth);

  const initialValues = {
    teamName: "",
    event: selectedEvent._id,
    members: [
      {
        memberName: "",
        email: "",
        phone: "",
        college: "",
        otherDetails: "",
      },
    ],
  };

  const validationSchema = Yup.object({
    teamName: Yup.string().required(),
    members: Yup.array().of(
      Yup.object().shape({
        memberName: Yup.string().required(),
        email: Yup.string().email().required(),
        phone: Yup.number().required(),
        college: Yup.string().required(),
        otherDetails: Yup.string().required(),
      })
    ),
  });

  return (
    <ModalWrapper size="large" header="Register to This event">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            dispatch(
              registerTeamApi(values, {
                ...selectedEvent,
                registerdTeamLead: [...selectedEvent.registerdTeamLead, user],
                registrationChange: true,
              })
            ).then(() => dispatch(closeModal()));
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form className="ui form">
            <FieldArray name="members">
              {({ insert, remove, push }) => (
                <div style={{ marginBottom: 16 }}>
                  <Grid>
                    <Grid.Column width={13}>
                      <MyTextInput
                        name="teamName"
                        placeholder="Team Name (must be unique)"
                        label={"Team Name"}
                      />
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {selectedEvent.minSizeTeam > values.members.length &&
                        push({
                          memberName: "",
                          email: "",
                          phone: "",
                          college: "",
                          otherDetails: "",
                        })}
                      <Button
                        content="Add Member"
                        type="button"
                        icon="add user"
                        color="blue"
                        floated="right"
                        disabled={
                          values.members.length > selectedEvent.maxSizeTeam - 1
                        }
                        style={{ marginTop: 20 }}
                        onClick={() => {
                          push({
                            memberName: "",
                            email: "",
                            phone: "",
                            college: "",
                            otherDetails: "",
                          });
                          window.scroll({
                            top: 1000,
                            behavior: "smooth",
                          });
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  {values.members?.length > 0 &&
                    values.members.map((member, index) => (
                      <Segment key={index}>
                        <Header as={"h4"} content="Team Member" color="blue" />
                        <FormGroup widths={"equal"}>
                          <MyTextInput
                            name={`members.${index}.memberName`}
                            placeholder="Name"
                            label={"Name"}
                          />
                          <MyTextInput
                            name={`members.${index}.email`}
                            placeholder="Email"
                            label={"Email"}
                          />
                        </FormGroup>
                        <FormGroup widths={"equal"}>
                          <MyTextInput
                            name={`members.${index}.phone`}
                            placeholder="Contact number"
                            label={"Contact number"}
                          />
                          <MyTextInput
                            name={`members.${index}.college`}
                            placeholder="College Name"
                            label={"College Name"}
                          />
                        </FormGroup>
                        <MyTextInput
                          name={`members.${index}.otherDetails`}
                          placeholder="like 2nd year B.tech student"
                          label={"Other Details"}
                        />
                      </Segment>
                    ))}
                </div>
              )}
            </FieldArray>

            {errors.auth && <Label basic color="red" content={errors.auth} />}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Register"
              style={{ marginTop: 20 }}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
