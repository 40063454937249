import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button, Label } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useDispatch } from "react-redux";
import { forgotPasswordApi } from "../../app/apiRequest/loginService";

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();

  return (
    <ModalWrapper size="tiny" header="Enter your Email or Mobile number">
      <Formik
        initialValues={{ username: "" }}
        validationSchema={Yup.object({
          username: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            dispatch(forgotPasswordApi(values, setErrors));
          } catch (error) {
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyTextInput
              name="username"
              placeholder="Email Address/ Mobile number"
              style={{ marginBottom: 20 }}
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 20 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="teal"
              content="Submit"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
