const FILES_LOADING = "FILES_LOADING";
const FILES_FAILED = "FILES_FAILED";
const UPLOAD_FILE = "UPLOAD_FILE";
const MANAGE_FILE = "MANAGE_FILE";
const DELETE_FILE = "DELETE_FILE";
const FETCH_FILES = "FETCH_FILES";
const CLEAR_FILES = "CLEAR_FILES";
const UNREAD_FILES = "UNREAD_FILES";

export const FILE_RETAIN_STATE = "FILE_RETAIN_STATE";

export const filesLoading = () => ({
  type: FILES_LOADING,
});

export const filesFailed = (errmess) => ({
  type: FILES_FAILED,
  payload: errmess,
});

export function addFiles(files, moreFiles, lastDocId) {
  return {
    type: FETCH_FILES,
    payload: { files, moreFiles, lastDocId },
  };
}

export function clearFiles() {
  return {
    type: CLEAR_FILES,
  };
}

export function uploadFile(file) {
  return {
    type: UPLOAD_FILE,
    payload: file,
  };
}

export function manageFile(file) {
  return {
    type: MANAGE_FILE,
    payload: file,
  };
}

export function deleteFile(fileId) {
  return {
    type: DELETE_FILE,
    payload: fileId,
  };
}

export function unreadFile(count) {
  return {
    type: UNREAD_FILES,
    payload: count,
  };
}

const initialState = {
  isLoading: true,
  errMess: null,
  files: [],
  moreFiles: false,
  lastDocId: null,
  unreadFile: 0,
};

export default function clubFilesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FILES_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case FILES_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case UPLOAD_FILE:
      return {
        ...state,
        files: [payload, ...state.files],
      };
    case MANAGE_FILE:
      return {
        ...state,
        files: [...state.files.filter((evt) => evt.id !== payload.id), payload],
      };
    case DELETE_FILE:
      return {
        ...state,
        files: [...state.files.filter((evt) => evt.id !== payload)],
      };
    case FETCH_FILES:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        files: [...state.files, ...payload.files],
        moreFiles: payload.moreFiles,
        lastDocId: payload.lastDocId,
      };
    case CLEAR_FILES:
      return {
        ...state,
        files: [],
        moreFiles: true,
        lastDocId: null,
      };
    case UNREAD_FILES:
      return {
        ...state,
        unreadFile: payload,
      };
    default:
      return state;
  }
}
