const GROUP_CHAT_LOADING = "GROUP_CHAT_LOADING";
const GROUP_CHAT_FAILED = "GROUP_CHAT_FAILED";
const ADD_GROUP_CHAT = "ADD_GROUP_CHAT";
const SEND_GROUP_CHAT = "SEND_GROUP_CHAT";
const CLEAR_GROUP_CHAT = "CLEAR_GROUP_CHAT";
const GROUP_CHAT_RETAIN_STATE = "GROUP_CHAT_RETAIN_STATE";

export const groupChatLoading = () => ({
  type: GROUP_CHAT_LOADING,
});

export const groupChatFailed = (errmess) => ({
  type: GROUP_CHAT_FAILED,
  payload: errmess,
});

export const addgroupChats = (chats, moreChats, lastDocId) => ({
  type: ADD_GROUP_CHAT,
  payload: { chats, moreChats, lastDocId },
});

export function sendgroupChat(chat) {
  return {
    type: SEND_GROUP_CHAT,
    payload: chat,
  };
}

export function chatRetainState(parentChat) {
  return {
    type: GROUP_CHAT_RETAIN_STATE,
    payload: parentChat,
  };
}

export function cleargroupChats() {
  return {
    type: CLEAR_GROUP_CHAT,
  };
}

const initialState = {
  parentChatId: null,
  chats: [],
  isLoading: false,
  errMess: null,
  moreChats: true,
  lastDocId: null,
  retainState: false
};

export default function groupDiscussionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GROUP_CHAT_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case GROUP_CHAT_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case ADD_GROUP_CHAT:
      return {
        ...state,
        chats: [...state.chats, ...payload.chats],
        moreChats: payload.moreChats,
        lastDocId: payload.lastDocId,
        isLoading: false,
        errMess: null,
      };
    case SEND_GROUP_CHAT:
      return {
        ...state,
        chats: [payload,...state.chats],
      };
    case CLEAR_GROUP_CHAT:
      return {
        ...state,
        chats: [],
        moreChats: true,
        lastDocId: null,
      };
    case GROUP_CHAT_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
        parentChatId: payload,
      };
    default:
      return state;
  }
}
