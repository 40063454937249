import React from "react";
import { Link } from "react-router-dom";
import { Item } from "semantic-ui-react";
export default function FeaturedAdItem({ ad }) {
  var image = "https://react.semantic-ui.com/images/wireframe/image.png";
  var header = "Header";
  var meta = "Description";
  var link = "/clubs";
  if (ad.fest) {
    image = ad.fest.festImg;
    header = ad.fest.festName;
    meta = ad.fest.college.collegeName;
    link = `/club/fest/${ad.fest._id}`;
  } else if (ad.college) {
    image = ad.college.collegeImg;
    header = ad.college.collegeName;
    meta = ad.college.city?.address;
    link = `/college/${ad.college._id}`;
  } else if (ad.club) {
    image = ad.club.clubImg;
    header = ad.club.clubName;
    if (ad.club.learningXClub) {
      meta = "LearningX club";
    } else if (ad.club.college) {
      meta = ad.club.college.collegeName;
    } else {
      meta = "Private Club";
    }
    link = `/club/about/${ad.club._id}`;
  } else if (ad.event) {
    image = ad.event.eventImg;
    header = ad.event.eventTitle;
    meta = ad.event.college?.collegeName;
    link = `/events/${ad.event._id}`;
  }

  return (
    <Item>
      <Item.Image size="tiny" src={image} />

      <Item.Content>
        <Item.Header as={Link} to={link}>
          {header}
        </Item.Header>
        <Item.Meta>{meta}</Item.Meta>
        <Item.Description>{<p className="two-lines">{ad.description}</p>}</Item.Description>
      </Item.Content>
    </Item>
  );
}
