const SESSION_LOADING = "SESSION_LOADING";
const SESSION_FAILED = "SESSION_FAILED";
const CREATE_SESSION = "CREATE_SESSION";
const UPDATE_SESSION = "UPDATE_SESSION";
const DELETE_SESSION = "DELETE_SESSION";
const FETCH_SESSION = "FETCH_SESSION";
const CLEAR_SESSIONS = "CLEAR_SESSIONS";

export const SESSION_RETAIN_STATE = "SESSION_RETAIN_STATE";

export const sessionsLoading = () => ({
  type: SESSION_LOADING,
});

export const sessionsFailed = (errmess) => ({
  type: SESSION_FAILED,
  payload: errmess,
});

export function addSessions(upcomingSessions, recentlySessions) {
  return {
    type: FETCH_SESSION,
    payload: {
      upcomingSessions,
      recentlySessions,
    },
  };
}

export function createSession(session) {
  return {
    type: CREATE_SESSION,
    payload: session,
  };
}

export function updateSession(session) {
  return {
    type: UPDATE_SESSION,
    payload: session,
  };
}

export function deleteSession(id) {
  return {
    type: DELETE_SESSION,
    payload: id,
  };
}

export function clearSessions() {
  return {
    type: CLEAR_SESSIONS,
  };
}

const initialState = {
  isLoading: true,
  errMess: null,
  upcomingSessions: [],
  recentlySessions: [],
  retainState: false,
};

export default function clubSessionReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SESSION_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case SESSION_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case CREATE_SESSION:
      return {
        ...state,
        upcomingSessions: [payload, ...state.upcomingSessions],
      };
    case UPDATE_SESSION:
      return {
        ...state,
        upcomingSessions: [
          ...state.upcomingSessions.filter((evt) => evt._id !== payload._id),
          payload,
        ],
        recentlySessions: [
          ...state.recentlySessions.filter((evt) => evt._id !== payload._id),
        ],
      };
    case DELETE_SESSION:
      return {
        ...state,
        upcomingSessions: [
          ...state.upcomingSessions.filter((evt) => evt._id !== payload),
        ],
        recentlySessions: [
          ...state.recentlySessions.filter((evt) => evt._id !== payload),
        ],
      };
    case FETCH_SESSION:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        upcomingSessions: [
          ...state.upcomingSessions,
          ...payload.upcomingSessions,
        ],
        recentlySessions: [
          ...state.recentlySessions,
          ...payload.recentlySessions,
        ],
      };
    case CLEAR_SESSIONS:
      return {
        ...state,
        recentlySessions: [],
        upcomingSessions: [],
      };
    case SESSION_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
