import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Icon, Image, List, Segment } from "semantic-ui-react";
import { fetchClubsApi } from "../../../app/apiRequest/clubService";
import { fetchSinglePostApi } from "../../../app/apiRequest/postService";
import { openModal } from "../../../app/common/modals/modalReducer";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";

export default function ClubListModal() {
  const dispatch = useDispatch();
  const { yourClubs } = useSelector((state) => state.club);
  const { user } = useSelector((state) => state.auth);
  const { modalProps } = useSelector((state) => state.modal);

  useEffect(() => {
    if (yourClubs.length > 0) return;
    dispatch(fetchClubsApi(`?members=${user}`));
    // eslint-disable-next-line
  }, [dispatch, user]);

  var clubs = [];

  // eslint-disable-next-line
  yourClubs.map((club) => {
    if (club.admin.includes(user)) {
      clubs.push(club);
    }
  });

  const handleShareBtn = (club) => {
    dispatch(fetchSinglePostApi(modalProps)).then(() =>
      dispatch(
        openModal({
          modalType: "CreatePostForm",
          modalProps: {
            userId: club._id,
            displayName: club.clubName,
            displayImg: club.clubImg,
            todo: "share",
            postBy: "club",
            postId: modalProps,
          },
        })
      )
    );
  };

  return (
    <ModalWrapper size="mini" header="Select a club to share post">
      {clubs.length === 0 && (
        <p>You don't have your own club. First! create your club.</p>
      )}
      {clubs.length > 0 ? (
        clubs.map((club) => (
          <List divided relaxed key={club._id}>
            <List.Item onClick={() => handleShareBtn(club)}>
              <Image
                src={club.clubImg}
                size="mini"
                avatar
                verticalAlign="middle"
              />
              <List.Content>
                <List.Header as="a">{club.clubName}</List.Header>
                <List.Description as="a">{`${club.members.length} members`}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        ))
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="users" />
            You have not your own club.
          </Header>
          <Button
            onClick={() =>
              dispatch(
                openModal({
                  modalType: "CreateClubForm",
                  modalProps: { userId: user, todo: "create" },
                })
              )
            }
            primary
          >
            Create Club
          </Button>
        </Segment>
      )}
    </ModalWrapper>
  );
}
