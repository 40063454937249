import { toast } from "react-toastify";
import {
  addEvents,
  createEventComment,
  deleteEventComment,
  listenToEventChat,
  listenToRegisteredTeams,
  listenToSelectedEvent,
  listenToSelectedTeam,
  updateEventComment,
  updateRegisteredTeam,
} from "../../features/events/eventReducer";
import { fetchEventReminder } from "../../features/reminder/reminderReducer";
import { addEventsToSearch } from "../../features/search/searchReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const createEventApi = (event) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "events", {
    method: "POST",
    body: JSON.stringify(event),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedEvent(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => {
      console.log("Create Event ", error.message);
      alert("Your event could not be created\nError: " + error.message);
      dispatch(asyncActionError(error.message));
    });
};

export const fetchEventsApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "events" + query)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (query.includes("&stages.endDate[$gte]=")) {
        dispatch(fetchEventReminder(response.events));
      } else if (query.includes("search")) {
        dispatch(addEventsToSearch(response.events));
      } else {
        dispatch(
          addEvents({
            events: response.events,
            moreEvents: response.moreEvents,
            lastDocId: response.lastDocId,
          })
        );
      }
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const deleteEventApi = (eventId) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "events/" + eventId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("Event Deleted", response);
    })
    .catch((error) => console.log(error.message));
};

export const manageEventApi = (event) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "events/" + event._id, {
    method: "PUT",
    body: JSON.stringify(event),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(listenToSelectedEvent(response)))
    .catch((error) => {
      console.log("Event updated ", error.message);
      alert("Your event could not be updated\nError: " + error.message);
    });
};

export const fetchSingleEventApi = (eventId) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "events/" + eventId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response) dispatch(asyncActionError("Event not found!"));
      else {
        dispatch(listenToSelectedEvent(response));
        dispatch(asyncActionFinish());
      }
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError("URL doesn't exist!"));
    });
};

export const fetchRegisteredTeamsApi = (eventId, query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "events/" + eventId + "/teams" + query,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToRegisteredTeams(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const fetchSingleRegisteredTeamApi = (eventId, userId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "events/" +
      eventId +
      "/teams/" +
      userId,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (response.length > 0) dispatch(listenToSelectedTeam(response[0]));
      else dispatch(listenToSelectedTeam(null));
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const updateRegisteredTeamApi = (team, index) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "events/" +
      team.event +
      "/teams/" +
      team._id,
    {
      method: "PUT",
      body: JSON.stringify(team),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateRegisteredTeam(response, index)))
    .catch((error) => {
      console.log("Team updated ", error.message);
      alert("Your Team could not be updated\nError: " + error.message);
    });
};

export const registerTeamApi = (team, event) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "events/" + team.event + "/teams",
    {
      method: "POST",
      body: JSON.stringify(team),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      toast.success("Team registered!");
      dispatch(manageEventApi(event));
    })
    .catch((error) => {
      console.log("Create club ", error.message);
      alert("Your club could not be created\nError: " + error.message);
    });
};

export const createEventCommentApi = (comment) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL +
      "events/" +
      comment.event +
      "/comments",
    {
      method: "POST",
      body: JSON.stringify(comment),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(createEventComment(response)))
    .catch((error) => {
      console.log("Create club ", error.message);
      alert("Your club could not be created\nError: " + error.message);
    });
};

export const fetchEventCommentsApi = (eventId) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "events/" + eventId + "/comments"
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToEventChat(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const deleteEventCommentApi = (commentId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "events/comments/" + commentId,
    {
      method: "DELETE",
      headers: {
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("EventComment Deleted", response);
      dispatch(deleteEventComment(commentId));
    })
    .catch((error) => console.log(error.message));
};

export const updateEventCommentApi = (comment) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "events/comments/" + comment._id,
    {
      method: "PUT",
      body: JSON.stringify(comment),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateEventComment(response)))
    .catch((error) => {
      console.log("EventComment updated ", error.message);
      alert("Your EventComment could not be updated\nError: " + error.message);
    });
};
