import React from "react";
import { Button, Image, List, Search } from "semantic-ui-react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { useSelector, useDispatch } from "react-redux";
import { manageEventApi } from "../../../app/apiRequest/eventService";
import {
  updateCollegeApi,
  updateCollegeFestApi,
} from "../../../app/apiRequest/collegeFestService";
import {
  cleanSearchQuery,
  finishSearch,
  startSearch,
  updateSelection,
} from "../../search/searchReducer";
import { fetchProfilesApi } from "../../../app/apiRequest/profileService";
import { toast } from "react-toastify";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { asyncActionError } from "../../../app/async/asyncReducer";

export default function ManageAdmin() {
  const dispatch = useDispatch();
  const { admin, type, selected } = useSelector(
    (state) => state.modal.modalProps
  );
  const { loading, results, value } = useSelector((state) => state.search);

  const timeoutRef = React.useRef();

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch(startSearch(data.value));

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch(cleanSearchQuery());
          return;
        }

        dispatch(fetchProfilesApi(`?$text[$search]=${data.value}`))
          .then((response) => {
            var result = [];
            for (let i of response) {
              if (!admin.some((item) => item._id === i._id)) {
                result.push({
                  _id: i._id,
                  title: i.displayName,
                  description: i.user_name,
                  image: i.userImg,
                });
              }
            }
            dispatch(finishSearch(result));
          })
          .catch((error) => dispatch(asyncActionError(error.message)));

        // const re = new RegExp(_.escapeRegExp(data.value), "i");
        // const result = a.filter((el) => re.test(el.title));

        // dispatch(finishSearch(result));
      }, 300);
    },
    // eslint-disable-next-line
    [loading, dispatch]
  );

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMakeAdminBtn = (userId) => {
    if (type === "event") {
      dispatch(manageEventApi({ ...selected, admin: [...admin] })).then(() =>
        toast.success("Event has beent updated")
      );
    } else if (type === "college") {
      dispatch(updateCollegeApi({ ...selected, admin: [...admin] })).then(() =>
        toast.success("College has beent updated")
      );
    } else {
      dispatch(updateCollegeFestApi({ ...selected, admin: [...admin] })).then(
        () => toast.success("Fest has beent updated")
      );
    }
    dispatch(closeModal());
  };

  const handleRemoveAdminBtn = (userId) => {
    if (type === "event") {
      dispatch(
        manageEventApi({
          ...selected,
          admin: [...admin.filter((user) => user._id !== userId)],
        })
      ).then(() => toast.success("Event has beent updated"));
    } else if (type === "college") {
      dispatch(
        updateCollegeApi({
          ...selected,
          admin: [...admin.filter((user) => user._id !== userId)],
        })
      ).then(() => toast.success("College has beent updated"));
    } else {
      dispatch(
        updateCollegeFestApi({
          ...selected,
          admin: [...admin.filter((user) => user._id !== userId)],
        })
      ).then(() => toast.success("Fest has beent updated"));
    }
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="tiny" header="Manage Admin">
      <Search
        loading={loading}
        placeholder="type username..."
        onResultSelect={(e, data) => {
          dispatch(updateSelection(data.result.title));
          if (admin[0].isNotAdmin) admin.shift();
          admin.unshift({
            _id: data.result._id,
            displayName: data.result.title,
            userImg: data.result.image,
            user_name: data.result.description,
            isNotAdmin: true,
          });
        }}
        onSearchChange={handleSearchChange}
        results={results}
        value={value}
        fluid
      />
      {admin?.length > 0 &&
        admin.map((user) => (
          <List key={user._id} selection>
            <List.Item>
              <Image avatar src={user.userImg} />
              <List.Content>
                <List.Header>{user.displayName}</List.Header>
                {user.user_name}
              </List.Content>
              {user.isNotAdmin && (
                <Button
                  icon="add user"
                  content="make Admin"
                  color="blue"
                  style={{ marginLeft: 32 }}
                  onClick={() => handleMakeAdminBtn(user._id)}
                />
              )}
              {selected.admin.length > 2 && !user.isNotAdmin && (
                <Button
                  icon="remove user"
                  content="remove Admin"
                  color="red"
                  style={{ marginLeft: 32 }}
                  onClick={() => handleRemoveAdminBtn(user._id)}
                />
              )}
            </List.Item>
          </List>
        ))}
    </ModalWrapper>
  );
}
