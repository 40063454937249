import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Segment, Header, Comment } from "semantic-ui-react";
import EventDetailedChatForm from "./EventDetailedChatForm";
import { createDataTree } from "../../../app/common/util/util";
import { formatDistance } from "date-fns";
import displayLinks from "../../../app/common/text/handleLinkClick";

export default function EventDetailedChat({ eventId }) {
  const { comments } = useSelector((state) => state.event);
  const {isAuthenticated} = useSelector((state) => state.auth);
  const [showReplyForm, setShowReplyForm] = useState({
    open: false,
    commentId: null,
  });

  function handleCloseReplyForm() {
    setShowReplyForm({ open: false, commentId: null });
  }

  return (
    <>
      <Segment
        textAlign="center"
        attached="top"
        inverted
        color="teal"
        style={{ border: "none" }}
      >
        <Header>{isAuthenticated ? 'Chat about this event' : 'Sign in to view and comment'}</Header>
      </Segment>
      {isAuthenticated && 
      <Segment attached>
        <EventDetailedChatForm eventId={eventId} parentCommentId={null} closeForm={setShowReplyForm} />
        <Comment.Group style={{marginBottom : 40}}>
          {createDataTree(comments).map((comment) => (
            <Comment key={comment._id}>
              <Comment.Avatar src={comment.user.userImg || "/assets/user.png"} />
              <Comment.Content>
                <Comment.Author as={Link} to={`/profile/${comment.user._id}`}>
                  {comment.user.displayName}
                </Comment.Author>
                <Comment.Metadata>
                  <div>{formatDistance(new Date(comment.createdAt), new Date(), { addSuffix: true })}</div>
                </Comment.Metadata>
                <Comment.Text>
                  {comment.comment.split("\n").map((comment, i) => (
                    <span key={i}>
                      {displayLinks(comment)}
                    </span>
                  ))}
                </Comment.Text>
                <Comment.Actions>
                  <Comment.Action
                    onClick={() =>
                      setShowReplyForm({ open: true, commentId: comment._id })
                    }
                  >
                    Reply
                  </Comment.Action>
                  {showReplyForm.open &&
                    showReplyForm.commentId === comment._id && (
                      <EventDetailedChatForm
                        eventId={eventId}
                        parentCommentId={comment._id}
                        closeForm={handleCloseReplyForm}
                      />
                    )}
                </Comment.Actions>
              </Comment.Content>
              {comment.childNodes.length > 0 && (
                <Comment.Group>
                  {comment.childNodes.reverse().map((child) => (
                    <Comment key={child._id}>
                      <Comment.Avatar
                        src={child.user.userImg || "/assets/user.png"}
                      />
                      <Comment.Content>
                        <Comment.Author as={Link} to={`/profile/${child.user._id}`}>
                          {child.user.displayName}
                        </Comment.Author>
                        <Comment.Metadata>
                          <div>{formatDistance(new Date(child.createdAt), new Date(), { addSuffix: true })}</div>
                        </Comment.Metadata>
                        <Comment.Text>
                          {child.comment.split("\n").map((comment, i) => (
                            <span key={i}>
                              {displayLinks(comment)}
                            </span>
                          ))}
                        </Comment.Text>
                        <Comment.Actions>
                          <Comment.Action
                            onClick={() =>
                              setShowReplyForm({
                                open: true,
                                commentId: child._id,
                              })
                            }
                          >
                            Reply
                          </Comment.Action>
                          {showReplyForm.open &&
                            showReplyForm.commentId === child._id && (
                              <EventDetailedChatForm
                                eventId={eventId}
                                parentCommentId={child.parentCommentId}
                                closeForm={handleCloseReplyForm}
                              />
                            )}
                        </Comment.Actions>
                      </Comment.Content>
                    </Comment>
                  ))}
                </Comment.Group>
              )}
            </Comment>
          ))}
        </Comment.Group>
      </Segment>}
    </>
  );
}
