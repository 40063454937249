import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Popup, Feed } from "semantic-ui-react";
import {
  deleteChannelMemberApi,
  manageMembersApi,
} from "../../../../app/apiRequest/channelService";

export default function ChannelMemberItem({ member, adminCount }) {
  const history = useHistory();
  const [openPopup, setOpenPopup] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { selectedChannel } = useSelector((state) => state.club);

  const isAdmin = selectedChannel?.admin.includes(user);

  const handleMember = (admin, decline) => {
    if (decline) {
      deleteChannelMemberApi(member.channel, member.user._id).then(() => {
        window.location.reload();
      });
    } else {
      manageMembersApi({
        ...member,
        admin: admin,
      }).then(() => {
        window.location.reload();
      });
    }
    setOpenPopup(false);
  };

  return (
    <>
      <Feed style={{ margin: 12 }}>
        <Feed.Event>
          <Feed.Label>
            <img src={member.user.userImg} alt="" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              <Feed.User
                as={"a"}
                onClick={() => history.push(`/profile/${member.user._id}`)}
              >
                {member.user.displayName}
              </Feed.User>
              <Feed.Date>
                {isAdmin && (
                  <Popup
                    trigger={
                      <Button
                        icon="ellipsis horizontal"
                        size="mini"
                        style={{ marginLeft: 16, marginTop: -16 }}
                      />
                    }
                    content={
                      <div>
                        {member.admin && adminCount > 1 && (
                          <div>
                            <Button
                              fluid
                              content="remove from admin"
                              onClick={() => handleMember(false, false)}
                              style={{ display: "block", marginBottom: 5 }}
                            />
                          </div>
                        )}
                        {member.active && !member.admin && (
                          <div>
                            <Button
                              fluid
                              content="make admin"
                              onClick={() => handleMember(true, false)}
                              style={{ display: "block", marginBottom: 5 }}
                            />
                            <Button
                              fluid
                              content="remove from channel"
                              onClick={() => handleMember(false, true)}
                              style={{ display: "block" }}
                            />
                          </div>
                        )}
                      </div>
                    }
                    on="click"
                    position="right center"
                    open={openPopup}
                    onClose={() => setOpenPopup(false)}
                    onOpen={() => setOpenPopup(true)}
                  />
                )}
              </Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </>
  );
}
