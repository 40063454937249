import React from "react";

export default function ExplorePage() {
  return (
    <div className="about">
      <div class="top boxes">
        <div class="box b1">
          <img class="abtimg" src="images/1.png" alt="" />
          <p class="txt">Find all college clubs and events in one place.</p>
        </div>
        <div class="box b2">
          <img class="abtimg" src="images/2.png" alt="" />
          <p class="txt">Join or create clubs and events effortlessly.</p>
        </div>
        <div class="box b3">
          <img class="abtimg" src="images/3.png" alt="" />
          <p class="txt">Explore your interest areas through clubs.</p>
        </div>
      </div>

      <div class="bottom boxes">
        <div class="box b4">
          <img class="abtimg" src="images/4.png" alt="" />
          <p class="txt">
            Promote your club events across the college and to external
            colleges.
          </p>
        </div>
        <div class="box b5">
          <img class="abtimg" src="images/5.png" alt="" />
          <p class="txt">Share your club activities on the feed.</p>
        </div>
      </div>
    </div>
  );
}
