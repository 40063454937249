import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { fetchSingleCollegeFestApi } from "../../../app/apiRequest/collegeFestService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import EventDashboard from "../../events/eventDashboard/EventDashboard";
import FestivalHeader from "./FestivalHeader";
import { setEventFilter } from "../../events/eventReducer";
export default function FestivalPage({ match }) {
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(null);
  const { selectedFest } = useSelector((state) => state.college);
  const { loading, error } = useSelector((state) => state.async);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchSingleCollegeFestApi(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (selectedFest) {
      var query = "?stepsDone=6&festival=" + match.params.id;
      if (selectedFest.admin.some((item) => item._id === user)) {
        query = "?festival=" + match.params.id;
      }
      setActiveItem("events");
    }
    dispatch(setEventFilter(query));
  }, [dispatch, match.params.id, selectedFest, user]);

  if ((loading && !selectedFest) || (!selectedFest && !error))
    return <LoadingComponent content="Loading fest..." />;

  return (
    <>
      <div style={{ marginRight: 20 }}>
        <FestivalHeader
          fest={selectedFest}
          isAdmin={selectedFest.admin.some((item) => item._id === user)}
          isFollower={selectedFest.followedBy.includes(user)}
        />
        <Menu pointing secondary>
          <Menu.Item name="events" active={true} />
        </Menu>
        {activeItem === "events" && (
          <div style={{ marginTop: 10 }}>
            <EventDashboard isFeed={false} />
          </div>
        )}
      </div>
    </>
  );
}
