import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Header } from "semantic-ui-react";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClubNavMenu from "../ClubNavMenu";
import displayLinks from "../../../../app/common/text/handleLinkClick";

export default function ClubfaqsPage({ match }) {
  const dispatch = useDispatch();
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);
  const [panels, setPenals] = useState([]);

  useEffect(() => {
    if (match.params.id === selectedClub?._id) return;
    dispatch(fetchSingleClubApi(match.params.id));
  }, [dispatch, match.params.id, selectedClub]);

  useEffect(() => {
    if (selectedClub) {
      selectedClub.faqs.map((faq) =>
        setPenals((oldArray) => [
          ...oldArray,
          {
            key: faq._id,
            title: faq.question,
            content: displayLinks(faq.answer),
          },
        ])
      );
    }
  }, [selectedClub]);

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  return (
    <div className="club-detailed-page">
      <ClubNavMenu club={selectedClub} />
      <div>
        <Header as="h1">Some important frequently asked questions</Header>
        <Accordion
          panels={panels}
          defaultActiveIndex={[0]}
          exclusive={false}
          fluid
        />
      </div>
    </div>
  );
}
