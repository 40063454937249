const CREATE_COLLEGE = "CREATE_COLLEGE";
const LISTEN_TO_COLLEGE = "LISTEN_TO_COLLEGE";
const CLEAR_SELECTED_COLLEGE = "CLEAR_SELECTED_COLLEGE";
const UPDATE_COLLEGE = "UPDATE_COLLEGE";
const CREATE_FEST = "CREATE_FEST";
const LISTEN_TO_FEST = "LISTEN_TO_FEST";
const CLEAR_SELECTED_FEST = "CLEAR_SELECTED_FEST";
const FETCH_FESTS = "FETCH_FESTS";
const UPDATE_FEST = "UPDATE_FEST";
const DELETE_FEST = "DELETE_FEST";

export const FOLLOW_FEST = "FOLLOW_FEST";
export const UNFOLLOW_FEST = "UNFOLLOW_FEST";
export const CLEAR_FESTS = "CLEAR_FESTS";

export function createCollege(college) {
  return {
    type: CREATE_COLLEGE,
    payload: college,
  };
}

export function listenToCollege(college) {
  return {
    type: LISTEN_TO_COLLEGE,
    payload: college,
  };
}

export function clearSelectedCollege() {
  return {
    type: CLEAR_SELECTED_COLLEGE,
  };
}

export function updateCollege(college) {
  return {
    type: UPDATE_COLLEGE,
    payload: college,
  };
}

export function createFest(fest) {
  return {
    type: CREATE_FEST,
    payload: fest,
  };
}

export function listenToFest(fest) {
  return {
    type: LISTEN_TO_FEST,
    payload: fest,
  };
}

export function clearSelectedFest() {
  return {
    type: CLEAR_SELECTED_FEST,
  };
}

export function fetchCollegeFests(fests) {
  return {
    type: FETCH_FESTS,
    payload: fests,
  };
}

export function updateFest(fest) {
  return {
    type: UPDATE_FEST,
    payload: fest,
  };
}

export function deleteFest(festId) {
  return {
    type: DELETE_FEST,
    payload: festId,
  };
}

const initialState = {
  selectedCollege: null,
  selectedFest: null,
  selectedCity: null,
  selectedFestIsFollowed: false,
  fests: [],
};

export default function collegeFestReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_COLLEGE:
      return {
        ...state,
        selectedCollege: payload,
      };
    case LISTEN_TO_COLLEGE:
      return {
        ...state,
        selectedCollege: payload,
      };
    case CLEAR_SELECTED_COLLEGE:
      return {
        ...state,
        selectedCollege: null,
      };
    case UPDATE_COLLEGE:
      return {
        ...state,
        selectedCollege: payload,
      };
    case CREATE_FEST:
      return {
        ...state,
        fests: [payload, ...state.fests],
      };
    case LISTEN_TO_FEST:
      return {
        ...state,
        selectedFest: payload,
      };
    case CLEAR_SELECTED_FEST:
      return {
        ...state,
        selectedFest: null,
      };
    case FETCH_FESTS:
      return {
        ...state,
        fests: payload,
      };
    case CLEAR_FESTS:
      return {
        ...state,
        fests: [],
      };
    case UPDATE_FEST:
      return {
        ...state,
        selectedFest: payload,
      };
    case DELETE_FEST:
      return {
        ...state,
        fests: [...state.fests.filter((fest) => fest.festId !== payload)],
      };
    case FOLLOW_FEST:
      return {
        ...state,
        selectedFestIsFollowed: true,
      };
    case UNFOLLOW_FEST:
      return {
        ...state,
        selectedFestIsFollowed: false,
      };
    default: {
      return state;
    }
  }
}
