import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, FormRadio, Image, Label } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import PhotoCropper from "../../../app/common/photos/PhotoCropper";
import { getFile, isImage } from "../../../app/common/util/util";
import { toast } from "react-toastify";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { closeModal, openModal } from "../../../app/common/modals/modalReducer";
import { clubCategoryData } from "../../../app/api/clubArrays";
import { createClubApi } from "../../../app/apiRequest/clubService";
import { uploadFileApi } from "../../../app/apiRequest/extraService";
import { useSelector } from "react-redux";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../app/async/asyncReducer";
import { compressImg } from "../../../app/common/photos/compressImg";

export default function CreateClubForm() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
  const hiddenFileInput = useRef(null);
  const { college } = useSelector((state) => state.modal.modalProps);
  const [radioValue, setRadioValue] = useState({ name: "private" });
  const [announcementRadio, setAnnouncementRadio] = useState({ name: "true" });
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { loading } = useSelector((state) => state.async);

  const initialValues = {
    clubName: "",
    clubImg:
      "https://learningx-s3.s3.ap-south-1.amazonaws.com/CvW3AqVxR-image.png",
    category: "",
    email: "",
    website: "",
  };

  const validationSchema = Yup.object({
    clubName: Yup.string().required(),
    category: Yup.string().required(),
    email: Yup.string().email().required(),
  });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [filename, setFilename] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    console.log(URL.createObjectURL(file));
    if (isImage(file.name)) {
      setFiles(URL.createObjectURL(file));
      setFilename(file.name);
      setPhotoCropperOpen(true);
    } else {
      toast.error("select images or video");
    }
  };

  const handleRadioChange = (e, { name }) => {
    setRadioValue({ name });
  };

  const handleAnnouncementRadioChange = (e, { name }) => {
    setAnnouncementRadio({ name });
  };

  return (
    <ModalWrapper size="small" header="Create a Club">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (announcementRadio.name === "true")
              values.isAdminChannelRequired = true;
            else values.isAdminChannelRequired = false;
            if (college) {
              values.college = college;
              values.college_status = "verified";
              values.privacy = "public";
            } else {
              values.privacy = radioValue.name;
              if (values.privacy === "public") {
                values.college = currentUserProfile.college?._id;
              }
            }
            if (images.length > 0) {
              var data = new FormData();
              await compressImg(images[images.length - 1]).then(
                async (compressedDataUrl) => {
                  let file = await getFile(compressedDataUrl, filename);
                  data.append("imageFile", file);
                }
              );
              await dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                values.clubImg = response[0].location;
                dispatch(createClubApi(values)).then(() => {
                  dispatch(closeModal());
                  dispatch(openModal({ modalType: "ClubDescriptionForm" }));
                });
              });
            } else {
              await dispatch(createClubApi(values)).then(() => {
                dispatch(closeModal());
                dispatch(openModal({ modalType: "ClubDescriptionForm" }));
              });
            }
          } catch (error) {
            dispatch(asyncActionError(error));
            setErrors({ error });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <Image
              style={{ width: 150, height: 150 }}
              src={
                images.length > 0 ? images[images.length - 1] : values.clubImg
              }
              floated="left"
            />
            <Button
              icon="file image"
              content="Add club image"
              color="blue"
              onClick={handleClick}
              style={{ marginTop: 50 }}
              type="button"
            />
            <p>Image dimensions:- (1 : 1)</p>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
            <MyTextInput
              name="clubName"
              placeholder="Club name"
              label={"Club Name"}
            />
            <MySelectInput
              name="category"
              placeholder="College Club"
              options={clubCategoryData}
              label={"Club Category"}
            />
            {!college &&
              currentUserProfile.college &&
              currentUserProfile.college?._id !==
                process.env.REACT_APP_OTHER_COLLEGE_ID && (
                <FormGroup inline>
                  <label>Do you want to add to college page</label>
                  <FormRadio
                    label="Yes"
                    checked={radioValue.name === "public"}
                    onChange={handleRadioChange}
                    name="public"
                  />
                  <FormRadio
                    label="No"
                    checked={radioValue.name === "private"}
                    onChange={handleRadioChange}
                    name="private"
                  />
                </FormGroup>
              )}
            <p>* Announcement channel - where only admin can text</p>
            <FormGroup inline>
              <label>Do you want to an Announcement channel</label>
              <FormRadio
                label="Yes"
                checked={announcementRadio.name === "true"}
                onChange={handleAnnouncementRadioChange}
                name="true"
              />
              <FormRadio
                label="No"
                checked={announcementRadio.name === "false"}
                onChange={handleAnnouncementRadioChange}
                name="false"
              />
            </FormGroup>
            {/* {!college && (
              <div>
                <p>
                  <strong>Select College </strong>(*if not found your college
                  then select 'other')
                </p>
                <Dropdown
                  name="college"
                  placeholder="Select College"
                  onChange={(e, { value }) => {
                    values.college = value;
                    setCollegeId(value);
                  }}
                  fluid
                  search
                  selection
                  options={collegeListArrays}
                  style={{ marginTop: -10, marginBottom: 10 }}
                />
              </div>
            )} */}
            <MyTextInput
              name="email"
              placeholder="Contact email"
              label={"Contact Email"}
            />
            <MyTextInput
              name="website"
              placeholder="Website"
              label={"Website"}
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <div>
              <Button
                loading={isSubmitting || loading}
                disabled={!isValid || !dirty || isSubmitting || loading}
                type="submit"
                floated="right"
                color="teal"
                content="Next"
                style={{ margin: 10 }}
              />
              <Button
                content="Cancel"
                floated="right"
                type="button"
                style={{ margin: 10 }}
                onClick={() => dispatch(closeModal())}
              />
            </div>
          </Form>
        )}
      </Formik>
      <PhotoCropper
        photoCropperOpen={photoCropperOpen}
        setPhotoCropperOpen={setPhotoCropperOpen}
        images={images}
        setImages={setImages}
        imagePreview={files}
        imageNo={0}
        editing={false}
        aspectRatio={1 / 1}
      />
    </ModalWrapper>
  );
}
