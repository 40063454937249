import React from "react";
import { hydrate, render } from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "video-react/dist/video-react.css";
import "react-calendar/dist/Calendar.css";
import "./app/layout/styles.css";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { ConfigureStore, history } from "./app/store/configureStore";

const store = ConfigureStore();

const rootEl = document.getElementById("root");

const APP = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

function rootRender() {
  if (rootEl.hasChildNodes()) {
    hydrate(APP, rootEl);
  } else {
    render(APP, rootEl);
  }
}

if (module.hot) {
  module.hot.accept("./app/layout/App", function () {
    setTimeout(render);
  });
}

rootRender();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
