import { toast } from "react-toastify";
import {
  listenToCurrentUserProfile,
  listenToSelectedUserProfile,
} from "../../features/profile/profileReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";
import { logoutUser } from "../../features/auth/authReducer";
import { closeModal } from "../common/modals/modalReducer";

export const fetchProfilesApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "users" + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json());
};

export const fetchUserProfileApi = (userId) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "profile/" + userId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(listenToSelectedUserProfile(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError(error.message));
    });
};

export const updateUserProfileApi = (profile) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  dispatch(asyncActionStart());
  return fetch(process.env.REACT_APP_API_BASE_URL + "profile/" + profile._id, {
    method: "PUT",
    body: JSON.stringify(profile),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToSelectedUserProfile(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => {
      console.log("Profile ", error.message);
      toast.error("Your profile could not be updated\nError: " + error.message);
      asyncActionError(error.message);
    });
};

export const fetchUserApi = (userId) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "users/" + userId, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(listenToCurrentUserProfile(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError(error.message));
    });
};

export const updateUserApi = (profile) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  dispatch(asyncActionStart());
  return fetch(
    process.env.REACT_APP_API_BASE_URL + "users/update/" + profile._id,
    {
      method: "PUT",
      body: JSON.stringify(profile),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(asyncActionFinish());
      dispatch(listenToCurrentUserProfile(response));
    })
    .catch((error) => {
      console.log("Profile ", error.message);
      toast.error("Your profile could not be updated\nError: " + error.message);
      asyncActionError(error.message);
    });
};

export const closeAccountApi = (profileId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "profile/" + profileId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(logoutUser());
      toast.success("Account Closed!");
    })
    .catch((error) => {
      console.log(error.message);
      toast.error("Something went wrong!");
    });
};

export const activateAccountApi = (user) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  dispatch(asyncActionStart());
  return fetch(
    process.env.REACT_APP_API_BASE_URL + "users/activate/" + user._id,
    {
      method: "PUT",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      credentials: "same-origin",
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(asyncActionFinish());
      dispatch(listenToCurrentUserProfile(response));
      dispatch(closeModal());
      toast.success("Account Activated!");
    })
    .catch((error) => {
      console.log("Profile ", error.message);
      toast.error("Something went wrong");
      asyncActionError(error.message);
    });
};
