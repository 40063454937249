import React from "react";
import { useDispatch } from "react-redux";
import { Icon, TableRow, TableCell } from "semantic-ui-react";
import { openModal } from "../../../../app/common/modals/modalReducer";
import { formatBytes } from "../../../../app/common/util/util";

export default function ClubFilesListItem({ file }) {
  const dispatch = useDispatch();
  var icon = file.filetype;
  if (file.filetype === "link") icon = "linkify";
  const handleCellClick = () => {
    if (file.filetype === "image") {
      dispatch(
        openModal({
          modalType: "FullSizeImgModal",
          modalProps: { id: 0, images: [file.filesLink] },
        })
      );
    } else if (file.filetype === "video") {
      dispatch(
        openModal({
          modalType: "FullSizeImgModal",
          modalProps: { video: file.filesLink },
        })
      );
    }
  };

  const handleCellHover = (event) => {
    // Add CSS styles or class to change appearance on hover
    event.currentTarget.classList.add("hovered");
  };

  const handleCellLeave = (event) => {
    // Remove CSS styles or class when hover ends
    event.currentTarget.classList.remove("hovered");
  };
  return (
    <>
      <TableRow>
        <TableCell>
          <Icon name={icon} />
        </TableCell>
        {file.filetype === "file" && (
          <TableCell>
            <a href={file.filesLink} target="_blank" rel="noopener noreferrer">
              {file.filename}
            </a>
          </TableCell>
        )}
        {file.filetype === "link" && (
          <TableCell>
            <a href={file.filesLink} target="_blank" rel="noopener noreferrer">
              {file.filesLink}
            </a>
          </TableCell>
        )}
        {(file.filetype === "image" || file.filetype === "video") && (
          <TableCell
            onClick={handleCellClick}
            onMouseEnter={handleCellHover}
            onMouseLeave={handleCellLeave}
            className="hoverable-cell" // Add a class for styling
          >
            {file.filename}
          </TableCell>
        )}
        <TableCell>
          {formatBytes(file.filesize)} {file.realFiletype}
        </TableCell>
        <TableCell>{new Date(file.createdAt).toLocaleString()}</TableCell>
        <TableCell>{file.user.displayName}</TableCell>
      </TableRow>
    </>
  );
}
