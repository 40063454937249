import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Menu, Segment } from "semantic-ui-react";
import { fetchUserProfileApi } from "../../../app/apiRequest/profileService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import FeaturedFeed from "../../common/FeaturedFeed";
import ProfileHeader from "./ProfileHeader";
import { openModal } from "../../../app/common/modals/modalReducer";
import { Redirect } from "react-router-dom";
import ClubListItem from "../../club/clubDashboard/ClubListItem";
import EventDashboard from "../../events/eventDashboard/EventDashboard";
import { setEventFilter } from "../../events/eventReducer";
import { fetchClubsApi } from "../../../app/apiRequest/clubService";
import { fetchChatRoomApi } from "../../../app/apiRequest/chatService";
import { Helmet } from "react-helmet";
import cuid from "cuid";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function ProfilePage({ match, location }) {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState("clubs");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { selectedUserProfile, currentUserProfile } = useSelector(
    (state) => state.profile
  );
  const { loading, error } = useSelector((state) => state.async);
  const { clubs, yourClubs } = useSelector((state) => state.club);

  useEffect(() => {
    dispatch(fetchUserProfileApi(match.params.id));
  }, [dispatch, match.params.id, user]);

  useEffect(() => {
    if (activeItem !== "clubs") return;
    if (isCurrentUser && yourClubs.length > 0) return;
    dispatch(fetchClubsApi("?members=" + match.params.id));
    // eslint-disable-next-line
  }, [dispatch, activeItem, match.params.id]);

  let isCurrentUser;
  if (match.params.id === user) {
    isCurrentUser = true;
  } else {
    isCurrentUser = false;
  }

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    if (name === "events" && isCurrentUser) {
      dispatch(
        setEventFilter(
          `?$or[0][registerdTeamLead]=${match.params.id}&$or[1][admin]=${match.params.id}`
        )
      );
    } else if (name === "events" && !isCurrentUser) {
      dispatch(
        setEventFilter(
          `?stepsDone=6&$or[0][registerdTeamLead]=${match.params.id}&$or[1][admin]=${match.params.id}`
        )
      );
    }
  };

  const handleMessageBtn = () => {
    const randomString = cuid();
    const chatRoom = {
      _id: randomString,
      users: [currentUserProfile, selectedUserProfile?.user],
      lastChat: "",
      newChat: true,
      unreadCount: 0,
      blockedBy: [],
    };
    dispatch(fetchChatRoomApi(match.params.id, chatRoom)).then(() => {
      history.push(`/chats/${match.params.id}`);
      // window.location = `/chats/${match.params.id}`;
    });
  };

  if ((loading && !selectedUserProfile) || (!selectedUserProfile && !error))
    return <LoadingComponent content="Loading profile..." />;

  if (error) return <Redirect to={"/error"} />;

  return (
    <>
      <Grid>
        <Grid.Column width={9}>
          <ProfileHeader profile={selectedUserProfile} />
          {isCurrentUser ? (
            <div>
              <Button
                content="Edit profile"
                style={{ marginTop: 20 }}
                icon="edit"
                color="blue"
                onClick={() => {
                  dispatch(openModal({ modalType: "EditProfileForm" }));
                }}
              />
              {currentUserProfile?.admin && (
                <Button
                  content="Create College"
                  style={{ marginTop: 20 }}
                  icon="add"
                  color="blue"
                  onClick={() =>
                    dispatch(
                      openModal({ modalType: "CollegeForm", modalProps: null })
                    )
                  }
                />
              )}
            </div>
          ) : (
            <div>
              {!selectedUserProfile?.blockedUser.includes(user) && (
                <Button
                  content="Message"
                  icon="chat"
                  style={{ marginTop: 20 }}
                  color="blue"
                  onClick={handleMessageBtn}
                />
              )}
              <Button
                icon="exclamation triangle"
                style={{ marginTop: 20 }}
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "ReportForm",
                      modalProps: {
                        type: "profile",
                        id: match.params.id,
                      },
                    })
                  );
                }}
              />
            </div>
          )}

          <Segment vertical>
            <div style={{ marginTop: 20 }}>
              <Menu pointing secondary>
                <Menu.Item
                  name="clubs"
                  active={activeItem === "clubs"}
                  onClick={handleItemClick}
                />
                <Menu.Item
                  name="events"
                  active={activeItem === "events"}
                  onClick={handleItemClick}
                />
              </Menu>
            </div>
            {activeItem === "clubs" && (
              <div style={{ marginTop: 10 }}>
                <div className="card-container">
                  {isCurrentUser
                    ? yourClubs.map((club) => (
                        <ClubListItem club={club} key={club._id} />
                      ))
                    : clubs.map((club) => (
                        <ClubListItem club={club} key={club._id} />
                      ))}
                </div>
              </div>
            )}
            {activeItem === "events" && (
              <div style={{ marginTop: 10, marginLeft: -10 }}>
                <EventDashboard isFeed={false} />
              </div>
            )}
          </Segment>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={5}>
          <FeaturedFeed userId={user} />
        </Grid.Column>
      </Grid>
      <Helmet>
        <title>{selectedUserProfile?.user.displayName}</title>
        <meta name="description" content={selectedUserProfile?.bio} />
        <meta
          property="og:title"
          content={selectedUserProfile?.user.displayName}
        />
        <meta property="og:description" content={selectedUserProfile?.bio} />
        <meta property="og:image" content={selectedUserProfile?.user.userImg} />
      </Helmet>
    </>
  );
}
