import React from "react";
import NotificationListItem from "./NotificationListItem";
import InfiniteScroll from "react-infinite-scroller";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function NotificationList({
  notifications,
  getNextNotifications,
  loading,
  moreNotification,
}) {
  return (
    <>
      {notifications.length !== 0 ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextNotifications}
          hasMore={!loading && moreNotification}
          initialLoad={false}
        >
          {notifications.map((notification) => (
            <NotificationListItem
              notification={notification}
              key={notification._id}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell outline" />
            You have no notification.
          </Header>
          <Button as={Link} to='/clubs' primary>Go to Home Page</Button>
        </Segment>
      )}
    </>
  );
}
