import React from "react";
import { Header, HeaderContent, Icon } from "semantic-ui-react";
import FooterPage from "./FooterPage";
import ExplorePage from "../explore/ExplorePage";
import { useSelector } from "react-redux";

export default function AboutUsPage() {
  const { isMobileDevice } = useSelector((state) => state.auth);
  return (
    <div>
      <div style={{ marginTop: 60, marginLeft: isMobileDevice ? 0 : 120 }}>
        <div className="first-div">
          <h1 className="heading">
            Helping Students to Stay connected
            <br />
            with their academic clubs and events.
          </h1>
          <div className="icons">
            <div className="community">
              <Header as="h2" icon textAlign="center">
                <Icon name="users" circular />
                <HeaderContent>2,000 Community members</HeaderContent>
              </Header>
            </div>
            <div className="community">
              <Header as="h2" icon textAlign="center">
                <Icon name="university" circular />
                <HeaderContent>
                  20+
                  <br />
                  Schools and Colleges
                </HeaderContent>
              </Header>
            </div>
            <div className="community">
              <Header as="h2" icon textAlign="center">
                <Icon name="handshake" circular />
                <HeaderContent>2000+ Connections</HeaderContent>
              </Header>
            </div>
            <div className="community">
              <Header as="h2" icon textAlign="center">
                <Icon name="calendar check" circular />
                <HeaderContent>
                  100+
                  <br />
                  Events Organised
                </HeaderContent>
              </Header>
            </div>
          </div>
        </div>
        <div className="company">
          <h3 style={{ textAlign: "center" }}>Company Overview:</h3>
          <div className="company-overview">
            Club-Chat is an innovative platform designed to revolutionize how
            students discover, join, and manage academic organizations and
            events during their college years. Our mission is to enhance student
            productivity and engagement, ensuring they make the most of their
            academic journey by participating in various clubs and events that
            align with their interests and career goals.
          </div>
        </div>
        <ExplorePage />
        <h2 style={{ textAlign: "center" }}>Why Club-Chat?</h2>
        <div className="whylearningx">
          <div className="img1">
            <img src={"/images/Rectangle 8.png"} alt="" width="350px" />
          </div>
          <div className="make-connections">
            <h3>Make Connections</h3>
            Connect with Students from different Colleges
            <br />
            <br />
            <div className="text1">
              Connecting with students from different colleges has many
              benefits. It helps you expand your network and build relationships
              with people from diverse backgrounds and perspectives, which is
              valuable for career or further education opportunities.
            </div>
          </div>
        </div>
        <div className="live-sessions">
          <div className="live">
            <h3>Live Sessions</h3>
            Join daily sessions on our club or You can also give live sessions
            <div className="text2">
              <br />
              <li>
                Collaboration Opportunities:Live sessions allow for real-time
                teamwork and problem-solving.
              </li>
              <br />
              <li>
                Better Communication:Seeing and hearing each other in real-time
                fosters a sense of community,especially for virtual or
                geographically dispersed clubs.
              </li>
            </div>
          </div>
          <div className="img2">
            <img src={"/images/Rectangle 7.png"} alt="" width="350px" />
          </div>
        </div>

        <div className="whylearningx">
          <div className="img1">
            <img src={"/images/Rectangle 9.png"} alt="" width="350px" />
          </div>
          <div className="earn-rewards">
            <h3>Earn rewards</h3>
            <div className="text3">
              Participating in competitions from colleges or companies is a
              great way to challenge yourself and test your skills.These
              competitions can be academic,bussiness,design,or creative
              contests. Prizes can include
              cash,scholarships,recognition,professional development
              opportunities,or access to resources and mentors.
            </div>
          </div>
        </div>

        <div className="live-sessions">
          <div className="create-club">
            <h3>Create Your Club</h3>
            Join or create your college club.
            <br />
            <div className="text2">
              <br />
              Club-Chat offers a "Create Your Club" program,allowing individuals
              to start and manage their own clubs or organizations.Available
              through colleges,community centers,or other groups,this program
              provides resources and support to help with planning,
              promoting,and running clubs.Support includes access to meeting
              spaces,funding,and guidance on leadership and management.The aim
              is to empower individuals to lead and create activities that build
              community and foster a sense of belonging.
            </div>
          </div>
          <div className="img2">
            <img src={"/images/Rectangle 4.png"} alt="" width="350px" />
          </div>
        </div>
        <div className="whylearningx">
          <div className="img5">
            <img src={"/images/image 35.png"} alt="" width="350px" />
          </div>
          <div className="learn-skills">
            <h3>Learn Skills</h3>
            <br />
            <div className="text3">
              <li>
                There are many skills that you can learn from club live
                sessions, depending on the specific focus of the club and the
                goals of the sessions.Some examples of skills that you might
                learn from club live sessions include:
                <br />
                <li>Leardership,Communication,Collaboration</li>
                <br />
                <li>Problem-solving,Time management,Adaptability</li>
              </li>
            </div>
          </div>
        </div>
        <br />
      </div>
      <FooterPage />
    </div>
  );
}
