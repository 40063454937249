import React from "react";
import { Card, Header, Icon, Label } from "semantic-ui-react";

export default function EventDetailedInfo({ event }) {
  return (
    <>
      <Header
        as={"h2"}
        icon="info circle"
        content={`All that you need to know about ${event.eventTitle}`}
      />
      <p>{event.description}</p>
      {event.guidelines.length > 0 && (
        <div>
          <h4>Rules &amp; Guidelines for {event.eventTitle}:</h4>
          <ul>
            {event.guidelines.map(
              (guideline, i) =>
                guideline.guideline && <li key={i}>{guideline.guideline}</li>
            )}
          </ul>
        </div>
      )}
      {event.eventType === "contest" && event.rewards.length > 0 && (
        <div>
          <Header
            as={"h2"}
            icon="trophy"
            content="What's at stake - Rewards and Prizes?"
            style={{ marginBottom: 20 }}
          />
          <Card.Group>
            {event.rewards.map((reward) => (
              <Card key={reward._id}>
                <Card.Content>
                  <Card.Header>{reward.rank}</Card.Header>
                  {reward.certificate && (
                    <Label
                      style={{ top: "-10px" }}
                      ribbon="right"
                      color="green"
                      content="certificate"
                    />
                  )}
                </Card.Content>
                <Card.Content extra>
                  <Icon name="rupee" />
                  <strong>{reward.money}</strong>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </div>
      )}
    </>
  );
}
