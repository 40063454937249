import { Form, Formik } from "formik";
import React from "react";
import ModalWrapper from "../../../../app/common/modals/ModalWrapper";
import MyTextArea from "../../../../app/common/form/MyTextArea";
import { Button, Container, Header, Image } from "semantic-ui-react";
import { useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import { getFile, isImage, isVideo } from "../../../../app/common/util/util";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ControlBar, Player } from "video-react";
import { closeModal } from "../../../../app/common/modals/modalReducer";
import { uploadFileApi } from "../../../../app/apiRequest/extraService";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../../../app/async/asyncReducer";
import { sendChannelChatApi } from "../../../../app/apiRequest/channelService";
import { compressImg } from "../../../../app/common/photos/compressImg";

export default function DiscussionForm() {
  const dispatch = useDispatch();
  const hiddenImageInput = useRef(null);
  const hiddenVideoInput = useRef(null);
  const [filedata, setFiledata] = useState(null);
  const [filetype, setFiletype] = useState("text");
  const { room, club, socket } = useSelector((state) => state.modal.modalProps);
  const { loading } = useSelector((state) => state.async);

  const handleClick = (e, { name }) => {
    name === "addImage"
      ? hiddenImageInput.current.click()
      : hiddenVideoInput.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    if (isImage(file.name)) {
      setFiledata(file);
      setFiletype("image");
    } else {
      toast.error("select image");
    }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    console.log(file.size);
    if (file.size > 5e7) toast.error("File size must be less than 50MB !");
    if (isVideo(file.name)) {
      setFiledata(file);
      setFiletype("video");
    } else {
      toast.error("select video");
    }
  };

  const initialValues = {
    title: "",
    chat: "",
    club: club,
    channel: room,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(),
    chat: Yup.string(),
  });

  return (
    <ModalWrapper size="tiny" header="Start a discussion">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values.filetype = filetype;
            if (filetype === "text") {
              sendChannelChatApi(values).then(
                (response) => {
                  values._id = response._id;
                  socket.emit("chatMessage", values);
                },
                (error) => {
                  console.log("send chat ", error.message);
                  alert(
                    "Your message could not be sent\nError: " + error.message
                  );
                }
              );
            } else {
              var data = new FormData();
              await compressImg(URL.createObjectURL(filedata)).then(
                async (compressedDataUrl) => {
                  let file = await getFile(compressedDataUrl, filedata.name);
                  data.append("imageFile", file);
                }
              );
              await dispatch(uploadFileApi(data)).then((response) => {
                dispatch(asyncActionFinish());
                console.log(response);
                values.filename = filedata.name;
                values.filesize = filedata.size;
                values.realFiletype = filedata.type;
                values.file = response[0].location;
                sendChannelChatApi(values).then(
                  (response) => {
                    values._id = response._id;
                    socket.emit("chatMessage", values);
                  },
                  (error) => {
                    console.log("send chat ", error.message);
                    alert(
                      "Your message could not be sent\nError: " + error.message
                    );
                  }
                );
              });
            }
          } catch (error) {
            dispatch(asyncActionError(error));
            console.log(error);
          } finally {
            dispatch(closeModal());
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form" style={{ marginTop: -10 }}>
            <Header
              as={"h4"}
              color="black"
              content="What do you want to discuss about?"
              style={{ marginTop: 10, marginBottom: 5 }}
            />
            <MyTextArea
              name="title"
              placeholder="Write something here..."
              rows="3"
            />
            <Header
              as={"h4"}
              color="black"
              content="Your discussion subtitle (optional)"
              style={{ marginTop: 10, marginBottom: 5 }}
            />
            <MyTextArea
              name="chat"
              placeholder="Write something here..."
              rows="4"
            />
            {filetype === "image" && (
              <Image src={URL.createObjectURL(filedata)} />
            )}
            {filetype === "video" && (
              <Container>
                <Player playsInline fluid={false} width="100%" height={300}>
                  <source src={URL.createObjectURL(filedata)} />
                  <ControlBar autoHide={true} />
                </Player>
              </Container>
            )}
            <Button
              loading={isSubmitting || loading}
              disabled={!isValid || !dirty || isSubmitting || loading}
              type="submit"
              floated="right"
              size="large"
              color="teal"
              content="Post"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </Form>
        )}
      </Formik>
      <Button.Group size="small" style={{ marginTop: 10, marginBottom: 10 }}>
        <Button
          name="addImage"
          icon="file image"
          content="Add Images"
          color="green"
          onClick={handleClick}
        />
        <input
          type="file"
          ref={hiddenImageInput}
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        <Button
          icon="file video"
          content="Add Video"
          color="blue"
          name="addVideo"
          onClick={handleClick}
        />
        <input
          type="file"
          name="videofile"
          ref={hiddenVideoInput}
          onChange={handleVideoChange}
          style={{ display: "none" }}
        />
      </Button.Group>
    </ModalWrapper>
  );
}
