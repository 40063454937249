import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, FormRadio, Label } from "semantic-ui-react";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import ModalWrapper from "../../../../app/common/modals/ModalWrapper";
import { closeModal } from "../../../../app/common/modals/modalReducer";
import { useSelector } from "react-redux";
import { asyncActionError } from "../../../../app/async/asyncReducer";
import { createChannelApi, updateChannelApi } from "../../../../app/apiRequest/channelService";

export default function ChannelForm() {
  const dispatch = useDispatch();
  const [privacyRadioValue, setPrivacyRadioValue] = useState({
    name: "standard",
  });
  const [permissionRadioValue, setPermissionRadioValue] = useState({
    name: "public",
  });
  const { loading } = useSelector((state) => state.async);
  const { club, channel } = useSelector((state) => state.modal.modalProps);

  const initialValues = channel ?? {
    name: "",
    club: club._id,
  };

  useState(() => {
    if (channel) {
      setPrivacyRadioValue({ name: channel.privacy });
      if (channel.permission === "private") {
        setPermissionRadioValue({ name: "admin" });
      } else {
        setPermissionRadioValue({ name: "public" });
      }
    }
  }, [channel]);

  const validationSchema = Yup.object({
    name: Yup.string().required(),
  });

  const handlePrivacyRadioChange = (e, { name }) => {
    setPrivacyRadioValue({ name });
  };

  const handlePermissionRadioChange = (e, { name }) => {
    setPermissionRadioValue({ name });
  };

  return (
    <ModalWrapper size="small" header="Add channel">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values.privacy = privacyRadioValue.name;
            if (permissionRadioValue.name === "admin") {
              values.permission = "private";
            } else values.permission = "public";
            if(channel) dispatch(updateChannelApi(values))
            else dispatch(createChannelApi(values));
          } catch (error) {
            dispatch(asyncActionError(error));
            setErrors({ error });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyTextInput
              name="name"
              placeholder="Channel name"
              label={"Channel Name"}
            />
            <FormGroup inline>
              <label>Privacy</label>
              <FormRadio
                label="Standard"
                checked={privacyRadioValue.name === "standard"}
                onChange={handlePrivacyRadioChange}
                name="standard"
              />
              <FormRadio
                label="Private"
                checked={privacyRadioValue.name === "private"}
                onChange={handlePrivacyRadioChange}
                name="private"
              />
            </FormGroup>
            <p>* Standard - Accessible to everyone on the club (default)</p>
            <p>
              * Private - Accessible onlt to specific group of people within the
              club{" "}
            </p>
            <FormGroup inline>
              <label>Channel permissions to write messages</label>
              <FormRadio
                label="Only Admin"
                checked={permissionRadioValue.name === "admin"}
                onChange={handlePermissionRadioChange}
                name="admin"
              />
              <FormRadio
                label="All members"
                checked={permissionRadioValue.name === "public"}
                onChange={handlePermissionRadioChange}
                name="public"
              />
            </FormGroup>

            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <div>
              <Button
                loading={isSubmitting || loading}
                disabled={!isValid || !dirty || isSubmitting || loading}
                type="submit"
                floated="right"
                color="teal"
                content="Next"
                style={{ margin: 10 }}
              />
              <Button
                content="Cancel"
                floated="right"
                type="button"
                style={{ margin: 10 }}
                onClick={() => dispatch(closeModal())}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
